// @flow

import React, { useState, useEffect } from 'react'
import LinkHP from './LinkHP'
import LinkExternalHP from './LinkExternalHP'
import { Link } from 'gatsby'
import shouldScrollToAnchor from '../lib/shouldScrollToAnchor'

type Props = {
  href?: string,
  to?: string,
  style?: string,
  target?: string,
  title: string,
  padded?: boolean,
  name?: string,
  textClasses?: string,
  dataLayer?: Object,
  className?: string,
  onClick?: Function,
}

const styles = {
  black: {
    div:
      'inline-block border text-white border-black bg-black hover:bg-white hover:border-white hover:text-black transition duration-250',
  },
  shop: {
    div:
      'font-bold inline-block border text-white border-blue bg-blue hover:bg-black hover:border-black hover:text-white transition duration-250',
  },
  blackOutlined: {
    div:
      'inline-block border text-white border-black bg-black hover:bg-white hover:border-black hover:text-black transition duration-250',
  },
  blackWithWhiteOutlined: {
    div:
      'inline-block border text-white border-white bg-transparent hover:bg-white hover:border-black hover:text-black transition duration-250',
  },
  outlineOnly: {
    div:
      'inline-block border-2 text-black border-black bg-transparent hover:bg-black hover:text-white transition duration-250',
  },
  white: {
    div:
      'inline-block border text-black border-white bg-white hover:bg-black hover:text-white transition duration-250',
  },
  outlineWhite: {
    div:
      'inline-block border text-black border-white hover:bg-white hover:text-black transition duration-250',
  },
  whiteOutlineBlack: {
    div:
      'inline-block border text-black border-black transition duration-250 hover:bg-black hover:text-white',
  },
  simple: {
    div: 'underline hover:underline',
  },
}

const Button = ({
  style = 'none',
  title,
  to,
  target,
  href,
  padded = false,
  name,
  textClasses,
  dataLayer,
  className = '',
  onClick,
}: Props) => {
  const [samePage, setSamePage] = useState(false)
  const linkClassName = `block w-full h-full ${
    textClasses ? textClasses : 'text-20px'
  } ${
    !padded ? 'py-2 px-4 md:px-4 lg:px-6 md:py-3' : 'md:px-6 md:py-2 px-4 py-2'
  }`

  useEffect(() => {
    if (
      (typeof window !== 'undefined' &&
        typeof to === 'string' &&
        to.indexOf('#') === 0) ||
      (!to && !href)
    ) {
      setSamePage(true)
    }
  }, [])

  const handleOnClick = e =>
    typeof onClick === 'function' ? onClick() : shouldScrollToAnchor(e, to)

  return (
    <div
      data-name={name}
      className={`${styles[style] ? styles[style].div : ''} ${className}`}
    >
      {href && href.length > 0 ? (
        <LinkExternalHP
          target={target}
          className={linkClassName}
          href={href}
          dataLayer={dataLayer}
        >
          {title}
        </LinkExternalHP>
      ) : (
        <div>
          {!samePage ? (
            <LinkHP className={linkClassName} to={to} dataLayer={dataLayer}>
              {title}
            </LinkHP>
          ) : (
            <span
              className={`cursor-pointer ${linkClassName}`}
              onClick={handleOnClick}
              dataLayer={dataLayer}
            >
              {title}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default Button
