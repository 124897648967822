// @flow
import React from "react"
import type { ApiImage } from "../types"
import Appear from "./Appear"
import Button from "./Button"
import Container from "./Container"
import Grid from "./Grid"
import Html from "./Html"
import LazyImage from "./LazyImage"
import makeLayer from "../lib/makeLayer"

type Props = {
  text: string,
  image: ApiImage,
  ctaText: string,
  ctaUrl: string,
  gradientStart?: string,
  gradientEnd?: string,
  textColor: string,
  subtitleColor: string,
  ctaStyle: string,
  type: string,
  whiteParentBG?: boolean,
  anchor: string,
}

const CategoryBlock13 = ({
  moduleName,
  text,
  image,
  ctaText,
  ctaUrl,
  gradientStart = "",
  gradientEnd = "",
  title,
  subtitle,
  subtitleMarkdown,
  textColor="1A1A1A",
  subtitleColor,
  ctaStyle,
  type,
  whiteParentBG = false,
  anchor,
}: Props) => {
  subtitleColor = subtitleColor ? subtitleColor : textColor

  let wrapperBackgroundColor = ""
  if (gradientStart === gradientEnd) wrapperBackgroundColor = gradientEnd
  if (wrapperBackgroundColor && wrapperBackgroundColor.match(/^\d/))
    wrapperBackgroundColor = "#" + wrapperBackgroundColor

  const content = (
    <>
      <div className="w-full ">
        {title && (
          <Appear>
            <h4
              className="mt-2 mb-6 md:mb-8 text-big4 md:w-2/3 px-5 md:px-8"
              style={
                textColor ? { color: `#${textColor.replace("#", "")}` } : {}
              }
            >
              {title}
            </h4>
          </Appear>
        )}
      </div>
      { title && <div className="h-px w-full bg-white mb-6 md:mb-8"></div> }
      <Grid className="px-5 md:px-8">
        <div
          className={`col-span-4 col-start-1 my-4 md:flex md:flex-col md:justify-center  md:mb-0 ${type === "vertical"  ? "md:col-start-8 md2:col-start-7  md:col-span-4 md2:col-span-4" : "md:col-start-7 md:col-span-5"}`}
        >
          {subtitle && (
            <Appear>
              <h6
                className="mb-8 text-big1"
                style={
                  subtitleColor
                    ? { color: `#${subtitleColor.replace("#", "")}` }
                    : {}
                }
              >
                {subtitle}
              </h6>
            </Appear>
          )}
          {subtitleMarkdown && (
            <Appear>
              <div
                className="mb-4"
                style={
                  textColor ? { color: `#${textColor.replace("#", "")}` } : {}
                }
              >
                <Html text={subtitleMarkdown} />
              </div>
            </Appear>
          )}
          <Appear>
            <div
              className={`${type === "vertical" ? "text-big2" : "text-base"}`}
              style={textColor ? { color: `#${textColor.replace("#", "")}` } : {}}
            >
              <Html text={text} />
            </div>
          </Appear>
          {ctaText.length > 0 && (
            <div className="mt-8">
              <Appear>
                <Button
                  dataLayer={makeLayer({
                    event: "e_contactClick",
                    linkPlacement: "content-webinar-download",
                    linkID: ctaUrl,
                    linkClass: "webinar",
                  })}
                  style="black"
                  title={ctaText}
                  href={ctaUrl}
                />
              </Appear>
            </div>
          )}
        </div>
        <div
          className={`col-span-4 col-start-1 mb-8 ${
            whiteParentBG ? "text-black" : "text-white"
          } md:flex md:flex-col md:justify-center md:col-span-6 md2:col-span-5 md:col-start-1 md:mb-0 md:row-start-1`}
        >
          <LazyImage image={image} alt={text} />
        </div>
      </Grid>
    </>
  )

  return (
    <div
      className={
        gradientStart ? "py-4 md:py-8 lg:py-8" : "pt-4 md:pt-12 lg:pt-12 pb-4 md:pb-12 lg:pb-12"
      }
    >
      <Container customPadding="0" className="relative">
        {type === "printer" && (
          <Grid >
            <div
              className="col-span-4 col-start-1 md:col-span-12 md:col-start-1"
              data-anchor={anchor}
              style={
                gradientStart && gradientStart.length > 0
                  ? {
                      backgroundImage: `linear-gradient(-45deg, #${gradientStart.replace(
                        "#",
                        ""
                      )} 0%, #${gradientEnd.replace("#", "")} 100%)`,
                    }
                  : { backgroundColor: "#efefef" }
              }
            >
              {content}
            </div>
          </Grid>
        )}

        {type !== "printer" && (
          <Grid className="relative">
            <div
              className={`col-span-4 col-start-1 md:col-span-9 md:col-start-4 ${
                type === "vertical" ? "px-0" : "md:p-18"
              }`}
              data-anchor={anchor}

            >
              {content}
            </div>
          </Grid>
        )}
      </Container>
    </div>
  )
}

export default CategoryBlock13
