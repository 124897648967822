// @flow

import React from 'react'
import Container from './Container'
import Spacer from './Spacer'


type Props = {
  url: string
}

const EloquaForms = ({ url }: Props) => {
  return (
    <Spacer top bottom>
      <Container>
        <div className="h-1200px md:h-100px">
          <iframe
            className="w-full h-full overflow-visible"
            src={url}
          ></iframe>
        </div>
      </Container>
    </Spacer>
  )
}

export default EloquaForms
