// @flow

import React, { useEffect } from 'react'
import { getCookie } from '../lib/cookies'
import { navigate } from 'gatsby'
require('intersection-observer')

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'

// OR: may also need to set as global property
global.EventSource = NativeEventSource || EventSourcePolyfill

type Props = {
  children: React$Node
}

const Layout = ({ children }: Props) => {
  useEffect(() => {
    const cookie = getCookie('country')
    if (window.location.pathname === '/' && cookie) {
      navigate(cookie)
    }
  }, [])
  return (
    <main className="relative font-primary text-normal">{children}</main>
  )
}

export default Layout
