// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import TitleUnderlines from './TitleUnderlines'

type Props = {
  position: string,
  title: string,
  text: string,
}

const FeaturedText = ({ title, text }: Props) => {
  // Asi accedes a los literals y a media
  // const literals = useLiterals()
  // const media = useMedia()
  return (
    <div>
      <Container customPadding="px-0" backgroundColor="bg-lightBeige22" className="relative z-10 w-full py-6 ms:py-12">
        <Appear>
          <div className="w-full text-40px md:text-80px">
            <TitleUnderlines borderColor="font-secondary border-black" withoutSecondBorder featuredText>{title}</TitleUnderlines>
          </div>
        </Appear>
      </Container>
      <Container backgroundColor="bg-lightBeige22" className="relative z-10 w-full">
        <Grid>
          <div className="col-span-4 col-start-1 py-6 text-left md:col-span-7 md:col-start-1 md:pb-10">
            <Appear>
              <div className=" text-30px leading-9 font-secondary"><Html text={text} /></div>
            </Appear>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default FeaturedText