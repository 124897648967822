// @flow

import React, { useEffect } from 'react'
import Spacer from './Spacer'
import Appear from './Appear'
import Grid from './Grid'
import Button from './Button'
import LazyImage from './LazyImage'
import Container from './Container'
import Markdown from './Markdown'
import makeAssetUrl from '../lib/makeAssetUrl'
import Video from './Video'
import makeLayer from '../lib/makeLayer'

type Props = {
  image: Object,
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  image2: Object,
  image3: Object,
  image3right?: Object,
  text2: string,
  text2right?: string,
  ctaText2: string,
  ctaText2right?: string,
  ctaUrl2: string,
  ctaUrl2right?: string,
  videoUrl: string,
  videoPlaceholder: string,
  videoTitle: string,
}

const PolestarInfo = ({
  image,
  title,
  text,
  ctaText,
  ctaUrl,
  image2,
  image3,
  image3right,
  text2,
  text2right,
  ctaText2,
  ctaText2right,
  ctaUrl2,
  ctaUrl2right,
  pttId,
  videoUrl,
  videoPlaceholder,
  videoTitle
}: Props): React$Node => {

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-menuid="${menuId}"`)
    const yOffset = 0
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      const parts2 = lastPart.split('_')
      if (parts2.length > 0) {
        setTimeout(() => {
          shouldScrollToId(parts2[0])
        }, 1000)
      }
    }
  }, [])

  const twoInACol = (image3right && Object.keys(image3right).length > 0) || (ctaText2right && ctaText2right.length > 0) || (text2right && text2right.length > 0) || (ctaUrl2right && ctaUrl2right.length > 0 )

  const content = (type) => (
    <div>
      <div className="md:grid md:grid-cols-11 md:gap-0">
        <Appear className="col-span-4 md:col-start-1 md:col-span-12 h-50vw md:h-60vh">
          <LazyImage image={image2} alt={title} cover />
        </Appear>
        <Appear className="col-span-4 md:col-start-1 md:col-span-11 md:flex">
          <h2 className="w-full md:pb-8 md:pt-8 text-big3 px-8 md:px-10 py-8 md:w-30/100 bg-blueSld">{title}</h2>
          <div className="md:w-70/100 md:px-10 p-8 bg-lightBlue23">
            <Markdown text={text} className="pb-8 font-light" />
            <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaUrl})} target="_blank" title={ctaText} href={ctaUrl} style="blackOutlined"/>
          </div>
        </Appear>
        <Appear className="flex flex-col col-span-4 font-light md:flex-row md:col-start-1 md:col-span-11 bg-orangeSld">
          <LazyImage image={image3} alt={ctaText2} cover className="md:w-30/100"/>
          <div className="md:w-70/100">
            <div className="p-8">
              <Markdown text={text2} className="pb-8 font-normal leading-tight md:pt-0 md:pb-8"/>
              <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaUrl2})} target="_blank" title={ctaText2} href={ctaUrl2} style="blackOutlined" textClasses="text-left font-normal"/>
            </div>
          </div>
        </Appear>
        {twoInACol && <Appear className="flex flex-col col-span-4  font-light md:mt-0 md:flex-row md:col-start-1 md:col-span-11 bg-lightLime22">
          {(image3right && Object.keys(image3right).length > 0) && <LazyImage image={image3right} alt={ctaText2right} className="md:w-30/100"/>}
          <div className="flex flex-col justify-start md:w-70/1000">
            <div className="p-8">
              {text2right.length > 0 && <Markdown text={text2right} className="pb-8 font-normal leading-tight md:pt-0 md:pb-8"/>}
              {ctaText2right.length > 0 && ctaUrl2right.length > 0 && <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaUrl2right})} target="_blank" title={ctaText2right} href={ctaUrl2right} style="blackOutlined" textClasses="text-center font-normal"/>}
            </div>
          </div>
        </Appear>}
      </div>
      {videoUrl &&
        <div className="pt-8 md:pt-20 md:px-0 md:-mb-1"
        data-anchor-name="video" >
          {videoTitle && <div>
              <h3 className="px-6 pb-6 text-big3 w-full md:w-1/2">{videoTitle}</h3>
              <div className="border border-b bg-black mb-6 w-full"/>
            </div>
          }
          <Appear>
            <Video centerPlay={true} url={videoUrl} extraId={pttId+type} image={videoPlaceholder} noGrid={true} />
          </Appear>
        </div>
      }
    </div>
  )
  return (
    <div
      className="h-full pt-6 pb-12 my-10 md:pt-0 md:pb-0"
      data-menuid="sustainability"
      style={{
        backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Spacer top bottom className="relative">
        <Container className="hidden lg:block w-full mx-auto" customPadding="px-4 lg:pl-48 lg:pr-0"> 
          <Grid className="w-full h-full">
            <div className="h-full col-span-12 bg-white w-full">
              {content('desktop')}
            </div>
          </Grid>
        </Container>
        <Grid className="w-full h-full lg:hidden">
          <div className="h-full col-span-4 ml-4 bg-white md:ml-0 md:col-span-11 md:col-start-2">
            {content('mobile')}
          </div>
        </Grid>
      </Spacer>
    </div>
  )
}

export default PolestarInfo
