// @flow
import React from 'react'

type Props = {
  label: string,
  placeholder?: string,
  error: boolean,
  onChange: Function,
  onBlur: Function,
  id: string,
  className?: string,
  value: string
}

const TextArea = ({
  label,
  placeholder,
  error,
  onChange,
  id,
  className = '',
  value,
  onBlur
}: Props): React$Node => (
  <div className={`flex flex-col ${className}`}>
    <label className="pb-2 font-light">{label}</label>
    <textarea
      className={`border border-1 ${
        error ? 'border-red' : 'border-black'
      } outline-none h-24 p-2 font-light`}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      name={id}
      value={value}
      id={id}
    />
  </div>
)

export default TextArea
