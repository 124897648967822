// @flow
import React, { useEffect, useState } from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import LazyImage from './LazyImage'
import LionRedSquare from './LionRedSquare'
import useIsMobile from '../hooks/useIsMobile'
import type { ApiImage } from '../types'

type Props = {
  title: string,
  text: string,
  image: ApiImage,
  reverse?: boolean,
  bottomSquares?: boolean,
}

const LionMasonryItem = ({
  title,
  text,
  image,
  reverse = false,
  bottomSquares = false
}: Props) => {
  const isMobile = useIsMobile()
  const [screenWidth, setScreenWidth] = useState()
  const [negativeMargin, setNegativeMargin] = useState(0)
  
  const textBlockStyles = reverse
  ? "col-start-1 md:col-start-8 col-span-4 md:row-start-1"
  : "col-start-1 col-span-4 md:col-start-2 md:col-span-4 md:flex md:flex-col md:justify-center"

  const imageStyles = reverse
  ? "col-start-1 col-span-3 md:col-start-3 md:col-span-4 md:row-start-1"
  : "col-start-2 col-span-3 md:col-start-8 md:col-span-5 mb-8 md:mb-0"

  const handleResize = () => {
    const width = document.documentElement.clientWidth || document.body.clientWidth
    let column
    if (width > 1600) {
      column = 1600 / 12
    } else if (width > 1800) {
      column = 1800 / 12
    } else {
      column = width / 12
    }
    setScreenWidth(width)
    setNegativeMargin(column)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
  }, [screenWidth])


  return (
    <Container
      backgroundColor="bg-realBlack"
      className={!bottomSquares && !reverse ? "pb-10 md:pb-40" : ""}
    >
      {!reverse && negativeMargin && (
      <Grid
        noGap
        style={{
          marginTop: negativeMargin && !reverse && !isMobile ? -negativeMargin : 0
          }}
        >
        <div className="flex justify-end md:col-span-1 md:col-start-7 md:block">
          <Appear>
            <LionRedSquare width={isMobile && 50} />
          </Appear>
        </div>
      </Grid>)}
      <Grid noGap>
        <div className={`text-white ${reverse ? 'row-start-3' : 'row-start-2'} md:row-start-1 ${textBlockStyles}`}>
          <h3 className="w-2/3 mb-6 font-light leading-tight md:text-40px text-24px md:mb-10">
            <Appear>
              <Html text={title} />
            </Appear>
          </h3>
          <div className="mb-12 font-light leading-tight md:text-24px md:mb-0">
            <Appear>
              <Html text={text} />
            </Appear>
          </div>
        </div>
        {reverse && isMobile && <div className="col-span-1 col-start-4 row-start-2">
          <LionRedSquare width={50} />
        </div>}
        <div className={`${imageStyles} row-start-1`}>
          <Appear>
            <LazyImage image={image} />
          </Appear>
        </div>
      </Grid>
      {reverse && !isMobile && <Grid noGap>
        <div className="md:col-span-2">
          <LionRedSquare />
        </div>
      </Grid>}
      {!reverse && bottomSquares && (
        <Grid noGap>
          <div className="col-span-1 row-start-1 md:col-span-2 md:col-start-6">
            <Appear>
              <LionRedSquare />
            </Appear>
          </div>
          <div className="col-span-1 col-start-2 row-start-2 md:col-span-1 md:col-start-5">
            <Appear>
              <LionRedSquare width={isMobile && 50} />
            </Appear>
          </div>
        </Grid>
      )}
    </Container>
  )
}

export default LionMasonryItem