// @flow
import React from 'react'
import Button from './Button'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from './Markdown'
import colorCodeFormat from '../lib/colorCodeFormat'
import Appear from './Appear'

type Props = {
  title: string,
  text: string,
  align: "left" | "right",
  primaryColor: string,
  secondaryColor?: string,
  image: ApiImage,
  ctaText1: string,
  ctaUrl1: string,
  ctaText2: string,
  ctaUrl2: string,
  textColor: string,
  footerText: string,
  anchor?: string,
} 

const PrinterDiscover = ({
  title,
  text,
  image,
  align,
  primaryColor,
  secondaryColor,
  footerText,
  ctaText1,
  ctaText2,
  ctaUrl1,
  ctaUrl2,
  textColor="#000000",
  anchor,
}: Props) => {    
  return (
    <Grid noGap={true}>
      <div
        data-anchor={anchor}
        style={{
          background: primaryColor ? colorCodeFormat(primaryColor) : null,
          color: colorCodeFormat(textColor)
        }}
        className={` col-span-4 md:col-span-5 ${align === "right" ? "md:order-1" : "md:order-2"} flex flex-col justify-between`}>
          <div className='h-full'>
            <div
              style={{
                background: colorCodeFormat(secondaryColor)
              }}
              className={`px-4 pb-8 pt-12 ${secondaryColor !== "" ? "" : "border-b border-current" }`}>
              <Appear><Markdown text={title} className='leading-tight text-30px md:text-40px md:w-5/6'/></Appear>
            </div>
            <Appear><Markdown className='px-4 pt-8 pb-12 md:w-5/6' text={text} /></Appear>
          </div>
          {footerText && (
            <div className='px-4 py-8 border-t border-current'>
              <Appear>
                <Markdown className='w-2/3 mb-6' text={footerText}/>
                <div className='flex gap-4'>
                    <Button
                      style="black"
                      title={ctaText1}
                      href={ctaUrl1}
                    />
                    <Button
                      style="black"
                      title={ctaText2}
                      href={ctaUrl2}
                    />
                </div>
              </Appear>
            </div>
            )}
      </div>
      <div className={`col-span-4 md:col-span-7 ${align === "left" ? "md:order-1" : "md:order-2" }`}>
          <LazyImage cover image={image} alt={title} />
      </div>
    </Grid>
  )
}

export default PrinterDiscover