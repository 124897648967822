// @flow
import React, { useState, useEffect } from 'react'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import youtubeParser from '../lib/youtubeParser'
import { useMedia } from 'store/'
import type { ApiImage } from '../types'
import loadYoutube from "../lib/loadYoutube"

type Props = {
  url: string,
  image: ApiImage,
  id: string | number,
}

const Youtube = ({ url, image, id }: Props) => {
  const [playing, setPlaying] = useState(false)
  const [player, setPlayer] = useState({})

  const togglePlaying = () => {
    if (!playing) {
      setPlaying(true)
      player.playVideo()
      window.dataLayer = window.dataLayer || []  
      window.dataLayer.push({
        event: 'e_videoPlay',
        videoName: url,
      })
    }
  }

  const loadVideo = () => {
    loadYoutube(() => {
      setPlayer(
        new window.YT.Player(`youtube-player-${id}`, {
          videoId: youtubeParser(url),
          rel: 0,
          showinfo: 0,
          modestbranding: 1
        })
      )
    })
  }

  useEffect(() => {
    loadVideo()
  }, [])

  return (
    <div className="relative mb-8 cursor-pointer" onClick={togglePlaying}>
      <div className={`absolute h-full w-full ${playing ? 'visible' : 'invisible'}`}>
        <div className={`w-full h-full`} id={`youtube-player-${id}`}></div>
      </div>
      <div className={`${playing ? 'invisible' : 'visible'}`}>
        <LazyImage image={image} alt="Video" />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end justify-start pb-4 pl-4 cursor-pointer">
          <LazySimpleImage image={useMedia('player')} alt="Video" imageClassName="w-12" />
        </div>
      </div>
    </div>
  )
}

export default Youtube
