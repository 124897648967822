// @flow
import React, { useRef } from 'react'
import Bubble from './Bubble'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import TitleCtas from './TitleCtas'
import LazyImage from './LazyImage'
import ScrollViewer from './ScrollViewer'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  texture: Object,
  texture1: Object,
  texture2: Object,
  ctaUrl1: string,
  ctaUrl2: string,
  ctaUrl3: string,
  ctaText1: string,
  ctaText2: string,
  ctaText3: string,
  title: string,
  image360: Array<Object>,
  image: Object,
  imagezoom: Object,
  backgroundImage: Object
}

const DesignJet3 = ({
  title,
  texture,
  ctaUrl1,
  ctaText1,
  ctaText2,
  ctaUrl2,
  ctaText3,
  ctaUrl3,
  image360,
  image,
  backgroundImage,
}: Props) => {
  const viewerParent = useRef({})
  return (
    <Spacer top className="relative">
      <img className="absolute w-full md:hidden" src={`${makeAssetUrl({ asset: backgroundImage, isImage: true})}`} style={{ top: '-10rem', zIndex: -1 }} />
      <Container
        className={`relative overflow-visible md:overflow-visible ${
          image360 ? 'bg-paleYellow' : ''
        }`}
      >
        <Grid className="overflow-visible">
          <TitleCtas
            ctaUrl1={ctaUrl1}
            ctaText1={ctaText1}
            ctaUrl2={ctaUrl2}
            ctaText2={ctaText2}
            ctaUrl3={ctaUrl3}
            ctaText3={ctaText3}
            title={title}
            className="flex flex-col w-full col-span-4 md:items-center md:col-span-12"
          />
          <div
            className="flex justify-center col-span-4 md:col-start-3 md:col-span-8"
            ref={viewerParent}
          >
            {typeof image360 !== 'undefined' ? (
              <ScrollViewer parent={viewerParent} image360={image360} />
            ) : (
              <LazyImage alt={title} image={image} />
            )}
          </div>
        </Grid>
        {texture && (
          <Appear className="absolute w-64 h-64 -top-75p -left-35p md:h-100 md:w-100 md:-left-10p lg:-left-30p md:-top-35p" style={{zIndex: -1}}>
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture, isImage: true})} />
          </Appear>
        )}
      </Container>
    </Spacer>
  )
}

export default DesignJet3
