// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from '../types'
import Button from './Button'

type Props = {
  backgroundColor?: string,
  gradientStart?: string,
  gradientEnd?: string,
  title: string,
  text: string,
  image: ApiImage,
  inverted?: boolean,
  smallImages?: boolean,
  ctaTitle?: string,
  ctaUrl?: string,
  anchor?: string,
}

const PrinterFullImageRight = ({
  backgroundColor = '',
  gradientStart = '',
  gradientEnd = '',
  title,
  text,
  image,
  inverted = false,
  smallImages = false,
  ctaTitle,
  ctaUrl,
  anchor,
}: Props): React$Element<"div"> => {

  const textStart = !inverted ? 'md:col-start-4' : 'md:col-start-9'
  let imgStart = !inverted ? 'md:col-start-8' : 'md:col-start-3'
  let imgWidth = 'md:col-span-5'
  let spacerTop = true
  let spacerBottom = true
  let marginRight = ''
  if (smallImages) {
    imgStart = !inverted ? 'md:col-start-9' : 'md:col-start-4'
    imgWidth = 'md:col-span-4'
    spacerTop = false
    spacerBottom = false
    marginRight = 'mr-6'
  }

  let bgColor = backgroundColor && `#${backgroundColor.replace('#', '')}`
  if (!backgroundColor) {
    if (gradientEnd && gradientStart !== gradientEnd) {
      // Remove former gradient
      bgColor = '#F19880'
    } else {
      bgColor = `#${gradientStart.replace('#', '')}`
    }
  }

  return (
    <div
      style={{backgroundColor: bgColor}}
      data-anchor={anchor}
    >
      <Spacer
        top={spacerTop}
        bottom={spacerBottom}
        className={`relative pt-16 pb-16 md:pt-16 lg:pt-16 md:pb-16 lg:pb-16 ${marginRight}`}
      >
        <Container className="top-0 bottom-0 flex items-center md:absolute md:left-half md:transform md:-translate-x-1/2">
          <Grid>
            <div
              className={`col-span-4 col-start-1 text-white md:col-span-3 ${textStart}`}
            >
              <h3 className="mb-4 text-big1 md:mb-4">
                <Appear>
                  <Html text={title} />
                </Appear>
              </h3>
              <div className="mb-4 font-light text-small">
                <Appear>
                  <Html text={text} />
                </Appear>
              </div>
              {ctaTitle && (
                <Appear>
                  <Button style="white" title={ctaTitle} href={ctaUrl} />
                </Appear>
              )}
            </div>
          </Grid>
        </Container>
        <Container>
          <div className="grid grid-cols-12 gap-0 mt-12 md:mt-0">
            <div
              className={`col-span-12 col-start-1 ${imgWidth} ${imgStart}`}
            >
              <LazyImage image={image} alt={title} />
            </div>
          </div>
        </Container>
      </Spacer>
    </div>
  )
}

export default PrinterFullImageRight