// @flow
import React, { useEffect, useState } from 'react'
import LazyImage from './LazyImage'
import Button from './Button'
import VimeoVideo from './VimeoVideo'
import type { ApiImage } from '../types/ApiImage'

type Props = {
  image1: ApiImage,
  video: string,
  title: string,
  subtitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  ctaText2: string,
  ctaUrl2: string,
  image2: ApiImage,
  coverImage2: Boolean,
}

const HeaderTwoColumns = ({
  image1,
  video,
  title,
  subtitle,
  text,
  ctaText,
  ctaUrl,
  ctaText2,
  ctaUrl2,
  image2,
  coverImage2 = true,
}: Props): React$Node => {
  const [scroll, setScroll] = useState<number>(100)
  const [fontSize, setFontSize] = useState<string | null>(null)
  useEffect(() => {
    const screenHeight = window.innerHeight
    const titleLength = title.length

    if (titleLength > 30) {
      setFontSize('text-28px md2:text-28px lg:text-48px')
    } else if (screenHeight < 800) {
      setFontSize('md2:text-42px md2:text-42px')
    }
  }, [])

  const handleScroll = () => {
    const scrollY = window.scrollY
    setScroll(100 - scrollY / 1.5)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const videoComponent = video && <VimeoVideo url={video} />
  return (
    <div
      style={{ opacity: scroll / 100, display: scroll < 0 && 'none' }}
      className="top-0 left-0 z-10 w-full bg-white opacity-100 md:fixed mt-menu-mobile md:mt-menu-desktop"
    >
      <div className="grid grid-cols-4 md:grid-cols-8">
        <div className="order-2 col-span-4 leading-none border-b border-r border-black md:order-1 md:col-span-3">
          <div className="hidden border-b md:block">
            {video ? (
              videoComponent
            ) : (
              <LazyImage image={image1} alt="Printer" cover={true} />
            )}
          </div>
          <div
            className={`px-4 py-6 border-b break-words ${
              fontSize ? fontSize : 'text-42px md2:text-70px'
            } md:pl-12`}
          >
            {title}
          </div>
          <div
            className={`px-4 py-4 border-b md:pl-12 ${
              fontSize ? 'text-24px lg:30px' : 'text-28px md:text-36px'
            }`}
          >
            {subtitle}
          </div>
          <div className="px-4 py-4 border-b md:pl-12 text-20px md:text-24px">
            {text}
          </div>
          <div className="flex gap-4 px-4 py-4 md:pl-12">
            {ctaText && (
              <Button
                textClasses="text-base md:text-lg md2:text-xl"
                href={ctaUrl}
                title={ctaText}
                style="blackOutlined"
              />
            )}
            {ctaText2 && (
              <Button
                textClasses="text-base md:text-lg md2:text-xl"
                href={ctaUrl2}
                title={ctaText2}
                style="blackOutlined"
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-center order-1 col-span-4 border-b md:order-2 md:col-span-5 bg-cream">
          <LazyImage
            imageClassName={`object-cover object-top h-full md:h-full md:object-center `}
            className={`${!coverImage2 ? 'w-8/10' : 'h-12rem md:h-full'}`}
            image={image2}
            alt="Printer 630"
            draggable={false}
            cover={!!coverImage2}
          />
        </div>
      </div>
    </div>
  )
}

export default HeaderTwoColumns
