import { useEffect, useState } from 'react'
import throttle from '../lib/throttle'

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(undefined)
  const handleResize = throttle(() => {
    const width =
      document.documentElement.clientWidth || document.body.clientWidth
    if (width <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, 100)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return isMobile
}

export default useIsMobile
