// @flow
import React from 'react'
import LazyImage from './LazyImage'
import Html from './Html'
import type { ApiImage } from '../types'
import Button from './Button'
import makeLayer from '../lib/makeLayer'


type Options = {
  image: ApiImage,
  title: string,
  subtitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
}

type Props = {
  id: string,
  module: string,
  options: Options,
}

const TyphoonPrintersItem = ({
  id,
  module,
  options,
}: Props): React$Node => {
  const { image, title, subtitle, text, ctaText, ctaUrl } = options

  return (
    <div className="relative w-full pt-16 mx-2 text-white md:pt-24">
      <div className="absolute top-0 left-0 right-0 w-full mx-auto leading-tight text-center lg:w-2/3 text-22px md:text-30px"><Html text={title} /></div>
      <div className="flex items-center justify-center w-full bg-white">
        <LazyImage image={image} draggable={false} alt={title} />
      </div>
      <div className="flex flex-col items-start justify-start my-8">
        {subtitle && <div className="mb-2 text-lg text-left md:text-20px">{subtitle}</div>}
        <div className="text-base font-light text-left md:text-20px"><Html text={text} /></div>
      </div>
      <Button 
        href={ctaUrl} 
        title={ctaText}  
        style="white" dataLayer={makeLayer({            
        event: 'e_document',
        documentAction: 'download',
        documentID: `polestar-discover-cta`,
        documentType: 'pdf'
       })} />
    </div>
  )
}

export default TyphoonPrintersItem
