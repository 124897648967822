// @flow
import React, { useState, useRef } from 'react'
import Animated from './Animated'
import { useLiterals } from 'store/'

type Item = {
  label: string,
  value: string,
}

type Props = {
  label: string,
  placeholder?: string,
  error: boolean,
  onChange: Function,
  onBlur: Function,
  id: string,
  className?: string,
  value: any,
  items: Array<Item>,
  required?: boolean,
}

const animations = {
  arrowLeft: {
    from: {
      transform: `translateX(-10px) rotate(45deg)`
    },
    to: {
      transform: `translateX(-10px) rotate(-45deg)`
    },
    duration: 0.3
  },
  arrowRight: {
    from: {
      transform: `rotate(-45deg)`
    },
    to: {
      transform: `rotate(45deg)`
    },
    duration: 0.3
  }
}

const Select = ({
  label,
  items,
  onChange,
  onBlur,
  id,
  className = '',
  value,
  error,
  required = true,
}: Props): React$Node => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef(null)
  return (
    <div className={`flex flex-col ${className}`}>
      <label className="pb-2 font-light">{label} {required && '*'}</label>
      <div
        className={`max-w-full md:max-w-auto flex items-center h-10 p-2 font-light bg-white border ${
          error ? 'border-red' : 'border-black'
        } rounded-none`}
      >
        <select
          ref={selectRef}
          className="flex-1 flex-shrink-0 w-full font-light bg-white appearance-none focus:outline-none"
          value={value}
          id={id}
          onChange={e => {
            onChange(e)
            setOpen(false)
          }}
          onFocus={() => {
            setOpen(true)
          }}
          onBlur={e => {
            onBlur(e)
            setOpen(false)
          }}
        >
          <option value="-1">{useLiterals('select')}</option>
          {items.map(item => (
            <option value={typeof item.value !== 'undefined' ? item.value : item} key={typeof item.value !== 'undefined' ? item.value : item}>
              {typeof item.label !== 'undefined' ? item.label : item}
            </option>
          ))}
        </select>
        <div className="relative">
          <Animated
            className="absolute h-px bg-black w-15px"
            type="spring"
            animation={animations.arrowLeft}
            bind={open}
          ></Animated>
          <Animated
            className="h-px bg-black w-15px"
            type="spring"
            animation={animations.arrowRight}
            bind={open}
          ></Animated>
        </div>
      </div>
    </div>
  )
}

export default Select
