// @flow
import React, { useState } from 'react'
import Appear from './Appear'
import Markdown from './Markdown'
import IconChevron from './icons/IconChevron'

type Props = {
  title: string,
  text: string,
}

const FootnotesDropDown = ({ title, text }: Props) => {
  const [openNotes, setOpenNotes] = useState(false)

  return (
    <Appear>
      <div className="border px-4 pt-4 pb-3 mt-10 mb-16">
        <div
          className="flex justify-between cursor-pointer items-center w-full text-18px"
          onClick={() => setOpenNotes(!openNotes)}
        >
          <h4>{title}</h4>
          <div
            className="h-7 w-7 p-2 transition-transform duration-250"
            style={{
              transform: openNotes ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <IconChevron />
          </div>
        </div>

        <div
          className={`${
            openNotes
              ? 'max-h-200px scroller mt-3 md:pr-4 overflow-y-scroll overflow-x-hidden'
              : 'max-h-0 pt-0 overflow-hidden'
          } transition-all duration-250 `}
        >
          <Markdown className="text-14px leading-normal" text={text} />
        </div>
      </div>
    </Appear>
  )
}

export default FootnotesDropDown
