// @flow
import React from 'react'
import ReactMarkdown from 'react-markdown'

type Props = {
  text: string,
  className?: string,
  h1?: Boolean
}

const Markdown = ({ text, className, h1 }: Props): React$Node => {
  let parsedText = text
  parsedText = parsedText.replace(/[ ]*\n/g, "<br> \n\n")

  if (h1) {
    parsedText = `# ${parsedText}`
  }

  return (
    <ReactMarkdown allowDangerousHtml={true} className={`${className} contains-html`} source={parsedText}></ReactMarkdown>
  )
}

export default Markdown