// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import TyphoonPrintersItem from './TyphoonPrintersItem'
import PrintersGallery from './PrintersGallery'

type Props = {
  title: string,
  items: Array<Object>,
}

const TyphoonPrintersDark = ({
  title,
  items,
}: Props) => {
  return (
      <Container backgroundColor="bg-black" className="pt-0 pb-32 overflow-hidden md:py-40">
        <Grid>
          <div className="col-span-4 leading-tight text-center text-white md:col-span-6 md:col-start-4 text-30px md:text-40px">
            <Appear>{title}</Appear>
          </div>
          <div className='col-span-4 col-start-1 mt-20 text-center md:hidden'>
            <Appear>
              <div
                className="ml-12"
              >
                <PrintersGallery printers={items} />
              </div>
            </Appear>
          </div>
          <div className={`${items.length > 2 ? 'col-span-10 col-start-2' : 'col-span-8 col-start-3'} hidden mt-32 md:block`}>
            <div className={`${items.length > 2 ? 'grid-cols-9' : 'grid-cols-8'} grid gap-4`}>
              {items.map(printer => (
                <div className={`${items.length > 2 ? 'col-span-3' : 'col-span-4'}`} key={printer.id}>
                <TyphoonPrintersItem
                  {...printer}
                />
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
  )
}

export default TyphoonPrintersDark
