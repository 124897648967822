// @flow
import React, { useEffect, useRef, useState } from 'react'
import useIsMobile from '../hooks/useIsMobile'
import FeaturedPrinter from './FeaturedPrinter'
import CustomCursor from './CustomCursor'
import type { Ref, FeaturedPrinter as FeaturedPrinterType } from '../types'
import PolestarDiscoverCard from './PolestarDiscoverCard'
import TyphoonPrintersItem from './TyphoonPrintersItem'

let offsetX = 40
let offsetY = 40

const lerp = (min, max, n, decimals = 1) => parseFloat((1 - n) * min + n * max).toFixed(decimals)

type PolestarDiscoverCardType = {
  modelName: string,
  key: string,
  position: number,
  title: string,
  ctaText: string,
  ctaText2: string,
  url: string,
}

type Props = {
  printers: Array<FeaturedPrinterType> | Array<PolestarDiscoverCardType>,
  page?: string,
  type?: "printers" | "printersWithBorders",
  typhoonPrinters?: boolean,
  borders?: boolean
}

let cursorPosition = {
  x: 0,
  y: 0,
}

function getTranslateXY(element) {
  if (element) {
    const style = window.getComputedStyle(element)
    const matrix = new DOMMatrixReadOnly(style.transform)
    return {
        x: matrix.m41,
        y: matrix.m42
    }
  }
}

const PrintersGallery = ({ printers, page, type, typhoonPrinters, borders = true }: Props) => {
  const listContentRef: Ref = useRef(null)
  const cursorRef: Ref = useRef(null)
  const galleryRef: Ref = useRef(null)
  const [itemSlideWidth, setItemSlideWidth] = useState(0)
  const isMobile = useIsMobile()
  let index = 0
  let translateX = 0
  let sliderManager
  let timer

  const [mouseDown, setMouseDown] = useState(false)

  const handleClick = (e) => {
    if (e.type === 'mousedown') setMouseDown(true)
    if (e.type === 'mouseup') setMouseDown(false)
  }

  const handleHover = e => {
    const {
      top,
      left,
      height,
    } = galleryRef.current.getBoundingClientRect()
    if (cursorRef && cursorRef.current) {
      const currentPosition = getTranslateXY(cursorRef.current)
      if (currentPosition) {
        if (e.type === 'mousemove' || e.type === 'mouseenter') {
          cursorRef.current.style.opacity = 1
        } else if (e.type === 'mouseleave') {
          cursorRef.current.style.opacity = 0
        }
        cursorPosition.x = e.clientX - left - offsetX
        cursorPosition.y = e.clientY - top - offsetY - height
        cursorRef.current.style.transform = `translate3d(${lerp(cursorPosition.x, currentPosition.x, 0.1)}px, ${lerp(cursorPosition.y, currentPosition.y, 0.1)}px, 0)`
      }
    }
  }

  const goToSlide = number => {
    if (number < 0) {
      index = 0
    } else if (number > printers.length - 1) {
      index = printers.length - 1
    } else {
      index = number
    }

    const { itemWidth, itemMargin } = itemStyle()

    translateX = -(itemWidth * index + itemMargin * index)
    listContentRef.current.classList.add(
      'transition-transform',
      'duration-500',
      'ease-in-out'
    )
    listContentRef.current.style.transform = `translateX(${translateX}px)`
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (!listContentRef.current) return
      listContentRef.current.classList.remove(
        'transition-transform',
        'duration-500',
        'ease-in-out'
      )
    }, 400)
  }

  const itemStyle = () => {
    let w = type === "printersWithBorders" ? galleryRef.current.offsetWidth : (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)

    let itemWidth = w * 0.6666

    if (w > 1400) {
      itemWidth = w * 0.3333
    } else if (w > 768) {
      itemWidth = w * 0.3333
    }

    const item = listContentRef.current.querySelector('div')
    const style = window.getComputedStyle(item)

    return {
      itemWidth,
      itemMargin: parseFloat(style.marginRight)
    }
  }

  const createHammer = () => {

    const Hammer = require('hammerjs')

    if (!sliderManager) {
      sliderManager = new Hammer.Manager(listContentRef.current)
      sliderManager.add(
        new Hammer.Pan({
          threshold: 0,
          pointers: 0,
          direction: Hammer.DIRECTION_HORIZONTAL
        })
      )
      sliderManager.on('pan', e => {
        if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) return
        const percentage = e.deltaX
        const transformPercentage = translateX + e.deltaX
        listContentRef.current.style.transform = `translateX(${transformPercentage}px)`
        if (e.isFinal) {
          if (e.velocityX > 1) {
            goToSlide(index - 1)
          } else if (e.velocityX < -1) {
            goToSlide(index + 1)
          } else {
            if (percentage <= -(25 / printers.length)) {
              goToSlide(index + 1)
            } else if (percentage >= 25 / printers.length) {
              goToSlide(index - 1)
            } else {
              goToSlide(index)
            }
          }
        }
      })
    }
  }

  const updateItemSlideWidth = () => {
    const { itemWidth } = itemStyle()
    setItemSlideWidth(itemWidth)
  }

  let debouncedTimer = null
  const onResize = () => {
    if (debouncedTimer) {
      clearTimeout(debouncedTimer)
    }
    debouncedTimer = setTimeout(() => {
      updateItemSlideWidth()
      goToSlide(index)
    }, 100)
  }

  useEffect(() => {
    if (printers.length > 0) {
      createHammer()
      updateItemSlideWidth()
      window.addEventListener('resize', onResize)
    }
    return () => {
      if (printers.length > 0) {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [])

  return (
    <div className={type === "printersWithBorders" ? 'px-4 w-full' : ''}>
      <div
        ref={galleryRef}
        className={`
          box-border relative overflow-visible cursor-none
          ${type === 'printersWithBorders' ? '' : 'ml-4'}
          ${((page && page === 'printer') || type === 'printersWithBorders') ? '' : 'md:ml-leftMd lg:ml-leftLg xl:ml-leftXl'}
        `}
        onMouseDown={handleClick}
        onMouseUp={handleClick}
        onMouseMove={handleHover}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <div
          ref={listContentRef}
          className="inline-flex flex-no-wrap"
          style={{
            width: `${itemSlideWidth * printers.length}px`,
            transform: `translateX(0px)`
          }}
        >
          {printers && printers.map((printer, i) => {
            if (printer.modelName && printer.modelName === 'PolestarDiscoverCard') {
              return (
                <PolestarDiscoverCard 
                  {...printer}
                  key={printer.key}
                  width={itemSlideWidth}
                  selectNone={true}
                  i={i}
                />
              )
            }
            //typhoon
            if ((printer.module && printer.module === 'PolestarDiscoverCard') || typhoonPrinters) {
              return (
                <div className="text-left">
                  <PolestarDiscoverCard 
                    {...printer.options}
                    key={printer.id}
                    width={itemSlideWidth}
                    selectNone={true}
                    i={i}
                    type="typhoon"
                    typhoonPrinters={typhoonPrinters}
                  />
                </div>
              )
            }
            if (printer.module && printer.module === 'TyphoonPrintersItem') {
              return <TyphoonPrintersItem {...printer} />
            }
            return (
              <FeaturedPrinter
                {...printer}
                key={`featured-${printer.id}`}
                width={itemSlideWidth}
                selectNone={true}
                i={i}
                type={type}
                borders={borders}
              />
            )
          })}
        </div>
        {!isMobile && (
          <div
            className="transition transition-opacity duration-250"
            ref={cursorRef}
            style={{
              zIndex: 20,
              opacity: 0,
              pointerEvents: 'none',
              position: 'absolute'
            }}
          >
            <CustomCursor shrink={mouseDown} />
          </div>
        )}
      </div>
    </div>
  )
}

export default PrintersGallery
