// @flow

import React, { useState, useEffect } from "react"
import Animated from "./Animated"
import LazyImage from "./LazyImage"
import { useMedia, useLiterals } from "store/"
import type { ApiImage } from "../types"
import Checkbox from "./Checkbox"
import makeAssetUrl from '../lib/makeAssetUrl'
import useDidMount from '../hooks/useDidMount'

type Props = {
  items: any,
  selectedPrinters: Array<string>,
  setSelectedPrinters: Function,
  error: any,
  isMulti: boolean,
}

const animations = {
  plus: {
    from: {
      transform: "rotate(90deg)",
    },
    to: {
      transform: "rotate(0deg)",
    },
    duration: 0.3,
  },
  dropdown: () => {
    if (typeof document !== "undefined") {
      const box = document.querySelector("#items-box")
      let height = 0
      if (box) {
        box.querySelectorAll(".item").forEach(item => {
          height += item.offsetHeight
        })
      }
      return {
        from: {
          "max-height": 0,
          overflow: "hidden",
          height: `${height}px`,
        },
        to: {
          "max-height": `${height}px`,
          height: `${height}px`,
        },
        duration: 0.5,
      }
    }
  },
}

const PrintersSelect = ({
  items,
  selectedPrinters,
  setSelectedPrinters,
  error,
  isMulti,
}: Props): React$Node => {
  const [dropdown, setDropdown] = useState(false)
  const [imagesReady, setImagesReady] = useState(false)
  const [defaultText, setDefaultText] = useState(useLiterals("choose_the_printer_of_your_interest"))
  const [defaultSelect, setDefaultSelect] = useState(false)
  
  useEffect(() => {
    if (selectedPrinters) {
      let itemFound = false
      items.forEach(printer => {
        const itemId = printer.id.toString()
        if (!itemFound && (itemId == selectedPrinters || selectedPrinters.includes(itemId))) {
          setDefaultSelect(printer.title)
          itemFound = true
        }
      })
      if (!itemFound) {
        setDefaultSelect(defaultText)
      }
    } else {
      setDefaultSelect(defaultText)
    }
  }, [])

  const handleClickPrinter = item => {
    setDefaultSelect(item.title)
    !isMulti 
      ? setSelectedPrinters([item.id]) 
      : onTogglePrinter(item.id)
  }

  const onTogglePrinter = printerId => {
    const printerIndex = selectedPrinters.findIndex(
      selectedPrinter => selectedPrinter === printerId
    )

    if (printerIndex !== -1) {
      selectedPrinters.splice(printerIndex, 1)
    } else {
      selectedPrinters.push(printerId)
    }

    setSelectedPrinters(selectedPrinters)
  }

  const handleClickSelect = () => {
    if (!imagesReady) {
      const images = document.querySelectorAll('#items-box img')
      let loadedImages = 0
      if (loadedImages !== images.length) {
        images.forEach(image => {
          if (image.complete) {
            loadedImages += 1
            if (loadedImages === images.length) {
              setDropdown(!dropdown)
              setImagesReady(true)
            }
          } else {
            image.onload = () => {
              loadedImages += 1
              if (loadedImages === images.length) {
                setDropdown(!dropdown)
                setImagesReady(true)
              }
            }
          }
        })
      }
    } else {
      setDropdown(!dropdown)
    }
  }

  return (
    <div>
      <div className={`border  ${error ? "border-red" : "border-black"}  justify-between`}>
        <div
          className="flex justify-between w-full col-span-2 p-4 cursor-pointer"
          onClick={handleClickSelect}
        >
          <span className="font-light">
            {defaultSelect}
          </span>
          <div className="relative flex items-center">
            <Animated
              className="absolute h-px bg-black w-15px"
              type="spring"
              bind={dropdown}
              animation={animations.plus}
            ></Animated>
            <div className="h-px bg-black w-15px"></div>
          </div>
        </div>
        <div id="items-box">
          <Animated
            animation={animations.dropdown()}
            bind={dropdown}
            type="spring"
            className="col-span-2 col-start-1 overflow-hidden border-box"
          >
            {items.map((item, index) => (
              <div
                onClick={() => handleClickPrinter(item)}
                className={`${
                  !index
                    ? "border-t border-b"
                    : `${index !== items.length - 1 ? "border-b" : ""}`
                } item grid grid-cols-12 px-4 py-6`}
                key={item.id}
              >
                <div
                  className={`col-start-1 col-span-1 flex items-center justify-center`}
                >
                  {isMulti ? (
                    <Checkbox 
                      status={selectedPrinters.includes(item.id)} 
                      label={' '}
                    />
                  ) : (
                    <div
                      className={`border ${
                        error ? "border-red" : "border-black"
                      } rounded-full w-4 h-4 ${
                        selectedPrinters.includes(item.id)
                          ? "bg-black"
                          : ""
                      }`}
                    />
                  )}
                </div>
                <div className="self-center hidden md:inline md:col-start-2 md:col-span-4">
                  <img
                    className={`w-full`}
                    src={makeAssetUrl({ asset: item.image, isImage: true, lazy: false })}
                    alt={item.title}
                  />
                </div>
                <div className="col-span-10 col-start-3 md:col-start-7">
                  <span>{item.title}</span>
                  <p className="pt-2 font-light">{item.teaser}</p>
                </div>
              </div>
            ))}
          </Animated>
        </div>
      </div>
    </div>
  )
}

export default PrintersSelect
