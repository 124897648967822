// @flow

import React from 'react'

import Grid from './Grid'
import ModuleIterator from './ModuleIterator'
import Postiframe from './Postiframe'
import PostSharesocial from './PostSharesocial'
import PostSharesocialMobile from './PostSharesocialMobile'

type Props = {
  lateral: Array<any>,
  sidebar: Array<any>,
  contentRight: Array<any>,
  contentLeft: Node,
  childAfterMenu: boolean,
}

const PostWrapper = ({
  children,
  childAfterMenu,
  sidebar,
  contentRight,
}: Props) => {
  const iFrameModule = contentRight?.find(
    item => item.module.toLowerCase() === 'postiframe'
  )
  const shareModule = contentRight?.find(
    item => item.module.toLowerCase() === 'sharesocialnetworks'
  )
  return (
    <>
      <Grid
        className={childAfterMenu ? 'mt-menu-mobile md:mt-menu-desktop' : ''}
      >
        {sidebar?.length > 0 ? (
          <ModuleIterator
            modules={sidebar}
            extraProps={{
              title: iFrameModule?.options?.title ?? '',
              url: iFrameModule?.options?.url ?? '',
            }}
          />
        ) : null}
        <div className="px-4 md:px-0 col-span-4 leading-normal col-start-1 md:col-start-4 md:col-span-6 bg-white">
          {children}
        </div>
        {contentRight?.length > 0 ? (
          <div className="hidden md:flex md:flex-col md:gap-4 h-45rem col-start-10 col-span-2 sticky top-menu-desktop-plus">
            {iFrameModule ? (
              <Postiframe
                blogSidebar
                url={iFrameModule.options.url}
                urlSticky={iFrameModule.options.urlSticky}
              />
            ) : null}
            {iFrameModule && shareModule ? <hr /> : null}
            {shareModule ? (
              <PostSharesocial label={shareModule?.options?.title ?? 'Share'} />
            ) : null}
          </div>
        ) : null}
        <PostSharesocialMobile />
      </Grid>
    </>
  )
}

export default PostWrapper
