// @flow

import React, { useEffect, useState } from 'react'
import ModuleIterator from './ModuleIterator'
import { blogFetchURL } from '../../config'
import { navigate } from 'gatsby'

type Props = { items: Array<Module> }

const BlogWrapper = (props: Props) => {
  const currentSegments =
    typeof window === 'undefined'
      ? ''
      : new URLSearchParams(window?.location?.search).get('segment')
  const currentSubsegments =
    typeof window === 'undefined'
      ? ''
      : new URLSearchParams(window?.location?.search).get('subsegment')

  const [filters, setFilters] = useState(() => ({
    segments:
      currentSegments?.split(',').length > 0
        ? [...new Set(currentSegments.split(','))]
        : [],
    subsegments:
      currentSubsegments?.split(',').length > 0
        ? [...new Set(currentSubsegments.split(','))]
        : [],
  }))

  const [data, setData] = useState(null)

  useEffect(() => {
    if (props.items.length > 0 && !data) return setData(props.items)

    const currentPathname = window?.location?.pathname

    const query = []
    if (filters.segments.length > 0) {
      query.push(`segment=${filters.segments.join(',')}`)
    }
    if (filters.subsegments.length > 0) {
      query.push(`subsegment=${filters.subsegments.join(',')}`)
    }

    const queryString = query.length > 0 ? `?${query.join('&')}` : ''
    navigate(`${currentPathname}${queryString}`, { replace: true })

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${blogFetchURL}${query.length > 0 ? `&${query.join('&')}` : ''}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          }
        )
        if (!response.ok) throw new Error('Error fetching data')
        const data = await response.json()

        setData(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [filters])

  return data ? <ModuleIterator modules={data} extraProps={setFilters} /> : null
}

export default BlogWrapper
