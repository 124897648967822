// @flow
import React from 'react'
import SimpleButton from './SimpleButton'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Markdown from './Markdown'
import Button from './Button'
import makeLayer from '../lib/makeLayer'
import DownloadButton from './DownloadButton'

type Props = {
  image: string,
  position: number,
  title: string,
  subtitle?: string,
  ctaText: string,
  ctaText2: string,
  url: string,
  width: string | number,
  selectNone: boolean,
  i: any,
  small: boolean,
  invertTextColor: boolean,
  wider?: boolean,
  type?: string,
  typhoonPrinters?: boolean,
}

function resolveMargin(ignore: boolean, i: number): string {
  if (!ignore) {
    return i === 0 ? 'md:ml-4 lg:ml-0' : ''
  }
  return ''
}

const PolestarDiscoverCard = ({
  title,
  text,
  subtitle,
  ctaText,
  url,
  ctaText2,
  url2,
  internalUrl = false,
  image = '',
  width,
  i,
  selectNone = false,
  invertTextColor = false,
  small = false,
  wider = false,
  type,
  typhoonPrinters
}: Props): React$Node => {

  if (type === 'typhoon') {
    return (
      <div
        style={width ? { width: `${width}px` } : {}}
        className={`select-none flex-shrink-0 w-full mr-4 ${resolveMargin(false, i)} ${typhoonPrinters ? '' : 'md:mb-32'}`}
      >
        <div className={`mb-8 bg-white ${typhoonPrinters ? 'border border-solid border-paleGray' : ''}`}>
          <LazyImage image={image} alt={title} draggable={!selectNone} />
        </div>
        <div className="text-black">
          <Appear>
            <Markdown text={title} className={`mb-8 text-22px md:text-30px leading-none`} />
            {subtitle && <Markdown text={subtitle} className="mb-4 text-base font-light md:mb-6 md:text-20px md:flex-grow" />}
            <Markdown text={text} className={`text-base font-light ${typhoonPrinters ? 'md:text-base' : 'md:text-20px'} md:flex-grow`} />
          </Appear>
        </div>
         <div className={`flex items-start pt-8`}>
            <Appear>
              <SimpleButton
                href={internalUrl}
                title={ctaText}
                color="blue"
              />
            </Appear>
          </div>
      </div>
    )
  }

  return (
    <div
      style={width ? { width: `${width}px` } : {}}
      className={`select-none flex-shrink-0 w-full mr-4 ${resolveMargin(false, i)}`}
    >
      <div className={`mb-8 bg-grayLight`}>
        <LazyImage image={image} alt={title} draggable={!selectNone} />
      </div>
      <Appear>
        <Markdown text={title} className={`mb-4 ${!small ? 'text-big1' : ''} ${invertTextColor ? 'text-white' : `${wider ? 'text-realBlack' : 'text-gray'}`}`} />
        <Markdown text={text} className="font-light md:flex-grow" />
        { ctaText && url && !internalUrl && 
          <div className="flex items-start justify-start mt-4">
          {wider
            ? <DownloadButton ctaLabel={ctaText} ctaLink={url} />
            : <Button href={url} title={ctaText} style="blackOutlined" dataLayer={makeLayer({
              event: 'e_document',
              documentAction: 'download',
              documentID: `polestar-discover-cta${i + 1}`,
              documentType: 'pdf'
            })} />}
          </div>
        }
        { ctaText && !url && internalUrl && 
          <div className={`flex items-start pt-2`}>
            <Appear>
              <SimpleButton
                href={internalUrl}
                title={ctaText}
                color="blue"
              />
            </Appear>
          </div>
        }
        {ctaText2 && url2 &&
          <div className="flex items-start mt-4">
          {wider
            ? <DownloadButton ctaLabel={ctaText2} ctaLink={url2} />
            : <Button href={url2} title={ctaText2} style="blackOutlined" dataLayer={makeLayer({
              event: 'e_document',
              documentAction: 'download',
              documentID: `polestar-discover-cta2-${i + 1}`,
              documentType: 'pdf'
            })} />}
          </div>
        }
      </Appear>
    </div>
  )
}

export default PolestarDiscoverCard
