/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LinkHP from './LinkHP'
import LinkExternalHP from './LinkExternalHP'
import LazySimpleImage from './LazySimpleImage'
import { useMedia, useLiterals } from 'store/'
import config from '../../config'

type Props = {
  children: React$Node,
  title: string,
  companyName: string,
  industry: Array<any>,
  printers: Array<any>,
  location: string,
  website: string,
  file: any
}

const StoryBlock1 = ({
  children,
  companyName,
  industry,
  printers,
  location,
  website,
  file
}: Props) => {
  return (
    <div className="hidden md:block md:top-0 md:sticky">
      <Spacer top bottom>
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 bg-white pointer-events-auto md:col-span-2 md:col-start-1">
              <div>{useLiterals('technical_data')}</div>
              <div className="mt-8 font-light text-small">
                <div>{useLiterals('industry_:')}</div>
                <div>{industry && industry.map(industry => industry.title).join('; ')}</div>
              </div>
              <div className="mt-8 font-light text-small">
                <div>{useLiterals('company_name')}</div>
                <div>{companyName}</div>
              </div>
              <div className="mt-8 font-light text-small">
                <div>{useLiterals('location')}</div>
                <div>{location}</div>
              </div>
              <div className="mt-8 font-light text-small">
                <div>{useLiterals('hp_equipment')}</div>
                <div>{printers && printers.map(({ title, url }) => <LinkHP className="block mr-2 text-blue" key={url} to={url}>{title}</LinkHP>)}</div>
              </div>
              <div className="mt-8 font-light text-small">
                <div>{useLiterals('website')}</div>
                <LinkExternalHP className="break-words text-blue" href={website ? website : ''} target="_blank">
                  {website && website.length > 0 && website.replace('https://', '').replace('http://', '').replace(/\/+$/, '')}
                </LinkExternalHP>
              </div>
              {file && file.file && (
                <LinkExternalHP
                  className="flex items-center justify-between py-4 mt-8 border-t border-b text-small"
                  href={config.cloudfront.baseURL + file.file}
                  download={true}
                >
                  <span className="flex-shrink-0">
                    <LazySimpleImage
                      image={useMedia('document')}
                      alt={useLiterals('')}
                    />
                  </span>
                  <span className="cursor-pointer">
                    {useLiterals('download_case_study')}
                  </span>
                </LinkExternalHP>
              )}
              <div className="hidden mt-8 md:block">{children}</div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default StoryBlock1
