// @flow

import React from 'react'
import Html from './Html'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import Markdown from './Markdown'

type Props = {
  text: string,
  padding?: 'none' | 'small',
  color?: string,
  type?: string,
}

const Terms = ({ text, padding, color, type }: Props) => {
  const leftPadding =
    padding && padding.length > 0
      ? `${padding === 'small' ? 'md:col-start-2' : 'md:col-start-1'}`
      : `${type === 'printer' ? '' : type === 'solution' ? 'md:col-start-3' : 'md:col-start-4'}`
  return (
    <Spacer>
      <Container
        style={{ color: color && color }}
        className={padding === 'none' ? 'pb-16 sm:pb-24 md:pb-32' : ''}
      >
        <Grid className={padding === 'none' ? 'border-t border-black' : ''}>
          <div
            className={`col-span-4 col-start-1 ${leftPadding} ${type === 'printer' ? 'col-span-12' : 'md:col-span-8'
              } `}
          >
            <Appear>
              <div className={padding === 'none' ? 'mt-10' : type === 'solution' ? 'mt-10' : 'mt-16'}>
                <Markdown text={text} className="text-sm font-light" />
              </div>
            </Appear>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default Terms
