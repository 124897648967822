// @flow
import React, { useRef, useState, useEffect } from 'react'
import type { Ref } from 'types/'
import { navigate } from 'gatsby'

const sections: Array<Obj> = [
  {
    id: 'base',
    path: '/assets/static/base.obj'
  },
  {
    id: '3',
    path: '/assets/static/apparel.obj',
    color: 'CBBE4A',
    x: 0.7406,
    y: 0.7099
  },
  {
    id: '17',
    path: '/assets/static/arquitect.obj',
    color: 'D41A1A',
    x: 0.439,
    y: 0.6266
  },
  {
    id: '4',
    path: '/assets/static/art.obj',
    color: '41D45E',
    x: 0.49375,
    y: 0.3437849
  },
  {
    id: '15',
    path: '/assets/static/decor.obj',
    color: '4BF6FC',
    x: 0.282,
    y: 0.64165
  },
  {
    id: 'printer1',
    path: '/assets/static/print.obj',
    color: '1F21FF',
    x: 0.77656,
    y: 0.44904815
  },
  {
    id: '2',
    path: '/assets/static/signage.obj',
    color: 'FF19FF',
    x: 0.18046875,
    y: 0.36506159
  }
]
const hitboxes = [
  {
    id: 'hit_3',
    path: '/assets/static/hit_apparel.obj',
    color: 'CBBE4A'
  },
  {
    id: 'hit_17',
    path: '/assets/static/hit_arquitect.obj',
    color: 'D41A1A'
  },
  {
    id: 'hit_4',
    path: '/assets/static/hit_art.obj',
    color: '41D45E'
  },
  {
    id: 'hit_15',
    path: '/assets/static/hit_decor.obj',
    color: '4BF6FC'
  },
  {
    id: 'hit_printer1',
    path: '/assets/static/hit_print.obj',
    color: '1F21FF'
  },
  {
    id: 'hit_2',
    path: '/assets/static/hit_signage.obj',
    color: 'FF19FF'
  }
]
const availableSections = sections.map(item => item.id)
const touchableSections = hitboxes.map(item => item.id)

type Item = {
  id: string,
  title: string,
  url: string
}

type Obj = {
  color?: string,
  id: string,
  path?: string,
  x?: number,
  y?: number
}
type Props = {
  inline?: boolean,
  currentId?: string,
  items: Array<Item>
}

const Map360 = ({ inline = false, currentId, items }: Props) => {
  const mainRef: Ref = useRef(null)
  const textRef: Ref = useRef(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [currentText, setCurrentText] = useState(false)

  useEffect(() => {
    const loadedObjects = []
    let size = {
      width: mainRef.current.offsetWidth,
      height: mainRef.current.offsetHeight
    }

    const THREE = require('three')
    const { OBJLoader } = require('three/examples/jsm/loaders/OBJLoader')

    let renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(size.width, size.height)
    mainRef.current.appendChild(renderer.domElement)
    renderer.domElement.style.width = '100%'
    renderer.domElement.style.height = '100%'
    renderer.domElement.style.position = 'absolute'
    renderer.domElement.style.top = '0'
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap

    let scene = new THREE.Scene()
    scene.background = new THREE.Color(0x1a1a1a)

    let camera = new THREE.PerspectiveCamera(
      45,
      size.width / size.height,
      1,
      1000
    )
    camera.position.set(0, 15, 20)
    camera.lookAt(0, 0, 0)

    const light1 = new THREE.PointLight(0xff0040, 0, 10, 1)
    light1.position.set(0, 1, 0)
    scene.add(light1)

    const group = new THREE.Group()
    const hitGroup = new THREE.Group()
    const loader = new OBJLoader()
    const materials = {}
    const colors = {}
    const lightPositions = {}
    const baseMaterial = new THREE.MeshPhongMaterial({
      color: 0x3f5157
    })
    const hitboxMaterial = new THREE.MeshBasicMaterial({
      color: 0x3f5157,
      transparent: true,
      opacity: 0
    })
    const raycaster = new THREE.Raycaster()
    const lerp = (min, max, n) => (1 - n) * min + n * max
    const interpolate = (min, max, n) => (n - min) / (max - min)
    const calculatePercentage = (min, max, n) => (max - min) * n + min

    let intensity = 0
    let mouse = new THREE.Vector3(1, 1, 0)
    let intersects = []
    let interactionEnabled = inline
    let activeObj = null

    const navigateTo = (name, redirect = true) => {
      minify()
      selectSection(name, true, false)
      if (redirect) {
        const item = items.find(item => item.id === name)
        if (item) {
          navigate(item.url)
        }
      }
    }

    const minify = () => {
      setFullscreen(false)
      interactionEnabled = false
    }

    const resetInteraction = () => {
      setFullscreen(true)
      interactionEnabled = true
    }

    const fitCameraToObject = (camera, object, offset) => {
      offset = offset || 1.5

      const boundingBox = new THREE.Box3()

      boundingBox.setFromObject(object)

      const center = boundingBox.getCenter(new THREE.Vector3())
      const size = boundingBox.getSize(new THREE.Vector3())

      const startDistance = center.distanceTo(camera.position)
      // here we must check if the screen is horizontal or vertical, because camera.fov is
      // based on the vertical direction.
      const endDistance =
        camera.aspect > 1
          ? (size.y / 2 + offset) / Math.abs(Math.tan(camera.fov / 2))
          : (size.y / 2 + offset) /
            Math.abs(Math.tan(camera.fov / 2)) /
            camera.aspect

      camera.position.set(
        0,
        (camera.position.y * endDistance) / startDistance,
        (camera.position.z * endDistance) / startDistance
      )
      camera.lookAt(center)
    }

    const applyMaterial = (obj, material) => {
      obj.traverse(child => {
        if (child instanceof THREE.Mesh) {
          child.material = material
          child.castShadow = true
          child.receiveShadow = true
        }
      })
    }

    const loadObj = (url, id, hitbox = false) => {
      loader.load(url, obj => {
        loadedObjects.push(id)
        obj.name = id
        if (!hitbox) {
          applyMaterial(obj, baseMaterial)
          obj.castShadow = true
          obj.receiveShadow = true
          group.add(obj)
        } else {
          applyMaterial(obj, hitboxMaterial)
          hitGroup.add(obj)
        }
        triggerIfIsLoaded()
      })
    }

    const addLight = (
      intensity,
      position,
      distance = 40,
      castShadow = false
    ) => {
      const light = new THREE.PointLight(0xffffff, intensity)
      light.castShadow = castShadow
      light.position.set(position.x, position.y, position.z)
      light.angle = Math.PI / 4
      light.distance = distance
      if (castShadow) {
        light.shadow.radius = 8
        light.shadow.distance = 400
        light.shadow.power = 1 * Math.PI
      }
      scene.add(light)
    }

    const selectSection = (name, lights = false, text = true) => {
      group.children.forEach(obj => {
        if (obj.name === name && obj.name !== activeObj) {
          applyMaterial(obj, materials[name])
          activeObj = name
          light1.color = colors[name]
          if (text) {
            renderer.domElement.style.cursor = 'pointer'
            const item = items.find(item => item.id === name) || { title: '' }
            setCurrentText(item.title)
          }
        } else if (obj.name !== activeObj) {
          applyMaterial(obj, baseMaterial)
        }
      })
      if (activeObj) {
        intensity = 3
      } else {
        intensity = 0
        renderer.domElement.style.cursor = 'auto'
        setCurrentText(false)
      }
      if (lights) {
        const lightPosition = lightPositions[name]
        moveLight(lightPosition.x, lightPosition.y)
      }
    }

    const moveLight = (percentageX, percentageY) => {
      light1.position.set(
        calculatePercentage(-13, 13, percentageX),
        1,
        calculatePercentage(12, -12, percentageY)
      )
    }

    const resizeCanvasToDisplaySize = () => {
      const canvas = renderer.domElement
      const width = canvas.clientWidth
      const height = canvas.clientHeight
      if (canvas.width !== width || canvas.height !== height) {
        size = { width, height }
        renderer.setSize(width, height, false)
        camera.aspect = width / height
        camera.updateProjectionMatrix()
      }
    }

    const triggerIfIsLoaded = () => {
      if (loadedObjects.length === hitboxes.length + sections.length) {
        isReady()
      }
    }

    const isReady = () => {
      setLoaded(true)
      selectDefault()
      animate(true)
    }

    const selectDefault = () => {
      if (
        currentId &&
        currentId.length > 0 &&
        availableSections.includes(currentId)
      ) {
        navigateTo(currentId, false)
      } else {
        activeObj = null
        intensity = 0
        selectSection('none', false)
      }
    }

    addLight(5, { x: 0, y: 20, z: 0 }, 25, true)
    addLight(8, { x: 0, y: 20, z: 0 }, 25, false)
    addLight(2.2, { x: 0, y: 5, z: -20 }, 30, false)
    addLight(2.2, { x: -20, y: 5, z: 0 }, 20, false)
    addLight(1.6, { x: 0, y: 5, z: 20 }, 30, false)

    sections.forEach((obj: Obj) => {
      loadObj(obj.path, obj.id)
      if (typeof obj.color !== 'undefined') {
        materials[obj.id] = new THREE.MeshStandardMaterial({
          color: `#${obj.color}`
        })
        colors[obj.id] = new THREE.Color(`#${obj.color || ''}`)
        lightPositions[obj.id] = { x: obj.x, y: obj.y }
      }
    })
    hitboxes.forEach(obj => {
      loadObj(obj.path, obj.id, true)
    })

    scene.add(group)
    scene.add(hitGroup)

    fitCameraToObject(camera, group, inline ? 15 : 20)

    function animate(force = false) {
      if (interactionEnabled || force) {
        resizeCanvasToDisplaySize()
        renderer.render(scene, camera)
        light1.intensity = parseFloat(
          lerp(light1.intensity, intensity, 0.1)
        ).toFixed(2)
      }
      requestAnimationFrame(animate)
    }
    animate(true)

    window.addEventListener('keydown', e => {
      if (interactionEnabled) {
        let isEscape = false
        if ('key' in e) {
          isEscape = e.key === 'Escape' || e.key === 'Esc'
        } else {
          isEscape = e.keyCode === 27
        }
        if (isEscape) {
          selectDefault()
          minify()
        }
      }
    })
    mainRef.current.addEventListener('click', () => {
      if (interactionEnabled) {
        if (activeObj) {
          navigateTo(activeObj)
        } else {
          selectDefault()
          minify()
        }
      } else {
        resetInteraction()
      }
    })

    const handleMouseMove = (event: any) => {
      if (!interactionEnabled) return
      let x = 0
      let y = 0
      const { top } = mainRef.current.getBoundingClientRect()
      if (event.type === 'mousemove') {
        x = event.clientX
        y = event.clientY - top
      } else if (event.type === 'touchmove') {
        x = event.touches[0].clientX
        y = event.touches[0].clientY - top
      }
      textRef.current.style.left = `${x}px`
      textRef.current.style.top = `${y}px`
      mouse.x = (x / size.width) * 2 - 1
      mouse.y = -(y / size.height) * 2 + 1
      const percentageX = interpolate(-1, 1, mouse.x)
      const percentageY = interpolate(-1, 1, mouse.y)
      group.rotation.y = hitGroup.rotation.y =
        (calculatePercentage(-10, 10, percentageX) * Math.PI) / 180
      group.rotation.x = hitGroup.rotation.x =
        (calculatePercentage(-10, 10, percentageY) * Math.PI) / 180
      update(percentageX, percentageY)
    }

    mainRef.current.addEventListener('touchmove', handleMouseMove)
    mainRef.current.addEventListener('mousemove', handleMouseMove)

    const update = (percentageX, percentageY) => {
      raycaster.setFromCamera(mouse, camera)
      intersects = raycaster.intersectObjects(hitGroup.children, true)
      let intersectedObject = false
      if (intersects.length > 0) {
        intersects.forEach(({ object }) => {
          if (
            touchableSections.includes(object.name) ||
            (object.parent && touchableSections.includes(object.parent.name))
          ) {
            const supposedIntersectedObject = touchableSections.includes(object.name)
              ? object
              : object.parent

            const name = supposedIntersectedObject.name.replace('hit_', '')
            const found = items.find(item => item.id === name)
            if (found) {
              intersectedObject = supposedIntersectedObject
            }
          }
        })
      }
      if (!intersectedObject) {
        activeObj = null
        intensity = 0
        selectSection('none', false)
      } else {
        const name = intersectedObject.name.replace('hit_', '')
        selectSection(name, false)
      }

      moveLight(percentageX, percentageY)
    }

    return () => {
      mainRef.current.removeEventListener('mousemove', handleMouseMove)
      mainRef.current.removeEventListener('touchmove', handleMouseMove)
    }
  }, [])

  let className =
    'overflow-hidden transition duration-500 map360 transition-map'
  if (inline) {
    className += 'w-full h-70vh relative'
  } else {
    className += 'hidden md:block '
    if (loaded) {
      className += 'opacity-1 '
    } else {
      className += 'opacity-0 '
    }
    if (fullscreen) {
      className += 'fixed z-top w-full h-full mr-0 mb-0 rounded-none inset-0'
    } else {
      className +=
        'relative cursor-pointer transform hover:scale-110 w-full rounded-full pt-100p'
    }
  }

  return (
    <div className={className} ref={mainRef}>
      <span
        ref={textRef}
        className={`${
          currentText && (fullscreen || inline) ? 'opacity-1' : 'opacity-0'
        } z-10 text-white text-big2 transition transition-opacity duration-500 pointer-events-none absolute bottom-0 left-0 pl-4 pt-4`}
      >
        {currentText}
      </span>
    </div>
  )
}

export default Map360
