const THREE = require('three')
const { OBJLoader } = require('three/examples/jsm/loaders/OBJLoader')
const { OrbitControls } = require('three/examples/jsm/controls/OrbitControls')

const loadObjects = (objects) => {
  var loader = new OBJLoader()
  const promises = []
  objects.forEach(obj => {
    promises.push(new Promise((resolve, reject) => {
      loader.load(obj.url, loadedObj => {
        loadedObj.userData.id = obj.id
        loadedObj.receiveShadow = true
        loadedObj.traverse(child => {
          if (child instanceof THREE.Mesh) {
            child.material = obj.material
          }
        })
        resolve(loadedObj)
      }, undefined, error => {
        console.log(error)
        reject(null)
      })
    }))
  })
  return Promise.all(promises)
}

const applyMaterial = (obj, material) => {
  obj.traverse(child => {
    if (child instanceof THREE.Mesh) {
      child.material = material
      child.castShadow = true
      child.receiveShadow = true
    }
  })
}

const addLight = (
  scene,
  intensity,
  position,
  distance = 40,
  castShadow = false
) => {
  const light = new THREE.PointLight(0xffffff, intensity)
  light.castShadow = castShadow
  light.position.set(position.x, position.y, position.z)
  light.angle = Math.PI / 4
  light.distance = distance
  if (castShadow) {
    light.shadow.radius = 8
    light.shadow.distance = 400
    light.shadow.pow
    conster = 1 * Math.PI
  }
  const helper = new THREE.PointLightHelper(light, 2)

  scene.add(helper)
  scene.add(light)
}

const fitCameraToObject = (camera, object, offset) => {
  offset = offset || 1.5

  const boundingBox = new THREE.Box3()

  boundingBox.setFromObject(object)

  const center = boundingBox.getCenter(new THREE.Vector3())
  const size = boundingBox.getSize(new THREE.Vector3())

  const startDistance = center.distanceTo(camera.position)
  // here we must check if the screen is horizontal or vertical, because camera.fov is
  // based on the vertical direction.
  const endDistance =
    camera.aspect > 1
      ? (size.y / 2 + offset) / Math.abs(Math.tan(camera.fov / 2))
      : (size.y / 2 + offset) /
        Math.abs(Math.tan(camera.fov / 2)) /
        camera.aspect

  camera.position.set(
    0,
    (camera.position.y * endDistance) / startDistance,
    (camera.position.z * endDistance) / startDistance
  )
  camera.lookAt(center)
  }

function toScreenPosition(obj, renderer, camera)
{
    var vector = new THREE.Vector3()

    const canvas = renderer.getContext().canvas
    const { x, y } = canvas.getBoundingClientRect()
    var widthHalf = 0.5*canvas.width
    var heightHalf = 0.5*canvas.height

    obj.updateMatrixWorld()
    vector.setFromMatrixPosition(obj.matrixWorld)
    vector.project(camera)

    vector.x = ( vector.x * widthHalf ) + widthHalf
    vector.y = - ( vector.y * heightHalf ) + heightHalf

    return { 
        x: vector.x + x,
        y: vector.y + y
    }

}


module.exports = {
  loadObjects,
  addLight,
  fitCameraToObject,
  applyMaterial,
  toScreenPosition,
}