// @flow

import React from 'react'
import Html from './Html'

import Appear from './Appear'

type Props = {
  text: string,
}

const StoryBlock3 = ({ text }: Props) => {
  return (
    <Appear>
      <div className="mb-4">
        <div className="font-light break-words md:break-normal text-16px md:text-20px">
          <Html text={text} md={true} />
        </div>
      </div>
    </Appear>
  )
}

export default StoryBlock3
