// @flow

import React, { useState } from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import Button from './Button'
import { useMedia } from '../store'

type Props = {
  position: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  title: string,
  supertitle: string,
  anchor: string
}

const PrinterRequestFreeSample = ({ text, ctaText, ctaUrl, title, supertitle, anchor }: Props) => {

  const mainContent = (hidden = false) => (
    <Container data-anchor={anchor} className={`relative z-10 py-12 md:py-20 lg:py-24 ${hidden ? 'invisible' : ''}`}>
      <Grid>
        <div className="flex items-stretch col-span-4 col-start-1 bg-grayLight md:col-span-5 md:col-start-8">
          <div>
            <Appear>
              <h5 className="mb-2 text-normal">{supertitle}</h5>
              <h3 className="mb-4 text-big3">{title}</h3>
            </Appear>
            <Appear>
              <Html text={text} />
            </Appear>
            <div className="flex mt-8">
              {ctaText && <Appear><Button style="white" title={ctaText} href={ctaUrl} /></Appear>}
            </div>
          </div>
          <div className="flex items-center justify-center flex-shrink-0 w-1/3 ml-4 md:ml-8">
            <div className="relative w-full h-20 bg-white rounded-full pt-full">
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                <LazySimpleImage imageClassName="w-20" image={useMedia('free-sample')} alt={title} />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Container>
  )

  return (
    <div
      className="relative text-black"
    >
      <div
      >
        {mainContent(false)}
        <div
          className="absolute bottom-0 right-0 z-0 w-full h-full bg-grayLight md:w-1/2"
        />
      </div>
    </div>
  )
}

export default PrinterRequestFreeSample