// @flow

import React from 'react'
import Html from './Html'
import Appear from './Appear'
import IconQuote from './icons/IconQuote'

type Props = {
  text: string,
  title: string,
}

// quote component
const StoryBlock5 = ({ text, title = '' }: Props) => {
  return (
    <Appear className="flex flex-col md:flex-row gap-4 border-b mb-10 pb-1 md:pb-2">
      <IconQuote />
      <div className="flex flex-col gap-4 md:gap-5">
        <div className="text-18px md:text-24px">
          <Html text={text} />
        </div>
        <div>
          <span className="text-14px md:text-16px">{title}</span>
        </div>
      </div>
    </Appear>
  )
}

export default StoryBlock5
