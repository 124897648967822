// @flow

import React from 'react'

const IconArrow = ({ direction }: { direction: 'right' | 'left' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={direction === 'right' ? {} : { transform: 'rotate(180deg)' }}
    >
      <path
        d="M12.0788 7.50011H0.5C0.357683 7.50011 0.238775 7.45235 0.143275 7.35683C0.0477584 7.26133 0 7.14242 0 7.00011C0 6.85779 0.0477584 6.73888 0.143275 6.64338C0.238775 6.54786 0.357683 6.50011 0.5 6.50011H12.0788L6.64037 1.06166C6.54294 0.964206 6.49198 0.849457 6.4875 0.717407C6.48302 0.585357 6.5359 0.464206 6.64615 0.353956C6.7564 0.24754 6.87435 0.193373 7 0.191456C7.12565 0.189523 7.2436 0.24369 7.35385 0.353956L13.4346 6.43473C13.5218 6.5219 13.583 6.611 13.6183 6.70203C13.6535 6.79305 13.6712 6.89241 13.6712 7.00011C13.6712 7.10781 13.6535 7.20716 13.6183 7.29818C13.583 7.38921 13.5218 7.47831 13.4346 7.56548L7.35385 13.6463C7.26027 13.7399 7.14648 13.7899 7.0125 13.7963C6.87852 13.8027 6.7564 13.7527 6.64615 13.6463C6.5359 13.536 6.48077 13.4171 6.48077 13.2895C6.48077 13.162 6.5359 13.0431 6.64615 12.9328L12.0788 7.50011Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconArrow
