// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'

type PostCategory = {
  primaryColor: string,
  secondaryColor: string,
  title: string,
}

type Props = {
  title: string,
  description: string,
  postCategory: PostCategory,
  noPaddingBottom: boolean,
}

const BlogBlock1 = ({
  title,
  description,
  postCategory,
  noPaddingBottom = false,
  extraProps,
}: Props): React$Element<'div'> => {
  const isColorValid = color => {
    if (!color) return false
    return new RegExp('^#[0-9a-fA-F]{6}$').test(color)
  }

  let paddingBottom = 'pb-2'
  if (postCategory) {
    paddingBottom = 'pb-8 md:pb-16'
  }
  if (noPaddingBottom) {
    paddingBottom = ''
  }

  return extraProps.isBlogPost ? (
    <div className={`${paddingBottom} mt-menu-mobile md:mt-menu-desktop`}>
      {postCategory.title ? (
        <div
          className="py-4"
          style={
            isColorValid(postCategory.primaryColor)
              ? { backgroundColor: postCategory.primaryColor }
              : {}
          }
        >
          <Grid>
            <div className="col-span-4 col-start-1 px-4 md:px-0 md:col-span-6 md:col-start-4">
              <h1 className="text-left text-26px leading-snug md:text-48px font-secondary">
                <Appear>{title}</Appear>
              </h1>
            </div>
          </Grid>
        </div>
      ) : null}
      <div
        className="py-4 md:py-10"
        style={
          isColorValid(postCategory.secondaryColor)
            ? { backgroundColor: postCategory.secondaryColor }
            : {}
        }
      >
        <Grid>
          <div className="col-span-4 col-start-1 md:col-span-6 px-4 md:px-0 md:col-start-4">
            <h2 className="text-16px md:text-20px">
              <Appear>{description}</Appear>
            </h2>
          </div>
        </Grid>
      </div>
    </div>
  ) : (
    <div className={`${paddingBottom}`}>
      {postCategory.title ? (
        <div
          className="py-4"
          style={
            isColorValid(postCategory.primaryColor)
              ? { backgroundColor: postCategory.primaryColor }
              : {}
          }
        >
          <Container>
            <Grid>
              <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
                <h1 className="text-left text-big3">
                  <Appear>{postCategory.title}</Appear>
                </h1>
              </div>
            </Grid>
          </Container>
        </div>
      ) : null}
      <div
        className="py-4 md:py-10"
        style={
          isColorValid(postCategory.secondaryColor)
            ? { backgroundColor: postCategory.secondaryColor }
            : {}
        }
      >
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
              <h1 className="text-left text-big4">
                <Appear>{title}</Appear>
              </h1>
            </div>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default BlogBlock1
