const updateRequestAnimationFrameReference = (window) => {
  if (!window) return {}

  const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame

  const cancelAnimationFrame =
    window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.msCancelAnimationFrame

  return {
    requestAnimationFrame,
    cancelAnimationFrame,
  }
}

const loadImages = async ({
  setImages,
  setImagesMobile,
  setLoadingMetadata,
  currentFolder,
  numberOfImages,
  isMobile,
}) => {
  setImages([])
  setImagesMobile([])
  const imagesUrls = []
  for (let i = 1; i <= numberOfImages; i++) {
    imagesUrls.push(`${currentFolder}/${i}.jpg`)
  }

  for await (const url of imagesUrls) {
    new Promise((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.onerror = reject
      img.key = url
      img.onload = () => {
        if (isMobile) {
          setImagesMobile(prevImages => [...prevImages, img])
        } else {
          setImages(prevImages => [...prevImages, img])
        }
      }
    })
    setLoadingMetadata(false)
  }
}

const optimizeScrollPlay = async ({
  scrollDurationRef,
  containerVideoRef,
  canvasRef,
  numberOfImages,
  setVideoOver,
  canvasRefMobile,
  menuHeight,
  isMobile,
  images,
  imagesMobile,
}) => {
  const ctx = canvasRef.current.getContext('2d')
  let frame = 0

  const drawVideo = (percentage, callback) => {
    let newPercentage = percentage
    if (percentage > 100) {
      newPercentage = 100
    }
    const finalFrame = Math.max(
      1,
      Math.floor((newPercentage / 100) * numberOfImages)
    )

    const difference = Math.abs(finalFrame - frame)
    if (finalFrame > frame) {
      if (difference > 60) frame += 20
      else
      frame++
    } else if (finalFrame < frame) {
      if (difference > 60) frame -= 20
      else
      frame--
    } else {
      frame = finalFrame
    }

    const draw = (img) => {
      const canvas = canvasRef.current
      if (!canvas) return
      canvas.height = canvas.offsetHeight
      canvas.width = canvas.offsetWidth
      const videoAspectRatio = isMobile ? 9 / 16 : 16 / 9
      const canvasAspectRatio = canvas.width / canvas.height
      const pixelRatio = 1

      let drawWidth, drawHeight
      let xOffset = 0
      let yOffset = 0

      if (videoAspectRatio < canvasAspectRatio) {
        drawHeight = canvas.height * pixelRatio
        drawWidth = drawHeight * videoAspectRatio
        xOffset = (canvas.width * pixelRatio - drawWidth) / 2
      } else {
        drawWidth = canvas.width * pixelRatio
        drawHeight = drawWidth / videoAspectRatio
        yOffset = (canvas.height * pixelRatio - drawHeight) / 2
      }
      ctx.drawImage(img, xOffset, yOffset, drawWidth, drawHeight)
      callback()
    }

    const responsiveImages = isMobile ? imagesMobile : images
    const sortedImages =  responsiveImages.sort((a, b) => {
      const aIndex = parseInt(a.currentSrc.split('/').pop().split('.')[0])
      const bIndex = parseInt(b.currentSrc.split('/').pop().split('.')[0])
      return aIndex - bIndex
    })
    if (sortedImages[frame - 1]) {
      draw(sortedImages[frame - 1])
    } else {
      return callback()
    }
  }

  const scrollPlay = () => {
    const currentScrollY =
    !containerVideoRef.current ? 0 : window.pageYOffset - containerVideoRef.current.offsetTop - menuHeight

    const percentageViewed = Math.max(
      0,
      Math.min(120, (currentScrollY / scrollDurationRef.current) * 100)
    )

    const percentageTrigger = isMobile ? 100 : 110

    if (percentageViewed > percentageTrigger) {
      if (!canvasRef || !canvasRef.current) return
      const finalPaddingX = isMobile ? 1.25 : 2.5
      const newPercentage = (percentageViewed - percentageTrigger) * 10

      const paddingX = ((finalPaddingX * newPercentage) / percentageTrigger).toFixed(2)
      canvasRef.current.style.padding = `${0}rem ${paddingX < finalPaddingX ? paddingX : finalPaddingX }rem`

      if (percentageViewed > 120) {
        canvasRef.current.style.marginBottom = `-200px`
      } else {
        canvasRef.current.style.marginBottom = `0`
      }

      setVideoOver(true)
    } else if (percentageViewed <= percentageTrigger) {
      if (canvasRefMobile.current) {
        canvasRefMobile.current.style.padding = '0'
        canvasRefMobile.current.style.height = '100vh'
      }
      if (canvasRef.current) {
        canvasRef.current.style.padding = '0'
        canvasRef.current.style.height = '100vh'
      }
      setVideoOver(false)
    }

    drawVideo(percentageViewed, () => {
      window.requestAnimationFrame(scrollPlay)
    })
  }

  return scrollPlay
}

export { updateRequestAnimationFrameReference, loadImages, optimizeScrollPlay }
