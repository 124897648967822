// @flow

import React, { useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Map360 from './Map360'
import Appear from './Appear'
import Spacer from './Spacer'
import Html from './Html'
import { useMedia } from '../store'

type Item = {
  id: string,
  title: string,
  url: string
}

type Props = {
  position: string,
  title: string,
  text: string,
  currentId: string,
  items: Array<Item>
}

const shouldScrollToId = menuId => {
  const element = document.querySelector(`[data-name="${menuId}"`)
  const yOffset = -200
  if (element) {
    const { top } = element.getBoundingClientRect()
    const y = top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

const Map = ({ title, text, items, currentId }: Props) => {
  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart.length > 0 && lastPart.includes('explore')) {
        setTimeout(() => {
          shouldScrollToId('explore')
        }, 1000)
      }
    }
  }, [])
  return (
    <div
      data-name="map"
      className="relative text-white bg-black"
    >
      <Spacer
        top
        bottom
      >
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 text-center md:col-span-10 md:col-start-2">
              <Appear>
                <h4 className="mb-8 text-big3"><Html text={title} /></h4>
              </Appear>
            </div>
          </Grid>
        </Container>
        <Map360 inline currentId={currentId} items={items} />
        <Container>
          <Grid>
            <div data-name="explore" className="col-span-4 col-start-1 text-center md:col-span-10 md:col-start-2">
              <Appear>
              <h4 className="mt-8 font-light text-big2"><Html text={text} /></h4>
              </Appear>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default Map