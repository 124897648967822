
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import makeAssetUrl from '../lib/makeAssetUrl'
import Button from './Button'
import Html from './Html'

const Fespa25Banner = ({
  moduleName,
  pretitle,
  pretitle2,
  title,
  image,
  imageMobile,
  text,
  ctaRegisterText,
  ctaRegister
}) => {

  const customStyle = {
    "--desktop-bg-image": `url(${makeAssetUrl({ asset: image,  isImage: true})})`,
    "--mobile-bg-image": `url(${makeAssetUrl({ asset: imageMobile,  isImage: true})})`,
  }

  return (
    <div
      className="relative w-full text-white bg-center bg-no-repeat bg-cover min-h-600 md:min-h-500 md:max-h-700px items-center flex bespa25Banner-container"
      style={customStyle}
    >
      <Container>
        <Grid>
          <div className="text-black z-20 col-span-12 col-start-1 md:flex gap-4">
            <h4 className="text-xl leading-none text-center md:text-left md:text-35px">
              {pretitle}
            </h4>
           {pretitle2 ?
            <>
              <div className="items-center justify-center h-full hidden md:flex">
                  <span className="text-xl leading-none text-left md:text-28px">|</span>
                </div>
                <h4 className="text-xl leading-none text-center md:text-left md:text-35px">
                  {pretitle2}
                </h4>
              </>  
              : null
            }
          </div>

          <div className="text-black z-20 col-span-12 col-start-1 text-left md:col-span-8">
            <h1
              className="leading-none text-center md:text-left font-secondary md:leading-32px sm:text-80px text-50px"
            >
              {title}
            </h1>
          </div>

          <div className="z-20 col-span-12 col-start-1 text-left mt-5">
            <div className="text-black text-center md:text-left">
              <Html text={text} />
            </div>
            <div
              className="z-20 font-light mt-5"
              data-tag-name={`${moduleName}-visit3dvirtualbooth`}
            >
              <Button
                className="text-20px w-full md:w-400px text-center"
                target="_blank"
                style="blackOutlined"
                title={ctaRegisterText}
                href={ctaRegister}
                padded
              />
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default Fespa25Banner
