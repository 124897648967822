const shouldScrollToAnchor = (e, to) => {
  if (typeof window !== "undefined") {
    e.preventDefault()
    if (!to) return
    window.history.replaceState(null, null, to)
    const element = document.querySelector(`[data-anchor="${to.split('#')[1]}"`)
    const yOffset = -100
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }
}

export default shouldScrollToAnchor