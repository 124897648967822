// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Video from './Video'
import type { ApiImage } from 'types/'

type Props = {
  title: string,
  url: string,
  image: ApiImage,
  whiteParentBG: boolean,
  anchor: string,
}

const CategoryBlockTitleVideo = ({ title, url, image, whiteParentBG = false, anchor }: Props) => {
  return (
    <div data-anchor={anchor}>
      <Spacer bottom>
        <Container>
          <Grid>
            <Appear className="col-span-4 mb-20 md:col-span-5 lg:col-span-6 lg:col-start-4 md:col-start-4">
              <h3 className={`text-big2 ${whiteParentBG ? "text-black" : "text-white"}`}>{title}</h3>
            </Appear>
            <Appear className="col-span-4 md:col-start-4 md:col-span-9">
              <Video url={url} image={image} noGrid centerPlay insideContainer/>
            </Appear>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlockTitleVideo