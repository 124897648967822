// @flow
import React from 'react'
import { Link } from 'gatsby'
import urlparameters from '../lib/urlparameters'

const LinkHP = (props) => {
  if (typeof window !== `undefined`) {
    if (
      props?.to?.indexOf('/contact/') !== -1 &&
      document?.URL?.indexOf('/contact/') === -1
    ) {
      sessionStorage.setItem('sourcePage', document.URL)
    }
  }
  return (
    <Link {...props} to={urlparameters(props.to, ['jumpid', 'bmtid'])}>
      {props.children}
    </Link>
  )
}

export default LinkHP
