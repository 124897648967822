// @flow

import React from 'react'
import type { Node } from 'react'
import Spacer from './Spacer'
type Props = {
  children: Node,
  hasGradient: boolean,
  isDifferent: boolean,
}

const PrinterWrapperInner = ({ children, isDifferent, hasGradient }: Props) => {
  return (
    <Spacer top={!isDifferent} bottom={!isDifferent}>
      <div data-hasgradient={hasGradient ? 1 : 0}>
        {children}
      </div>
    </Spacer>
  )
}

export default PrinterWrapperInner