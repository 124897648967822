// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import Viewer from './Viewer'
import Button from "./Button"
import makeLayer from '../lib/makeLayer'

type Props = {
  title: string,
  subtitle?: string,
  text: string,
  align: string,
  image: ApiImage,
  imagezoom: ApiImage,
  image360: Array<ApiImage>,
  menuId?: string,
  verticalSlug?: string,
  layout: "white" | "black",
  url?: string,
  ctaTitle?: string,
  ctaUrl?: string,
  anchor: string,
}

const CategoryBlock17 = ({
  align,
  menuId, 
  title,
  subtitle,
  text,
  image,
  imagezoom,
  image360,
  layout,
  ctaTitle,
  ctaUrl,
  anchor,
}: Props) => {
  const theLayout = ["white", "black"].includes(layout) ? layout : "white"

  const imageContent = (
    <Viewer
      image={image}
      image360={image360}
      imageZoom={imagezoom}
      alt={title}
    />
  )

  const hasCta = (
    typeof ctaTitle === "string"
    && ctaTitle !== ""
    && typeof ctaUrl === "string"
    && ctaUrl !== ""
  )

  const styles = "col-span-4 col-start-1 md:flex md:flex-col"

  const textContent = (
    <>
      {typeof subtitle === "string" && subtitle !== "" && (
        <h5 className="mb-5 font-light leading-tight md:mb-8 text-normal md:text-xl">{subtitle}</h5>
      )}
      {typeof title === "string" && title !== "" && (
        <h4 className="mb-5 font-bold leading-none md:mb-8 text-big3 md:text-5xl">
          <Appear>{title}</Appear>
        </h4>
      )}
      <Appear className={`font-light leading-tight text-normal md:text-xl ${hasCta ? "" : "mb-8 md:mb-16"}`}>
        <Html text={text} />
      </Appear>
      {hasCta && (
        <Appear className="mt-10 mb-8 md:mb-16">
          <Button
            href={ctaUrl}
            title={ctaTitle} 
            style={theLayout === "white" ? "black" : "white"}
            dataLayer={makeLayer({
              event: 'e_contactClick',
              linkPlacement: 'content-webinar-download',
              linkID: ctaUrl,
              linkClass: 'webinar'
            })}
          />
        </Appear>
      )}
    </>
  )

  return (
    <div
      data-menuid={menuId}
      data-hasgradient={theLayout === 'white' ? "1" : "0"}
      data-anchor={anchor}
      className={`pt-12 pb-10 md:pb-0 ${theLayout === "white" ? "bg-white text-black" : "bg-black text-white"}`}
    >
      <Spacer top bottom smaller>
        <Container>
          <Grid>
            {align === 'left' ? (
              <>
                <div className={`md:col-span-5 md:col-start-4 md:mb-0 md:justify-center ${styles}`}>
                  {imageContent}
                </div>
                <div className={`md:col-span-4 md:pt-2 md:col-start-9 md:mb-0 md:pl-8 md:justify-center ${styles}`}>
                  {textContent}
                </div>
              </>
            ) : (
              <>
                <div className={`md:col-span-5 md:pt-2 md:col-start-4 md:mb-0 md:pr-8 md:justify-start ${styles}`}>
                  {textContent}
                </div>
                <div className={`md:col-span-4 md:col-start-9 md:mb-0 ${styles} md:justify-start`}>
                  {imageContent}
                </div>
              </>
            )}
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock17
