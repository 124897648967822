const THREE = require('three')
const { OrbitControls } = require('three/examples/jsm/controls/OrbitControls')

class Controls {
  constructor(scene) {
    this.camera = new THREE.PerspectiveCamera(
      30,
      scene.getSize().width / scene.getSize().height,
      1,
      1000
    )
    this.camera.position.set(0, 0, 85)

    this.controls = new OrbitControls(this.camera, scene.getRenderer().domElement )
    this.controls.enableRotate = true
    this.controls.rotateSpeed = 0.1
    this.controls.enablePan = false
    this.controls.enableZoom = true
    this.controls.autoRotate = true
    this.controls.autoRotateSpeed = 0.2
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.05
    this.controls.target = new THREE.Vector3(0, 2, 0)
    this.controls.maxDistance = 85
    this.controls.minDistance = 5
    this.controls.maxPolarAngle = 90 * Math.PI/180
    this.controls.minPolarAngle = 75 * Math.PI/180
    this.showingInfo = false
  }

  updateAspect(size) {
    this.camera.aspect = size.width / size.height
    this.camera.updateProjectionMatrix()
  }

  setIsShowingInfo(showingInfo) {
    this.controls.autoRotate = !showingInfo
    this.showingInfo = showingInfo
  }
  
  getIsShowingInfo() {
    return this.showingInfo
  }

  get() {
    return this.controls
  }
  
  getCamera() {
    return this.camera
  }

  setCameraPosition(position, getDistance = false, zoomingIn = true) {
    // this.camera.position.x = x
    // this.camera.position.z = z
    // this.camera.position.y = y
    if (getDistance) {
      return this.camera.position.distanceTo(position)  
    }
    const speed = zoomingIn ? 0.08 : 0.15
    this.camera.position.lerp(position, speed)
  }
  
  setTarget(target) {
    this.controls.target = target
  }

  enableZoom(enable) {
    this.controls.enableZoom = enable
  }
  
  enableRotation(enable) {
    this.controls.autoRotate = enable
  }
}

export default Controls