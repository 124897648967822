// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Html from './Html'
import MadeWithPrinters from './MadeWithPrinters'
import type { ApiImage, SimplePrinter } from '../types'

type Props = {
  title: string,
  text: string,
  image: ApiImage,
  printers: Array<SimplePrinter>,
  menuId?: string,
  inGradient?: boolean,
  verticalSlug: string,
  whiteParentBG?: boolean,
}

const CategoryBlock1 = ({
  menuId,
  title,
  text,
  image,
  printers,
  inGradient,
  verticalSlug,
  whiteParentBG = false }: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer bottom smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 text-black md:flex md:flex-col md:justify-center md:col-span-9 md:col-start-4 md:mb-4`}>
              <LazyImage image={image} alt={title} />
            </div>
            <div className={`col-span-4 col-start-1 mb-12 text-black md:flex md:flex-col md:justify-center md:col-span-4 md:col-start-4 md:mb-0 md:pr-8`}>
              <h4 className="my-4 font-bold md:mt-0 md:mb-8 text-normal"><Appear>{title}</Appear></h4>
              <div className="font-light"><Appear><Html text={text} /></Appear></div>
            </div>
            {printers && printers.length > 0 &&
              <div className="col-span-4 col-start-1 mb-12 text-white md:col-span-4 md:col-start-8 md:mb-0">
                <MadeWithPrinters inGradient={inGradient} printers={printers} verticalSlug={verticalSlug} />
              </div>
            }
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock1