// @flow
import React from 'react'
import type { ApiImage } from '../types'
import Container from  './Container'
import Grid from './Grid'
import makeAssetUrl from '../lib/makeAssetUrl'
import Html from './Html'
import LazyImage from './LazyImage'
import Button from './Button'
import makeLayer from '../lib/makeLayer'

type Props = {
  title: string,
  ctaLabel: string,
  ctaLink: string,
  image: ApiImage,
  backgroundImage: ApiImage,
  text: string,
  anchor: string,
}

const ImagesPortfolio = ({ title, ctaLabel, ctaLink, image, backgroundImage, text, anchor }: Props) => {
  const noBackground = backgroundImage.length === 0
  const noImage = image.length === 0

  return (
    <div className="relative" data-anchor={anchor}>
      <div className={`absolute top-0 left-0 right-0 w-full ${noBackground && noImage ? 'h-full' : 'h-1/2'} bg-realBlack`} />
      {!noBackground && <div
        className="absolute bottom-0 left-0 right-0 w-full h-1/2"
        style={{
            backgroundImage: `url(${makeAssetUrl({ asset: backgroundImage, isImage: true })})`,
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
      >
        <div
            className="absolute top-0 left-0 right-0 h-40"
            style={{
                backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)"
            }}
        />
        <div
          className="absolute bottom-0 left-0 right-0 h-40"
          style={{
              backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)"
          }}
        />
      </div>}
      <Container>
        <Grid className={`relative pt-20 ${noBackground && noImage ? 'pb-32 md:pb-48' : 'pb-200px md:pb-500px md:pt-200px'}`}>
          <div className="flex flex-col items-center justify-center col-span-4 text-white md:col-span-6 md:col-start-4">
            <h2 className="mb-12 md:mb-16 leading-tight text-center text-3.5xl md:text-50px">{title}</h2>
            <div className="mb-12 text-base font-light leading-tight text-center md:mb-16 md:w-4/5 md:text-24px">
              <Html text={text}/>
            </div>
            <div className={`flex items-start mt-auto max-w-8/10`}>
                <Button href={ctaLink} title={ctaLabel}  style="white"/>
              </div>
            </div>
          {!noImage && <div className="col-span-4 my-12 md:my-24 md:col-span-12">
            <LazyImage image={image} />
          </div>}
        </Grid>
      </Container>
    </div>
  )
}

export default ImagesPortfolio
