// @flow

import React from 'react'
import LazyImage from './LazyImage'
import Appear from './Appear'
import { useMedia } from 'store/'

type Props = {
  className?: string,
  image: Object,
  title: string
}

const SizedImage = ({ className, image, title }: Props) => {
  return (
    <Appear className={className}>
      <div className="relative bg-white border border-black">
        <div
          className="absolute top-0 right-0 h-6 bg-white bg-repeat-x left-6"
          style={{
            backgroundImage: `url(${useMedia('rule-horizontal')})`,
            backgroundPosition: '0 9px'
          }}
        ></div>
        <div
          className="absolute bottom-0 left-0 right-0 w-6 bg-white bg-repeat-y top-6"
          style={{
            backgroundImage: `url(${useMedia('rule-vertical')})`,
            backgroundPosition: '9px 0'
          }}
        ></div>
        <div className="pt-6 ml-6">
          <LazyImage alt={title} image={image} className="h-full" />
        </div>
      </div>
    </Appear>
  )
}

export default SizedImage
