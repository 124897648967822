// @flow
import React from 'react'
import Container from './Container'
import Html from './Html'
import LazyImage from './LazyImage'
import Button from './Button'

type Props = {
  image: ApiImage,
  imageBackground: ApiImage,
  pretitle: string,
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  reverseSort?: boolean,
  imageLogo?: ApiImage,
  anchor?: string,
}

const CardDouble = ({
  image,
  imageBackground,
  pretitle,
  title,
  text,
  ctaText,
  ctaUrl,
  reverseSort = false,
  imageLogo,
  anchor,
}: Props): React$Node => {
  return (
    <Container customPadding="px-5 md:px-10 py-4 md2:py-8" anchor={anchor}>
      <div className="grid grid-cols-4 gap-4 bg-transparent md:grid-cols-8 xl:min-h-500">
        <div
          className={`col-span-4 md:col-span-4 order-2 ${
            reverseSort ? 'md:order-2' : 'md:order-1'
          }`}
        >
          <LazyImage
            className="hidden md:block"
            image={image}
            alt="I've got things you need"
            cover
            borderRadius
          />
          <LazyImage
            className="block md:hidden"
            image={imageBackground}
            alt="I've got things you need"
            cover
            borderRadius
          />
        </div>
        <div
          className={`relative col-span-4 bg-white md:col-span-4 rounded-lg order-1 p-10 ${
            reverseSort ? 'md:order-1' : 'md:order-2'
          }`}
        >
          {imageLogo && (
            <div className="absolute w-16 h-16 top-6 right-6 md:h-20 md:w-20">
              <LazyImage
                className="w-full h-full"
                image={imageLogo}
                alt="Logo"
              />
            </div>
          )}
          <div className="text-base text-blue md:text-20px">{pretitle}</div>
          <div className="w-4/5 py-6 leading-9 text-32px md:text-40px">
            <Html text={title} />
          </div>
          <div className="text-15px md:text-lg">
            <Html text={text} />
          </div>
          {ctaText && (
            <div className="mt-4">
              <Button
                textClasses="text-base md:text-lg md2:text-xl"
                href={ctaUrl}
                title={ctaText}
                style="blackOutlined"
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default CardDouble
