// @flow
import React, { useEffect, useState } from "react"
import type { Node } from "react"
import Container from "./Container"
import Grid from "./Grid"
import Spacer from "./Spacer"
import Appear from "./Appear"
import LazySimpleImage from "./LazySimpleImage"
import { useMedia } from "store/"
import useIsMobile from "../hooks/useIsMobile"
import LinkExternalHP from "./LinkExternalHP"
import makeLayer from "../lib/makeLayer"

type Props = {
  title: string,
  ctaText1: string,
  ctaUrl1: string,
  ctaText2: string,
  ctaUrl2: string,
  children: Node,
  mobileBgColor?: string,
  mobileColor?: string,
  documentId1: string,
  documentId2: string,
  documentType1: string,
  documentType2: string,
}

const PrinterInfo = ({
  children,
  title,
  ctaText1,
  ctaUrl1,
  ctaText2,
  ctaUrl2,
  documentId1,
  documentId2,
  documentType1,
  documentType2,
  mobileBgColor = false,
  mobileColor = false,
}: Props) => {
  const [currentHasGradient, setCurrentHasGradient] = useState(false)
  const isMobile = useIsMobile()

  const handleScroll = () => {
    const width =
      document.documentElement.clientWidth || document.body.clientWidth
    if (width <= 768 && mobileColor === "white") {
      setCurrentHasGradient(true)
    } else {
      const h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight

      const elements2 = document.querySelectorAll("[data-hasgradient]")
      let currentHasGradient = -1
      elements2.forEach(element => {
        const { top } = element.getBoundingClientRect()
        if (top <= h / 2) {
          currentHasGradient = element.getAttribute("data-hasgradient")
        }
      })
      setCurrentHasGradient(parseInt(currentHasGradient) === 1)
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div
      className="md:top-0 md:sticky"
      style={
        isMobile && mobileBgColor
          ? { backgroundColor: `#${mobileBgColor}` }
          : {}
      }
    >
      <Spacer top bottom>
        <Spacer top bottom smaller>
          <Container
            className={`transition-color transition duration-500 ${
              currentHasGradient ? "text-white" : "text-black"
            }`}
          >
            <Grid>
              <div className="col-span-4 col-start-1 pointer-events-auto md:col-span-2 md:col-start-1">
                <h4 className="mb-4 text-normal">
                  <Appear>{title}</Appear>
                </h4>
                <div className="flex md:block">
                  {ctaText1 && (
                    <div className="block mr-4 md:mr-0 text-smaller">
                      <Appear>
                        <LinkExternalHP
                          target="_blank"
                          href={ctaUrl1}
                          className={`flex items-center py-4 border-b ${
                            currentHasGradient ? "border-white" : "border-black"
                          } hover:underline`}
                          dataLayer={ (ctaUrl1.indexOf('reinvent.hp.com') === -1) ?
                            makeLayer({
                              event: "e_document",
                              documentAction: "download",
                              documentID: documentId1,
                              documentType: documentType1,
                            })
                          :
                            makeLayer({
                              event: 'e_contactClick',
                              linkPlacement: 'content-gated',
                              linkID: ctaUrl1,
                            })
                          }
                        >
                          <div className="flex-shrink-0">
                            <LazySimpleImage
                              image={useMedia(
                                currentHasGradient
                                  ? "document-white"
                                  : "document"
                              )}
                              alt={ctaText1}
                            />
                          </div>
                          <span className="ml-2 md:ml-4">{ctaText1}</span>
                        </LinkExternalHP>
                      </Appear>
                    </div>
                  )}
                  {ctaText2 && (
                    <div className="block text-smaller">
                      <Appear>
                        <LinkExternalHP
                          target="_blank"
                          href={ctaUrl2}
                          className={`flex items-center py-4 border-b ${
                            currentHasGradient ? "border-white" : "border-black"
                          } hover:underline`}
                          dataLayer={makeLayer({
                            event: "e_document",
                            documentAction: "download",
                            documentID: documentId2,
                            documentType: documentType2,
                          })}
                        >
                          <div className="flex-shrink-0">
                            <LazySimpleImage
                              image={useMedia(
                                currentHasGradient
                                  ? "document-white"
                                  : "document"
                              )}
                              alt={ctaText2}
                            />
                          </div>
                          <span className="ml-2 md:ml-4">{ctaText2}</span>
                        </LinkExternalHP>
                      </Appear>
                    </div>
                  )}
                </div>
                <div className="block mt-8 md:block">{children}</div>
              </div>
            </Grid>
          </Container>
        </Spacer>
      </Spacer>
    </div>
  )
}

export default PrinterInfo
