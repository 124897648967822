// @flow

import React from 'react'
import IconTalkExpert from './icons/IconTalkExpert'
import IconSamples from './icons/IconSamples'
import IconBookDemo from './icons/IconBookDemo'
import { Link } from 'gatsby'
import Container from './Container'
import Grid from './Grid'

type PostFooterSection = {
  key: string,
  title: string,
  text: string,
  ctaLabel: string,
  urlform: string,
}

type Props = {
  title: string,
  items: Array<PostFooterSection>,
  copyright: string,
}

const PostFooter = ({ title, items, copyright }: Props) => {
  const getIcon = index => {
    switch (index) {
      case 0:
        return <IconSamples />
      case 1:
        return <IconBookDemo />
      case 2:
      default:
        return <IconTalkExpert />
    }
  }
  return (
    <>
      <div className="bg-lightBeige22 pb-18 pt-5 md:pt-8">
        <Container>
          <Grid>
            <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 pb-5 leading-snug text-26px md:text-48px">
              <h3>{title}</h3>
            </div>
          </Grid>
        </Container>
        <hr />
        <Container>
          <Grid>
            <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 flex flex-col gap-50px md:grid md:grid-cols-3 md:gap-16 pt-8 md:pt-12 px-4 md:px-0">
              {items?.length > 0
                ? items.map(
                    ({ key, title, text, ctaLabel, urlform }, index) => (
                      <Link
                        to={urlform}
                        className="flex flex-col"
                        key={`${index}-${key}`}
                      >
                        <div className="w-auto h-15 mb-4">{getIcon(index)}</div>
                        <h4 className="text-20px md:text-24px mb-1 md:mb-3">
                          {title}
                        </h4>
                        <p className="mb-5 md:mb-7 text-16px leading-6">
                          {text}
                        </p>
                        <div className="inline-block self-start text-16px px-4 py-2 text-white whitespace-no-wrap bg-black border border-black focus:outline-none cursor-pointer hover:bg-grayFooter">
                          {ctaLabel}
                        </div>
                      </Link>
                    )
                  )
                : null}
            </div>
          </Grid>
        </Container>
      </div>
      <Container className="bg-black">
        <Grid>
          <div className="text-white w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <div className="py-4 text-14px">{copyright}</div>
          </div>
        </Grid>
      </Container>
    </>
  )
}

export default PostFooter
