// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Button from './Button'
import Appear from './Appear'
import { useLiterals } from 'store/'
import type { ApiImage } from '../types'
import FeaturedPrinter from './FeaturedPrinter'
import PrintersGallery from './PrintersGallery'
import useIsMobile from '../hooks/useIsMobile'

type FeaturedPrintersType = {
  id: string,
  image: ApiImage,
  title: string,
  url: string
}

type Props = {
  title: string,
  text: string,
  url: string,
  printers: Array<FeaturedPrintersType>,
  labelKey: string,
  noBg?: boolean,
  style?: string,
}

const RelatedPrinters = ({
  moduleName,
  id,
  title,
  text,
  printers,
  url,
  labelKey = 'view_all_the_products',
  noBg = false,
  textBlack = false,
  style,
}: Props) => {
  const isMobile = useIsMobile()
  printers.forEach(printer => printer.style = style)
  return (
    <Spacer
      top
      bottom
      className={`overflow-hidden text-black ${noBg ? '' : 'bg-white'}`}
    >
      <Container>
        <Grid>
          <div className='col-span-4 col-start-1 text-center md:col-span-12 md:col-start-1'>
            <Appear>
              <h3
                className={style === 'lion' ? 'leading-none text-40px text-black mb-8 md:mb-12' : `mb-6 text-big3 ${
                  textBlack ? 'text-black' : 'text-gray'
                }`}
              >
                {title}
              </h3>
            </Appear>
            <Appear>
              <p
                className={style === 'lion' ? 'text-20px mb-12 md:mb-16 text-black' : `mb-6 text-normal ${
                  textBlack ? 'text-black' : 'text-gray'
                }`}
              >
                {text}
              </p>
            </Appear>
            <Appear className={style === 'lion' ? 'mb-24' : ''}>
              <Button
                style='blackOutlined'
                title={useLiterals(labelKey)}
                href={url}
              />
            </Appear>
            <div
              className={`mt-12 md:mt-16 md:flex md:justify-start`}
            >
              {printers.length > 2 || isMobile ? (
                <PrintersGallery printers={printers} type="printersWithBorders" />
              ) : (
                printers.map(printer => (
                  <FeaturedPrinter
                    {...printer}
                    withLink={true}
                    className='w-full mb-8 md:w-1/3 md:mb-0'
                    i={0}
                    key={printer.id}
                    style={style}
                    type="printersWithBorders"
                  />
                ))
              )}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default RelatedPrinters
