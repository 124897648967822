// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from '../types'

type Props = {
  title: string,
  text: string,
  align: string,
  image: ApiImage,
  proportionalImageText?: number
}

const PrinterTitleText = ({ align, title, text, image, proportionalImageText }: Props) => {
  const textContent = (<div>
    <h3 className="mb-4 text-big1 md:mb-4"><Appear><Html text={title} /></Appear></h3>
    <div className="font-light text-small"><Appear><Html text={text} /></Appear></div>
  </div>)
  const imageContent = <div><LazyImage image={image} alt={title} /></div>
  return (
    <Spacer top bottom smaller>
      <Container>
        <Grid>
          {align === 'left' ? (
            <>
              <div className={`col-span-4 col-start-1 mb-12 md:flex md:flex-col md:justify-center ${proportionalImageText === 1 ? "md:col-span-4 md:col-start-4" : "md:col-span-3 md:col-start-4"} md:mb-0`}>
                {textContent}
              </div>
              <div className={`col-span-4 col-start-1 md:flex md:flex-col md:justify-center ${proportionalImageText === 1 ? "md:col-span-4 md:col-start-8" : "md:col-span-6 md:col-start-7"}`}>
                <div className="md:ml-8">
                  {imageContent}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-span-4 col-start-1 mb-12 md:flex md:flex-col md:justify-center md:col-span-4 md:col-start-4 md:mb-0">
                <div className="md:mr-8">
                  {imageContent}
                </div>
              </div>
              <div className="col-span-4 col-start-1 md:flex md:flex-col md:justify-center md:col-span-3 md:col-start-9">
                {textContent}
              </div>
            </>
          )}
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PrinterTitleText