// @flow
import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
  gradientHeading?: string,
  className?: string,
}

const GradientModule = ({ className, children, gradientHeading = ''}: Props) => {
  return (
    <div className={className} style={gradientHeading.length > 0 ? {
      backgroundImage: `linear-gradient(0deg, #${gradientHeading.replace('#', '')}00 0%, #${gradientHeading.replace('#', '')} 100%)`
    } : {}}>
      {children}
    </div>
  )
}

export default GradientModule