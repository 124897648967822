// @flow
import React, { useRef } from 'react'
import Bubble from './Bubble'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import TitleCtas from './TitleCtas'
import LazyImage from './LazyImage'
import ScrollViewer from './ScrollViewer'

type Props = {
  texture: Object,
  texture1: Object,
  texture2: Object,
  ctaUrl1: string,
  ctaUrl2: string,
  ctaUrl3: string,
  ctaText1: string,
  ctaText2: string,
  ctaText3: string,
  title: string,
  image360: Array<Object>,
  image: Object,
  imagezoom: Object,
  backgroundImage: Object
}

const DesignJet3 = ({
  image360,
  image,
}: Props) => {
  const viewerParent = useRef({})
  return (
    <Spacer className="relative">
      <Container
        className={`relative overflow-visible md:overflow-visible ${
          image360 ? 'bg-paleYellow' : ''
        }`}
      >
        <div
          className="flex justify-center col-span-4 md:col-start-3 md:col-span-8"
          ref={viewerParent}
        >
          {typeof image360 !== 'undefined' ? (
            <ScrollViewer parent={viewerParent} image360={image360} />
          ) : (
            <LazyImage alt={"Decoration image"} image={image} />
          )}
        </div>
      </Container>
    </Spacer>
  )
}

export default DesignJet3
