// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import Button from './Button'
import makeLayer from '../lib/makeLayer'
import Spacer from "./Spacer"
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  ctaTitle: string,
  ctaUrl: string,
  ctaPosition: "left" | "center" | "right",
  // inGradient: boolean,
  // verticalSlug: string,
  whiteParentBG?: boolean,
  backgroundColor?: string,
  spacerTop?: boolean
}

const CategoryBlock16 = ({
  ctaTitle,
  ctaUrl,
  ctaPosition,
  // inGradient,
  // verticalSlug,
  whiteParentBG = false,
  backgroundColor,
  spacerTop = false
}: Props) => {
  const isMobile = useIsMobile()
  function handlePosition(type: "column" | "flex") {
    if (type === "column") {
      switch (ctaPosition) {
        case "left": return "md:col-start-4"
        case "center": return "md:col-start-5"
        case "right": return "md:col-end-13"
      }
    }
    else if (type === "flex") {
      switch (ctaPosition) {
        case "left": return "justify-left"
        case "center": return "justify-center"
        case "right": return "justify-end"
      }
    }
  }

  return (
    <Container style={{ backgroundColor: backgroundColor ? backgroundColor : 'auto'}}>
      <Spacer top={spacerTop} smaller={!isMobile} small={isMobile}>
        <Grid>
          <div className={`col-start-1 col-span-4 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:justify-center md:mb-0 ${handlePosition("column")}`}>
            <Appear className={`w-full flex items-center ${handlePosition("flex")}`}>
              <Button
                href={ctaUrl}
                title={ctaTitle}
                style="black"
                dataLayer={makeLayer({
                  event: 'e_contactClick',
                  linkPlacement: 'content-gated',
                  linkID: ctaUrl,
                })}
              />
            </Appear>
          </div>
        </Grid>
      </Spacer>
    </Container>
  )
}

export default CategoryBlock16
