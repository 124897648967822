// @flow
import React from 'react'
import Appear from './Appear'
import Button from "./Button"
import Container from "./Container"

type Props = {
  title: string,
  cta1Text: string,
  cta2Text: string,
  cta1Url: string,
  cta2Url: string,
}

const TitleWithCtas = ({ title, cta1Text, cta2Text, cta1Url, cta2Url }: Props) => {
  return (
    <Container className="pt-6 pb-4 sm:pt-8 md:pt-12">
      <Appear>
        <h3 className="mb-8 leading-tight text-30px md:text-60px">
          {title}
        </h3>
      </Appear>
      <Appear className='flex items-center justify-start'>
        <div className='mr-4'>
          <Button title={cta1Text} href={cta1Url} style="whiteOutlineBlack"/>
        </div>
        <div>
          <Button title={cta2Text} href={cta2Url} style="whiteOutlineBlack"/>
        </div>
      </Appear>
    </Container>
  )
}

export default TitleWithCtas