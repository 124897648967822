/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazySimpleImage from './LazySimpleImage'
import LinkExternalHP from './LinkExternalHP'

import { useMedia, useLiterals } from 'store/'
import Appear from './Appear'
import Button from './Button'

type Item = {
  type: string,
  id: string,
  url: string
}
type Props = {
  text: string,
  url: string,
  items: Array<Item>,
  currentCountry: string,
  changeCountry: string
}

const Footer = ({ moduleName, text, url, items, currentCountry, changeCountry }: Props) => {
  const handleCountryChange = () => {
    const event = new CustomEvent('changeRegion', {
      detail: {
        open: true
      }
    })
    document.body.dispatchEvent(event)
  }
  return (
    <div className="relative py-12 z-top bg-black text-white md:py-12 w-full">
      <Container>
        <div className="w-full h-full flex justify-between items-center mb-8 md:mb-10">
          <span>
            <LinkExternalHP className="flex items-center text-lg" href={url} target="_blank">
              <span data-tag-name={`${moduleName}-largeformat`} className="pb-1 pr-2">{text}</span>
            </LinkExternalHP>
          </span>
          <LazySimpleImage
            imageClassName="h-10 transform scale-125"
            image={useMedia('hp')}
            alt={useLiterals('menu')}
          />
        </div>
        <Grid>
          <div className="col-span-4 col-start-1 md:col-span-6 md:col-start-1  md:flex md:flex-col md:items-start md:justify-end md:pt-4">
            <span>
              <Appear className='flex justify-end align-center'>
                <Button
                  target="_blank"
                  style="blackWithWhiteOutlined"
                  title={useLiterals('privacy')}
                  to={useLiterals('privacy_url')}
                  padded
                />
            </Appear>
            </span>

          </div>
          <div className="col-span-4 flex flex-col justify-end md:justify-between items-end md:col-end-13">
            <div className='text-right text-base'>
              <div
                onClick={handleCountryChange}
                className="text-white"
              >
                {currentCountry}
              </div>
              <div
                onClick={handleCountryChange}
                className="text-white cursor-pointer md:mt-1"
              >
                {changeCountry}
              </div>
            </div>
          
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
