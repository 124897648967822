// @flow

import React, { useState, useEffect, useRef } from 'react'
import useIsMobile from '../hooks/useIsMobile'
import LinkExternalHP from './LinkExternalHP'
import makeLayer from '../lib/makeLayer'

type Props = {
  ctaText1: string,
  ctaText2: string,
  ctaText3: string,
  url1: string,
  url2: string,
  points: Array,
  handleOpenModal: Function,
  ready: boolean,
  standHeight: number,
  clickedMenuButton: Function,
  inIframe: Boolean,
}

const Polestar3DMenu = ({
  ready,
  ctaText1,
  ctaText2,
  ctaText3,
  url1,
  url2,
  points,
  handleOpenModal,
  standHeight,
  clickedMenuButton,
  inIframe,
}: Props) => {
  const ref = useRef({})
  const menuRef = useRef({})
  const [menuOpen, setMenuOpen] = useState(false)
  const [iconInitialPosition, setIconInitialPosition] = useState()
  const [top, setTop] = useState()
  const [menuHeight, setMenuHeight] = useState()
  const [doubleColumnMenu, setDoubleColumnMenu] = useState()
  const [doubleList, setDoubleList] = useState()
  const isMobile = useIsMobile()

  const point12 = points.find(point => point.point === "POINT12")
  const index12 = points.indexOf(point12)
  if (point12 && points.includes(point12) && points[index12] === point12) {
    points.splice(index12, 1)
  }

  const OpenCloseIcon = <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
          <linearGradient x1="0%" y1="100%" x2="100%" y2="100%" id="linearGradient-1">
              <stop stopColor="#F66464" offset="0%"></stop>
              <stop stopColor="#0632FF" offset="48.0968045%"></stop>
              <stop stopColor="#4EB589" offset="100%"></stop>
          </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="06_HomeVB" transform="translate(-22.000000, -2352.000000)">
              <g id="Group-6" transform="translate(12.000000, 2352.000000)">
                  <g id="Group-3" transform="translate(10.000000, 0.000000)">
                      <circle id="Oval" stroke="url(#linearGradient-1)" strokeWidth="3" fill="#1D1E1F" cx="25" cy="25" r="23.5"></circle>
                      <g id="X" transform="translate(25.507215, 25.295830) rotate(45.000000) translate(-25.507215, -25.295830) translate(14.792930, 15.091749)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                          <line x1="10.5" y1="0" x2="10.5" y2="20" id="Line-3"></line>
                          <line x1="10.5" y1="5.25307797e-10" x2="10.5" y2="20" id="Line-3" transform="translate(10.500000, 10.000000) rotate(-90.000000) translate(-10.500000, -10.000000) "></line>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>

  const DownloadIcon =  <svg width="16" height="23" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="a" d="M0 .315h15.692v22.422H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -.05)">
        <path
          d="M14.77 21.79H1.693a.78.78 0 01-.77-.79V3.79h13.074c.427 0 .773.355.773.793V21.79zM12.308 1.367v1.476H5.123l7.185-1.476zm1.689 1.476h-.766V.79a.48.48 0 00-.17-.366.437.437 0 00-.381-.099L.372 2.852c-.017.002-.03.013-.045.017a.496.496 0 00-.155.087.43.43 0 00-.144.219.575.575 0 00-.019.096C.008 3.286 0 3.299 0 3.316v17.685c0 .957.76 1.736 1.693 1.736H15.23a.468.468 0 00.462-.473V4.584c0-.96-.76-1.741-1.696-1.741z"
          fill="#FFFFFF"
          mask="url(#b)"
        />
      </g>
      <path
        d="M9.981 14.299L8.31 16.015V8.32a.468.468 0 00-.462-.474.468.468 0 00-.461.474v7.696L5.712 14.3a.455.455 0 00-.654 0 .485.485 0 000 .67l2.462 2.527h.003a.45.45 0 00.645 0h.005l2.462-2.527a.485.485 0 000-.67.455.455 0 00-.654 0"
        fill="#FFFFFF"
      />
    </g></svg>

  const handleOpenMenu = () => {
    clickedMenuButton(!menuOpen)
    setMenuOpen(!menuOpen)
  }

  const splitPointsInTwoColumns = () => {
    const columnLength = Math.round(points.length / 2)
    const rightColumn = points.slice(columnLength)
    const leftColumn = points.slice(0, columnLength)
    const twoColumnsMenu = [leftColumn, rightColumn]
    setDoubleList(twoColumnsMenu)
  }

  const handlePointsColumns = () => {
    if (standHeight < (menuHeight + 32)) {
      setDoubleColumnMenu(true)
    } else {
      setDoubleColumnMenu(false)
    }
  }

  const handleButtonPosition = () => {
    if (ref.current) {
      if (document.body.clientWidth <= 768) {
        setIconInitialPosition(0)
        const menuHeight = menuRef.current.offsetHeight
        setMenuHeight(menuHeight)
      } else {
        const totalHeight = ref.current.offsetHeight
        const iconPosition = (totalHeight / 2) - 25
        setIconInitialPosition(iconPosition)
        const menuHeight = menuRef.current.offsetHeight
        setMenuHeight(menuHeight)
      }
    }
  }

  const handleTopDistance = () => {
    if (menuRef.current) {
      const top = menuOpen ? 25 : `-${menuRef.current.offsetHeight}px`
      setTop(top)
    }
  }

  useEffect(() => {
    splitPointsInTwoColumns()
    handleButtonPosition()
  }, [])

  useEffect(() => {
    handleTopDistance()
    handleButtonPosition()
  }, [menuOpen])

  useEffect(() => {
    handlePointsColumns()
    handleTopDistance()
  }, [standHeight])


  return ( 
  <div
    ref={ref}
    className={`relative duration-normal transition-opacity ${ready ? '' : 'opacity-0'} ${menuOpen ? '' : 'pointer-events-none'}`}
  >
    <div className={`overflow-hidden ${menuOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}>
      <div
        ref={menuRef}
        className="relative"
        style={{
          top,
          transition: 'top .5s',
          transitionDelay: !menuOpen ? '0s' : '.5s',
          paddingBottom: 32,
        }}
      >
        <div
          className="w-full px-4 pt-10 pb-8 rounded-lg"
          style={(isMobile) ? {
            backgroundColor: "rgba(29, 30, 31, 0.95)"
          } :
          {
            backgroundColor: "rgba(29, 30, 31, 0.75)"
          }
        }
        >
        {doubleColumnMenu ? <div className="flex flex-row items-start justify-start">
          {doubleList.map((column, index) => (
          <div key={index} className={`flex flex-col items-start justify-center ${index === 0 ? 'mr-4' : ''}`}>
            {column.map(item => {
              const { point, id, title, slug } = item
              const tagName = point.replace('POINT', 'cta')
              if (point) {
                return (
                  <div key={id} className="flex flex-row items-center justify-start pb-4">
                    <div
                      className="flex items-center justify-center flex-shrink-0 w-8 h-8 border border-white border-solid rounded-full cursor-pointer"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.25)"
                      }}
                      onClick={() => handleOpenModal(point)}
                      data-tag-name={tagName}
                      dataLayer={makeLayer({event: 'e_linkClick', linkPlacement: 'left-stand-navigation', linkID: slug})}
                    >
                      <div className="flex items-center justify-center w-5 h-5 bg-white rounded-full">
                        <span className="text-xs font-normal text-black font-primary">{`${point.replace( /^\D+/g, '')}`}</span>
                      </div>
                    </div>
                    <a
                      className="pl-6 text-sm font-light text-white cursor-pointer font-primary hover:underline"
                      onClick={() => handleOpenModal(point)}
                      data-tag-name={tagName}
                      dataLayer={makeLayer({event: 'e_linkClick', linkPlacement: 'left-stand-navigation', linkID: slug})}
                    >
                      {title}
                    </a>
                  </div>
                )
              }
            })}
          </div>
          ))}
        </div>
        : <div className="flex flex-col items-start justify-center">
            {points.map((item) => {
              const { point, id, title, slug } = item
              const tagName = point.replace('POINT', 'polestar-3D-cta')
              if (point) {
                return (
                  <div key={id} className="flex flex-row items-center justify-start pb-4">
                    <div
                      className="flex items-center justify-center flex-shrink-0 w-8 h-8 border border-white border-solid rounded-full cursor-pointer"
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.25)"
                      }}
                      data-tag-name={tagName}
                      dataLayer={makeLayer({event: 'e_linkClick', linkPlacement: 'left-stand-navigation', linkID: slug})}
                      onClick={() => handleOpenModal(point)}
                    >
                      <div className="flex items-center justify-center w-5 h-5 bg-white rounded-full">
                        <span className="text-xs font-normal text-black font-primary">{`${point.replace( /^\D+/g, '')}`}</span>
                      </div>
                    </div>
                    <a
                      className="pl-6 text-sm font-light text-white cursor-pointer font-primary hover:underline"
                      onClick={() => handleOpenModal(point)}
                      data-tag-name={tagName}
                      dataLayer={makeLayer({event: 'e_linkClick', linkPlacement: 'left-stand-navigation', linkID: slug})}
                    >
                      {title}
                    </a>
                  </div>
                )
              }
            })}
          </div>
        }
        <div className="flex flex-col py-4">
          <LinkExternalHP href={`${url1.trim()}${inIframe ? '?iniframe=iniframe' : ''}`} data-tag-name="polestar-3D-cta12" dataLayer={makeLayer({event: 'e_contactClick', linkPlacement: 'left-stand', linkID: 'live-product-demo'})} className="mb-3 text-sm font-light cursor-pointer text-blue font-primary hover:underline">{ctaText1}</LinkExternalHP>
          <LinkExternalHP href={`${url2.trim()}${inIframe ? '?iniframe=iniframe' : ''}`} data-tag-name="polestar-3D-cta13" dataLayer={makeLayer({event: 'e_contactClick', linkPlacement: 'left-stand', linkID: 'contact-expert'})} className="text-sm font-light cursor-pointer text-blue font-primary hover:underline">{ctaText2}</LinkExternalHP>
        </div>
        <div 
          className="rounded p-2px"
          style={{
            backgroundImage: 'linear-gradient(to right, #F66464, #0632FF, #4EB589)'
          }}
          onClick={() => handleOpenModal('POINT12')}
          data-tag-name="polestar-3D-cta14"
        >
          <div className="p-3 bg-black rounded">
            <div className="flex flex-row items-center justify-center text-sm text-white cursor-pointer font-primary text-light">
              <div className="flex-shrink-0">{DownloadIcon}</div>
                <a className="ml-3 text-center"dataLayer={makeLayer({event: 'e_contactClick', linkPlacement: 'left-stand', linkID: 'content-download'})}>{ctaText3} </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="absolute ml-4 cursor-pointer pointer-events-auto"
      style={{
        top: menuOpen ? '0' : iconInitialPosition,
        transform: !menuOpen ? 'rotate(45deg)' : 'rotate(0deg)',
        transition: menuOpen ? 'transform .5s, top .5s' : 'top .5s, transform .5s' ,
        transitionDelay: menuOpen ? '0s' : '.3s'
      }}
      onClick={() => handleOpenMenu()}
      dataLayer={makeLayer({
        event: 'e_linkClick',
        linkPlacement: 'left-stand-navigation',
        linkID: !menuOpen ? 'open-left-stand-navigation' : 'close-left-stand-navigation'
      })}
    >
      {OpenCloseIcon}
    </div>
  </div>
  )
}


export default Polestar3DMenu

// meeting room
// print samples