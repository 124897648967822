// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import Button from './Button'
import PolestarDiscoverCard from './PolestarDiscoverCard'
import PrintersGallery from './PrintersGallery'

type Props = {
  title: string,
  subtitle: string,
  ctaText: string,
  url: string,
  items: Array<Object>,
}

const TyphoonPrinters = ({
  title,
  subtitle,
  items,
  ctaText,
  url,
}: Props) => {

  return (
      <Container backgroundColor="bg-white" className="pt-20 pb-10 overflow-hidden md:py-32">
        <Grid>
          <div className="col-span-4 leading-none text-center md:col-start-5 text-30px md:text-40px">
            <Appear>{title}</Appear>
          </div>
          <div className="col-span-4 my-6 text-lg font-light leading-tight text-center md:my-10 md:col-span-6 md:col-start-4 md:text-20px">
            <Appear>{subtitle}</Appear>
          </div>
          <div className="col-span-4 mx-auto md:col-start-2 md:col-start-5">
            <Appear><Button style="black" title={ctaText} href={url} padded textClasses="text-center"/></Appear>
          </div>
          <div className='col-span-4 col-start-1 mt-12 text-center md:hidden md:mt-16 md:col-span-12'>
            <Appear>
              <div
                className='ml-12'
              >
                <PrintersGallery printers={items} typhoonPrinters />
              </div>
            </Appear>
          </div>
          <div className="hidden col-span-10 col-start-2 mt-32 md:block">
            <div className="grid grid-cols-9 gap-4">
              {items.map(printer => (
                <div className="col-span-3 text-left" key={printer.id}>
                <PolestarDiscoverCard 
                {...printer.options}
                key={printer.id}
                selectNone={true}
                type="typhoon"
                typhoonPrinters
              />
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
  )
}

export default TyphoonPrinters
