// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import Button from './Button'
import Markdown from './Markdown'
import { useMedia } from '../store'
import makeLayer from '../lib/makeLayer'

type Props = {
  pageName: [String],
  position: string,
  image: ApiImage,
  text: string,
  ctaText: string,
  ctaUrl: string,
  title: string,
  supertitle: string,
  urlForm: any,
  anchor: string,
  style?: string,
}

const RequestFreeSample = ({
  image,
  text,
  ctaText,
  ctaUrl,
  title,
  supertitle,
  urlForm,
  anchor,
  style,
  pageName,
}: Props) => {
  const mainContent = (hidden = false) => (
    <Container
      className={`relative z-10 py-12 md:py-20 lg:py-24 bg-black md:bg-transparent ${
        hidden ? 'invisible' : ''
      }`}
    >
      <Grid>
        <div className="flex items-stretch col-span-4 col-start-1 md:col-span-5 md:col-start-8">
          <div>
            <Appear>
              <h5 className="mb-2 text-normal">{supertitle}</h5>
              <h3 className="mb-4 font-secondary md:mb-8 text-big3">{title}</h3>
            </Appear>
            <Appear>
              <div className="font-light">
                <Html text={text} />
              </div>
            </Appear>
            <div className="flex mt-4 md:mt-10">
              <div data-anchor={anchor} />
              {ctaText && (
                <Appear>
                  <Button
                    name="request-free-sample"
                    style="white"
                    title={ctaText}
                    href={urlForm === false ? ctaUrl : ''}
                    to={urlForm}
                    dataLayer={makeLayer({
                      event: 'e_contactClick',
                      linkPlacement: 'footer',
                      linkID: 'free-sample-kit',
                    })}
                    textClasses="text-normal"
                  />
                </Appear>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center flex-shrink-0 w-1/3 ml-4 md:ml-8">
            <div className="relative w-full h-20 bg-white rounded-full pt-full">
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                <LazySimpleImage
                  imageClassName="w-20"
                  image={useMedia('free-sample')}
                  alt={title}
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Container>
  )

  if (style === '630-printer') {
    return (
      <div
        className="bg-darkgray flex h-30rem md:h-25rem lg:h-30rem sm:w-47/100 rounded-lg overflow-hidden flex-col md:flex-row"
        id="requestFreeSample"
      >
        <div className="h-2/3 md:h-full md:w-1/2 flex flex-col justify-between">
          <div>
            <Appear>
              <h3 className="font-secondary text-big2 p-5">{title}</h3>
              <div className="w-full h-1px bg-graySlider"></div>
            </Appear>
            <Appear>
              <Markdown text={text} className="p-5" />
            </Appear>
          </div>
          <div className="flex p-5">
            <div data-anchor={anchor} />
            {ctaText && (
              <Appear>
                <Button
                  name="request-free-sample"
                  style="outlineWhite"
                  textClasses="text-white text-normal hover:text-black"
                  title={ctaText}
                  href={urlForm === false ? ctaUrl : ''}
                  to={urlForm}
                  dataLayer={makeLayer({
                    event: 'e_contactClick',
                    linkPlacement: 'footer',
                    linkID: 'free-sample-kit',
                  })}
                  padded
                />
              </Appear>
            )}
          </div>
        </div>
        <div className="h-1/3 md:h-full md:w-1/2 overflow-hidden">
          <LazyImage
            cover
            image={image}
            alt={title}
            className="w-full h-full"
          />
        </div>
      </div>
    )
  }

  if (style === 'underline') {
    return (
      <Grid noGap>
        <div className="col-span-4 col-start-1 row-start-2 md:col-span-7 md:row-start-1">
          <LazyImage cover image={image} alt={title} />
        </div>
        <div className="flex flex-col justify-between col-span-4 col-start-1 row-start-1 pt-12 text-white bg-black md:row-start-1 md:col-start-8 md:col-span-5">
          <Appear>
            <h3 className="w-2/3 px-4 leading-none text-30px md:text-50px xl:mr-auto lg:w-1/2">
              {title}
            </h3>
            <div className="w-full my-6 border-b border-white" />
            <div className="px-4 pb-12 md:w-3/4 xl:mr-auto">
              <Markdown text={text} />
            </div>
          </Appear>
          {ctaText && (
            <Appear className="p-4 pb-8">
              <Button
                title={ctaText}
                to={urlForm || ctaUrl}
                style="outlineWhite"
                textClasses="text-white hover:text-black"
                padded
                dataLayer={
                  pageName[3] === 'hp-professional-print-service'
                    ? makeLayer({
                        event: 'e_contactClick',
                        linkPlacement: 'professional-print-service',
                        linkID: 'free-sample-kit',
                      })
                    : ''
                }
              />
            </Appear>
          )}
        </div>
      </Grid>
    )
  } else if (style === 'lowerline' || style?.toString() === '-1') {
    return (
      <Grid noGap>
        <div className="col-span-4 md:col-span-12 md:row-start-1 h-60vh order-2">
          <LazyImage cover image={image} alt={title} />
        </div>
        <div className="col-span-4 p-6 leading-none font-secondary bg-skyBlue text-30px md:text-50px md:col-span-3 order-1">
          {title}
        </div>
        <div className="flex flex-col justify-around col-span-4 p-4 bg-skyBlueLight md:col-span-7 order-1">
          <div className="w-7/10">
            <Markdown className="text-lg" text={text} />
          </div>
          <div className="mt-6">
            <Button
              title={ctaText}
              to={urlForm || ctaUrl}
              style="black"
              textClasses="text-white"
              padded
            />
          </div>
        </div>
        <div className="flex items-center justify-center col-span-4 py-6 md:col-span-2 md:py-0 order-1">
          <LazySimpleImage
            imageClassName="w-20"
            image={useMedia('free-sample')}
            alt={title}
          />
        </div>
      </Grid>
    )
  }

  if (style === 'visid') {
    return (
      <Container>
        <Grid>
          <div
            className="relative flex flex-row col-span-4 text-black md:col-span-10 md:col-start-2"
            data-anchor={anchor}
          >
            <div className="w-1/3 md:w-1/2">
              <LazyImage cover image={image} alt={title} />
            </div>
            <div className="flex flex-col w-2/3 md:w-1/2 bg-orange">
              <div className="flex flex-col justify-center p-3 md:p-6">
                <Appear>
                  {supertitle ? (
                    <h5 className="mb-2 text-normal">{supertitle}</h5>
                  ) : null}
                  <h3 className="text-big2">{title}</h3>
                </Appear>
              </div>
              <div className="flex-grow p-3 bg-nude md:p-6">
                <div className="col-span-4 col-start-1 md:col-span-4 md:col-start-2">
                  <Appear>
                    <div className="text-sm font-light md:text-20px">
                      <Html text={text} />
                    </div>
                  </Appear>
                </div>
                <div className="col-span-4 col-start-1 md:col-span-5 md:col-start-2">
                  <div className="flex mt-4 md:mt-6">
                    <Appear>
                      {ctaText && ctaUrl && (
                        <Button
                          title={ctaText}
                          to={urlForm || ctaUrl}
                          style="outlineOnly"
                          padded
                        />
                      )}
                    </Appear>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    )
  }

  return (
    <div className="relative text-white">
      <div>
        <div className="h-50vw md:h-full">{mainContent(true)}</div>
        {mainContent(false)}
        <div className="absolute top-0 bottom-0 left-0 right-0 z-0 w-full h-50vw md:h-auto bg-grayLight">
          <LazyImage cover image={image} alt={title} />
        </div>
        <div className="absolute bottom-0 right-0 z-0 w-full bg-black md:w-1/2 h-1/2" />
      </div>
    </div>
  )
}

export default RequestFreeSample
