// @flow

import React from 'react'
import Html from './Html'
import LazyImage from './LazyImage'
import LinkHP from './LinkHP'

type Props = {
  image: Object,
  title: string,
  subtitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  ctaText2: string,
  ctaUrl2: string,
}


const PolestarHeading = ({ image, title, subtitle, text, ctaText, ctaText2, ctaUrl, ctaUrl2}: Props) => {

  const shouldScrollToStand = () => {
    const element = document.querySelector(`[data-stand]`)
    const yOffset = -100
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
    window.history.replaceState(null, null, '#!3dvirtualbooth')
  }
  return (
    <div className='flex mt-menu-mobile md:mt-menu-desktop'>
      <div className='md:w-1/3 w-full leading-none bg-lightBlue23 flex flex-col justify-between'>
        <div>
          <div className='p-6 bg-darkBlue23 text-20px md:text-30px uppercase'>
            <Html text={subtitle} />
          </div>
          <div className='p-6 bg-blueSld text-50px font-secondary'>{title}</div>
          <div className='p-6 text-base md:text-20px'>
            <Html text={text} />
          </div>
        </div>
          {title && ctaText && !ctaUrl && 
            <div className="p-6 flex justify-center md:justify-start">
              <span data-tag-name="polestar-heading-cta1" onClick={shouldScrollToStand} className="inline-block text-white transition bg-black border border-black cursor-pointer hover:bg-white hover:text-black duration-250">
                <span className="block w-full h-full px-4 py-2 text-base text-center md:text-xl md:px-4 lg:px-6 md:py-3 text-small">
                  {ctaText}
                </span>
              </span>
            </div>
          }
        </div>
      <div className='hidden md:block w-2/3 h-auto relative'>
        <LazyImage image={image} alt={title} cover/>
        {ctaText2 && ctaUrl2 &&
          <LinkHP
            data-tag-name="polestar-heading-cta2"
            href={ctaUrl2}
            to={ctaUrl2}
            className={`z-10 bottom-8 right-8 absolute inline-block text-white transition bg-transparent p-2 border border-white cursor-pointer hover:bg-white hover:text-black duration-250`}
          >
            <span className="text-lg md:text-xl font-primary">{ctaText2}</span>
            <div className="flex-shrink-0 ml-2 font-primary">
            </div>
          </LinkHP>
        }
      </div>
    </div>
  )
}

export default PolestarHeading
