// @flow
import React from 'react'
import Container from './Container'
import Html from './Html'
import LazyImage from './LazyImage'
import SimpleButton from './SimpleButton'
import Button from './Button'
import type { ApiImage } from "../types/ApiImage"
import type { ContainerProps } from "./Container"

type PrintersSpecificationsType = {
  id: string,
  options: {
    image: ApiImage,
    title: string,
    ctaText: string,
    ctaUrl: string,
    text: string,
    ctaText2?: string,
    ctaUrl2?: string,
    ctaText3?: string,
    ctaUrl3?: string,
    ctaText4?: string,
    ctaUrl4?: string,
    anchor?: string,
  }
}

type Props = {
  title: string,
  anchor?: string,
  items: Array<PrintersSpecificationsType>,
  annotation: string,
}

const PrinterSpecifications = ({ title, anchor, items, annotation }: Props): React$Element<(_0: ContainerProps) => React$Element<"div">> => {
  return (
    <Container customPadding="px-5 md:px-10 pt-16 pb-10" anchor={anchor}>
      <div className="flex justify-center">
        <div
          className={`personalized-big md:text-56px text-36px text-white  ${
            items.length === 1 ? 'w-full' : 'md:1/2 lg:w-1/3  w-4/5'
          } leading-none text-center pb-6`}
        >
          <Html text={title} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-8">
        {items.map((item, index) => {
          return (
            <div
              className={`text-white ${
                items.length === 1 ? 'col-span-1 sm:col-span-4 md:col-start-3' : 'col-span-1 sm:col-span-2'
              } flex justify-center flex-col text-center p-2 md:p-0  ${
                index % 2 === 0
                  ? 'sm:col-start-1 md:col-start-2'
                  : 'sm:col-start-3 md:col-start-6'
              }`}
              key={item.id}
            >
              <div className="flex justify-center w-full">
                <LazyImage
                  key={item.options.text}
                  alt={item.options.text}
                  image={item.options.image}
                  className={`pt-10 ${items.length === 1 ? 'w-1/2' : ''} `}
                />
              </div>
              <h4 className="mb-2 text-20px md:text-24px">
                {item.options.title}
              </h4>
              <div className="mt-2 mb-4 text-sm md:text-base">
                <SimpleButton
                  href={item.options.ctaUrl3}
                  title={item.options.ctaText3 || ''}
                  color="blue"
                />
              </div>
              <span className="w-8 m-auto mt-0 mb-4 border border-b border-gray" style={{
                borderColor: '#2d2d2d'
              }}></span>
              <div className="mt-1 mb-6 text-xs uppercase text-gray" data-anchor={item.options.anchor}>
                { item.options.ctaText }
              </div>
              <div className="text-12px md:text-sm">
                <Html text={item.options.text} />
              </div>
              <div className="flex justify-center gap-4 mt-10">
                {item.options.ctaUrl2 && (
                  <Button
                    textClasses="text-base md:text-lg md2:text-xl"
                    href={item.options.ctaUrl2}
                    title={item.options.ctaText2 || ''}
                    style="blackWithWhiteOutlined"
                  />
                )}
                {item.options.ctaUrl4 && (
                  <Button
                    textClasses="text-base md:text-lg md2:text-xl"
                    href={item.options.ctaUrl4}
                    title={item.options.ctaText4 || ''}
                    style="blackWithWhiteOutlined"
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div>
        <p className="pt-10 text-sm text-grayText">{annotation}</p>
      </div>
    </Container>
  )
}

export default PrinterSpecifications
