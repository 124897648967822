// @flow
import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import { useMedia } from 'store/'
import youtubeParser from '../lib/youtubeParser'
import loadYoutube from '../lib/loadYoutube'
import makeLayer from '../lib/makeLayer'

type Props = {
  url: string,
  image: ApiImage,
  id: string,
  noGrid: boolean,
  centerPlay?: boolean,
  dataTagName?: string,
  insideContainer?: boolean,
  anchor: string,
  padding?: string,
}

const Video = ({
  url,
  image,
  noGrid = false,
  centerPlay = false,
  extraId,
  dataTagName,
  insideContainer = false,
  anchor = '',
  padding = '',
  extraProps,
}: Props) => {
  const [playing, setPlaying] = useState(false)
  const [player, setPlayer] = useState(false)

  const togglePlaying = () => {
    if (!playing) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'e_videoPlay',
        videoName: url,
      })

      setPlaying(true)
      player.playVideo()
    }
  }

  const loadVideo = () => {
    loadYoutube(() => {
      const id = youtubeParser(url)
      const p = new window.YT.Player(`youtube-player-${id}-${extraId}`, {
        videoId: id,
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        host: `${window.location.protocol}//www.youtube.com`,
      })
      setPlayer(p)
    })
  }

  useEffect(() => {
    if (!player) {
      loadVideo()
    }
  }, [])

  if (noGrid) {
    return (
      <div data-anchor={anchor}>
        <div
          dataLayer={makeLayer({
            event: 'e_videoPlay',
            videoName: dataTagName,
          })}
          onClick={togglePlaying}
          className="relative w-full overflow-hidden"
          style={{ paddingTop: '56.2%' }}
        >
          <div
            className={`absolute top-0 left-0 w-full h-full ${
              playing ? 'visible' : 'invisible'
            }`}
          >
            <div
              className="w-full h-full"
              id={`youtube-player-${youtubeParser(url)}-${extraId}`}
            ></div>
          </div>
          <div
            className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 ${
              playing ? 'hidden' : ''
            }`}
          >
            <div className="relative">
              <LazyImage image={image} alt="Video" cover />
              <div
                className={`${
                  centerPlay
                    ? 'top-0 right-0 flex items-center justify-center'
                    : ''
                } absolute bottom-0 left-0 cursor-pointer`}
                style={
                  centerPlay ? {} : { marginBottom: '6%', marginLeft: '2%' }
                }
              >
                <LazySimpleImage
                  image={useMedia('player')}
                  alt="Video"
                  imageClassName="w-10 h-10 md:w-auto md:h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (insideContainer) {
    return (
      <div data-anchor={anchor}>
        <div className="grid grid-cols-4 gap-4 px-4 pb-8 md:grid-cols-11 md:pb-40 md:pr-4 md:pl-0">
          <div className="col-span-4 md:col-start-2 md:col-span-10">
            <div
              dataLayer={makeLayer({
                event: 'e_videoPlay',
                videoName: dataTagName,
              })}
              onClick={togglePlaying}
              className="relative w-full overflow-hidden"
              style={{ paddingTop: '56.2%' }}
            >
              <div
                className={`absolute top-0 left-0 w-full h-full ${
                  playing ? 'visible' : 'invisible'
                }`}
              >
                <div
                  className="w-full h-full"
                  id={`youtube-player-${youtubeParser(url)}-${extraId}`}
                ></div>
              </div>
              <div
                className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 ${
                  playing ? 'hidden' : ''
                }`}
              >
                <div className="relative">
                  <LazyImage image={image} alt="Video" />
                  <div
                    className={`${
                      centerPlay
                        ? 'top-0 right-0 flex items-center justify-center'
                        : ''
                    } absolute bottom-0 left-0 cursor-pointer`}
                    style={
                      centerPlay ? {} : { marginBottom: '6%', marginLeft: '2%' }
                    }
                  >
                    <LazySimpleImage
                      image={useMedia('player')}
                      alt="Video"
                      imageClassName="w-10 h-10 md:w-auto md:h-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (extraProps.isBlogPost) {
    return (
      <div data-anchor={anchor}>
        <Spacer smaller top bottom small>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-12">
              <div
                onClick={togglePlaying}
                className="relative w-full"
                style={{ paddingTop: '56.2%' }}
              >
                <div
                  className={`absolute  top-0 left-0 w-full h-full ${
                    playing ? 'visible' : 'invisible'
                  }`}
                >
                  <div
                    className="w-full h-full"
                    id={`youtube-player-${youtubeParser(url)}-${extraId}`}
                  ></div>
                </div>
                <div
                  className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 overflow-hidden ${
                    playing ? 'hidden' : ''
                  }`}
                >
                  <LazyImage image={image} alt="Video" cover />
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center cursor-pointer">
                    <LazySimpleImage image={useMedia('player')} alt="Video" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Spacer>
      </div>
    )
  }

  return (
    <div data-anchor={anchor}>
      <Spacer smaller top bottom small>
        <Container>
          <Grid>
            <div
              className={`col-span-4 col-start-1 ${
                padding === 'small'
                  ? 'md:col-span-12'
                  : 'md:col-span-8 md:col-start-3'
              }`}
            >
              <div
                onClick={togglePlaying}
                className="relative w-full"
                style={{ paddingTop: '56.2%' }}
              >
                <div
                  className={`absolute top-0 left-0 w-full h-full ${
                    playing ? 'visible' : 'invisible'
                  }`}
                >
                  <div
                    className="w-full h-full"
                    id={`youtube-player-${youtubeParser(url)}-${extraId}`}
                  ></div>
                </div>
                <div
                  className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 ${
                    playing ? 'hidden' : ''
                  }`}
                >
                  <LazyImage image={image} alt="Video" />
                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center cursor-pointer">
                    <LazySimpleImage image={useMedia('player')} alt="Video" />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default Video
