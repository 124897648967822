// @flow

import React from 'react'

type PodcastType = {
  id: string,
  title: string,
  urlId: string,
}

type Props = { items: Array<PodcastType> }
const PodcastSelector = ({ items }: Props) => {
  const thisPodcast = items?.[0] ?? null
  return thisPodcast ? (
    <iframe
      className="w-full"
      style={{ height: 152 }}
      src={`https://www.podbean.com/media/player/${thisPodcast.urlId}?from=yiiadmin&download=1&version=1&skin=1&btn-skin=107&auto=0&share=1&fonts=Helvetica&download=1&rtl=0&pbad=1`}
      frameBorder="0"
    ></iframe>
  ) : null
}

export default PodcastSelector
