// @flow

import React, { useEffect, useState } from 'react'
import Grid from './Grid'
import Container from './Container'
import Html from './Html'
import Appear from './Appear'
import type { ApiImage } from '../types'
import makeAssetUrl from '../lib/makeAssetUrl'
import useIsMobile from '../hooks/useIsMobile'

type Video = {
  file: string,
}

type Props = {
  title: string,
  subtitle: string,
  backgroundImage?: ApiImage,
  backgroundImageMobile?: ApiImage,
  video?: Video,
  videoMobile?: Video,
}

const TyphoonHeadingWide = ({ title, subtitle, backgroundImage, backgroundImageMobile, video, videoMobile }: Props) => {
  const isMobile = useIsMobile()
  const [videoFile, setVideoFile] = useState()

  useEffect(() => {
    if (isMobile) {
      const file = videoMobile ? makeAssetUrl({ asset: videoMobile, isImage: false }) : null
      setVideoFile(file)
    } else {
      const file = video ? makeAssetUrl({ asset: video, isImage: false }) : null
      setVideoFile(file)
    }
  }, [isMobile])

  return (
    <div
      className={`flex items-center justify-center w-full bg-gray ${videoFile ? '' : 'h-screen'}`}
      style={{
          backgroundImage: `url(${makeAssetUrl({ asset: isMobile ? backgroundImageMobile : backgroundImage, isImage: true })})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
    >
    {videoFile ? (
      <div
        className="relative w-full h-full bg-black mt-menu-mobile md:mt-menu-desktop"
      >
        <video
          key={isMobile ? 'mobile' : 'desktop'}
          autoPlay
          controls={false}
          muted
          height="100%"
          width="100%"
        >
          <source
            src={videoFile}
            type="video/mp4"
          />
        </video>
        <div className="absolute top-0 bottom-0 left-0 right-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)'}} />
        <Container className="absolute top-0 bottom-0 left-0 right-0">
        <Grid className="flex items-center justify-center h-full">
          <div className="col-span-4 text-center text-white md:col-start-3 md:col-span-8">
            <Appear>
              <h1 className="mb-10 leading-tight text-40px md:text-70px">
                <Html text={title} />
              </h1>
              <div className="mb-8 text-22px md:text-50px">
                <Html text={subtitle} />
              </div>
            </Appear>
          </div>
        </Grid>
      </Container>
      </div>
    ) : (
      <Container className="md:mt-menu-desktop mt-menu-mobile">
        <Grid>
          <div className="col-span-4 text-center text-white md:col-start-3 md:col-span-8">
            <Appear>
              <h1 className="mb-10 leading-tight text-40px md:text-70px"><Html text={title} /></h1>
              <div className="mb-8 text-22px md:text-50px"><Html text={subtitle} /></div>
            </Appear>
          </div>
        </Grid>
      </Container>
    )}
    </div>
  )
}

export default TyphoonHeadingWide
