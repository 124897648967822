// @flow
import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import BlogHomePost from './BlogHomePost'

type Props = {
  post: any,
  hidden: boolean,
}

const Blog4 = ({ post, hidden }: Props) => {
  if (hidden) return null
  return (
    <Spacer>
      <Container className="pb-8">
        <BlogHomePost {...post}/>
      </Container>
    </Spacer>
  )
}

export default Blog4