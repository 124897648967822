// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from './Markdown'
import Button from './Button'
import Appear from './Appear'
import makeLayer from '../lib/makeLayer'
import type { ApiImage } from "../types/ApiImage"

type ItemProps = {
  key: string,
  image: ApiImage,
  title: string,
  text: string,
  cta1Text?: string,
  cta1Url?: string,
  cta2Text?: string,
  cta2Url?: string,
}

type Props = {
  title?: string,
  items: Array<ItemProps>,
  pageName: [String],
}

const InfoCards = ({ title, items, pageName }: Props): React$Node => {
  return (
    <Container className="pt-6 md:pb-8 md:pt-12">
      <Grid>
        {items.map(item => {
          return (
            <Appear
              key={item.key}
              className="col-span-4 sm:col-span-2 md:col-span-4"
            >
              <h4 className="pb-4 mt-4 mb-6 leading-tight border-b border-black sm:mt-8 md:mt-16 text-20px md:text-30px">
                {<Markdown text={item.title} className="mt-6" />}
              </h4>
              <div className="w-full md:h-30vh">
                <LazyImage cover image={item.image} alt={title} />
              </div>
              <Markdown text={item.text} className="mt-6" />
              {item.cta1Text && (
                <div className="my-6">
                  <Button
                    style="blackOutlined"
                    href={item.cta1Url}
                    title={item.cta1Text}
                    padded
                    dataLayer={
                      pageName[3] === 'hp-professional-print-service'
                        ? makeLayer({
                            event: 'e_contactClick',
                            linkPlacement: 'professional-print-service',
                            linkID: 'contact-expert',
                          })
                        : ''
                    }
                  />
                </div>
              )}
            </Appear>
          )
        })}
      </Grid>
    </Container>
  )
}

export default InfoCards
