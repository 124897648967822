// @flow

import React, { useEffect } from 'react'
import ModuleIterator from 'components/ModuleIterator'
import Layout from '../components/Layout'
import Bax from '../components/Bax'
import { Helmet } from 'react-helmet'
import shouldScrollTo from '../lib/shouldScrollTo'
import { GlobalLiterals, GlobalMedia } from 'store/'
import findGetParameter from '../lib/findGetParameter'
import type { Meta, Module, KeyValue, alternateUrl } from 'types/'
import { getCookie, setCookie } from '../lib/cookies'

type Props = {
  meta: Meta,
  structure: Array<Module>,
  literals: {},
  media: Array<KeyValue>,
  language: string,
  pageName: Array<String>,
  alternateUrls?: Array<alternateUrl>,
}

const Page = ({
  meta,
  structure = [],
  literals,
  media,
  language,
  pageName,
  alternateUrls,
}: Props): React$Element<React$FragmentType> => {
  const {
    title,
    description,
    favicon,
    canonical,
    author,
    publicUrl,
    image,
    hreflang,
  }: Meta = meta

  const isBlogPost =
    typeof window !== 'undefined'
      ? window.location.pathname.match(new RegExp(/blog\/./g))
      : false

  // Handle user activity time tracking
  const currentTime = Date.now() / 1000
  let userActivityCookie = getCookie('userJourney')
  const viewing = userActivityCookie && userActivityCookie.viewing

  if (userActivityCookie && userActivityCookie.history[viewing]) {
    let lastTimestamp =
      userActivityCookie.history[viewing] &&
      userActivityCookie.history[viewing].accessed
    userActivityCookie.history[viewing] = {
      viewingTime:
        userActivityCookie.history[viewing].viewingTime +
        currentTime -
        lastTimestamp,
    }

    delete userActivityCookie.viewing

    setCookie('userJourney', userActivityCookie)
  }

  const loadScript = ({ innerHTML, src, id, element = 'script', head = false }) => {
    const existingScript = document.getElementById(id)
    if (!existingScript) {
      const script = document.createElement(element)
      script.id = id
      if (innerHTML) {
        script.innerHTML = innerHTML
      }
      if (src) {
        script.src = src
      }
      if (head) {
        document.getElementsByTagName('head')[0].appendChild(script)
      } else {
        document.body.appendChild(script)
      }
    }
  }

  useEffect(() => {
    loadScript({
      innerHTML: `
      var _elqQ = _elqQ || [];
      _elqQ.push(['elqSetSiteId', '704062726']);
      _elqQ.push(['elqUseFirstPartyCookie', 'reinvent.hp.com']);
      _elqQ.push(['elqTrackPageView']);
      (function() {
        function async_load() {
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//images.marketingcontent.ext.hp.com/i/elqCfg.min.js';
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }
        async_load()
      })();
      `,
      id: 'eloqua',
    })

    loadScript({
      innerHTML: `
      var hashedem = "";
      function CreateRow(label, value) {
        var p = document.createElement('p');
        var b = document.createElement('b');
        p.appendChild(b);
        b.appendChild(document.createTextNode(label));
        p.appendChild(document.createTextNode(value));
        document.getElementById('contactinfo').appendChild(p);
      }

      var hashedemEvent = new Event('hashedemTrigger');

      function SetElqContent() {

        if (this.GetElqContentPersonalizationValue) {
          hashedem = GetElqContentPersonalizationValue('V_Email_Address');
          console.log("----------------------");
          console.log(hashedem);
          console.log("----------------------");

          if (!!hashedem && hashedem.length > 0) {
            document.dispatchEvent(hashedemEvent);
          }    
        } else {
          console.log('lookup failed');
        }
      }
      `,
      id: 'eloqua2',
    })

    loadScript({
      innerHTML: `
      _elqQ.push(['elqDataLookup', escape('bc123b08ae984a4a9a383d59eba8f067'), '']);
      `,
      id: 'eloqua3',
    })

    if (process.env.NODE_ENV !== 'dev') {
      loadScript({
        src: '/privacy-banner3.js',
        id: 'privacy-banner',
      })

      loadScript({
        innerHTML: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1869805,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        id: 'hotjar',
        head: true,
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const taggedElements = document.querySelectorAll(
        '[data-layer],[datalayer]'
      )
      taggedElements.forEach(taggedElement => {
        taggedElement.addEventListener('click', () => {
          let dataJson = taggedElement.getAttribute('data-layer')
          if (!dataJson) {
            dataJson = taggedElement.getAttribute('datalayer')
          }
          try {
            const data = dataJson && JSON.parse(dataJson)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(data)
          } catch (e) {
            console.log('Error parsing JSON')
          }
        })
      })
    }, 300)
  }, [])

  if (typeof window !== 'undefined') {
    useEffect(() => {
      if (window.location.hash && window.location.hash.length > 0) {
        const parts = window.location.hash.split('#!')
        const lastPart = parts[parts.length - 1]
        const anchors = document.querySelectorAll('[data-anchor]')
        anchors.forEach(element => {
          const dataAnchor = element.getAttribute('data-anchor')
          if (dataAnchor && lastPart === `#${dataAnchor}`) {
            setTimeout(() => {
              shouldScrollTo(`[data-anchor="${dataAnchor}"]`)
            }, 1000)
          }
        })
      }
    }, [window.location.hash])
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      //pageView
      if (pageName && pageName.length > 0 && pageName[0].length > 0) {
        const newLayer = {
          event: 'e_pageView',
          pageNameL5: pageName[0] ? pageName[0].toLowerCase() : '',
          pageNameL6: pageName[1] ? pageName[1].toLowerCase() : '',
          pageNameL7: pageName[2] ? pageName[2].toLowerCase() : '',
          pageNameL8: pageName[3] ? pageName[3].toLowerCase() : '',
        }

        if (newLayer.pageNameL8 && newLayer.pageNameL8 === 'home') {
          newLayer.pageNameL8 = 'largeformat-home'
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(newLayer)
        console.log('PV:', newLayer)
      }
    }, [window.location.hash])
  }

  const faviconMedia: KeyValue = media.find(item => item.key === favicon) || {
    key: '',
    value: '',
  }
  const imageMedia: KeyValue = media.find(item => item.key === image) || {
    key: '',
    value: '',
  }

  const country = language === 'en' ? 'uk' : language.split('_')[0]
  const parsedHrefLang = hreflang || (language === 'en' ? 'en' : language.split('_')[1]?.toLowerCase())

  if (typeof window !== 'undefined') {
    if (!pageName.includes('Printers')) {
      const gatedContent = findGetParameter(window.location.href, 'gatedurl') || findGetParameter(window.location.href, 'gated')
      if (gatedContent) {
        structure.push({
          id: 'iFrameOpenGated',
          module: 'OpenGated',
          options: [],
          structure: [],
        })
      }
    }
  }

  return (
    <>
      <Helmet>
        <html lang={parsedHrefLang} />
        <meta name="target_country" content={country} />
        <meta name="web_section_id" content="R12180" />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="language" content={language} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:url" content={canonical} />
        <meta name="og:type" content="website" />
        <meta name="web_section_id" content="R12180" />
        <meta
          name="og:image"
          content={`${publicUrl.replace(/\/$/, '')}${imageMedia.value}`}
        />
        <meta rel="icon" href={faviconMedia.value} />
        <link rel="canonical" href={canonical} />
        <link rel="alternate" href={canonical} hrefLang={parsedHrefLang} />
        { alternateUrls && alternateUrls.length > 0 && (
          alternateUrls.map((alternateUrl, index) => (
            <link
              key={`alternateUrl-${index}`}
              rel="alternate"
              href={alternateUrl.url}
              hrefLang={alternateUrl.hreflang}
            />
          ))
        )}
        <meta
          name="author"
          content={author || 'Dallonses, hello@dallonses.com'}
        />
        {isBlogPost ? (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Blog',
                  item: canonical
                    .split('/')
                    .slice(0, canonical.split('/').length - 1)
                    .join('/'),
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: title,
                  item: canonical,
                },
              ],
            })}
          </script>
        ) : null}
      </Helmet>
      <GlobalLiterals literals={literals}>
        <GlobalMedia media={media}>
          <Layout>
            <ModuleIterator modules={structure} pageName={pageName} />
            <Bax />
          </Layout>
        </GlobalMedia>
      </GlobalLiterals>
    </>
  )
}

export default Page
