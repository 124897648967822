// @flow
import React, { useEffect } from 'react'
import Player from '@vimeo/player'

type Props = {
  url: string,
}

const getVimeoId = url => {
  const match = /vimeo.*\/(\d+)/i.exec(url)
  if (match) {
    return match[1]
  }
  return 0
}

const VimeoVideo = ({ url }: Props) => {
  const id = getVimeoId(url)

  const loadVideo = () => {
    if (id) {
      new Player(`player-${id}`, {
        id,
        responsive: true,
      })
    }
  }

  useEffect(() => {
    loadVideo()
  }, [])

  return (
    <div
      className="w-200 sm:w-full"
      id={`player-${id}`}
      data-vimeo-background="true"
      data-vimeo-responsive="true"
    />
  )
}

export default VimeoVideo
