// @flow

import React from 'react'
import Html from './Html'
import Appear from './Appear'
import IconQuote from './icons/IconQuote'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'

type Props = {
  text: string,
  title: string,
}

// quote component
const StoryBlock5 = ({
  text,
  title = '',
  paddingBottom = false,
  extraProps,
}: Props) => {
  return extraProps.isBlogPost ? (
    <Appear className="flex flex-col md:flex-row gap-4 border-b mb-10 pb-1 md:pb-2">
      <IconQuote />
      <div className="flex flex-col gap-4 md:gap-5">
        <div className="text-18px md:text-24px">
          <Html text={text} />
        </div>
        <div>
          <span className="text-14px md:text-16px">{title}</span>
        </div>
      </div>
    </Appear>
  ) : (
    <Spacer top smaller bottom={paddingBottom}>
      <Container>
        <Appear>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-start-3 md:col-span-6">
              <div className="mb-8 font-light text-big2">
                <Html text={text} />
              </div>
              <div>
                <span className="font-light">{title}</span>
              </div>
            </div>
          </Grid>
        </Appear>
      </Container>
    </Spacer>
  )
}

export default StoryBlock5
