// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'

type Props = {
  text: string,
  menuId?: string,
  whiteParentBG: boolean,
  legalFormat?: 1 | -1,
  backgroundColor?: string,
  hasgradient?: boolean
}

const CategoryBlock8 = ({ menuId, text,backgroundColor, whiteParentBG = false, legalFormat = -1, hasgradient }: Props) => {

  switch (backgroundColor) {
    case "F3B09B":
      backgroundColor = "bg-lightSalmon23"
      break
      }

  return (
    <div data-menuid={menuId} data-hasgradient={hasgradient && 1 }>
      <Spacer className={`${backgroundColor && backgroundColor} py-4`} smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 text-black md:flex md:flex-col md:justify-center ${legalFormat === 1 ? "md:col-span-8" : "md:col-span-9"} md:col-start-4 md:mb-0`}>
              <div className={`font-light ${legalFormat === 1 ? "text-sm" : ""}`}><Appear><Html text={text} /></Appear></div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock8
