/* eslint-disable react/jsx-key */
// @flow

import React, { useState } from 'react'
import Container from './Container'
import Svg from './Svg'
import makeAssetUrl from '../lib/makeAssetUrl'
import type { ApiImage } from '../types'
import LinkHP from './LinkHP'
import LinkExternalHP from './LinkExternalHP'
import { useLiterals, useMedia } from 'store/'
import makeLayer from '../lib/makeLayer'

type MenuLink = {
  id: string,
  title: string,
  url: string,
  type: string,
  urlForm: any,
  image: ApiImage,
}
type Props = {
  items: Array<MenuLink>,
  language: string,
  section: string,
  hasShoppableSticky: Boolean,
}

const FloatingMenu = ({
  moduleName,
  items,
  language,
  section,
  hidden = true,
  hasShoppableSticky,
}: Props) => {
  const [inside, setInside] = useState(false)
  return (
    <div>
      <div
        id="floating-menu-content"
        className={`${hidden ? 'hidden md:hidden' : ''} w-full z-top md:block ${
          section === 'designjet'
            ? 'bg-paleYellow text-black fill-svg-white border-b'
            : 'bg-white text-black '
        }`}
      >
        <Container
          className="flex items-center justify-between text-smaller text-blue"
          customPadding="md:px-0"
        >
          {items &&
            items.map((item, index) => {
              if (item.nolink) {
                return (
                  <LinkHP
                    key={`link-1-${item.id}`}
                    className={`relative flex items-center ${
                      index < items.length - 1 ? 'mr-4' : ''
                    } pointer-events-none`}
                    title={item.title}
                  >
                    <span className="">{item.title}</span>
                  </LinkHP>
                )
              }
              if (item.urlForm) {
                //this color is hardcode and also it has to be a prop
                const fakePropColor = '#ffffff'
                return (
                  <div
                    className="relative w-full py-2 md:py-3 lg:py-3 xl:py-4"
                    key={`no-link-${item.id}`}
                  >
                    <div
                      className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                      style={{
                        backgroundColor: fakePropColor,
                        opacity: index === 0 ? 1 : `${index === 1 ? 0.6 : 0.4}`,
                      }}
                    />
                    <LinkHP
                      key={`link-1-${item.id}`}
                      className={`relative flex items-center bg-transparent ${
                        index < items.length - 1 ? 'mr-4' : ''
                      } hover:underline`}
                      to={
                        !item.urlForm2 || item.urlForm2 == '-1'
                          ? item.urlForm
                          : item.urlForm2
                      }
                      title={item.title}
                      data-name={`floating-${item.type}`}
                      dataLayer={makeLayer({
                        event: 'e_contactClick',
                        linkPlacement: 'header-float',
                        linkID: item.type,
                      })}
                    >
                      <span className="px-4">{item.title}</span>
                    </LinkHP>
                  </div>
                )
              }
              return (
                <LinkExternalHP
                  key={`link-2-${item.id}`}
                  className={`relative flex items-center ${
                    index < items.length - 1 ? 'mr-4' : ''
                  } hover:underline`}
                  href={item.url}
                  title={item.title}
                  data-name={`floating-${item.type}`}
                  dataLayer={makeLayer({
                    event: 'e_contactClick',
                    linkPlacement: 'header-float',
                    linkID: item.type,
                  })}
                >
                  <span className="">{item.title}</span>
                </LinkExternalHP>
              )
            })}
        </Container>
      </div>
      {!hasShoppableSticky &&
        [
          'ph_PH',
          'th_TH',
          'id_ID',
          'in_IN',
          'kr_KR',
          'my_MY',
          'en_AU',
        ].includes(language) && (
          <div
            onMouseEnter={() => setInside(true)}
            onMouseLeave={() => setInside(false)}
            className="fixed right-0 flex h-16 md:h-20 bottom-8"
          >
            <div
              className={`h-16 md:h-20 flex items-center pl-8 transition-transform duration-250 transform ${
                inside
                  ? 'translate-x-0'
                  : 'translate-x-full pointer-events-none'
              } text-smaller bg-blue`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: useLiterals('sticky_text') }}
              ></span>
            </div>
            <div
              onClick={() => setInside(!inside)}
              className="z-10 flex items-center justify-center w-16 md:pointer-events-none md:w-20 bg-blue"
            >
              <img
                className={'block w-8 h-8'}
                src={useMedia('call-us')}
                alt={useLiterals('sticky_text')}
              />
            </div>
          </div>
        )}
    </div>
  )
}

export default FloatingMenu
