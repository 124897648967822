// @flow

import React, { useState, useEffect, useRef } from 'react'
import Container from './Container'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Spacer from './Spacer'
import Grid from "./Grid"
import { useLiterals, useMedia } from 'store/'
import type { ApiImage, Ref } from '../types'
import useIsMobile from '../hooks/useIsMobile'
import smoothscroll from 'smoothscroll-polyfill'
import Html from './Html'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}
type Item = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  url: string,
}
type Props = {
  items: Array<Item>,
  title: string,
  ctaLabel: string,
  ctaLink: string,
}

const drag = {
  active: false,
  start: 0,
  scrollLeft: 0,
  current: 0
}

const PostSlider = ({ items, title, ctaLabel, ctaLink }: Props) => {

  const slider: Ref = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isMobile = useIsMobile()
  const [currentMenuId, setCurrentMenuId] = useState(-1)
  const [renderedItems, setRenderedItems] = useState(false)

  const handleSlider = (direction: string): void => {
    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    switch (direction) {
      case 'forwards':
        if (nextIndex < items.length - 1) {
          nextIndex += 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth'
          })
          setCurrentIndex(nextIndex)
        }
        break
      case 'backwards':
        if (nextIndex > 0) {
          nextIndex -= 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth'
          })
          setCurrentIndex(nextIndex)
        }
        break
    }
  }

  const slideTo = (index: number): void => {
    const childW = slider.current.children[0].offsetWidth
    slider.current.scrollTo({
      left: index * childW,
      behavior: 'smooth'
    })
    setCurrentIndex(index)
  }

  const handleDrag = e => {
    if (items.length < 2) return
    let pX = e.pageX
    if (e.type.includes('touch') && !e.type.includes('touchend')) {
      pX = e.touches[0].pageX
    }
    let nextIndex = currentIndex
    switch (e.type) {
      case 'mousedown':
        // case 'touchstart':
        slider.current.classList.remove('snap-x-mandatory')
        drag.active = true
        drag.start = pX - slider.current.offsetLeft
        drag.scrollLeft = slider.current.scrollLeft
        break
      case 'mouseup':
        // case 'touchend':
        drag.active = false
        if (
          drag.scrollLeft < slider.current.scrollLeft &&
          nextIndex < items.length - 1
        ) {
          nextIndex++
          slideTo(nextIndex)
        }
        if (drag.scrollLeft > slider.current.scrollLeft && nextIndex > 0) {
          nextIndex--
          slideTo(nextIndex)
        }
        break
      case 'mousemove':
        // case 'touchmove':
        if (drag.active) {
          const x = pX - slider.current.offsetLeft
          // El 2 es arbitrario. Define la velocidad en la que hace el scroll
          const walk = (x - drag.start) * 2
          slider.current.scrollLeft = drag.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const handleSliderScroll = ({ target }) => {
    if (items.length < 2) return
    if (isMobile) {
      if (!target.scrollLeft) {
        setCurrentIndex(0)
        return
      }
      const position =
        (target.offsetWidth * items.length) / target.scrollLeft - 1
      if (Number.isInteger(position) && position <= items.length) {
        setCurrentIndex(position)
      }
    }
  }

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-name="${menuId}"`)
    const yOffset = -200
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    const elements = document.querySelectorAll(
      '[data-name="featured-webinars"]'
    )
    let currentMenuId = -1
    elements.forEach(element => {
      const { top, bottom } = element.getBoundingClientRect()
      if (top <= h / 2 && bottom > 0) {
        currentMenuId = element.getAttribute('data-name')
      }
    })
    setCurrentMenuId(currentMenuId)
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart.length > 0 && lastPart.includes('featured')) {
        setTimeout(() => {
          shouldScrollToId('featured')
        }, 1000)
      }
    }


    if (typeof window !== 'undefined') {
      setRenderedItems(
        <div
          className={`flex items-start md:items-center w-full overflow-x-auto snap-x-mandatory ${
            items.length > 1 ? 'cursor-pointer bg-localactive:cursor-grab' : ''
          } md:overflow-x-hidden slider`}
          ref={slider}
          onMouseDown={handleDrag}
          onMouseMove={handleDrag}
          onMouseUp={handleDrag}
          onScroll={handleSliderScroll}
        >
          {items.map(
            (
              {
                id,
                image,
                title,
                teaser,
                url,
              },
              index
            ) => (
              <div
                key={id}
                id={`slide-${index}`}
                className="grid flex-shrink-0 w-full h-full px-4 py-2 snap-align-start md:snap-align-center md:grid-cols-3 md:gap-8 md:px-24"
              >
                <div className="flex items-start h-full row-start-2 select-none md:col-start-1 md:col-span-1 md:row-start-1">
                  <a href={url} className="w-full">
                    <LazyImage image={image} alt={title} />
                  </a>
                </div>
                <div className="flex flex-col justify-center row-start-1 md:col-start-2 md:col-span-2">
                  <a href={url} className="mb-4 select-none text-28px text-purple">
                    <Html text={title} />
                  </a>
                  <div className="mb-4 mr-4 font-light select-none text-20px">
                    <Html text={teaser} />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (currentMenuId !== -1) {
      const url = `#!${currentMenuId}`
      window.history.replaceState(null, null, url)
    }
  }, [currentMenuId])

  return (
    <Spacer top bottom smaller>
      <Container>
        <Grid>
          <div className="col-span-4 col-start-1 pt-10 md:col-start-3 md:col-span-8 bg-grayLighter">
            <div className="relative flex items-start justify-between w-full px-4 pb-8 md:pb-10 md:items-center md:justify-center md:block md:px-10">
              <h3 className="w-2/3 text-3xl md:text-center md:text-4xl md:w-full">{title}</h3>
              <a href={ctaLink} className="text-lg md:absolute md:top-0 md:right-0 md:mr-10 text-purple md:text-20px hover:underline">{ctaLabel}</a>
            </div>
            <div
              data-name="featured"
              className="relative flex items-center w-full"
            >
              {items.length > 1 && (
                <div className="absolute z-20 flex justify-between w-full px-5">
                  <div
                    className={`${currentIndex === 0 ? 'opacity-30 pointer-events-none' : ''} hidden md:flex items-center justify-center cursor-pointer flex-shrink-0`}
                    onClick={() => handleSlider('backwards')}
                  >
                    <LazySimpleImage
                      alt={useLiterals('previous')}
                      image={useMedia('black-arrow-big')}
                      imageClassName="transform rotate-180 h-35px"
                    />
                  </div>
                  <div
                    className={`${currentIndex === items.length - 1 ? 'opacity-30 pointer-events-none' : ''} hidden md:flex items-center justify-center cursor-pointer flex-shrink-0`}
                    onClick={() => handleSlider('forwards')}
                  >
                    <LazySimpleImage
                      alt={useLiterals('previous')}
                      image={useMedia('black-arrow-big')}
                      imageClassName="h-35px"
                    />
                  </div>
                </div>
              )}
              {(renderedItems)}
            </div>
            {items.length > 1 && (
              <div className="flex items-center px-4 py-6 bg-grayLighter md:pb-10 md:pt-6 md:px-10">
                <div
                  className={`${currentIndex === 0 ? 'opacity-30 pointer-events-none' : ''} md:hidden flex items-center justify-center pr-1 cursor-pointer flex-shrink-0`}
                  onClick={() => handleSlider('backwards')}
                >
                  <LazySimpleImage
                    alt={useLiterals('previous')}
                    image={useMedia('black-arrow-big')}
                    imageClassName="transform rotate-180 h-6"
                  />
                </div>
                <div className="flex items-center justify-center w-full md:px-32 md:gap-16">
                  <div className="flex items-center justify-around">
                    {items.map((item, index) => (
                      <div
                        onClick={() => slideTo(index)}
                        key={index}
                        className={`w-4 h-4 mx-3 cursor-pointer ${
                          currentIndex === index ? 'opacity-100' : 'opacity-30'
                        } border bg-black border-black rounded-full`}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={`${currentIndex === items.length - 1 ? 'opacity-30 pointer-events-none' : ''} md:hidden flex items-center justify-center cursor-pointer flex-shrink-0`}
                  onClick={() => handleSlider('forwards')}
                >
                  <LazySimpleImage
                    alt={useLiterals('previous')}
                    image={useMedia('black-arrow-big')}
                    imageClassName="h-6"
                  />
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PostSlider
