// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Html from './Html'
import MadeWithPrinters from './MadeWithPrinters'
import type { ApiImage, SimplePrinter } from '../types'

type Props = {
  title1: string,
  text1: string,
  title2: string,
  text2: string,
  image1: ApiImage,
  image2: ApiImage,
  image1zoom: ApiImage,
  image2zoom: ApiImage,
  printers: Array<SimplePrinter>,
  menuId?: string,
  inGradient?: boolean,
  verticalSlug: string,
  whiteParentBG?: boolean,
}

const CategoryBlock4 = ({
  inGradient,
  menuId,
  title1,
  text1,
  title2,
  text2,
  image1,
  image2,
  printers,
  verticalSlug,
  whiteParentBG = true
}: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer top bottom smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mt-8 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-10 md:col-start-4 md:mb-0`}>
              <div className="md:flex">
                <div className={`mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:mr-4 md:mb-0`}>
                  <LazyImage image={image1} alt={title1} />
                  <h4 className="my-4 font-bold md:my-12 text-normal"><Appear>{title1}</Appear></h4>
                  <div className="font-light"><Appear><Html text={text1} /></Appear></div>
                </div>
                <div className={`mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:mb-0`}>
                  <LazyImage image={image2} alt={title2} />
                  <h4 className="my-4 font-bold md:my-12 text-normal"><Appear>{title2}</Appear></h4>
                  <div className="font-light"><Appear><Html text={text2} /></Appear></div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid>
            <div className={`col-span-4 col-start-1 mt-8 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-4 md:col-start-4 md:mb-0`}>
              <MadeWithPrinters inGradient={inGradient} printers={printers} verticalSlug={verticalSlug} eventTagModuleName="CategoryBlock4"/>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock4