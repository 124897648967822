/* eslint-disable react/jsx-no-target-blank */
// @flow

import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import useIsMobile from '../hooks/useIsMobile'
import LazySimpleImage from './LazySimpleImage'
import makeAssetUrl from '../lib/makeAssetUrl'
import { useLiterals, useMedia } from 'store/'
import type { ApiImage } from '../types'
import LinkExternalHP from './LinkExternalHP'
import makeLayer from '../lib/makeLayer'
import TitleUnderlines from './TitleUnderlines'
import Button from './Button'

type Webinar = {
  id: string,
  url: string,
  title: string,
  ctaUrl: string,
  backgroundColor: string,
}
type Props = {
  position: string,
  image: ApiImage,
  title: string,
  text: string,
  style: string,
  webinars: Array<Webinar>,
}

//HARDCODED TEXT
const webinarsCTA = 'Replay now'

const FeaturedImage = ({
  moduleName,
  image,
  title,
  text,
  webinars,
  style,
}: Props) => {
  const [opacity, setOpacity] = useState(0)
  const [height, setHeight] = useState(null)
  const [smallScreen, setSmallScreen] = useState()
  const isMobile = useIsMobile()

  const hideWebinars =
    typeof process.env.GATSBY_HIDE_WEBINARS !== 'undefined' &&
    process.env.GATSBY_HIDE_WEBINARS === 'TRUE'

  useEffect(() => {
    if (isMobile) {
      setHeight(`${((window.innerHeight - 188) * 100) / window.innerHeight}vh`)
    } else {
      setHeight(`${((window.innerHeight - 110) * 100) / window.innerHeight}vh`)
    }
    setTimeout(() => {
      setOpacity(1)
    }, 500)
  }, [isMobile])

  useEffect(() => {
    if (typeof window != 'undefined' && window.innerHeight < 725 && !isMobile) {
      setSmallScreen(true)
    }
  }, [])

  const scrollDown = () => {
    const yOffset = -80
    const map = document.querySelector('[data-stand]')
    if (map) {
      const { top } = map.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const customStyle = {
    backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`,
  }
  if (height) {
    customStyle.height = height
  }

  return (
    <div
      className={`relative w-full h-screen text-white bg-center bg-no-repeat bg-cover min-h-600  md:h-80vh ${
        style === 'visid' ? '' : 'gradient-overlay'
      } mt-menu-mobile md:mt-menu-desktop`}
      style={customStyle}
    >
      <Appear className="w-full py-8 text-left">
        <h1
          className={`text-5xl leading-none text-left font-secondary md:leading-tight sm:text-80px lg:text-150px md:text-110px" ${
            smallScreen ? 'text-60px' : 'md:text-110px'
          }  ignore-br md:use-br`}
        >
          <TitleUnderlines>{title}</TitleUnderlines>
        </h1>
      </Appear>
      <Container>
        <Grid>
          <div className="z-20 col-span-3 col-start-1 text-left md:col-span-8">
            <Appear>
              <h4 className="text-xl leading-none text-left md:text-30px md:w-1/2">
                {text}
              </h4>
            </Appear>
          </div>
          <div
            onClick={scrollDown}
            className="absolute bottom-0 z-20 flex pb-40 font-light cursor-pointer md:pb-10 md:justify-start md:items-center"
            data-tag-name={`${moduleName}-visit3dvirtualbooth`}
            dataLayer={makeLayer({
              event: 'e_linkClick',
              linkPlacement: 'content-anchor',
              linkID: 'Stand3D',
            })}
          >
            <Button
              style="blackOutlined"
              className="flex items-center mb-16 text-20px md:mb-0"
              padded={true}
              title={useLiterals('3d_explore')}
            />
          </div>
        </Grid>
      </Container>
      {webinars && !hideWebinars && (
        <div className="flex md:flex-col gap-4 absolute right-0 mb-4 mx-4 md:mb-10 md:mr-16 bottom-0 z-40 w-[50%]  justify-end">
          {webinars.map(webinar => {
            if (webinar.title) {
              return (
                <div
                  key={webinar.id}
                  className="flex text-20px"
                  style={{
                    transition: 'opacity 0.5s',
                    opacity: opacity,
                  }}
                >
                  <div className="flex flex-col items-start justify-between">
                    <div className="w-40 mb-6 md:w-64">{webinar.title}</div>
                    <Button
                      style="white"
                      className="flex items-center text-20px"
                      padded={true}
                      title="Replay now"
                      href={webinar.ctaUrl}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

export default FeaturedImage
