const THREE = require('three')

class Textures {

  constructor(printerId) {
    this.printerId = printerId
    this.textures = []
  }

  numberOfObjectsToLoad() {
    return this.list().length
  }

  async load(loadingCallback = () => {}) {
    const texturesRaw = await this.loadTextures(loadingCallback)
    this.textures = this.transformTextures(texturesRaw)
  }

  get(id = false) {
    if (id) {
      const t = this.textures.find(i => i.id === id)
      if (t) {
        return t.texture
      }
      return null
    }
    return this.textures
  }

  list() {

    const allTextures = {
      p2: [
        '/stand3d/assets/p2/textures/wood_only.jpg', 
        '/stand3d/assets/p2/textures/woodb.jpg', 
        '/stand3d/assets/p2/textures/circles.jpg', 
        '/stand3d/assets/p2/textures/cta.png',
        '/stand3d/assets/p2/textures/seats.jpg',
        '/stand3d/assets/p2/textures/bars.jpg',
        '/stand3d/assets/p2/textures/walls.jpg',
        '/stand3d/assets/p2/textures/squares.jpg',
        '/stand3d/assets/p2/textures/texts.jpg',
        '/stand3d/assets/p2/textures/column.jpg',
        '/stand3d/assets/p2/textures/prints.jpg', 
        '/stand3d/assets/p2/textures/vandif.jpg',
        '/stand3d/assets/p2/textures/vannorm.jpg',
        '/stand3d/assets/p2/textures/envirohp.jpg',
        '/stand3d/assets/p2/textures/envirohp2.jpg',
        '/stand3d/assets/p2/textures/squares.jpg',
        '/stand3d/assets/p2/textures/impres1.jpg',
        '/stand3d/assets/p2/textures/polestar.jpg',
        '/stand3d/assets/p2/textures/screens.jpg',
        '/stand3d/assets/p2/textures/books.jpg',
        '/stand3d/assets/p2/textures/persalpha.jpg',
        '/stand3d/assets/p2/textures/perscolor.jpg',
      ],
      p3: [
        '/stand3d/assets/p3/textures/wood_only.jpg', 
        '/stand3d/assets/p3/textures/woodb.jpg', 
        '/stand3d/assets/p3/textures/circles.jpg', 
        '/stand3d/assets/p3/textures/cta.png',
        '/stand3d/assets/p3/textures/seats.jpg',
        '/stand3d/assets/p3/textures/bars.jpg',
        '/stand3d/assets/p3/textures/walls.jpg',
        '/stand3d/assets/p3/textures/squares.jpg',
        '/stand3d/assets/p3/textures/texts.jpg',
        '/stand3d/assets/p3/textures/column.jpg',
        '/stand3d/assets/p3/textures/prints.jpg', 
        '/stand3d/assets/p3/textures/envirohp.jpg',
        '/stand3d/assets/p3/textures/envirohp2.jpg',
        '/stand3d/assets/p3/textures/squares.jpg',
        '/stand3d/assets/p3/textures/impres1.jpg',
        '/stand3d/assets/p3/textures/lion.jpg',
        '/stand3d/assets/p3/textures/screens.jpg',
        '/stand3d/assets/p3/textures/books.jpg',
        '/stand3d/assets/p3/textures/persalpha.jpg',
        '/stand3d/assets/p3/textures/perscolor.jpg',
      ],
      p4: [
        '/stand3d/assets/p4/textures/wood_only.jpg', 
        '/stand3d/assets/p4/textures/woodb.jpg', 
        '/stand3d/assets/p4/textures/circles.jpg', 
        '/stand3d/assets/p4/textures/cta.png',
        '/stand3d/assets/p4/textures/seats.jpg',
        '/stand3d/assets/p4/textures/bars.jpg',
        '/stand3d/assets/p4/textures/walls.jpg',
        '/stand3d/assets/p4/textures/squares.jpg',
        '/stand3d/assets/p4/textures/texts.jpg',
        '/stand3d/assets/p4/textures/column.jpg',
        '/stand3d/assets/p4/textures/prints.jpg', 
        '/stand3d/assets/p4/textures/envirohp.jpg',
        '/stand3d/assets/p4/textures/envirohp2.jpg',
        '/stand3d/assets/p4/textures/squares.jpg',
        '/stand3d/assets/p4/textures/impres1.jpg',
        '/stand3d/assets/p4/textures/printer.jpg',
        '/stand3d/assets/p4/textures/screens.jpg',
        '/stand3d/assets/p4/textures/books.jpg',
        '/stand3d/assets/p4/textures/persalpha.jpg',
        '/stand3d/assets/p4/textures/perscolor.jpg',
      ],
      p5: [
        '/stand3d/assets/p5/textures/wood_only.jpg', 
        '/stand3d/assets/p5/textures/woodb.jpg', 
        '/stand3d/assets/p5/textures/circles.jpg', 
        '/stand3d/assets/p5/textures/cta.png',
        '/stand3d/assets/p5/textures/seats.jpg',
        '/stand3d/assets/p5/textures/bars.jpg',
        '/stand3d/assets/p5/textures/walls.jpg',
        '/stand3d/assets/p5/textures/squares.jpg',
        '/stand3d/assets/p5/textures/texts.jpg',
        '/stand3d/assets/p5/textures/column.jpg',
        '/stand3d/assets/p5/textures/prints.jpg', 
        '/stand3d/assets/p5/textures/vandif.jpg',
        '/stand3d/assets/p5/textures/vannorm.jpg',
        '/stand3d/assets/p5/textures/envirohp.jpg',
        '/stand3d/assets/p5/textures/envirohp2.jpg',
        '/stand3d/assets/p5/textures/squares.jpg',
        '/stand3d/assets/p5/textures/impres1.jpg',
        '/stand3d/assets/p5/textures/screens.jpg',
        '/stand3d/assets/p5/textures/books.jpg',
        '/stand3d/assets/p5/textures/persalpha.jpg',
        '/stand3d/assets/p5/textures/perscolor.jpg',
        '/stand3d/assets/p5/textures/printer.jpg',
      ],
    }

    const textures = allTextures[`p${this.printerId}`]

    for (let i = 1; i < 12; i++) {
      textures.push(`/stand3d/assets/points/point${i}.png`)
    }

    return textures
  }

  loadTextures(loadingCallback) {
    const loader = new THREE.TextureLoader()
    const promises = []
    const textures = this.list()
    textures.forEach(texture => {
      promises.push(new Promise((resolve, reject) => {
        loader.load(texture, text => {
          loadingCallback(text)
          resolve({
            id: texture.split('/').pop().split('.')[0],
            texture: text,
          })
        }, undefined, error => {
          console.log('error', texture)
          reject(error)
        })
      }))
    })
    return Promise.all(promises)
  }

  transformTextures(textures) {
    //CTA
    const ctaTextureIndex = textures.findIndex(i => i.id === 'cta')
    textures[ctaTextureIndex].texture.repeat.set(1, 1)
    textures[ctaTextureIndex].texture.wrapS = THREE.ClampToEdgeWrapping
    textures[ctaTextureIndex].texture.wrapT = THREE.ClampToEdgeWrapping
    return textures
  }
  

}

export default Textures