// @flow
import React from 'react'
import Grid from './Grid'
import Markdown from './Markdown'
import Video from './Video'
import Button from './Button'
import Appear from './Appear'
import makeLayer from '../lib/makeLayer'

const scrollDownIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="a">
        <path d="M1440 0v12435H0V0h1440Z" />
      </clipPath>
      <clipPath id="b">
        <path d="M25 0c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-31 -850)">
      <g clipPath="url(#b)" transform="rotate(90 -384.5 465.5)">
        <path
          d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25Z"
          stroke="#FFF"
          strokeWidth="3"
          fill="none"
          strokeMiterlimit="5"
        />
      </g>
      <path
        d="m65.082 871.082-9.435 8.454-9.183-8.454"
        stroke="#FFF"
        strokeWidth="1.5"
        fill="none"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
)

type Props = {
  pageName: [String],
  title: string,
  subtitle: string,
  cta1Text: string,
  cta1Url: string,
  videoPlaceholderImage: string,
  cta2Text: string,
  videoUrl: string,
  verticalSlug: string,
  internal1Url: string,
}

const HeadingVideo = ({
  title,
  subtitle,
  cta1Text,
  cta1Url,
  internal1Url,
  videoPlaceholderImage,
  cta2Text,
  videoUrl,
  pageName,
}: Props) => {

  const splitTitle = title.split('<br>')
  return (
    <div className="relative overflow-hidden mt-menu-mobile md:mt-menu-desktop">
      {/* mobile */}
      <div className="mb-6 sm:hidden">
        <Appear>
          <Grid className="w-full pt-4 text-black h-max">
            <div className="col-span-4 leading-tight text-40px">
              {splitTitle.map(titleHalf => (
                <div
                  key={titleHalf}
                  className="px-4 py-1 border-b border-black"
                >
                  {titleHalf}
                </div>
              ))}
            </div>
            <div className="col-span-4 px-4 mb-4">
              <Markdown text={subtitle} />
            </div>
            <div className="col-span-3 col-start-1 px-4">
              <Button
                title={cta1Text}
                to={cta1Url}
                style="whiteOutlineBlack"
                padded
                dataLayer={
                  internal1Url
                    ? makeLayer({
                        event: 'e_contactClick',
                        linkPlacement: 'professional-print-service',
                        linkID: internal1Url,
                      })
                    : makeLayer({
                        event: 'e_contactClick',
                        linkPlacement: 'professional-print-service',
                        linkID: cta1Url,
                      })
                }
              />
            </div>
          </Grid>
        </Appear>
      </div>
      <Video url={videoUrl} image={videoPlaceholderImage} centerPlay noGrid />
      {/* desktop */}
      <Appear className="absolute inset-x-0 top-0 hidden pointer-events-none sm:block">
        <Grid className="w-full pt-4 text-white h-max">
          <div className="col-span-4 leading-tight md:col-span-12 text-60px md:text-90px lg:text-120px">
            {splitTitle.map(titleHalf => (
              <div key={titleHalf} className="px-4 border-b border-white">
                {titleHalf}
              </div>
            ))}
          </div>
          <div className="col-span-2 px-4 mb-4 md:col-span-5">
            <Markdown text={subtitle} />
          </div>
          <div className="col-span-3 col-start-1 px-4">
            <Button
              title={cta1Text}
              to={cta1Url}
              style="outlineWhite"
              textClasses="text-white hover:text-black"
              padded
              className="pointer-events-auto"
              dataLayer={
                pageName[3] === 'hp-professional-print-service'
                  ? makeLayer({
                      event: 'e_contactClick',
                      linkPlacement: 'professional-print-service',
                      linkID: 'contact-expert',
                    })
                  : ''
              }
            />
          </div>
        </Grid>
      </Appear>
      <Appear className="absolute bottom-0 left-0 items-center hidden px-4 py-8 md:flex">
        <div className="w-12 h-12">{scrollDownIcon}</div>
        <span className="ml-4 text-white">{cta2Text}</span>
      </Appear>
    </div>
  )
}

export default HeadingVideo
