// @flow
import React, { useState, useEffect } from 'react'
import Animated from './Animated'
import Cross from './Cross'
import youtubeParser from '../lib/youtubeParser'
import loadYoutube from "../lib/loadYoutube"

type Props = {
  video: string | null,
  close: Function
}

const fade = {
  to: {
    opacity: 1
  },
  from: {
    opacity: 0
  },
  duration: 0.5
}

const Overlay = ({ video, close }: Props) => {
  const [state, setState] = useState(false)
  const [player, setPlayer] = useState({})

  useEffect(() => {
    if (video) {
      setState(true)
      return
    }
    setState(false)
  }, [video])

  const loadVideo = id => {
    loadYoutube(() => {
      const current = new window.YT.Player(`youtube-player`, {
        videoId: id,
        playerVars: {
          autoplay: 1,
          fs: 1,
          rel: 0,
          showinfo: 0,
          modestbranding: 1
        },
        events: {
          onReady: function() {
            current.playVideo()
          }
        }
      })
      setPlayer(current)
    })
  }

  useEffect(() => {
    const playerIsInitialized = Object.keys(player).length
    if (state && !playerIsInitialized) {
      const id = youtubeParser(video)
      loadVideo(id)
    }
    if (state && playerIsInitialized) {
      player.loadVideoById(youtubeParser(video))
    }
  }, [state])

  const handleClose = () => {
    player.pauseVideo()
    close()
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      player.stopVideo()
      close()
    })
  }, [])

  return (
    <Animated
      animation={fade}
      bind={state}
      type="spring"
      className={`fixed inset-0 z-50 ${
        !video ? 'pointer-events-none' : ''
      } overlay`}
      onClick={handleClose}
    >
      <div className="relative flex flex-col items-center justify-center w-full h-full mt-menu-mobile md:mt-menu-desktop">
        <div className="w-full px-16">
          <div
            className="relative w-full h-auto"
            style={{ paddingTop: '56.2%' }}
          >
            <div
              className="absolute top-0 right-0 -mt-8 cursor-pointer"
              onClick={handleClose}
            >
              <Cross color="white" big={true} />
            </div>
            <div
              className="absolute top-0 left-0 w-full h-full"
              id={`youtube-player`}
            ></div>
          </div>
        </div>
      </div>
    </Animated>
  )
}

export default Overlay
