/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react'
import { useLiterals } from 'store/'
import Html from './Html'

type Props = {
  text?: string,
  onChange?: Function,
  id?: string,
  status: boolean,
  error?: boolean,
  className?: string,
  label?: string,
}

const Checkbox = ({
  text,
  onChange,
  status,
  id,
  error,
  className = '',
  label
}: Props): React$Node => {
  return (
    <div className={`flex flex-row ${className}`} onChange={onChange}>
      <input
        type="checkbox"
        id={id}
        name={id}
        style={{
          border: error ? '1px solid red' : ''
        }}
        className={`appearance-none outline-none ${
          status ? 'checkbox-selected' : 'checkbox'
        } flex items-center justify-center overflow-hidden flex-shrink-0 cursor-pointer`}
      />
      {label && <label className="pl-4 -mt-1 font-light cursor-pointer select-none">{label}</label>}
    </div>
  )
}

export default Checkbox