// @flow
import React from 'react'
import type { ApiImage } from '../types'
import Container from  './Container'
import Grid from './Grid'
import Video from './Video'

type Props = {
  id: string,
  image: ApiImage,
  video: string
}

const FloatingVideo = ({ id, image, video }: Props) => {
  return (
    <div className="pt-12 bg-realBlack md:pt-100px">
      <Container>
        <Grid>
          <div className="col-span-4 md:col-span-10 md:col-start-2">
            <Video
              id={id}
              image={image}
              url={video}
              noGrid
            />
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default FloatingVideo
