// @flow

import React from 'react'
import Markdown from './Markdown'
import Container from './Container'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import PrintersGallery from './PrintersGallery'
import Appear from './Appear'
import Button from './Button'
import makeLayer from '../lib/makeLayer'

type Props = {
  title?: string,
  terms?: string,
  ctaText?: string,
  ctaUrl?: string,
  cards: Array<any>,
  hasLeftStickyMenu?: Boolean,
  wider?: boolean,
  anchor: string,
}

const PolestarDiscover = ({
  title = false,
  terms = false,
  cards,
  hasLeftStickyMenu = false,
  ctaText = false,
  ctaUrl = false,
  wider = false,
  anchor,
}: Props): ReactNode => {

  const content = <Appear>
      <div className='flex md:flex-row flex-col items-end w-full justify-between md:pb-16 pb-10'>
        {(title) ?
          <h2 className={`font-secondary pl-4 ${wider ? 'pt-16' : 'pt-6'} ${wider ? 'md:max-w-550' : 'w-full md:w-2/3 lg:w-1/2'} ${!ctaText? `${wider ? 'pb-16 md:py-32' : 'py-8 px-4 md:px-2'}` : `${ wider ? 'md:pt-32' : 'md:pt-0'}`} ${wider ? 'text-3.5xl md:text-50px leading-none' : 'text-5xl md:text-80px leading-none'}`}>{title}</h2>
          :
          <div className="pt-12 md:py-12"></div>
        }
        {ctaText &&
        <div className='mt-6 md:mt-0 mb-4'>
            <Button
              style="blackOutlined"
              title={ctaText}
              href={ctaUrl}
              textClasses="md:min-w-120px text-center"
              />
        </div>
        }
      </div>
      <div className="overflow-hidden md:hidden">
        <PrintersGallery printers={cards}/>
      </div>
      <div className={`flex-wrap justify-between hidden md:flex md:pt-8 lg:mt-10`}>
        {cards && cards.map((card, index) => { 
          const { key, image, title, text, ctaText, url, internalUrl, ctaText2, url2 } = card
          card.wider = wider
          return (
          <div key={key} className="font-secondary flex flex-col flex-wrap w-1/3 px-2 min-h-500 h-25vw md:h-35vw max-h-700px">
            <LazyImage image={image} alt={title} />
            <Markdown text={title} className={wider ? "py-8 text-30px" : "pt-4 pb-8 text-3xl"} />
            <Markdown text={text} className={'text-xl leading-6'} />
            { wider && ctaText && url &&
              <div className={`flex items-start mt-8 max-w-8/10`}>
                  <Button href={url} title={ctaText}  style="whiteOutlineBlack" dataLayer={makeLayer({
                    event: 'e_document',
                    documentAction: 'download',
                    documentID: `polestar-discover-cta${index + 1}`,
                    documentType: 'pdf'
                })} />
              </div>
            }
            { !wider && ctaText && url && !internalUrl && 
              <div className={`flex items-start mt-auto max-w-8/10`}>
                <Button href={url} title={ctaText}  style="whiteOutlineBlack" dataLayer={makeLayer({
                  event: 'e_document',
                  documentAction: 'download',
                  documentID: `polestar-discover-cta${index + 1}`,
                  documentType: 'pdf'
                })} />
              </div>
            }
            { !wider && ctaText && !url && internalUrl && 
              <div className={`flex items-start pt-2`}>
                <Appear>
                <Button
                  href={internalUrl}
                  title={ctaText}
                  style="white"
                  className='mb-8 border-black'
                  />
                </Appear>
              </div>
            }
          </div>
        )})}
      </div>
      {terms &&
        <div className="pt-16 md:pt-24 px-4 md:px-2">
            <Markdown text={terms} className="text-sm font-light" />
        </div>
      }
    </Appear>

  const wrapper = (hasLeftStickyMenu) ?
  <div className="md:grid md:grid-cols-12 relative">
    <div className="md:col-start-3 md:col-span-10">
      {content}
    </div>
  </div>
  : <div>{content}</div>

  return (
    <Spacer bottom>
    <div data-anchor={anchor} />
      <Container className="hidden md:block relative">
        <div className='h-px w-full absolute md:mt-44 lg:mt-56 left-0 bg-black'></div>
        {wrapper}
      </Container>
      <div className="md:hidden">
        {content}
      </div>
    </Spacer>
  )
}

export default PolestarDiscover
