// @flow

import React, { useState } from 'react'
import LazyImage from './LazyImage'
import { Link } from 'gatsby'
import Container from './Container'
import Grid from './Grid'
import useIsMobile from '../hooks/useIsMobile'

type ItemType = {
  id: string,
  url: string,
  image: ApiImage,
  teaser: string,
  bgColor: string,
  fontColor: string,
  title: string,
  category: string,
}
type Props = { title: string, readMore: string, items: Array<ItemType> }

const RecentlyAddedPosts = ({ title, readMore, items }: Props) => {
  const [numberItems, setNumberItems] = useState(6)
  const isMobile = useIsMobile()

  const areAllItemsShowing = numberItems >= items.length
  return items?.length > 0 ? (
    <>
      <Container className="mt-10 md:mt-70px">
        <Grid>
          <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <h4 className="text-30px md:text-48px">{title}</h4>
          </div>
        </Grid>
      </Container>
      <hr className="w-full mt-4 md:mt-6 mb-8 md:mb-12" />
      <Container className='mb-10 md:mb-70px"'>
        <Grid>
          <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-7">
              {items.slice(0, numberItems).map((item, index) => {
                const isOdd = index % 2 !== 0
                const mustGoUp = !isMobile && index % 2 !== 0 && index % 6 > 2

                return (
                  <Link
                    to={item.url}
                    key={item.id}
                    className={`flex flex-col zoom-parent ${
                      isOdd ? 'md:h-25rem' : 'md:h-90'
                    }`}
                    style={{ transform: mustGoUp ? 'translateY(-40px)' : '' }}
                  >
                    {item?.image && Object.keys(item.image).length > 0 ? (
                      <LazyImage
                        image={item.image}
                        alt={item.title}
                        cover
                        className={`w-full h-10rem overflow-hidden ${
                          isOdd ? 'md:h-72' : 'md:h-59'
                        }`}
                        imageClassName="zoom-child"
                      />
                    ) : (
                      <div className="bg-grayLight h-2/3 w-full"></div>
                    )}
                    <div className="pt-2 flex flex-col gap-1 md:gap-2">
                      <p className="md:pl-5 text-16px md:text-18px">
                        {item.category}
                      </p>
                      <hr className="w-full" />
                      <h4
                        className="overflow-hidden text-18px md:text-24px leading-snug md:px-5 md:h-16 md:mb-2"
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: '2',
                        }}
                      >
                        {item.title}
                      </h4>
                    </div>
                  </Link>
                )
              })}
            </div>
            <button
              className={`inline-block mt-12 px-4 py-2 text-16px text-center text-white whitespace-no-wrap bg-black border border-black focus:outline-none ${
                areAllItemsShowing
                  ? 'opacity-75 cursor-not-allowed md:hover:bg-black md:hover:text-white'
                  : 'cursor-pointer md:hover:bg-white md:hover:border-black md:hover:text-black'
              }`}
              onClick={() => setNumberItems(prev => prev + 6)}
              disabled={areAllItemsShowing}
            >
              {readMore}
            </button>
          </div>
        </Grid>
      </Container>
    </>
  ) : null
}

export default RecentlyAddedPosts
