// @flow

import { useCallback, useEffect } from 'react'

export function useOnClickOutside(ref, handler) {
  const handleMouseDown = useCallback(
    event => {
      if (!ref?.current || ref?.current.contains(event.target)) return
      handler(event)
    },
    [handler, ref]
  )
  const handleESC = useCallback(
    event => {
      if (!ref.current) return
      if (event.code === 'Escape') handler(event)
    },
    [ref, handler]
  )

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('keydown', handleESC)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('keydown', handleESC)
    }
  }, [handleMouseDown, handleESC])
}
