// @flow
import React from 'react'
import Container from './Container'
import Video from './Video'
import Appear from './Appear'

type Props = {
  title: string,
  image: ApiImage,
  url: string
} 

const VideoWithTitle = ({title, image, url }: Props) => {
  const splitTitle = title.split('<br>')
  const titleTop = splitTitle[0]
  const titleBottom = splitTitle[1]

  return (
    <div className='py-6 bg-lightLavender md:pt-10 md:pb-16'>
      <h2 className="mb-8 leading-tight text-30px md:text-80px md:mb-12">
        <div className="border-b border-black">
          <Appear className="px-4 py-1 mx-auto lg:px-0 lg:w-9/10 lg:max-w-9/10 xl:max-w-1800">{titleTop}</Appear>
        </div>
        <Appear className="px-4 py-1 mx-auto lg:px-0 lg:w-9/10 lg:max-w-9/10 xl:max-w-1800">{titleBottom}</Appear>
      </h2>
      <Container>
        <Appear>
          <Video url={url} image={image} centerPlay noGrid/>
        </Appear>
      </Container>
    </div>
  )
}

export default VideoWithTitle
