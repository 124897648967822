// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import Viewer from './Viewer'
import LazyImage from './LazyImage'
import MadeWithPrinters from './MadeWithPrinters'
import Bubble from './Bubble'
import type { ApiImage, SimplePrinter } from '../types'

type Props = {
  title: string,
  text: string,
  // align: string,
  image: ApiImage,
  imagezoom: ApiImage,
  image360: Array<ApiImage>,
  printers: Array<SimplePrinter>,
  menuId?: string,
  inGradient?: boolean,
  smallSize?: number,
  verticalSlug: string,
  layout: string,
  texture?: ApiImage,
  texture2?: ApiImage,
  bubbleText?: string,
  isNew?: number,
  whiteParentBG?: boolean,
  backgroundColor?: String,
  styleColorsPrimary?: string,
  styleColorsSecondary?: string,
  styleColors?: { primary: string, secondary: string }

}

type ImageBubblesType = {
  texture: ApiImage,
  texture2: ApiImage,
  image: ApiImage,
  text: string,
  layout: string
}



const getLayout = layout => {
  switch (layout) {
    case 'white':
      return {
        bubble1:
          'col-span-1 col-start-4 row-start-1 row-span-2 overflow-hidden',
        bubble2:
          'col-span-2 square row-start-6 mt-12 row-span-2 col-start-1 z-10 rounded-full flex items-center justify-center p-2 md:p-12 bg-white text-black',
        bubble3: 'col-span-1 row-start-5 row-span-2 col-start-1 z-10'
      }
    case 'black':
      return {
        bubble1:
          'col-span-1 col-start-1 row-span-2 row-start-1 overflow-hidden',
        bubble2:
          'col-span-3 square row-start-6 row-span-2 mt-12 col-start-3 z-10 rounded-full flex justify-self-end items-center justify-center p-2 md:p-12 bg-black text-white',
        bubble3: 'col-span-2 row-start-5 row-span-2 col-start-4 z-10'
      }
    case 'odd':
      return {
        bubble1:
          'col-span-1 col-start-4 row-start-1 row-span-2 overflow-hidden',
        bubble2:
          'col-span-3 square row-start-6 row-span-2 col-start-3 z-10 rounded-full flex justify-self-end items-center justify-center p-2 md:p-12 bg-black text-white',
        bubble3:
          'col-span-3 w-32 h-32 row-start-5 row-span-2 col-start-2 z-10 justify-self-end'
      }
    default:
      return {
        bubble1: '',
        bubble2: '',
        bubble3: ''
      }
  }
}

const ImageBubbles = ({
  texture,
  text,
  layout,
  image,
  texture2
}: ImageBubblesType) => {
  const {
    bubble1,
    bubble2,
    bubble3
  }: { bubble1: string, bubble2: string, bubble3: string } = getLayout(layout)

  return (
    <div className="grid grid-cols-4 overflow-visible">
      <Appear
        className="relative flex col-span-4 col-start-1 row-span-6 row-start-1 overflow-hidden bg-white rounded-full square"
        // style={{ clipPath: `circle(50% at 50% 50%)` }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-9/12">
            <LazyImage image={image} alt={text} />
          </div>
        </div>
      </Appear>
      <Appear className={bubble1}>
        <div className="w-full">
          <LazyImage
            alt={text}
            image={texture}
            color={layout}
            className="w-full h-full overflow-hidden rounded-full"
          />
        </div>
      </Appear>
      {text ? (
        <Appear className={bubble2}>
          <h4 className="font-light leading-none text-center text-normal md:text-2xl">
            {text}
          </h4>
        </Appear>
      ) : (
        <Appear className={bubble3}>
          <LazyImage
            alt={text}
            image={texture2}
            color={layout}
            className="w-full h-full overflow-hidden rounded-full"
          />
        </Appear>
      )}
    </div>
  )
}

const CategoryBlock5 = ({
  inGradient,
  // align,
  menuId,
  title,
  text,
  image,
  imagezoom,
  image360,
  printers,
  verticalSlug,
  smallSize,
  layout,
  texture = {},
  texture2 = {},
  bubbleText = '',
  isNew = false,
  whiteParentBG = false,
  backgroundColor,
  styleColorsPrimary,
  styleColorsSecondary,
}: Props) => {

  switch (backgroundColor) {
    case "F4BAA9":
      backgroundColor = "bg-lighterSalmon23"
      break
      }

  
  const imageContent = (
    <Viewer
      iconColor={whiteParentBG ? "black" : "white"}
      image={image}
      image360={image360}
      imageZoom={imagezoom}
      alt={title}
      isNew={isNew}
    />
  )
  const textContent = (
    <>
      {title && title.length > 0 &&
      <h4 className={`font-bold text-big1 text-black ${styleColorsPrimary ? "bg-darkSalmon23 w-full py-3 pl-4": "border-b border-black pb-2 md:mt-4 md:mb-6 my-4"}`}>
        <Appear>{title}</Appear>
      </h4>}
      <div className={`font-light text-black ${styleColorsSecondary && "pl-4 py-4"}`}>
        <Appear>
          <Html text={text} />
        </Appear>
      </div>
      {Array.isArray(printers) && printers.length > 0 && (
        <div className={`mt-8 text-black ${styleColorsSecondary && "px-4"}`}>
          <MadeWithPrinters
            inGradient={inGradient}
            printers={printers}
            verticalSlug={verticalSlug}
            learnMore={['black', 'white'].includes(layout)}
            eventTagModuleName="CategoryBlock5"
            whiteParentBG
          />
        </div>
      )}
    </>
  )
  
  return (
    <div data-menuid={menuId}>
      <Spacer className={`${backgroundColor && backgroundColor}`} top bottom smaller>
        <Container>
          <Grid>
            {/* {align === 'left' ? ( */}
              <>
                <div
                  className={`col-span-4 col-start-1 mb-12 text-whicte md:flex md:flex-col md:justify-center ${
                    smallSize === 1
                      ? 'md:col-span-2 md:col-start-4 md:mb-0'
                      : 'md:col-span-5 md:col-start-4 md:mb-0'
                  }`}
                >
                  {layout &&
                  layout !== 'default' &&
                  layout !== '' &&
                  layout !== Number('-1') ? (
                    <ImageBubbles
                      layout={layout}
                      texture={texture}
                      texture2={texture2}
                      text={bubbleText}
                      image={image}
                    />
                  ) : (
                    imageContent
                  )}
                </div>
                <div
                  className={`col-span-4 col-start-1 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-start md:items-start ${styleColorsSecondary ? "bg-lightBeige22":""} ${
                    smallSize === 1
                      ? 'md:col-span-6 md:col-start-6'
                      : 'md:col-span-4 md:col-start-9'
                  } md:mb-0`}
                >
                  {textContent}
                </div>
              </>
            {/* ) : (
              <>
                <div
                  className={`col-span-4 col-start-1 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center ${
                    smallSize === 1
                      ? 'md:col-span-6   md:col-start-4'
                      : 'md:col-span-4 md:col-start-4'
                  } md:mb-0 md:pr-8`}
                >
                  {textContent}
                </div>
                <div
                  className={`col-span-4 col-start-1 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center ${
                    smallSize === 1
                      ? 'md:col-span-2 md:col-start-10'
                      : 'md:col-span-5 md:col-start-8'
                  } md:mb-0`}
                >
                  {layout &&
                  layout !== 'default' &&
                  layout !== '' &&
                  layout !== Number('-1') ? (
                    <ImageBubbles
                      layout={layout}
                      texture={texture}
                      texture2={texture2}
                      text={bubbleText}
                      image={image}
                    />
                  ) : (
                    imageContent
                  )}
                </div>
              </>
            )} */}
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock5
