// @flow
import React from 'react'
import Appear from './Appear'
import Grid from './Grid'
import DownloadButton from './DownloadButton'

type Props = {
  text: string,
  ctaLabel: string,
  ctaLink: string,
  insideContainer?: boolean,
}

const TextCta = ({
  text,
  ctaLabel,
  ctaLink,
  insideContainer
}: Props) => {
  const content = (
    <Appear>
      <div className="w-full">
        <p className="text-base font-light md:pb-4 md:text-20px">{text}</p>
        <DownloadButton ctaLink={ctaLink} ctaLabel={ctaLabel} />
      </div>
    </Appear>
  )

  if (insideContainer) {
    return (
      <div className="grid grid-cols-4 gap-4 px-4 pb-16 md:grid-cols-11 md:pb-24 md:pr-4 md:pl-0">
        <div className="col-span-4 md:col-start-2 md:col-span-10">
         {content}
        </div>
      </div>
    )
  }
  return (
    <Grid>
      <div className="col-span-4 md:col-start-2 md:col-span-11">
        {content}
      </div>
    </Grid>
  )
}

export default TextCta