function findGetParameter(path, parameterName) {
  let value = ''

  if (path.substring(path.indexOf('?')+1)) {
    const parameters = path.substring(path.indexOf('?')+1).split('&')
    parameters.forEach(param => {
      const [ key, v ] = param.split('=')
      if (!value && key === parameterName) {
        if (parameterName === 'gatedurl' || parameterName === 'gated') {
          value = path.substring(path.indexOf(`${parameterName}=`)+1+parameterName.length)
        } else {
          value = v
        }
      }
    })
  }
  
  if (value.charAt(value.length - 1) == '#') {
    value = value.substring(0, value.length - 1)
  }
  
  return value
}

export default findGetParameter
