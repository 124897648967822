// @flow
import React from 'react'

import Spacer from './Spacer'
import Appear from './Appear'
import Button from './Button'
import Html from './Html'
import LinkExternalHP from './LinkExternalHP'
import LazyImage from './LazyImage'

type Props = {
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  type: string,
  image: ApiImage,
  postCategory: Record<string, string>,
}

const TitleText = ({ text, title }: { text: string, title: string }) => (
  <>
    <h4 className="my-4 text-xl font-bold text-center md:my-8">
      <Appear>
        <Html text={title} />
      </Appear>
    </h4>
    <div className="font-light text-center">
      <Appear>
        <Html text={text} />
      </Appear>
    </div>
  </>
)

const DesignJet8 = (props: Props): React$Element<(_0: Props) => any> => {
  const { title, text, ctaText, ctaUrl, type, image } = props

  const hasImage = typeof image === 'object' && image.length !== 0

  if (type === 'post') {
    return (
      <section className="container my-10 md:my-70px mx-auto w-full justify-center">
        <div className="bg-grayLight border flex flex-col md:flex-row">
          {hasImage ? (
            <div className="w-full border-b md:border-b-0 md:w-2/5 justify-center flex items-end md:justify-center h-full overflow-hidden">
              <LazyImage
                image={image}
                alt={title}
                className="w-3/4 min-h-150 mt-10"
              />
            </div>
          ) : null}
          <div className="flex flex-col md:border-l md:border-b-0">
            {title ? <h4 className="text-24px p-4 border-b">{title}</h4> : null}
            {text || (ctaText && ctaUrl) ? (
              <div className="p-4">
                {text ? <p className="text-18px">{text}</p> : null}
                {ctaUrl && ctaText ? (
                  <LinkExternalHP
                    href={ctaUrl}
                    className="inline-block mt-4 mb-12 md:mb-0 px-4 py-2 text-14px text-center text-white whitespace-no-wrap bg-black border border-black focus:outline-none cursor-pointer hover:bg-white hover:border-black hover:text-black"
                  >
                    {ctaText}
                  </LinkExternalHP>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    )
  }

  return (
    <Spacer top bottom>
      <TitleText title={title} text={text} />
      <div className="flex justify-center mt-8">
        <Appear>
          <Button style="shop" title={ctaText} href={ctaUrl} />
        </Appear>
      </div>
    </Spacer>
  )
}

export default DesignJet8
