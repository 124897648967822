// @flow

import React, { useRef, useState } from 'react'
import Grid from './Grid'
import Container from './Container'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import IconChevron from './icons/IconChevron'

type SegmentType = {
  title: string,
  slug: string,
  subsegments?: Array<SegmentType>,
}

type SubsegmentProps = {
  subsegment: SegmentType,
  addSubsegment: Function,
  isActive: boolean,
}
type SegmentButtonProps = {
  segment: SegmentType,
  isActive: boolean,
  addSegment: Function,
  addSubsegment?: Function,
}

type Props = {
  title: string,
  items: Array<SegmentType>,
  extraProps: any,
}

const AllSegment = { title: 'All', slug: '', subsegments: [] }

const Subsegment = ({
  subsegment,
  addSubsegment,
  isActive,
}: SubsegmentProps) => {
  const [checked, setChecked] = useState(isActive)

  return (
    <div className="flex items-center gap-2 blog-subsegment-checkbox">
      <input
        type="checkbox"
        id={subsegment.slug}
        value={subsegment.slug}
        checked={checked}
        onChange={() => {
          setChecked(!checked)
          addSubsegment(subsegment.slug)
        }}
      />
      <label htmlFor={subsegment.slug}>{subsegment.title}</label>
    </div>
  )
}

const SegmentButton = ({
  segment,
  isActive,
  addSegment,
  addSubsegment,
}: SegmentButtonProps) => {
  const currentParams =
    typeof window !== 'undefined' ? window?.location.search : ''
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)

  const hasSubsegments = segment?.subsegments && segment.subsegments?.length > 0
  const hasActiveSubsegments =
    hasSubsegments &&
    segment.subsegments &&
    segment.subsegments.some(s => currentParams.includes(s.slug))

  useOnClickOutside(buttonRef, () => setOpen(false))

  return (
    <div
      ref={buttonRef}
      className="relative text-14px md:text-16px focus:outline-none"
    >
      <div
        onClick={() =>
          hasSubsegments ? setOpen(!open) : addSegment(segment.slug)
        }
        className={`border border-black pt-1 px-2 flex gap-2 transition-color duration-250 whitespace-no-wrap focus:outline-none ${
          isActive || (hasSubsegments && open) || hasActiveSubsegments
            ? 'bg-black text-white hover:bg-gray hover:text-white'
            : 'bg-white text-black hover:bg-gray hover:text-white'
        }`}
      >
        {segment.title}
        {hasSubsegments ? (
          <button className="w-5 p-1 focus:outline-none">
            <IconChevron />
          </button>
        ) : null}
      </div>
      {open ? (
        <div className="absolute z-40 flex flex-col gap-2 p-3 whitespace-no-wrap bg-white shadow-xl">
          {segment?.subsegments?.map((subsegment, index) => (
            <Subsegment
              key={`${subsegment.slug}-${index}`}
              subsegment={subsegment}
              addSubsegment={addSubsegment}
              isActive={currentParams.includes(subsegment.slug)}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const SegmentFilters = ({ title, items, extraProps: setFilters }: Props) => {
  const currentParams =
    typeof window !== 'undefined' ? window?.location.search : ''

  const handleFilters = (
    slug: string,
    filterType: 'segments' | 'subsegments'
  ) => {
    if (!slug || !filterType || !setFilters) return
    setFilters(prevFilters => {
      if (prevFilters[filterType].includes(slug)) {
        const filters = { ...prevFilters }
        filters[filterType] = filters[filterType].filter(s => s !== slug)
        return filters
      } else {
        const filters = { ...prevFilters }
        filters[filterType] = [...filters[filterType], slug]
        return filters
      }
    })
  }
  return (
    <Container className="fixed top-menu-mobile md:top-menu-desktop z-20 bg-white">
      <Grid>
        <div className="col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 flex flex-col gap-4 py-4 md:py-5 ">
          <h4 className="text-24px">{title}</h4>
          {items?.length > 0 ? (
            <div className="flex gap-2 overflow-x-scroll md:overflow-visible">
              <SegmentButton
                segment={AllSegment}
                isActive={!currentParams || currentParams.includes('all')}
                addSegment={() => setFilters({ segments: [], subsegments: [] })}
              />
              {items.map((segment, index) => (
                <SegmentButton
                  key={`${segment.slug}-${index}`}
                  segment={segment}
                  isActive={currentParams.includes(segment.slug)}
                  addSegment={slug => handleFilters(slug, 'segments')}
                  addSubsegment={slug => handleFilters(slug, 'subsegments')}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Grid>
    </Container>
  )
}

export default SegmentFilters
