// @flow
import React, { useEffect, useRef, useState } from 'react'
import type { Node } from 'react'
import { checkCookie, setCookie, getCookie } from "../lib/cookies"
import { concatSeries } from 'async'
import Container from './Container'
import Button from './Button'
import Html from './Html'
import { post } from '../lib/fetch'
import generateToken from '../lib/generateToken'

type Props = {
  id: string,
  post_url: string,
  user_activity_cta_decline: string,
  user_activity_cta_show: string,
  user_activity_title: string,
  language: string,
}

// Track the user's navigation through pages and set the time spent on each one.
const UserActivity = ({ id, post_url, user_activity_cta_decline, user_activity_cta_show, user_activity_title, language }: Props) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    let userActivityCookie = getCookie('userJourney') || { userId: '', viewing: '', history: {} }

  if (!userActivityCookie.userId) {
    userActivityCookie.userId = generateToken()
  }

    const currentTime = Date.now() / 1000

    if (userActivityCookie && userActivityCookie.history[id]) {
      userActivityCookie.history[id].accessed = currentTime
    } else {
      userActivityCookie.history[id] = {
        viewingTime: 0,
        accessed: currentTime
      }
    }

    userActivityCookie.viewing = id

    setCookie('userJourney', userActivityCookie)
    //console.log(userActivityCookie)

    // After 5 page visits post results 
    if (userActivityCookie && Object.keys(userActivityCookie.history).length > 0) {
      setHidden(false)
      delete userActivityCookie.viewing
      const parsedInfo = {...userActivityCookie, history: JSON.stringify(userActivityCookie.history)}
      post(post_url, parsedInfo)
    }
  }, [])

  return <div />

  // return (
  //   <div className={`${hidden ? 'hidden' : 'sticky'} bottom-0 three-way-gradient p-8 z-50 text-white flex flex-col gap-2 items-center text-big2`}>
  //     <p className="w-full mb-5 text-center sm:w-115 md:w-192">{user_activity_title}</p>
  //     <Button
  //       style="white"
  //       title={user_activity_cta_show}
  //       href=""
  //     />
  //     {<button-
  //       type="button"
  //       className="my-3 font-light text-normal"
  //       onClick={() => setHidden(true)}
  //     >
  //       {user_activity_cta_decline}
  //     </button>}
  //   </div>
  // )
}

export default UserActivity
