// @flow

import React from 'react'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  image: ApiImage,
  imageAlt: string,
  caption: string,
  respectSize: boolean,
}

// Image component
const StoryBlock4 = ({ image, imageAlt, respectSize, caption }: Props) => {
  return (
    <div className={`my-8 ${caption ? 'border-b pb-1 md:pb-2' : ''}`}>
      <div className="w-full h-full overflow-hidden">
        {respectSize ? (
          <LazySimpleImage
            image={makeAssetUrl({ asset: image, isImage: true })}
            alt={imageAlt}
            className="w-full h-full object-cover"
          />
        ) : (
          <LazyImage
            image={image}
            alt={imageAlt}
            className="w-full h-full object-cover"
          />
        )}
      </div>
      {caption ? <p>{caption}</p> : null}
    </div>
  )
}

export default StoryBlock4
