import React, { useCallback, useEffect, useState } from 'react'

const CarouselDots = ({ emblaApi, className = '' }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [snaps, setSnaps] = useState([])

  const onDotClick = useCallback(
    index => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onInit = useCallback(emblaApi => {
    setSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback(emblaApi => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi
      .on('reInit', onInit)
      .on('reInit', onSelect)
      .on('select', onSelect)
  }, [emblaApi, onInit, onSelect])
  return snaps?.length > 0 ? (
    <div className={`px-4 flex gap-2 py-4 justify-center ${className}`}>
      {snaps.map((_, index) => (
        <button
          key={index}
          type="button"
          onClick={() => onDotClick(index)}
          className={`rounded-full transition-all duration-250 h-5px cursor-pointer focus:outline-none outline-none ${
            selectedIndex === index
              ? 'bg-black h-5px w-15'
              : 'bg-grayer h-5px w-5px'
          }`}
          style={{ aspectRatio: '1/1' }}
        />
      ))}
    </div>
  ) : null
}

export default CarouselDots
