// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import makeAssetUrl from '../lib/makeAssetUrl'
import LazyImage from './LazyImage'
import Html from './Html'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'



type Props = {
backgroundImage: ApiImage,
backgroundImageMobile: ApiImage,
titleImage: ApiImage,
title: string,
}

const LionHeading = ({
    backgroundImage,
    backgroundImageMobile,
    titleImage,
    title,
}: Props) => {
    const isMobile = useIsMobile()
  return (
    <div
        className="relative w-full h-screen"
        style={{
            backgroundImage: `url(${makeAssetUrl({ asset: isMobile ? backgroundImageMobile : backgroundImage, isImage: true })})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
    >
        <Container className="w-full h-screen">
            <Grid className="relative w-full h-full">
                <div className="flex flex-row items-center justify-center col-span-4 md:items-start md:col-start-5 md:col-end-9 mt-180px">
                    <div className="w-2/3 h-auto">
                        <Appear>
                            <LazyImage image={titleImage} className="w-full h-auto" />
                        </Appear>
                    </div>
                </div>
                <div className="flex flex-row items-end justify-center col-span-4 mb-12 text-center md:mb-16 md:col-start-4 md:col-end-10">
                    <Appear>
                        <h1 className="font-light text-white text-3.5xl md:text-60px">
                            <Html text={title} />
                        </h1>
                    </Appear>
                </div>
            </Grid>
        </Container>
        <div
            className="absolute bottom-0 left-0 right-0 h-16"
            style={{
                backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)"
            }}
        />
    </div>
  )
}

export default LionHeading