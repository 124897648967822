// @flow

import React from 'react'
import type { ApiImage } from '../types'
import Appear from './Appear'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import LazyImage from './LazyImage'
import Button from './Button'

type Props = {
  title: string,
  subtitle: string,
  text: string,
  anchor?: string,
  image: ApiImage,
  ctaLabel?: string,
  ctaLink?: string,
}

const PrinterFeatures = ({ title, subtitle, text, image, ctaLink, ctaLabel, anchor = false }: Props) => {
  return (
    <>
    <Container backgroundColor="bg-realBlack">
      <Grid>
        <Appear className='col-span-4 col-start-1 md:col-span-9'>
          <h3 className="my-8 text-white text-big4 w-9/10 font-regular">
            <Html text={title} />
          </h3>
        </Appear>
      </Grid>
    </Container>
    <div className='w-full h-px bg-white'></div>
    <Container backgroundColor="bg-realBlack">
      <Grid className='py-6 md:py-12'>
        <div data-anchor={anchor} />
        <div className="col-span-4 md:col-span-6 md:col-start-7 md:row-start-1 md:px-4">
          { subtitle &&
            <Appear>
              <div className="pb-6 leading-tight text-white font-regular text-20px">
                <Html text={subtitle} />
              </div>
            </Appear>
          }
          { text &&
            <Appear className="text-base font-light text-white">
              <Html text={text}/>
            </Appear>
          }
        </div>
        <div className={`mt-8 md:mt-12 md:ml-8 ${text ? "col-span-4 md:col-start-7 md:col-span-2 " : "col-span-4 md:col-start-6 md:col-span-2 row-start-4 md:row-start-3"}`}>
          {ctaLabel && ctaLink && <div className="flex justify-center text-center md:justify-start">
              <Appear>
                <Button style="white" title={ctaLabel} href={ctaLink} padded />
              </Appear>
            </div>}
        </div>
        <div className={`flex flex-col items-center justify-center col-span-4 mt-8 md:mt-0  ${text ? "md:col-span-6 md:col-start-1 md:row-start-1" : "md:pt-4 md:col-span-6 md:col-start-4"}`}>
          <Appear className="w-full h-auto">
            <LazyImage cover image={image} />
          </Appear>
        </div>
      </Grid>
    </Container>
    </>
  )
}

export default PrinterFeatures