const THREE = require('three')
const { OBJLoader } = require('three/examples/jsm/loaders/OBJLoader')

class Objects {

  constructor(printerId, textures) {
    this.printerId = printerId
    this.textures = textures
  }
  
  async add(scene) {
    
    this.scene = scene
    this.objects.map(object => this.scene.add(object))

    // Sphere

    const geometry = new THREE.SphereBufferGeometry( 300, 60, 40 )
    geometry.scale( - 1, 1, 1 )
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      map: this.textures.get('envirohp2'),
    })
    const mesh = new THREE.Mesh( geometry, material )
    this.scene.add( mesh )
  }

  numberOfObjectsToLoad() {
    return this.list().length
  }

  async load(loadingCallback = () => {}) {
    const objects = await this._loadObjects(loadingCallback)
    this.objects = objects
  }

  list() {

    const allObjects = {
      p2: [
        {
          id: 'wood',
          url: '/stand3d/assets/p2/objects/wood.obj',
          material: new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: this.textures.get('woodb'),
            emissiveMap: this.textures.get('woodb'),
          })
        },
        {
          id: 'circles',
          url: '/stand3d/assets/p2/objects/circles.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('circles'),
            emissive: 0xffffff,
            emissiveIntensity: 0.3,
            emissiveMap: this.textures.get('circles'),
          })
        },
        {
          id: 'bars',
          url: '/stand3d/assets/p2/objects/bars.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('bars'),
            emissive: 0x202020,
            emissiveIntensity: 0.7,
          })
        },
        {
          id: 'column',
          url: '/stand3d/assets/p2/objects/column.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x0e0100,
            emissive: 0x2e0b00,
            emissiveIntensity: 1.3,
            specular: 0xFED67A,
            shininess: 0.7,
            emissiveMap: this.textures.get('column'),
          })
        },
        {
          id: 'glass',
          url: '/stand3d/assets/p2/objects/glass.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            specular: 0xffffff,
            emissive: 0xffffff,
            emissiveIntensity: 0.7,
            shininess: 39.12,
            refractionRatio: 0.98,
            transparent: true, 
            vertexColors: true,
            side: THREE.DoubleSide,
            alphaTest: 0,
            opacity: 0.34,
            depthTest: true,
            depthWrite: true,
            reflectivity: 1,
          })
        },
        {
          id: 'prints',
          url: '/stand3d/assets/p2/objects/printa.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('prints'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'seats',
          url: '/stand3d/assets/p2/objects/seats.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('seats'),
          })
        },
        {
          id: 'neon',
          url: '/stand3d/assets/p2/objects/neon.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
          })
        },
        {
          id: 'squares',
          url: '/stand3d/assets/p2/objects/squares.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('squares'),
            side: THREE.DoubleSide,
          }),
          position: [-2.231, 1.718, -1.479]
        },
        {
          id: 'van',
          url: '/stand3d/assets/p2/objects/van.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('vandif'),
            emissiveMap: this.textures.get('vandif'),
            normalMap: this.textures.get('vannorm'),
            shininess: 14.24,
          })
        },
        {
          id: 'walls',
          url: '/stand3d/assets/p2/objects/walls.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('walls'),
          })
        },
        {
          id: 'texts',
          url: '/stand3d/assets/p2/objects/texts.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x383838,
            emissive: 0xffffff,
            emissiveIntensity: 1,
            alphaTest: 0.58,
            transparent: true,
            opacity: 1,
            emissiveMap: this.textures.get('texts'),
            alphaMap: this.textures.get('texts'),
          })
        },  
        {
          id: 'outside',
          url: '/stand3d/assets/p2/objects/outside.obj',
          material: new THREE.MeshStandardMaterial({
            color: 0x353535,
            emissive: 0x0a0a0a,
            emissiveIntensity: 0.76,
            roughness: 0.12,
          })
        },
        {
          id: 'screens',
          url: '/stand3d/assets/p2/objects/screens.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('screens'),
          })
        },
        {
          id: 'polestar',
          url: '/stand3d/assets/p2/objects/polestar.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('polestar'),
          })
        },
        {
          id: 'books',
          url: '/stand3d/assets/p2/objects/books.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('books'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'pers1',
          url: '/stand3d/assets/p2/objects/pers1.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [0.079, 0.18, 3.493],
        },
        {
          id: 'pers2',
          url: '/stand3d/assets/p2/objects/pers2.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-2.102,0.18,6.997],
        },
        {
          id: 'pers3',
          url: '/stand3d/assets/p2/objects/pers3.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-4.308,0.18,-2.464],
        },
        {
          id: 'pers4',
          url: '/stand3d/assets/p2/objects/pers4.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [7.647,0.18,1.422],
        },
        {
          id: 'pers5',
          url: '/stand3d/assets/p2/objects/pers5.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [9.476,0.18,4.096],
        },
        {
          id: 'pers6',
          url: '/stand3d/assets/p2/objects/pers6.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-5.613 , -0.619 , 4.616],
        },
      ],
      p3: [
        {
          id: 'wood',
          url: '/stand3d/assets/p3/objects/wood.obj',
          material: new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: this.textures.get('woodb'),
            emissiveMap: this.textures.get('woodb'),
          })
        },
        {
          id: 'circles',
          url: '/stand3d/assets/p3/objects/circles.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('circles'),
            emissive: 0xffffff,
            emissiveIntensity: 0.3,
            emissiveMap: this.textures.get('circles'),
          })
        },
        {
          id: 'bars',
          url: '/stand3d/assets/p3/objects/bars.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('bars'),
            emissive: 0x202020,
            emissiveIntensity: 0.7,
          })
        },
        {
          id: 'column',
          url: '/stand3d/assets/p3/objects/column.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x0e0100,
            emissive: 0x2e0b00,
            emissiveIntensity: 1.3,
            specular: 0xFED67A,
            shininess: 0.7,
            emissiveMap: this.textures.get('column'),
          })
        },
        {
          id: 'glass',
          url: '/stand3d/assets/p3/objects/glass.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            specular: 0xffffff,
            emissive: 0xffffff,
            emissiveIntensity: 0.7,
            shininess: 39.12,
            refractionRatio: 0.98,
            transparent: true, 
            vertexColors: true,
            side: THREE.DoubleSide,
            alphaTest: 0,
            opacity: 0.34,
            depthTest: true,
            depthWrite: true,
            reflectivity: 1,
          })
        },
        {
          id: 'prints',
          url: '/stand3d/assets/p3/objects/printa.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('prints'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'seats',
          url: '/stand3d/assets/p3/objects/seats.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('seats'),
          })
        },
        {
          id: 'neon',
          url: '/stand3d/assets/p3/objects/neon.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
          })
        },
        {
          id: 'squares',
          url: '/stand3d/assets/p3/objects/squares.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('squares'),
            side: THREE.DoubleSide,
          }),
          position: [-2.231, 1.718, -1.479]
        },
        {
          id: 'walls',
          url: '/stand3d/assets/p3/objects/walls.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('walls'),
          })
        },
        {
          id: 'texts',
          url: '/stand3d/assets/p3/objects/texts.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x383838,
            emissive: 0xffffff,
            emissiveIntensity: 1,
            alphaTest: 0.58,
            transparent: true,
            opacity: 1,
            emissiveMap: this.textures.get('texts'),
            alphaMap: this.textures.get('texts'),
          })
        },  
        {
          id: 'outside',
          url: '/stand3d/assets/p3/objects/outside.obj',
          material: new THREE.MeshStandardMaterial({
            color: 0x353535,
            emissive: 0x0a0a0a,
            emissiveIntensity: 0.76,
            roughness: 0.12,
          })
        },
        {
          id: 'screens',
          url: '/stand3d/assets/p3/objects/screens.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('screens'),
          })
        },
        {
          id: 'lion',
          url: '/stand3d/assets/p3/objects/lion.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('lion'),
          })
        },
        {
          id: 'books',
          url: '/stand3d/assets/p3/objects/books.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('books'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'pers1',
          url: '/stand3d/assets/p3/objects/pers1.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [0.079, 0.18, 3.493],
        },
        {
          id: 'pers2',
          url: '/stand3d/assets/p3/objects/pers2.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-2.102,0.18,6.997],
        },
        {
          id: 'pers3',
          url: '/stand3d/assets/p3/objects/pers3.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-4.308,0.18,-2.464],
        },
        {
          id: 'pers4',
          url: '/stand3d/assets/p3/objects/pers4.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [7.647,0.18,1.422],
        },
        {
          id: 'pers5',
          url: '/stand3d/assets/p3/objects/pers5.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [9.476,0.18,4.096],
        },
        {
          id: 'pers6',
          url: '/stand3d/assets/p3/objects/pers6.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-5.613 , -0.619 , 4.616],
        },
      ],
      p4: [
        {
          id: 'wood',
          url: '/stand3d/assets/p4/objects/wood.obj',
          material: new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: this.textures.get('woodb'),
            emissiveMap: this.textures.get('woodb'),
          })
        },
        {
          id: 'circles',
          url: '/stand3d/assets/p4/objects/circles.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('circles'),
            emissive: 0xffffff,
            emissiveIntensity: 0.3,
            emissiveMap: this.textures.get('circles'),
          })
        },
        {
          id: 'bars',
          url: '/stand3d/assets/p4/objects/bars.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('bars'),
            emissive: 0x202020,
            emissiveIntensity: 0.7,
          })
        },
        {
          id: 'column',
          url: '/stand3d/assets/p4/objects/column.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x0e0100,
            emissive: 0x2e0b00,
            emissiveIntensity: 1.3,
            specular: 0xFED67A,
            shininess: 0.7,
            emissiveMap: this.textures.get('column'),
          })
        },
        {
          id: 'glass',
          url: '/stand3d/assets/p4/objects/glass.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            specular: 0xffffff,
            emissive: 0xffffff,
            emissiveIntensity: 0.7,
            shininess: 39.12,
            refractionRatio: 0.98,
            transparent: true, 
            vertexColors: true,
            side: THREE.DoubleSide,
            alphaTest: 0,
            opacity: 0.34,
            depthTest: true,
            depthWrite: true,
            reflectivity: 1,
          })
        },
        {
          id: 'prints',
          url: '/stand3d/assets/p4/objects/printa.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('prints'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'seats',
          url: '/stand3d/assets/p4/objects/seats.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('seats'),
          })
        },
        {
          id: 'neon',
          url: '/stand3d/assets/p4/objects/neon.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
          })
        },
        {
          id: 'squares',
          url: '/stand3d/assets/p4/objects/squares.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('squares'),
            side: THREE.DoubleSide,
          }),
          position: [-2.231, 1.718, -1.479]
        },
        {
          id: 'walls',
          url: '/stand3d/assets/p4/objects/walls.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('walls'),
          })
        },
        {
          id: 'texts',
          url: '/stand3d/assets/p4/objects/texts.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x383838,
            emissive: 0xffffff,
            emissiveIntensity: 1,
            alphaTest: 0.58,
            transparent: true,
            opacity: 1,
            emissiveMap: this.textures.get('texts'),
            alphaMap: this.textures.get('texts'),
          })
        },  
        {
          id: 'outside',
          url: '/stand3d/assets/p4/objects/outside.obj',
          material: new THREE.MeshStandardMaterial({
            color: 0x353535,
            emissive: 0x0a0a0a,
            emissiveIntensity: 0.76,
            roughness: 0.12,
          })
        },
        {
          id: 'screens',
          url: '/stand3d/assets/p4/objects/screens.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('screens'),
          })
        },
        {
          id: 'printer',
          url: '/stand3d/assets/p4/objects/printer.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('printer'),
          })
        },
        {
          id: 'books',
          url: '/stand3d/assets/p4/objects/books.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('books'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'pers1',
          url: '/stand3d/assets/p4/objects/pers1.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [0.079, 0.18, 3.493],
        },
        {
          id: 'pers2',
          url: '/stand3d/assets/p4/objects/pers2.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-2.102,0.18,6.997],
        },
        {
          id: 'pers3',
          url: '/stand3d/assets/p4/objects/pers3.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-4.308,0.18,-2.464],
        },
        {
          id: 'pers4',
          url: '/stand3d/assets/p4/objects/pers4.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [7.647,0.18,1.422],
        },
        {
          id: 'pers5',
          url: '/stand3d/assets/p4/objects/pers5.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [9.476,0.18,4.096],
        },
        {
          id: 'pers6',
          url: '/stand3d/assets/p4/objects/pers6.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-5.613 , -0.619 , 4.616],
        },
      ],
      p5: [
        {
          id: 'printer',
          url: '/stand3d/assets/p5/objects/printer.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('printer'),
          })
        },
        {
          id: 'wood',
          url: '/stand3d/assets/p5/objects/wood.obj',
          material: new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: this.textures.get('woodb'),
            emissiveMap: this.textures.get('woodb'),
          })
        },
        {
          id: 'circles',
          url: '/stand3d/assets/p5/objects/circles.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('circles'),
            emissive: 0xffffff,
            emissiveIntensity: 0.3,
            emissiveMap: this.textures.get('circles'),
          })
        },
        {
          id: 'bars',
          url: '/stand3d/assets/p5/objects/bars.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('bars'),
            emissive: 0x202020,
            emissiveIntensity: 0.7,
          })
        },
        {
          id: 'column',
          url: '/stand3d/assets/p5/objects/column.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x0e0100,
            emissive: 0x2e0b00,
            emissiveIntensity: 1.3,
            specular: 0xFED67A,
            shininess: 0.7,
            emissiveMap: this.textures.get('column'),
          })
        },
        {
          id: 'glass',
          url: '/stand3d/assets/p5/objects/glass.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            specular: 0xffffff,
            emissive: 0xffffff,
            emissiveIntensity: 0.7,
            shininess: 39.12,
            refractionRatio: 0.98,
            transparent: true, 
            vertexColors: true,
            side: THREE.DoubleSide,
            alphaTest: 0,
            opacity: 0.34,
            depthTest: true,
            depthWrite: true,
            reflectivity: 1,
          })
        },
        {
          id: 'prints',
          url: '/stand3d/assets/p5/objects/printa.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('prints'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'seats',
          url: '/stand3d/assets/p5/objects/seats.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('seats'),
          })
        },
        {
          id: 'neon',
          url: '/stand3d/assets/p5/objects/neon.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
          })
        },
        {
          id: 'squares',
          url: '/stand3d/assets/p5/objects/squares.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('squares'),
            side: THREE.DoubleSide,
          }),
          position: [-2.231, 1.718, -1.479]
        },
        // {
        //   id: 'van',
        //   url: '/stand3d/assets/p5/objects/van.obj',
        //   material: new THREE.MeshPhongMaterial({
        //     color: 0xffffff,
        //     map: this.textures.get('vandif'),
        //     emissiveMap: this.textures.get('vandif'),
        //     normalMap: this.textures.get('vannorm'),
        //     shininess: 14.24,
        //   })
        // },
        {
          id: 'walls',
          url: '/stand3d/assets/p5/objects/walls.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('walls'),
          })
        },
        {
          id: 'texts',
          url: '/stand3d/assets/p5/objects/texts.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x383838,
            emissive: 0xffffff,
            emissiveIntensity: 1,
            alphaTest: 0.58,
            transparent: true,
            opacity: 1,
            emissiveMap: this.textures.get('texts'),
            alphaMap: this.textures.get('texts'),
          })
        },  
        {
          id: 'outside',
          url: '/stand3d/assets/p5/objects/outside.obj',
          material: new THREE.MeshStandardMaterial({
            color: 0x353535,
            emissive: 0x0a0a0a,
            emissiveIntensity: 0.76,
            roughness: 0.12,
          })
        },
        {
          id: 'screens',
          url: '/stand3d/assets/p5/objects/screens.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('screens'),
          })
        },
        {
          id: 'books',
          url: '/stand3d/assets/p5/objects/books.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0xffffff,
            map: this.textures.get('books'),
            side: THREE.DoubleSide,
          })
        },
        {
          id: 'pers1',
          url: '/stand3d/assets/p5/objects/pers1.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [0.079, 0.18, 3.493],
        },
        {
          id: 'pers2',
          url: '/stand3d/assets/p5/objects/pers2.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-2.102,0.18,6.997],
        },
        {
          id: 'pers3',
          url: '/stand3d/assets/p5/objects/pers3.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-4.308,0.18,-2.464],
        },
        {
          id: 'pers4',
          url: '/stand3d/assets/p5/objects/pers4.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [7.647,0.18,1.422],
        },
        {
          id: 'pers5',
          url: '/stand3d/assets/p5/objects/pers5.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [9.476,0.18,4.096],
        },
        {
          id: 'pers6',
          url: '/stand3d/assets/p5/objects/pers6.obj',
          material: new THREE.MeshPhongMaterial({
            color: 0x000000,
            alphaMap: this.textures.get('persalpha'),
            emissive: 0x000000,
            side: THREE.DoubleSide,
            transparent: true,
          }),
          position: [-5.613 , -0.619 , 4.616],
        },
      ],
    }

    return allObjects[`p${this.printerId}`]
  }

  _loadObjects(loadingCallback) {
    var loader = new OBJLoader()
    const promises = []
    this.list().forEach(obj => {
      promises.push(new Promise((resolve, reject) => {
        loader.load(obj.url, loadedObj => {
          loadingCallback(loadedObj)
          loadedObj.userData.id = obj.id
          loadedObj.receiveShadow = true
          loadedObj.traverse(child => {
            if (child instanceof THREE.Mesh) {
              child.material = obj.material
            }
          })
          if (typeof obj.position !== 'undefined') {
            loadedObj.position.set(obj.position[0], obj.position[1], obj.position[2])
          }
          resolve(loadedObj)
        }, undefined, error => {
          console.log(error)
          reject(null)
        })
      }))
    })
    return Promise.all(promises)
  }

  lookAtCamera(objects, camera) {
    objects.forEach(object => {
      const theObj = this.objects.find(obj => obj.userData.id === object)
      theObj.lookAt(camera.position)
    })
  }

}

export default Objects