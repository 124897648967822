// @flow
import React from 'react'

type Props = {
  className?: string,
  children: React$Node,
  style?: Object,
  noGap?: boolean,
}

const Grid = ({ className, children, style, noGap }: Props): React$Element<"div"> => {
  return (
    <div style={style} className={`grid grid-cols-4 ${noGap ? '' : 'gap-4'} md:grid-cols-12 ${className ? className : ''}`}>
      {children}
    </div>
  )
}

export default Grid