// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Html from './Html'
import Appear from './Appear'
import makeAssetUrl from '../lib/makeAssetUrl'
import LinkHP from './LinkHP'
import type { ApiImage } from '../types'

type FeaturedPrintersType = {
  id: string,
  image: ApiImage,
  title: string,
  url: string
}

type Props = {
  text: string,
  items: Array<FeaturedPrintersType>
}

const PrinterRange = ({ moduleName, id, index, text, items }: Props) => {
  return (
    <Spacer top bottom className="text-black bg-beige22">
      <Container>
        <Grid>
          <div className="col-span-4 col-start-1 text-center md:col-span-12 md:col-start-1">
            <h3 className="mb-12 text-center text-big1 ignore-br md:use-br">
              <Appear>
                <Html text={text} />
              </Appear>
            </h3>
            <div className="flex flex-wrap -mx-2 md:-mx-2 md:flex-no-wrap">
              {items.map(item => (
                <div
                  key={`range-${item.id}`}
                  className="relative flex-grow-0 flex-shrink-0 px-2 mb-4 text-white md:px-0 md:mx-2 md:flex-shrink flex-basis-1/3 md:flex-1 gradient-overlay"
                >
                  <div className="w-full md:hidden pt-full"></div>
                  <div
                    className="absolute top-0 flex items-center bg-center bg-no-repeat bg-cover left-2 right-2 bottom-2 md:w-auto md:h-auto md:relative md:left-auto md:right-auto md:top-auto md:bottom-auto"
                    style={{ backgroundImage: `url(${makeAssetUrl({ asset: item.image, isImage: true })}` }}
                  >
                    <LinkHP
                      className="relative z-10 flex items-center justify-center block w-full px-2 text-center md:py-12 hover:underline"
                      to={item.url}
                      title={item.title}
                      data-tag-name={`${moduleName}-${id}`} 
                    >
                      {item.title}
                    </LinkHP>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PrinterRange
