// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import LazyImage from './LazyImage'
import Button from './Button'
import type { ApiImage } from '../types'

type Props = {
  title: string,
  text: string,
  image: ApiImage,
  insideContainer?: boolean,
  anchor?: string,
  ctaLabel?: string,
  ctaLink?: string
}

const LionDiscover = ({ title, text, image, insideContainer = false, ctaLabel, ctaLink, anchor = false }: Props) => {
  const textContent = (
    <div className="col-span-4 md:col-start-2">
      <h3 className="w-3/4 mb-6 font-light leading-tight md:w-4/5 md:mb-12 text-24px md:text-40px">
        <Appear>
          <Html text={title} />
        </Appear>
      </h3>
      <div className="font-light leading-tight md:text-24px">
        <Appear>
          <Html text={text} />
        </Appear>
      </div>
      {ctaLabel && ctaLink && <div className="pt-8 pb-8 md:pb-0 md:pt-12">
        <Appear>
          <Button style="blackOutlined" title={ctaLabel} href={ctaLink} padded />
        </Appear>
      </div>}
    </div>
  )

  const imageContent = (
    <div className="col-span-4 md:col-start-7 md:col-span-6">
      <Appear>
        <LazyImage image={image} />
      </Appear>
    </div>
  )

  if (insideContainer) {
    return (
      <div className="py-6 pl-4 pr-4 md:pl-0 md:py-12">
        <div className="grid grid-cols-4 gap-4 md:grid-cols-11">
          {textContent}
          {imageContent}
        </div>
      </div>
    )
  }
  
  return (
    <Container className="mt-4 mb-16 md:mt-12 md:mb-40">
      <div data-anchor={anchor} />
      <Grid>
        {textContent}
        {imageContent}
      </Grid>
    </Container>
  )
}

export default LionDiscover