// @flow

import React, { useRef, useEffect, useState } from 'react'

type Props = {
    width?: Number | string,
}

const LionRedSquare = ({
    width,
}: Props) => {
  const squareRef = useRef({})
  const [screenWidth, setScreenWidth] = useState()
  const [squareHeight, setSquareHeight] = useState(width)

  const handleResize = () => {
    const width = document.documentElement.clientWidth || document.body.clientWidth
    setScreenWidth(width)
  }

  useEffect(() => {
    if (squareRef && squareRef.current) {
      const pxWidth = squareRef.current.offsetWidth
      setSquareHeight(pxWidth)
    }
  }, [screenWidth])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="bg-lionRed" ref={squareRef} style={width ? { width: width, height: width } : { width: "100%", height: squareHeight }} />
  )
}

export default LionRedSquare