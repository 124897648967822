// @flow

import React, { createContext, useContext } from 'react'

export const LiteralsContext: React$Context<?Object> = createContext()
export const MediaContext: React$Context<?Object> = createContext()

export const GlobalLiterals = ({
  children,
  literals
}: {
  children: React$Node,
  literals: Object
}) => (
  <LiteralsContext.Provider value={literals}>
    {children}
  </LiteralsContext.Provider>
)

export const GlobalMedia = ({
  children,
  media
}: {
  children: React$Node,
  media: Object
}) => <MediaContext.Provider value={media}>{children}</MediaContext.Provider>

export const useLiterals: function = key => {
  const ctx: Array<any> = useContext(LiteralsContext) || []
  const item = ctx.find(item => item.key === key)
  if (item) {
    return item.value
  }
  return key
}
export const useMedia: function = key => {
  const ctx: Array<any> = useContext(MediaContext) || []
  const item = ctx.find(item => item.key === key)
  if (item) {
    return item.value
  }
  return key
}
