// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Video from './Video'
import LionRedSquare from './LionRedSquare'
import Appear from './Appear'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'



type Props = {
url: string,
image: ApiImage,
}

const LionVideo = ({
    url,
    image
}: Props) => {
    const isMobile = useIsMobile()
  return (
      <Container backgroundColor="bg-realBlack" padding={!isMobile}>
        <Grid className="w-full h-full" noGap={!isMobile}>
            <div className="col-span-1 ml-4 md:ml-0 md:col-start-1 md:col-span-2 md:row-start-1">
                <Appear>
                    <LionRedSquare width={isMobile && 50} />
                </Appear>
            </div>
            <div className="col-span-4 md:col-start-3 md:col-span-10 md:row-start-2">
                <Appear>
                    <Video id="1" url={url} noGrid image={image} centerPlay={isMobile}/>
                </Appear>
            </div>
            <div className="hidden md:block md:col-start-2 md:col-span-1 md:row-start-3">
                <Appear>
                    <LionRedSquare />
                </Appear>
            </div>
        </Grid>
    </Container>
  )
}

export default LionVideo