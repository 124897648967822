// @flow

import React from 'react'
import { Link } from 'gatsby'
import useEmblaCarousel from 'embla-carousel-react'
import LazyImage from './LazyImage'
import CarouselButtons from './CarouselButtons'
import CarouselDots from './CarouselDots'

import type { Item } from './PostSlider'

type Props = { items: Array<Item> }
const PostSliderCarousel = ({ items }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })

  return (
    <div className="md:hidden">
      <CarouselButtons emblaApi={emblaApi} className="justify-end mx-4 my-4" />

      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex h-16rem gap-4 mx-5">
          {items?.length > 0
            ? items.map(({ id, image, title, url, category }) => (
                <Link
                  to={url}
                  key={id}
                  className="h-full"
                  style={{ flex: '0 0 100%' }}
                >
                  {image ? (
                    <LazyImage
                      image={image}
                      alt={title ?? ''}
                      cover
                      className="w-full h-150px"
                    />
                  ) : null}
                  <div className="px-2 text-14px mt-1">{category}</div>
                  <div
                    className="border-t text-20px pb-1 px-2 pt-2 leading-normal overflow-hidden"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '2',
                    }}
                  >
                    {title}
                  </div>
                </Link>
              ))
            : null}
        </div>
      </div>
      <CarouselDots emblaApi={emblaApi} />
    </div>
  )
}

export default PostSliderCarousel
