// @flow
import React, { useRef, useState, useEffect } from 'react'
import Bubble from './Bubble'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import Youtube from './Youtube'
import Html from './Html'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  items: Array<Object>,
  texture: Object,
  texture1: Object,
  texture2: Object
}

const getPosition = (index: number) => {
  switch (index) {
    case 0:
      return `col-start-1 col-span-4 mb-4 md:mb-0 md:col-start-1 md:col-span-6 md:row-start-1 z-10`
    case 1:
      return `col-start-1 col-span-4 mb-4 md:mb-0 md:col-start-4 md:col-span-6 md:row-start-2 md:mt-8 z-10`
    case 2:
      return `col-start-1 col-span-4 mb-4 md:mb-0 md:col-start-7 md:col-span-6 md:row-start-3 md:mt-8 z-10`
  }
}

const DesignJet1 = ({ items, texture, texture2 }: Props) => {
  return (
    <Spacer top>
      <Container className="relative pb-32 mt-24 overflow-hidden md:overflow-visible md:mt-0 md:pb-0">
        <Appear className="absolute w-80 h-80 -right-35p -top-30p md:w-220 md:h-220 md:-mt-64 md:-right-35p lg:-right-60p">
          <Bubble className="w-full h-full" src={makeAssetUrl({ assete:texture, isImage: true })} />
        </Appear>
        <Grid className="relative w-full overflow-visible">
          {items.map(({ id, url, image, title, text }, index) => (
            <Appear className={getPosition(index)} key={`${id}-${index}`}>
              <div className="mb-4 text-2xl md:text-4xl md:mb-8">{title}</div>
              <div className="mb-4 font-light md:mb-8">
                <Html text={text} />
              </div>
              <Youtube id={id} image={image} url={url} />
            </Appear>
          ))}
        </Grid>
        <Appear className="w-64 h-64 -left-35p top-75p absolute md:h-80 md:w-80 md:-left-10p md:top-50p lg:-left-30p">
          <Bubble className="w-full h-full" src={makeAssetUrl({ assete:texture2, isImage: true })} />
        </Appear>
      </Container>
    </Spacer>
  )
}

export default DesignJet1
