// @flow
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Animated from './Animated'
import LazySimpleImage from './LazySimpleImage'
import { useMedia } from 'store/'
import type { InputRef } from '../types'
import { debounce } from 'lodash'

type FiltersProps = {
  filters: Array<any>,
  selectedFilters: Array<any>,
  onValueChange: Function,
  isMobile: boolean,
  dropdown: boolean,
  selectFilter: Function,
  inputValue: string,
  type: string,
}

const animations = {
  filter: (filter: string, isMobile) => {
    if (typeof document !== 'undefined') {
      let height = 0
      const box = document.querySelector(`#${filter}`)
      if (box) {
        if (isMobile) {
          const filtersBox: any = document.querySelector('#filters-box')
          filtersBox.parentElement.style.overflow = 'scroll'
        }
        Array.from(box.children).forEach(child => {
          height += child.offsetHeight
        })
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`
        },
        duration: 0.5
      }
    }
  },
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  }
}

const SharedFilters = ({
  moduleName,
  id,
  filters,
  selectedFilters,
  selectFilter,
  onValueChange,
  isMobile,
  dropdown,
  inputValue,
  checkPrinterSolutions,
  type
}: FiltersProps): React$Node => {

  const [openFilters, setOpenFilters] = useState([])
  const input: InputRef = useRef()

  const handler = useCallback(
    debounce(() => {
      onValueChange(input.current.value)
    }, 200),
    []
  )

  useEffect(() => {
    input.current.value = inputValue
  }, [inputValue])

  useEffect(() => {
    if (!dropdown) {
      setOpenFilters([])
    }
  }, [dropdown])

  const handleFilterBox = (filter: string): void => {
    let currentFilters: Array<string> = []
    if (openFilters.includes(filter)) {
      currentFilters = openFilters.filter(f => f !== filter)
    } else {
      currentFilters.push(filter)
    }
    setOpenFilters(currentFilters)
  }

  return (
    <div id="filters-box" className="bg-white">
      <div className="flex justify-between pb-2 border-b border-1">
        <div className="flex-shrink-0">
          <LazySimpleImage image={useMedia('search')} alt="search" />
        </div>
        <input
          className="flex-1 pl-4 outline-none"
          ref={input}
          onChange={() => handler()}
        />
      </div>
      <div className="w-full mt-8 border-1">
        {filters.map((filter, index) => (
          <div key={filter.id}>
            <div data-tag-name={`${type}-type-${filter.id}`}
              className={`p-4 border ${index && 'border-t-0'} justify-between`}
            >
              <div
                className="flex justify-between w-full col-span-2 cursor-pointer"
                onClick={() => handleFilterBox(filter)}
              >
                <span className="font-light text-smaller">{filter.title}</span>
                <div className="relative flex items-center">
                  <Animated
                    key={`category-${index}`}
                    className="absolute h-px bg-black w-15px"
                    type="spring"
                    bind={openFilters.find(f => f === filter)}
                    animation={animations.plus}
                  ></Animated>
                  <div className="h-px bg-black w-15px"></div>
                </div>
              </div>
              <Animated
                animation={animations?.filter(
                  `${type}-category-${filter.id}`,
                  isMobile
                )}
                bind={openFilters.find((f: any) => f.id === filter.id)}
                type="spring"
                className="col-span-2 col-start-1 overflow-hidden border-box"
                id={`${type}-category-${filter.id}`}
              >
                {filter[type]?.map(({ id, title }, index) => (
                  <li
                    key={id}
                    className={`relative flex items-start box-border cursor-pointer text-black ${
                      !index ? 'pt-4 pb-2' : 'py-1'
                    }`}
                    data-tag-name={`${type}-type-${filter.id}-filter-${id}`}
                    onClick={() =>
                      selectFilter({ category: filter, id, label: title })
                    }
                  >
                    <div className="h-full">
                      <div
                        className={`${
                          selectedFilters.find(
                            ({ id: filterID, category }) => {
                              if (checkPrinterSolutions) {
                                return filterID === id 
                              } else {
                                return filterID === id && category === filter
                              }
                            }
                          )
                            ? 'checkbox-selected'
                            : 'checkbox'
                        } flex items-center justify-center flex-shrink-0 self-center`}
                      ></div>
                    </div>
                    <span className="pl-4 font-light text-smaller">
                      {title}
                    </span>
                  </li>
                ))}
              </Animated>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SharedFilters
