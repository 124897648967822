// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import useIsMobile from '../hooks/useIsMobile'
import colorCodeFormat from '../lib/colorCodeFormat'

type Props = {
  title: string,
  backgroundColor?: string,
  textColor?: string,
  noPaddingBottom?: boolean,
  insideContainer?: boolean,
}

const SimpleTitle = ({
  title,
  backgroundColor,
  textColor,
  noPaddingBottom,
  insideContainer = false,
}: Props) => {
  const isMobile = useIsMobile()

  const content = (
    <Appear>
      <h2
        className="leading-none text-40px md:text-50px font-regular"
        style={{
          color: textColor ? colorCodeFormat(textColor) : null,
        }}
      >
        {title}
      </h2>
    </Appear>
  )

  if (insideContainer) {
    return (
      <div className="grid grid-cols-4 gap-4 px-4 pb-16 md:grid-cols-11 md:pb-24 md:pr-4 md:pl-0">
        <div className="col-span-3 col-start-1 md:col-start-2 md:col-span-3">
          {content}
        </div>
        <div className='border-b-2 border-black py-4 col-span-4 md:col-span-12 -mx-4'/>
      </div>
    )
  }

  return (
    <Container style={{ backgroundColor: backgroundColor ? colorCodeFormat(backgroundColor) : null }}>
      <div style={{
        backgroundColor: backgroundColor ? colorCodeFormat(backgroundColor) : null ,
        paddingBottom: noPaddingBottom === 1 ? (isMobile ? 20 : 0) : (isMobile ? 40 : 60)
        }}
      >
        <Grid>
          <div className="col-span-3 col-start-1 md:col-start-2">
            {content}
          </div>
        </Grid>
      </div>
    </Container>
  )
}

export default SimpleTitle