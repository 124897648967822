// @flow
import React, { useEffect, useState, useRef } from 'react'
import Container from './Container'
import Spacer from './Spacer'
import Grid from './Grid'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  url: string,
  urlSticky?: string,
  countryWithAnotherSticky?: boolean,
  showModal?: boolean,
  blogSidebar?: boolean,
}

const Postiframe = ({
  url,
  urlSticky,
  showModal = true,
  blogSidebar = false,
  countryWithAnotherSticky = false,
}: Props): boolean | React$Element<*> => {
  const [shouldShow, setShouldShow] = useState(true)
  const ref = useRef({})
  const [openedModal, setOpenedModal] = useState(showModal)
  const isMobile = useIsMobile()
  const [smallerHeight, setSmallerHeight] = useState()

  useEffect(() => {
    if (isMobile) {
      setOpenedModal(false)
    }
  }, [isMobile])

  const modalSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 56 55"
    >
      <g id="mark_email_read_black_24dp" transform="translate(0.446 -0.319)">
        <rect
          id="Rectángulo_557"
          data-name="Rectángulo 557"
          width="56"
          height="55"
          transform="translate(-0.446 0.319)"
          fill="none"
        />
        <path
          id="Trazado_34"
          data-name="Trazado 34"
          d="M43.168,4H6.574A4.568,4.568,0,0,0,2.023,8.574L2,36.02a4.588,4.588,0,0,0,4.574,4.574h18.3V36.02H6.574V13.148l18.3,11.436,18.3-11.436V24.584h4.574V8.574A4.588,4.588,0,0,0,43.168,4Zm-18.3,16.01L6.574,8.574H43.168ZM37.084,45.168l-8.1-8.1,3.225-3.225L37.061,38.7l9.7-9.7,3.271,3.225Z"
          transform="translate(2.837 5.311)"
        />
      </g>
    </svg>
  )

  const closeModalSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 14"
    >
      <g
        id="Grupo_362"
        data-name="Grupo 362"
        transform="translate(-2391.615 2521.285)"
      >
        <path
          id="Trazado_39"
          data-name="Trazado 39"
          d="M2392.52-2540.388,2399-2534.1l-6.477,6.8"
          transform="translate(0 20)"
          fill="none"
          stroke="#000"
          strokeWidth="2.5"
        />
        <path
          id="Trazado_40"
          data-name="Trazado 40"
          d="M2392.52-2540.388,2399-2534.1l-6.477,6.8"
          transform="translate(7 20)"
          fill="none"
          stroke="#000"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  )

  useEffect(() => {
    function hashedemHandler() {
      setShouldShow(false)
    }

    document.addEventListener('hashedemTrigger', hashedemHandler, false)

    return () =>
      document.removeEventListener('hashedemTrigger', hashedemHandler)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerHeight <= 800) {
      if (openedModal) {
        setSmallerHeight(true)
      } else {
        setSmallerHeight(false)
      }
    }
  }, [openedModal])

  if (blogSidebar) {
    return (
      <div className="border md:static border-beige22 h-full w-full">
        <iframe className="p-0 m-0" src={url} height="100%" width="100%" />
      </div>
    )
  }
  return (
    shouldShow && (
      <Spacer top bottom smaller>
        <Container className="relative">
          <Grid>
            <div
              ref={ref}
              className="col-span-4 col-start-1 border border-grayLight md:col-start-3 md:col-span-8 bg-grayLighter h-40rem smIframe:h-24rem mdIframe:h-35rem lgIframe:h-24rem"
            >
              <iframe
                className="p-0 m-0"
                src={url}
                height="100%"
                width="100%"
                frameBorder="1"
              />
            </div>
            <div
              className={`fixed right-0 ${openedModal ? 'md:right-10' : ''} ${
                countryWithAnotherSticky ? `bottom-16 mb-16 ` : 'bottom-8'
              } z-superTop `}
            >
              {!openedModal && (
                <button
                  onClick={() => setOpenedModal(!openedModal)}
                  className="w-12 h-12 p-2 bg-cyan"
                >
                  {modalSVG}
                </button>
              )}
              {openedModal && (
                <div className="bg-white">
                  <div
                    className={`w-92 ${
                      countryWithAnotherSticky
                        ? `h-65vh ${!smallerHeight ? 'md:h-35rem' : ''}`
                        : 'md:h-35rem h-73vh'
                    } md:w-25rem`}
                  >
                    <iframe
                      className="m-0"
                      src={urlSticky || url}
                      height="100%"
                      width="100%"
                      frameBorder="1"
                    />
                    <button
                      className="absolute top-0 right-0 h-max w-max md:-mr-10"
                      onClick={() => setOpenedModal(!openedModal)}
                    >
                      <div className={`w-10 h-10 p-3  bg-cyan`}>
                        {closeModalSVG}
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Container>
      </Spacer>
    )
  )
}

export default Postiframe
