import React, { useEffect, useRef, useState } from 'react'
// Ordena la secuencia según la dirección
function reMap(animation, direction) {
  const anim = {}
  let entries = Object.entries(animation).filter(
    ([item]) => item !== 'duration'
  )
  if (direction === 'backwards') {
    entries = entries.reverse()
    entries.forEach(([, props], index) => {
      if (index < entries.length - 1) {
        anim[entries[entries.length - index - 1][0]] = props
        return
      }
      anim[entries[0][0]] = props
    })
    anim.duration = animation.duration
    return anim
  }
  entries.forEach(([segment, props]) => {
    anim[segment] = props
  })
  anim.duration = animation.duration
  return anim
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function from(ref, animation, didMount) {
  return new Promise(resolve => {
    const values = {}
    let cssText = ``
    const properties = Object.keys(animation.from).map(property =>
      property.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
    )
    const transition =
      properties.join(` ${didMount ? animation.duration : 0}s, `) +
      ` ${didMount ? animation.duration : 0}s`
    Object.keys(animation.from).forEach(property => {
      const prop = property.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
      values[prop] = animation.from[property]
    })
    properties.forEach(property => {
      cssText += `${property}: ${values[property]}; `
    })
    requestAnimationFrame(async () => {
      ref.style.cssText = cssText
      ref.style.transition = transition
      await delay(Math.abs(didMount ? animation.duration : 0) * 1000)
      resolve(true)
    })
  })
}

async function to(ref, animation, didMount) {
  return new Promise(resolve => {
    const values = {}
    let cssText = ``
    const properties = Object.keys(animation.to).map(property =>
      property.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
    )
    Object.keys(animation.to).forEach(property => {
      const prop = property.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
      values[prop] = animation.to[property]
    })
    properties.forEach(property => {
      cssText += `${property}: ${values[property]}; `
    })
    let transition = ''
    if (didMount) {
      transition =
        properties.join(` ${animation.duration}s, `) + ` ${animation.duration}s`
    }
    requestAnimationFrame(async () => {
      ref.style.cssText = cssText
      ref.style.transition = transition
      await delay(Math.abs(didMount ? animation.duration : 0) * 1000)
      resolve(true)
    })
  })
}

async function sequence(ref, animation, loop, direction) {
  let index = 0
  let progress = 0
  const mappedAnimation = reMap(animation, direction)
  let steps = Object.keys(mappedAnimation).filter(item => item !== 'duration')
  for await (let step of steps) {
    let cssText = ``
    let transition = ``
    if (index < steps.length) {
      // Calcula la porción de tiempo a animar
      const timeSpan =
        index === 0
          ? steps[index + 1].replace('%', '') -
            steps[index].replace('%', '') -
            progress
          : steps[index].replace('%', '') - progress
      progress = index !== 0 ? progress + timeSpan : progress
      const duration = (timeSpan * mappedAnimation.duration) / 100
      // Prepara el cssText
      Object.keys(mappedAnimation[step]).forEach((property, index, arr) => {
        const prop = property.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
        cssText += `${prop}: ${mappedAnimation[step][property]};`
        transition += `${prop} ${duration}s${
          index !== arr.length - 1 ? ',' : ';'
        } `
      })

      // Anima
      requestAnimationFrame(() => {
        cssText = `${cssText}transition: ${transition}`
        ref.style.cssText = cssText
        ref.style.transition = transition
      })

      await delay(Math.abs(duration) * 1000)
      index++

      // En caso de haber loop
      if (loop && index === steps.length) {
        sequence(ref, mappedAnimation, loop, direction)
      }
    }
  }
}

const Animated = ({
  children,
  animation,
  bind,
  className,
  loop = false,
  type,
  ignore = false,
  id = null
}) => {
  const ref = useRef({})
  const [didMount, setDidMount] = useState(false)
  useEffect(() => {
    (async () => {
      if (bind && didMount) {
        if (type === 'sequence') {
          await sequence(ref.current, animation, loop, 'forwards', didMount)
          setDidMount(true)
          return
        }
        if (type === 'spring') {
          await to(ref.current, animation, didMount)
          setDidMount(true)
          return
        }
        return
      }
      if (type === 'sequence') {
        await sequence(ref.current, animation, loop, 'backwards', didMount)
        setDidMount(true)
        return
      }
      if (type === 'spring') {
        await from(ref.current, animation, didMount)
        setDidMount(true)
        return
      }
    })()
  }, [bind, ignore])
  return (
    <div
      ref={ref}
      id={id}
      className={`${className} ${didMount ? 'initial' : 'hidden'}`}
    >
      {didMount && children}
    </div>
  )
}

export default Animated
