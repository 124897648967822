// @flow
import React from 'react'
import Appear from 'components/Appear'
import LinkExternalHP from './LinkExternalHP'
import makeLayer from '../lib/makeLayer'
import Button from './Button'

type Props = {
  ctaUrl1: string,
  ctaUrl2: string,
  ctaUrl3: string,
  ctaUrl4: string,
  ctaText1: string,
  ctaText2: string,
  ctaText3: string,
  ctaText4: string,
  className: string,
  title: string,
  titleLight?: boolean,
  titleBig?: boolean,
  cta1Download?: boolean,
  cta2Download?: boolean,
  cta3Download?: boolean,
  ctasTextBolder?: boolean,
  ctasTextLight?: boolean,
  alignTop?: boolean,
  dataLayer: Object,
  style?: string,
  newTitle: string,
}

const Icon = () => (
  <svg width="16" height="23" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="a" d="M0 .315h15.692v22.422H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 -.05)">
        <path
          d="M14.77 21.79H1.693a.78.78 0 01-.77-.79V3.79h13.074c.427 0 .773.355.773.793V21.79zM12.308 1.367v1.476H5.123l7.185-1.476zm1.689 1.476h-.766V.79a.48.48 0 00-.17-.366.437.437 0 00-.381-.099L.372 2.852c-.017.002-.03.013-.045.017a.496.496 0 00-.155.087.43.43 0 00-.144.219.575.575 0 00-.019.096C.008 3.286 0 3.299 0 3.316v17.685c0 .957.76 1.736 1.693 1.736H15.23a.468.468 0 00.462-.473V4.584c0-.96-.76-1.741-1.696-1.741z"
          fill="#0096D6"
          mask="url(#b)"
        />
      </g>
      <path
        d="M9.981 14.299L8.31 16.015V8.32a.468.468 0 00-.462-.474.468.468 0 00-.461.474v7.696L5.712 14.3a.455.455 0 00-.654 0 .485.485 0 000 .67l2.462 2.527h.003a.45.45 0 00.645 0h.005l2.462-2.527a.485.485 0 000-.67.455.455 0 00-.654 0"
        fill="#0096D6"
      />
    </g>
  </svg>
)

const TitleCtas = ({
  ctaUrl1,
  ctaText1,
  ctaText2,
  ctaUrl2,
  ctaText3,
  ctaUrl3,
  ctaText4, 
  ctaUrl4,
  className,
  title,
  titleLight,
  titleBig,
  ctasTextBolder,
  ctasTextLight = false,
  alignTop = false,
  cta1Download = false,
  cta2Download = false,
  cta3Download = false,
  dataTagName1,
  style
}: Props) => {
  let ctasTextWeight = ''
  if (ctasTextBolder) ctasTextWeight = 'font-normal'
  if (ctasTextLight) ctasTextWeight = 'font-light'

  return (
    <Appear className={className}>
      <div className="flex md:flex-row flex-col items-end justify-between w-full">
        {title && <h2 className={`mb-8 font-secondary ${titleBig ? `text-30px md:text-50px w-2/3 leading-tight` : 'md:text-80px text-5xl md:w-1/3 leading-none'} ${titleLight ? 'font-light' : ''}`}>{title}</h2>}
          {style==="printer" &&(
            <Button
            href={ctaUrl4}
            title={ctaText4}
            style="white"
            className='mb-8 border-black'
            dataLayer={makeLayer({
              event: 'e_document',
              documentAction: 'download',
              documentID: 'polestar-image3D-cta1',
              documentType: 'pdf'
            })}
            />
          )}
      </div>
      <div className="md:flex md:flex-row">
        {ctaUrl1 && ctaText1 && (
          <LinkExternalHP
            target="_blank"
            href={ctaUrl1}
            className={`text-blue flex ${alignTop ? 'py-0' : 'py-2 md:py-4'} items-center hover:underline md:px-2`}
            download={cta1Download}
            dataLayer={ (ctaUrl1.indexOf('reinvent.hp.com') === -1) ?
              makeLayer({
                event: 'e_document',
                documentAction: 'download',
                documentID: dataTagName1,
                documentType: 'pdf'
              })
            :
              makeLayer({
                event: 'e_contactClick',
                linkPlacement: 'content-gated',
                linkID: ctaUrl1,
              })
            }
          >
            <div className="flex-shrink-0">
              <Icon />
            </div>
            <span className={`ml-2 md:ml-4 ${ctasTextWeight}`}>{ctaText1}</span>
          </LinkExternalHP>
        )}
        {ctaUrl2 && ctaText2 && (
          <LinkExternalHP
            target="_blank"
            href={ctaUrl2}
            className={`text-blue flex items-center py-2 md:py-4 hover:underline md:px-2`}
            download={cta2Download}
            dataLayer={makeLayer({
              event: 'e_document',
              documentAction: 'download',
              documentID: ctaText2,
              documentType: 'pdf'
            })}
          >
            <div className="flex-shrink-0">
              <Icon />
            </div>
            <span className={`ml-2 md:ml-4 ${ctasTextBolder ? 'font-normal' : ''}`}>{ctaText2}</span>
          </LinkExternalHP>
        )}
        {ctaUrl3 && ctaText3 && (
          <LinkExternalHP
            target="_blank"
            href={ctaUrl3}
            className={`text-blue flex items-center py-2 md:py-4 hover:underline md:px-2`}
            download={cta3Download}
          >
            <div className="flex-shrink-0">
              <Icon />
            </div>
            <span className={`ml-2 md:ml-4 ${ctasTextBolder ? 'font-normal' : ''}`}>{ctaText3}</span>
          </LinkExternalHP>
        )}
      </div>
    </Appear>
  )
}

export default TitleCtas
