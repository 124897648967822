// @flow

import React, { useEffect, useState } from 'react'
import Appear from './Appear'

const IconArrow = () => (
  <svg width="17px" height="22px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Latex_800" transform="translate(-483.000000, -497.000000)" fill="#000000">
            <g id="header" transform="translate(148.000000, 294.000000)">
                <g id="Group-33" transform="translate(0.000000, 184.000000)">
                    <g id="CTA_2" transform="translate(232.000000, 14.000000)">
                        <path d="M106.427136,15.6507571 L100.98889,8.77335125 C100.849694,8.59655624 100.876093,8.33656357 101.048888,8.19336762 C101.218483,8.04937168 101.472876,8.07497096 101.614472,8.25336592 L107.052719,15.1315718 C107.125517,15.2251691 107.215914,15.2755677 107.305512,15.2755677 C107.395909,15.2755677 107.485506,15.2251691 107.558304,15.1323717 L112.984551,8.26216567 C113.123747,8.08537066 113.37814,8.05897141 113.550135,8.20136739 C113.72213,8.34456335 113.74933,8.60455601 113.609334,8.782151 L108.183887,15.6507571 C107.957493,15.9363491 107.638302,16.1003444 107.306311,16.1003444 C106.973521,16.1003444 106.65433,15.9363491 106.427136,15.6507571 Z" id="Fill-1" transform="translate(107.299311, 12.100344) scale(-1, 1) rotate(90.000000) translate(-107.299311, -12.100344) "></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

const IconCross = () => (
  <svg
  viewBox="0 0 34 34"
  width="34px"
  height="34px"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M33.32 16.32H17.68V.68a.68.68 0 0 0-1.36 0v15.64H.68a.68.68 0 0 0 0 1.36h15.64v15.64a.68.68 0 0 0 1.36 0V17.68h15.64a.68.68 0 0 0 0-1.36"
    fill="currentColor"
    fillRule="evenodd"
  />
</svg>
)

const setIcon = (icon) => {
  switch(icon) {
    case "cross":
      return <IconCross />
    case "arrow":
    default:
      return <IconArrow />
  }
}

type Props = {
  title: string,
  children: React$Node,
  index: number,
  forceOpen?: boolean,
  icon?: "arrow" | "cross",
  fullWidth?: boolean,
  titleClassName?: string,
  spacing?: string
}

const Collapsible = ({ title, children, index, forceOpen, icon = "arrow", fullWidth = false, titleClassName = "text-big2", spacing = "mt-8 mb-6" }: Props) => {
  const [open, setOpen] = useState(false)
  const iconIndicator = setIcon(icon)
  const rotationDeg = icon === "cross" ? "rotate(45deg)" : "rotate(270deg)"

  useEffect(() => {
    if (forceOpen) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [forceOpen])

  return (
    <div className={`relative grid gap-4 overflow-hidden border-b border-black md:mt-4 grid-cols-4  ${fullWidth ? "md:grid-cols-12" : "md:grid-cols-9"} `}>
      <div
        className={`flex items-center justify-between col-span-4 ${spacing} cursor-pointer  ${fullWidth ? "md:col-span-12" : "md:col-span-9 "}`}
        onClick={() => setOpen(!open)}
      >
        <h2
          className={titleClassName}
        >
          {title}
        </h2>
        <div style={{
            transform: open ? rotationDeg : 'rotate(90deg)',
            transition: 'transform .3s ease-in-out'
          }}
          className={icon === "cross" ? "w-8" : ""}
        >
          {iconIndicator}
        </div>
      </div>
      <div
        className="col-span-4 md:col-span-9"
        style={{
          height: open ? 'auto' : '0px',
          transition: 'height .5s ease-in-out'
        }}
      >
        <Appear>
          {children}
        </Appear>
      </div>
    </div>
  )
}

export default Collapsible
