// @flow
import React from 'react'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from "./Markdown"
import Button from './Button'
import Appear from './Appear'

type Props = {
  align: "right" | "left",
  title: String,
  text: String,
  ctaText: String,
  ctaUrl: String,
  image: ApiImage,

} 

const LateralImage = ({ align, title, text, ctaText, ctaUrl, image }: Props) => {
  return (
    <div className="bg-lavender">
      <Grid noGap>
        <div className={`md:row-start-1 flex flex-col justify-between col-span-4 pb-8 md:col-span-5 ${align === "right" ? "md:col-start-1" : "md:col-start-8"}`}>
          <Appear>
            <div className="px-4 py-6 leading-none md:py-10 bg-darkerLavender text-40px md:text-50px">
              {title}
            </div>
            <Markdown text={text} className="px-4 py-6"/>
          </Appear>
          <Appear className="px-4">
            <Button title={ctaText} to={ctaUrl} style="black" padded />
          </Appear>
        </div>
        <div className={`md:row-start-1 col-span-4 md:col-span-7 ${align === "right" ? "md:col-start-6" : "md:col-start-1"}`}>
          <LazyImage image={image} cover/>
        </div>
      </Grid>
    </div>
  )
}

export default LateralImage
