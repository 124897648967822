// @flow
import React, { useState, useEffect, useRef } from 'react'
import IconChevron from './icons/IconChevron'

type SidebarItem = { id: string, title: string, anchor: string }
type Props = {
  title: string,
  items: Array<SidebarItem>,
  extraProps: { title: string, url: string },
}

const SidebarNavigation = ({ title, items, extraProps: expertTips }: Props) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const [mobileShow, setMobileShow] = useState(false)
  const hasExpertTips = expertTips?.title && expertTips?.url
  const [hash, setHash] = useState()
  
  useEffect(() => {
    const updateHash = () => setHash(window.location.hash)
  
    if (typeof window !== "undefined" && window?.location?.hash) {
      setHash(window.location.hash)
      const element = document.querySelector(window.location.hash)
      if (element) setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 100)
      window.addEventListener("hashchange", updateHash)
    }
  
    return () => window.removeEventListener("hashchange", updateHash)
  }, [])

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting)
        if (visibleEntry) {
          const currentHash = `#${visibleEntry.target.id}`
          history.replaceState(null, '', currentHash)
          setHash(currentHash)
        }
      },
      { rootMargin: "0px", threshold: 1 }
    )
    
    items.forEach(({ anchor }) => {
      const element = document.getElementById(anchor)
      if (element) observer.current?.observe(element)
    })

    return () => observer.current?.disconnect()
  }, [items])

  return items?.length > 0 ? (
    <aside
      className="w-full top-menu-mobile-plus bg-white z-10 col-span-4 px-4 pt-5 pb-1 border-t border-b md:px-0 sticky border-grayLight md:py-0 md:border-0 md:col-start-2 md:col-span-2 md:top-menu-desktop-plus"
      style={{ height: 'fit-content' }}
    >
      <div className="flex gap-2 justify-between items-center md:justify-start">
        <div className="flex items-center gap-2">
          <h5 className="text-16px md:text-20px">{title}</h5>
          <div
            onClick={() => setMobileShow(!mobileShow)}
            className={`${
              mobileShow ? 'rotate-180 md:rotate-0' : 'rotate-0 md:rotate-0'
            } block transform duration-25 w-5 p-1 focus:outline-none md:hidden`}
          >
            <IconChevron />
          </div>
        </div>
        {hasExpertTips ? (
          <a
            href={expertTips.url}
            target="_blank"
            rel="noreferrer"
            className="px-2 pt-2 pb-1 border md:hidden"
          >
            {expertTips.title}
          </a>
        ) : null}
      </div>
      <hr className="hidden md:block" />
      <div
        className={`flex flex-col gap-3 md:gap-5 pt-4 md:py-5 transform duration-250 ${
          mobileShow
            ? 'max-h-600 md:max-h-full overflow-auto'
            : 'overflow-hidden max-h-0 md:max-h-full'
        }`}
      >
        {items.map(({ id, title, anchor }) => {
          const isSelected = hash === `#${anchor}`
          return (
            <a
              key={id}
              href={`#${anchor}`}
              onClick={()=> setHash(`#${anchor}`)}
              className={`text-16px relative pb-2 ${
                isSelected
                  ? 'border-b-2 border-blue text-blue'
                  : 'text-black hover:text-blue'
              }`}
            >
              {title}
            </a>
          )
        })}
      </div>
    </aside>
  ) : null
}

export default SidebarNavigation
