// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from 'store/'
import type { ApiImage } from '../types'

type Props = {
  position: string,
  image: ApiImage,
  title: string,
  text: string,
  text2: string,
  subtitle: string,
  textLeft: string,
  textRight: string,
}

const PrinterHeading = ({ image, title, text, text2, subtitle, textLeft, textRight }: Props) => {
  return (
    <div className='flex mt-menu-mobile md:mt-menu-desktop'>
    <div className='md:w-2/5 w-full leading-none bg-lightBlue23 flex flex-col justify-between'>
      <div>
        <div className='p-6 bg-darkBlue23 text-50px font-secondary'>{title}</div>
        <div className='p-6 bg-blueSld text-20px md:text-24px leading-9 font-secondary'>
          <Html text={text} />
        </div>
        <div className='p-6 text-sm md:text-lg leading-5'>
          <Html text={text2} />
          <div className='mt-4'>
            <Html text={textLeft} />
            <Html text={textRight} />
          </div>
        </div>
      </div>
      </div>
    <div 
      className='hidden md:block w-3/5 h-auto relative'
      style={{ backgroundImage: `radial-gradient(circle, rgba(19,48,63,1) 29%, rgba(26,26,26,1) 100%)` }}
      >
        <LazyImage image={image} alt={title} cover/>
    </div>
  </div>
  )
}

export default PrinterHeading