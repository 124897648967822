// @flow

import React from 'react'

const IconQuote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="32"
      viewBox="0 0 45 32"
    >
      <path
        id="Path_8949"
        dataName="Path 8949"
        d="M18.237.89a1.618,1.618,0,0,1,1.376.566,1.568,1.568,0,0,1,.172,1.455l-4.129,13.9v14.3q0,1.778-1.892,1.778H1.892Q0,32.89,0,31.112V18.1A4.2,4.2,0,0,1,.688,15.92L10.581,2.1A2.705,2.705,0,0,1,12.9.89Zm25.2,0a1.386,1.386,0,0,1,1.29.566A1.568,1.568,0,0,1,44.9,2.91l-4.043,13.9v14.3a1.69,1.69,0,0,1-.516,1.293,2.055,2.055,0,0,1-1.462.485H27.011q-1.893,0-1.892-1.778V18.1a4.2,4.2,0,0,1,.688-2.182L35.785,2.1A2.5,2.5,0,0,1,38.021.89Z"
        transform="translate(0 -0.89)"
      />
    </svg>
  )
}

export default IconQuote
