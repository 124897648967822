// @flow

import React from 'react'
import Container from './Container'
import LazyImage from './LazyImage'
import Spacer from './Spacer'
import Grid from './Grid'
import Html from './Html'
import PostSliderCarousel from './PostSliderCarousel'
import type { ApiImage } from '../types'

export type Item = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  url: string,
}
type Props = {
  items: Array<Item>,
  title: string,
  postCategory: { id: string, title: string },
}

const PostSlider = ({ items, title, postCategory }: Props) => {
  if (!items?.length) {
    return null
  }

  const getDesktopColumnClass = (isFirst: boolean) => {
    switch (items.length) {
      case 1:
        return 'md:col-span-12'
      case 2:
        return 'md:col-span-6'
      case 3:
      default:
        return isFirst ? 'md:col-span-6' : 'md:col-span-3'
    }
  }
  const getDesktopTitleClass = (isFirst: boolean) => {
    switch (items.length) {
      case 1:
        return 'md:text-28px md:leading-8'
      case 2:
        return 'md:text-18px md:leading-6'
      case 3:
      default:
        return isFirst
          ? 'md:text-28px md:leading-8'
          : 'md:text-18px md:leading-6'
    }
  }
  return (
    <>
      <Spacer top smaller>
        <Container>
          <Grid>
            <div className="col-span-12 col-start-1 md:col-start-3 md:col-span-8">
              <div className="relative flex items-start justify-between w-full md:items-center md:justify-center md:block">
                <h3 className="w-full text-26px md:text-48px font-normal">
                  {title}
                </h3>
              </div>
            </div>
          </Grid>
        </Container>
      </Spacer>
      <hr className="mt-4 mb-0 md:mt-8 md:mb-14 w-screen" />
      <PostSliderCarousel items={items} />
      <Spacer bottom smaller className="hidden md:block">
        <Container>
          <Grid>
            <div className="col-span-12 col-start-1 md:col-start-3 md:col-span-8">
              <Grid>
                {items?.length > 0
                  ? items.map(({ id, image, title, url }, index) => {
                      const isFirst = index === 0
                      const colClass = getDesktopColumnClass(isFirst)
                      const titleClass = getDesktopTitleClass(isFirst)
                      return (
                        <a
                          key={id}
                          id={`recommended-${index}`}
                          className={`w-full h-full zoom-parent flex flex-row md:flex-col relative ${colClass}`}
                          href={url}
                        >
                          {image ? (
                            <LazyImage
                              image={image}
                              alt={title ?? ''}
                              cover
                              className="w-full max-h-26rem overflow-hidden"
                              imageClassName="zoom-child"
                            />
                          ) : null}

                          <div
                            className="absolute top-0 left-0 w-full h-full flex flex-col justify-end text-white"
                            style={{
                              background:
                                'linear-gradient(to bottom, transparent 0%, transparent 50%,#444 100%)',
                            }}
                          >
                            {postCategory ? (
                              <div className="w-full px-4 text-16px">
                                {postCategory.title}
                              </div>
                            ) : null}
                            <hr className="mt-2 w-full" />
                            <div
                              className={`px-4 mt-3 h-21 select-none overflow-hidden ${titleClass}`}
                              style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '2',
                              }}
                            >
                              <Html text={title} />
                            </div>
                          </div>
                        </a>
                      )
                    })
                  : null}
              </Grid>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </>
  )
}

export default PostSlider
