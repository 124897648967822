// @flow

import React, { useEffect } from 'react'
import makeAssetUrl from '../lib/makeAssetUrl'
import Grid from './Grid'
import FeaturesRow from './FeaturesRow'
import Video from './Video'
import Appear from './Appear'
import useIsLargeDesktop from '../hooks/useIsLargeDesktop'
import GradientModule from './GradientModule'
import LazyImage from './LazyImage'
import Button from './Button'
import makeLayer from '../lib/makeLayer'

type Props = {
  image: Object,
  cards: Array,
  videoPlaceholder: string,
  url: string,
  gradientStart?: string,
  gradientEnd?: string,
  gradientOrientation?: string,
  gradientHeading?: string,
  text?: string,
  ctaText?: string,
  ctaUrl?: string,
  ctaImage?: string,
  anchor?: string,
}

const PolestarVideo = ({ image, cards, videoPlaceholder, url, pttId, gradientStart = '', gradientEnd = '', gradientOrientation = false, gradientHeading = false, text, ctaText, ctaUrl, ctaImage, anchor }: Props) => {
  const isLargeDesktop = useIsLargeDesktop()
  console.log(gradientStart,"GSTART")

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart.length > 0 && lastPart.includes('video')) {
        setTimeout(() => {
          const element = document.querySelector(`[data-anchor-name="video"]`)
          if (element) {
            const { top } = element.getBoundingClientRect()
            const y = top + window.pageYOffset - 200
            window.scrollTo({ top: y, behavior: 'smooth' })
          }
        }, 1000)
      }
    }
  }, [])

  const content = <div className={`${image.length === 0 ? " mt-6 md:mt-12" : "pb-5 mt-12 ml-10 sm:mt-16 sm:ml-16 md:mt-28"} md:grid md:grid-cols-11 md:gap-4 bg-white`}>
      <div className={`${image.length === 0 ? "w-full md:col-start-1 md:col-span-11 md:px-16" : "px-5 md:px-8 md:py-6 lg:py-8 lg:px-8 md:col-start-1 md:col-span-11"} `}>
        <Appear>
          <FeaturesRow features={cards} color={gradientStart ? 'text-black' : false} />
        </Appear>
      </div>
      <div className={` ${image.length === 0 ? "md:col-start-1 md:col-span-11 md:px-16" : "px-5 md:px-8 md:pb-6 lg:pb-8 lg:px-8 md:col-start-1 md:col-span-11" }`} data-anchor-name="video" >
        <Appear>
          <Video centerPlay={true} extraId={pttId} url={url} image={videoPlaceholder} noGrid={true} />
        </Appear>
      </div>
    </div>

  let video = <Grid className="w-full h-full">
      <div className={`h-full ${image.length === 0 ? "col-span-4 md:col-span-12 md:col-start-1" : "col-span-4 md:col-span-11 md:col-start-2" }`}>
          {content}
      </div>
    </Grid>

  if (isLargeDesktop) {
    video = 
      <Grid className="w-full h-full">
        <div className={`${image.length === 0 ? "h-full col-span-12 col-start-1" : "h-full col-span-11 col-start-2"} `}>
            {content}
        </div>
      </Grid>
  }

  let introModule
  if (text && ctaText) {
    introModule = <div className="m-auto lg:w-9/10 lg:max-w-9/10 xl:max-w-1800">
      <Grid>
        <div data-anchor={anchor} />
        <div className="col-span-4 col-start-1 pt-12 md:col-span-9 md:col-start-3 lg:col-start-2 lg:col-span-10">
          <Grid>
            <div className={`col-span-4 col-start-1 px-12 py-6 md:py-0 md:px-0 mb-12 md:flex md:flex-col md:justify-center md:col-span-8 md:col-start-1 md:mb-0 text-white`}>
              <Appear><h6 className="mb-8 text-big1">{text}</h6></Appear>
              <div className="mt-12">
                <Appear>
                  <Button style={"white"} title={ctaText} href={ctaUrl}
                  dataLayer={makeLayer({
                    event: 'e_contactClick',
                    linkPlacement: 'content-webinar-download',
                    linkID: ctaUrl,
                    linkClass: 'webinar'
                  })}
                  />
                </Appear>
              </div>
            </div>
            {ctaImage && 
              <div className={`col-span-4 col-start-1 mb-12 text-white md:flex md:flex-col md:justify-center md:col-span-4 md:col-start-9 md:mb-0 md:pl-8`}>
                <Appear>
                  <LazyImage image={ctaImage} alt={ctaText} />
                </Appear>
              </div>
            }
          </Grid>
        </div>
      </Grid>
    </div>
  }

  return (
    <div
      style={gradientStart && gradientStart.length > 0 && gradientEnd.length > 0 ? {
        backgroundImage: `linear-gradient(${gradientOrientation? gradientOrientation : '90'}deg, #${gradientStart.replace('#', '')} 0%, #${gradientEnd.replace('#', '')} 100%)`
      } : {
        backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover'
      }}
    className="relative"
    >
      <GradientModule gradientHeading={gradientHeading}>
        {!gradientStart && <div className="absolute top-0 bottom-0 right-0 w-1/2 h-full"  data-polestar-video />}
        {introModule}
        {video}
      </GradientModule>
    </div>
  )
}

export default PolestarVideo
