// @flow
import React, { useState, useEffect, useRef } from 'react'
import Container from './Container'
import { useLiterals, useMedia } from '../store'
import type { ApiImage } from '../types'
import LinkHP from './LinkHP'
import FloatingMenu from './FloatingMenu'
import { setCookie } from '../lib/cookies'
import useIsMobile from '../hooks/useIsMobile'
import makeAssetUrl from '../lib/makeAssetUrl'
import makeLayer from '../lib/makeLayer'

type Range = {
  id: string,
  image: ApiImage,
  title: string,
  url: string,
}
type MenuLink = {
  id: string,
  title: string,
  url: string,
}
type Props = {
  primary: Array<Range>,
  secondary: Array<MenuLink>,
  url: string,
  slug: string,
}

const floatingHeight = 46

const Menu = ({ moduleName, primary, secondary, url, structure }: Props) => {
  const [open, setOpen] = useState(false)
  const [bottom, setBottom] = useState(-floatingHeight)
  const isMobile = useIsMobile()
  const floating = useRef(null)
  const menuRef = useRef(null)
  const [inIframe, setInIframe] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)

  const origin = typeof window !== 'undefined' ? window.location.origin : 'https://largeformat.hp.com/'

  const hideSubmenu =
    typeof process.env.GATSBY_HIDE_SUBMENU !== 'undefined' &&
    process.env.GATSBY_HIDE_SUBMENU === 'TRUE'

  const handleOpenClose = () => {
    setOpen(!open)
  }

  const floatingMenu = structure.find(m => m.module === 'FloatingMenu')

  function checkDevice(): string {
    if (menuRef.current) {
      const h = menuRef.current.offsetHeight
      return `-${h}px`
    }
  }

  useEffect(() => {
    setBottom(open ? checkDevice() : -floatingHeight)
  }, [open, isMobile, screenWidth])

  const handleResize = () => {
    const width =
      document.documentElement.clientWidth || document.body.clientWidth
    setScreenWidth(width)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('jumpid')) {
      setCookie('jumpid', urlParams.get('jumpid'), 3600000)
    }
    if (urlParams.get('bmtid')) {
      setCookie('bmtid', urlParams.get('bmtid'), 3600000)
    }
    if (urlParams.get('iniframe')) {
      setInIframe(true)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (inIframe) return null

  return (
    <nav
      className="fixed top-0 left-0 right-0 text-white z-superTop"
      id="main-menu"
    >
      <div
        className="relative z-20 bg-black"
        onTouchStart={handleOpenClose}
        onMouseEnter={handleOpenClose}
      >
        <Container className="flex items-center justify-between py-4">
          <div itemScope itemType="https://schema.org/Organization">
            <a itemProp="url" href={origin} hidden>
              Home
            </a>
            <LinkHP to={url} className="flex items-center h-10">
              <img
                itemProp="logo"
                className="h-full"
                src={useMedia('hp')}
                alt={useLiterals('menu')}
              />
              <span className="ml-4 uppercase">
                {useLiterals('virtual_booth')}
              </span>
            </LinkHP>
          </div>
          <div className="flex items-center cursor-pointer">
            <span className="mr-2">{useLiterals('menu')}</span>
            <img
              className="h-full"
              src={useMedia('menu-icon')}
              alt={useLiterals('menu')}
            />
          </div>
        </Container>
      </div>
      <div
        ref={menuRef}
        className="absolute bottom-0 left-0 right-0 z-10 bg-black"
        style={{
          bottom,
          transition: 'bottom .5s, opacity .5s',
          opacity: 1,
        }}
        onMouseLeave={() => {
          if (open) {
            handleOpenClose()
          }
        }}
      >
        <Container>
          <div className="flex flex-wrap -mx-2 md:-mx-2">
            {primary.map(item => (
              <div
                key={`menu-1-${item.id}`}
                className="relative flex-grow-0 flex-shrink-0 px-2 mb-4 text-white md:h-32 md:overflow-hidden flex-basis-1/3 md:flex-basis-1/6 gradient-overlay"
              >
                <div className="w-full md:hidden pt-full"></div>
                <div
                  className="absolute top-0 bottom-0 flex items-center bg-center bg-no-repeat bg-cover left-2 right-2 md:w-auto md:h-full md:relative md:left-auto md:right-auto md:top-auto md:bottom-auto"
                  style={{
                    backgroundImage: `url(${makeAssetUrl({
                      asset: item.image,
                      isImage: true,
                    })})`,
                  }}
                >
                  <LinkHP
                    className="relative z-10 flex items-center justify-center block w-full px-2 text-center hover:underline"
                    to={item.url}
                    title={item.title}
                    data-tag-name={`${moduleName}-primary-${item.id}`}
                    dataLayer={makeLayer({
                      event: 'e_linkClick',
                      linkPlacement: 'header-menu',
                      linkID: item.slug,
                    })}
                  >
                    {item.title}
                  </LinkHP>
                </div>
              </div>
            ))}
          </div>
          {!hideSubmenu && (
            <div className="flex flex-wrap justify-center pt-4 md:justify-start">
              {secondary.map(item => (
                <LinkHP
                  key={`menu-2-${item.id}`}
                  className="relative mb-4 mr-4 hover:underline"
                  to={item.url}
                  title={item.title}
                  data-tag-name={`${moduleName}-secondary-${item.id}`}
                  dataLayer={makeLayer({
                    event: 'e_linkClick',
                    linkPlacement: 'header-hyperlink',
                    linkID: item.slug,
                  })}
                >
                  {item.title}
                </LinkHP>
              ))}
            </div>
          )}
        </Container>
        {floatingMenu && (
          <div ref={floating} className="pt-4" id="floating-menu">
            <FloatingMenu
              moduleName="FloatingMenu"
              {...floatingMenu.options}
              hidden={false}
            />
          </div>
        )}
      </div>
    </nav>
  )
}

export default Menu
