/* eslint-disable react/jsx-no-target-blank */
// @flow

import React from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import { useLiterals } from 'store/'
import Appear from './Appear'
import LinkExternalHP from './LinkExternalHP'

const StoryBlock7 = ({ url, marginBottom }: { url: string, marginBottom: Boolean }) => {
  return (
    <Spacer top smaller>
      <Container>
        <Appear>
          <Grid>
            <div className={`col-span-1 col-start-1 md:col-start-3 md:col-span-9
              ${marginBottom ? 'mb-18' : ''}
            `}>
              <div className="pb-2">{useLiterals('learn_more_at')}</div>
              <LinkExternalHP className="text-blue" target="_blank" href={url}>{url}</LinkExternalHP>
            </div>
          </Grid>
        </Appear>
      </Container>
    </Spacer>
  )
}

export default StoryBlock7
