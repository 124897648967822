
import React, { useEffect, useRef, useState } from 'react'
import type { Ref, Image as ImageType } from 'types/'
import WebP from '../lib/WebP'
import addObserver from '../lib/addObserver'
import throttle from '../lib/throttle'
import makeAssetUrl from '../lib/makeAssetUrl'
import config from '../../config'

type Props = {
  className?: string,
  imageClassName?: string,
  contain?: boolean,
  cover?: boolean,
  image: ImageType,
  alt: string,
  draggable?: boolean,
  onLoadCallback?: Function,
  isSquare?: boolean,
  borderRadius?: boolean,
  objectRightBottom?: boolean,
}

const LazyImage = ({
  className = '',
  imageClassName = '',
  image,
  alt,
  contain,
  cover,
  draggable = true,
  isSquare = false,
  onLoadCallback,
  borderRadius = false,
  objectRightBottom = false,
}: Props): React$Node => {
  const mainRef: Ref = useRef(null)
  const [imageSrc, setImageSrc] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [hidePlaceholder] = useState(
    typeof image !== 'undefined' ? true : false
  )
  let lastSize = -1

  if (
    typeof image === 'undefined' ||
    !image ||
    (Array.isArray(image) && image.length === 0)
  ) {
    return <div></div>
  }

  const wp = new WebP()
  const loadImage = () => {
    let newSize = 0
    wp.isSupported(webpIsSupported => {
      const availableRes = Object.keys(image)
        .filter(i => i !== 'lazy')
        .sort((a, b) => Number(a) - Number(b))
      availableRes.forEach((size, i) => {
        if (mainRef.current && mainRef.current.offsetWidth > size) {
          newSize = size
          if (typeof availableRes[i + 1] !== 'undefined') {
            newSize = availableRes[i + 1]
          }
        }
      })
      if (newSize > lastSize) {
        if (typeof image[newSize] === 'undefined') {
          newSize = availableRes[0]
        }
        const imageSize = image[newSize]
        const imageSizeExtensions = Object.keys(imageSize).filter(
          i => i !== 'webp'
        )

        let imageToLoadUrl = ''
        if (image.name) {
          imageToLoadUrl = makeAssetUrl({
            asset: image,
            isImage: true,
            lazy: false,
            extension: webpIsSupported
              ? 'webp'
              : image.extensions && image.extensions.find(i => i !== 'webp'),
          })
        } else {
          imageToLoadUrl = webpIsSupported
            ? `${config.cloudfront.baseURL}${config.cloudfront.resized}/${image[newSize]['webp']}`
            : `${config.cloudfront.baseURL}${config.cloudfront.resized}/${
                image[newSize][imageSizeExtensions[0]]
              }`
        }

        const imageToLoad = new window.Image()
        imageToLoad.onload = () => {
          if (onLoadCallback) {
            onLoadCallback()
          }
          setImageSrc(imageToLoadUrl)
          lastSize = newSize
        }
        imageToLoad.src = imageToLoadUrl
      }
    })
  }

  const throttleResize = throttle(loadImage, 1000)

  useEffect(() => {
    addObserver(mainRef.current, '200%', 0, () => {
      loadImage()
    })
    addObserver(mainRef.current, '0%', 0, () => {
      setShowImage(true)
    })
    window.addEventListener('resize', throttleResize)
    return () => {
      window.removeEventListener('resize', throttleResize)
    }
  }, [])

  return (
    <div
      ref={mainRef}
      className={`relative w-full ${cover || contain ? 'h-full' : ''} ${
        className ? className : ''
      }`}
      style={isSquare ? { aspectRatio: '1 / 1' } : {}}
    >
      <picture className="invisible">
        {image.lazy && image.lazy.webp && (
          <source
            srcSet={makeAssetUrl({
              asset: image,
              isImage: true,
              lazy: true,
              extension: 'webp',
            })}
            type="image/webp"
          />
        )}
        <img
          className={`w-full ${cover ? 'h-full object-cover' : ''} ${
            contain ? 'h-full object-contain' : ''
          } ${hidePlaceholder ? 'invisible' : ''}`}
          src={makeAssetUrl({ asset: image, isImage: true, lazy: true })}
          alt={alt}
        />
      </picture>
      {imageSrc !== false && (
        <img
          className={`${imageClassName} ${objectRightBottom ? 'md:object-right-bottom' : ''} ${
            borderRadius ? 'rounded-lg' : ''
          } absolute top-0 left-0 ${
            cover ? 'h-full' : ''
          } w-full duration-normal transform
          transition ${showImage ? 'opacity-100' : 'opacity-0'} ${
            contain ? 'object-contain' : ''
          } duration-normal ${cover ? 'object-cover' : ''}`}
          src={imageSrc}
          alt={alt}
          draggable={draggable}
        />
      )}
    </div>
  )
}

export default LazyImage
