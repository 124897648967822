// @flow
import React, { useEffect } from 'react'
import Cross from './Cross'

type Props = {
  url: string | null,
  close: Function,
}

const Overlay = ({ url, close }: Props): React$Element<"div"> => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleClose = () => {
    if (window.location.search.startsWith('?gatedurl=https://reinvent.hp.com/') || window.location.search.startsWith('?gated=https://reinvent.hp.com/')) {
      window.history.pushState(null, null, window.location.href.split('?')[0])
    }
    close()
  }

  const handleKeyDown = e => {
    if (url) {
      let isEscape = false
      if ('key' in e) {
        isEscape = e.key === 'Escape' || e.key === 'Esc'
      } else {
        isEscape = e.keyCode === 27
      }
      if (isEscape) {
        close()
      }
    }
  }

  return (
    <div
      className={`gated fixed  top-menu-mobile md:top-menu-desktop inset-0 z-50 ${
        url ? '' : 'hidden'
      } overlay`}
    >
      <div className="relative bottom-0 flex flex-col items-center justify-center w-full h-full">
        <div
          className="absolute top-0 right-0 z-10 mt-8 mr-4 cursor-pointer"
          onClick={handleClose}
        >
          <Cross color="black" big={true} />
        </div>
        <iframe
          className="absolute inset-0"
          src={url}
          height="100%"
          width="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  )
}

export default Overlay
