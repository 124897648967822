// @flow
import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import BlogHomePost from './BlogHomePost'

type Props = {
  post1: any,
  post2: any,
  hidden: boolean,
}

const Blog5 = ({ post1, post2, hidden }: Props) => {
  if (hidden) return null
  return (
    <Spacer smaller>
      <Container className="pb-8">
        <div className="grid-cols-2 row-gap-4 md:grid">
          <div className="col-span-1 mb-8 md:mr-6 md:mb-0">
            <BlogHomePost small {...post1} />
          </div>
          {post2 && <div className="col-span-1 md:ml-6">
            <BlogHomePost small {...post2} />
          </div>}
        </div>
      </Container>
    </Spacer>
  )
}

export default Blog5