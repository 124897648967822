// @flow

import React, { useState } from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import Spacer from './Spacer'
import Button from './Button'
import { useLiterals } from '../store'

type Props = {
  position: string,
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  legalText: string,
}

const RegisterToLearn = ({ text, title, legalText, ctaText, ctaUrl }: Props) => {

  const [value, setValue] = useState({ name: '', email: '', legal: false })

  const onChangeText = e => {
    value[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setValue({...value})
  }

  return (
    <div
      className="text-white bg-blue"
    >
      <Spacer
        top
        bottom
      >
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-4 md:col-start-2">
              <Appear>
                <h3 className="mb-4 text-white text-big3">{title}</h3>
              </Appear>
              <Appear>
                <Html text={text} />
              </Appear>
            </div>
            <div className="relative col-span-4 col-start-1 md:col-span-4 md:col-start-7">
              <Appear>
                <div className="mb-4">
                  <label className="block mb-1" htmlFor="name">{useLiterals('form_name')}</label>
                  <input
                    className="w-full px-2 py-1 bg-white rounded outline-none text-blue"
                    name="name"
                    id="name"
                    type="text"
                    onChange={onChangeText}
                    value={value.name}
                  />
                </div>
              </Appear>
              <Appear>
                <div className="mb-4">
                  <label className="block mb-1" htmlFor="email">{useLiterals('form_email')}</label>
                  <input
                    className="w-full px-2 py-1 bg-white rounded outline-none text-blue"
                    name="email"
                    id="email"
                    type="email"
                    onChange={onChangeText}
                    value={value.email}
                  />
                </div>
              </Appear>
              <Appear>
                <div className="flex mb-8">
                  <input
                    className=""
                    name="legal"
                    id="legal"
                    type="checkbox"
                    onChange={onChangeText}
                    value={value.legal}
                  />
                  <label className="pl-2 text-smaller" htmlFor="legal">{legalText}</label>
                </div>
              </Appear>
              <Appear>
                <Button href={ctaUrl} color="white" title={ctaText} />
              </Appear>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default RegisterToLearn