// @flow

import React from 'react'
import LazyImage from './LazyImage'
import Html from './Html'
import Appear from './Appear'
import { useLiterals, useMedia } from 'store/'
import LinkExternalHP from './LinkExternalHP'
import makeLayer from '../lib/makeLayer'

type Props = {
  theID: string,
  hpRef: string,
  managerRef: string,
  image: any,
  numberOfStars: string,
  price: string,
  anchorPrice: string,
  title: string,
  text: string,
  techSpecsURL: string,
  numberOfStarsURL: string,
  buyURL: string,
  buyText: string,
  unitsLeft: string,
  cashbackTitle?: string,
  cashbackUrl?: string,
}

const PrinterShoppableItem = ({
  moduleName,
  theID,
  hpRef,
  image,
  numberOfStars,
  price,
  anchorPrice,
  title,
  text,
  techSpecsURL,
  numberOfStarsURL,
  buyURL,
  buyText,
  unitsLeft,
  managerRef,
  linkid,
  cashbackTitle = false,
  cashbackUrl = false,
}: Props) => {
  const Stars = () => {
    const items = []
    for (let i = 0; i < 5; i++) {
      items.push(<img key={i} className="mr-1" src={i < numberOfStars ? useMedia('star') : useMedia('star-empty')}/>)
    }
    return (
      <span className="flex">
        {items}
      </span>
    )
  }

  return (
    <div className="bg-white border border-grayer">
      <LazyImage image={image} alt={title} />
      <Appear>
        <div className="flex items-center justify-between px-8 py-8 border-b border-grayer">
          {techSpecsURL && techSpecsURL.length > 0 ? <LinkExternalHP className="flex items-center font-light text-blue hover:underline" target="_blank" href={techSpecsURL}><span className="pr-2">{useLiterals('tech_specs')}</span><span><img src={useMedia('blue-arrow')}/></span></LinkExternalHP> : <div />}
          {numberOfStarsURL && numberOfStarsURL.length > 0 ? <LinkExternalHP href={numberOfStarsURL} target="_blank"><Stars /></LinkExternalHP> : <Stars />}
        </div>
      </Appear>
      <div className="p-8">
        <h3 className="mb-8 font-bold leading-none text-center text-big1"><Appear>{title}</Appear></h3>
        <span className="block mb-4 font-light"><Appear><Html text={text} /></Appear></span>
        <Appear>
        <div className="flex items-center justify-between text-blue">
          {price && price.length > 0 ? <span className="font-bold text-big2">{price}{anchorPrice && anchorPrice.length ? <span className="ml-4 line-through text-big1">{anchorPrice}</span> : null}</span> : <span />}
          {unitsLeft && unitsLeft.length > 0 ? <span className="font-light text-blue">{unitsLeft}</span> : <span />}
          {cashbackTitle && cashbackUrl &&
            <LinkExternalHP className="p-2 border-2 border-gray text-gray" href={cashbackUrl} target="_blank">
              {cashbackTitle}
            </LinkExternalHP>
          }
        </div>
        </Appear>
        <Appear>
        <div className="flex items-center justify-between mt-8">
          {buyURL && buyURL.length > 0 ? <LinkExternalHP dataLayer={makeLayer({event: 'e_buyClick', linkPlacement: 'content',linkID: linkid})} data-buyctaref={managerRef} data-buyctahpref={hpRef} className="inline-block px-8 py-3 text-white rounded-full bg-blue hover:bg-black" href={buyURL} target="_blank">{buyText}</LinkExternalHP> : <span />}
          {hpRef && hpRef.length > 0 ? <span className="font-light text-gray">{hpRef}</span> : <span/>}
        </div>
        </Appear>
      </div>
    </div>
  )
}

export default PrinterShoppableItem
