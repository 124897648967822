const THREE = require('three')

class Scene {
  constructor(mainRef) {
    this.mainRef = mainRef
    this.init3()
    this.setupScene()
    this.controls = null
  }

  addControls(controls) {
    this.controls = controls
  }

  _setSize() {
    this.size = {
      width: (this.mainRef.current) ? this.mainRef.current.offsetWidth : '',
      height: (this.mainRef.current) ? this.mainRef.current.offsetHeight : ''
    }
    if (this.controls) {
      this.controls.updateAspect(this.size)
    }
  }

  resize() {
    this._setSize()
    this.renderer.setSize(this.size.width, this.size.height)
  }

  init3() {
    this._setSize()
    this.renderer = new THREE.WebGLRenderer({ antialias: false })
    this.renderer.domElement.style.width = '100%'
    this.renderer.domElement.style.height = '100%'
    this.renderer.domElement.style.position = 'absolute'
    this.renderer.domElement.style.top = '0'
    this.renderer.antialias = true
    // this.renderer.shadowMap.enabled = true
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
    // this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.size.width, this.size.height )
    this.mainRef.current.appendChild(this.renderer.domElement)
    this.resize()

    const that = this
    window.addEventListener('resize', function() {
      that.resize() 
    })
  }

  setupScene() {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x1a1a1a)
  }

  get() {
    return this.scene
  }
  
  getSize() {
    return this.size
  }
  
  getRenderer() {
    return this.renderer
  }

  add(obj) {
    this.scene.add(obj)
  } 
}

export default Scene