// @flow
import React, { useRef, useState, useEffect } from 'react'
import type { Ref } from 'types/'

type Props = {
  image: string,
  alt: string,
  imageClassName?: string
}

const LazySimpleImage = ({ image, alt, imageClassName }: Props) => {

  const [loading, setLoading] = useState(false)
  const [isEdge, setIsEdge] = useState(null)
  const [hasImage, setHasImage] = useState(false)
  const imageRef: Ref = useRef(null)

  useEffect(() => {
    if (document.documentMode ||
      /Edge/.test(navigator.userAgent) ||
      /Edg/.test(navigator.userAgent)) 
    {
      setIsEdge(true)
    } else {
      setIsEdge(false)
    }
  }, [])

  const loadImage = () => {
    setLoading(true)
    const img = new Image()
    img.onload = () => {
      setHasImage(true)
      setLoading(false)
    }
    img.src = image
  }
  
  useEffect(() => {
    if (isEdge) {
      return
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loading) {
          loadImage()
          if (imageRef.current) {
            observer.unobserve(imageRef.current)
          }
        }
      },
      {
        rootMargin: '0px',
        threshold: 0
      }
    )
    if (imageRef.current) {
      observer.observe(imageRef.current)
    }
  }, [isEdge])

  return (
    <span ref={imageRef}>
      {(hasImage || isEdge) && (
        <img
          className={`${imageClassName ? imageClassName : ''}`}
          src={image}
          alt={alt}
        />
      )}
    </span>
  )
}

export default LazySimpleImage
