// @flow
import React from 'react'
import type { ApiImage } from '../types'
import Container from  './Container'
import Grid from './Grid'
import makeAssetUrl from '../lib/makeAssetUrl'
import ModuleIterator from './ModuleIterator'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  backgroundImage: ApiImage,
  items: Object,
  overlapModules?: boolean,
  title?: string,
  anchor?: string,
}

const FloatingContainer = ({ backgroundImage, items, overlapModules = false, title, anchor = false }: Props) => {
  const isMobile = useIsMobile()
  return (
    <>
    <div data-anchor={anchor} />
      {!overlapModules && title && <div className="w-full h-24 bg-black" />}
      <div
        className="relative w-full h-auto"
        style={{
            backgroundImage: `url(${makeAssetUrl({ asset: backgroundImage, isImage: true })})`,
            backgroundPosition: !isMobile ? overlapModules ? 'center' : 'top' : null,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
      >
      {overlapModules && <div
            className="absolute top-0 left-0 right-0 h-20 md:h-40 lg:h-15rem"
            style={{
                backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)"
            }}
        />}
      {overlapModules && <div
          className="absolute bottom-0 left-0 right-0 h-20 md:h-40 lg:h-15rem"
          style={{
              backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)"
          }}
      />}
        <Container padding={false}>
        {title && <Grid>
          <div className="col-span-4 px-4 pb-24 md:col-span-3 md:col-start-6 md:px-0">
            <h2 className="font-light leading-tight text-center text-white text-40px">{title}</h2>
          </div>
        </Grid>}
          <Grid className={overlapModules ? "py-200px md:py-400px lg:py-500px" : `${title ? '' : 'py-150px md:py-300px'}`}>
            <div className={`col-span-4 py-16 ml-4 overflow-hidden bg-white md:pt-20 ${overlapModules ? 'md:pb-20' : 'md:pb-0'} md:ml-0 md:col-span-11 md:col-start-2 lg:col-start-2`}>
              <div className="col-span-4 md:col-span-11">
                <ModuleIterator modules={items} parentName="FloatingContainer" />
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default FloatingContainer
