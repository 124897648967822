// @flow

import React, { useState, useEffect } from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import PrinterShoppableItem from './PrinterShoppableItem'
import { useLiterals } from 'store/'

type Props = {
  title?: String,
  items: any,
  parentName: string,
  postSlug?: string,
  darkBackground?: boolean,
}

function chunk(arr, bulkSize = 20) {
  const bulks = []
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
    bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize))
  }
  return bulks
}

const getApiItem = (refItem, apiItems) => apiItems.find(i => i.ref === refItem)

const PrinterShoppable = ({
  title,
  items,
  parentName,
  postSlug,
  darkBackground,
}: Props): React$Element<*> => {
  const [apiItems, setApiItems] = useState(undefined)
  const url = useLiterals('price_api_url')

  const fetchData = async () => {
    try {
      const res = await fetch(url)
      const text = await res.text()
      const data = new window.DOMParser().parseFromString(text, 'text/xml')
      let items = Array.from(data.getElementsByTagName('item'))

      let pageType = ''
      if (window.location.href.includes('/printer/')) {
        pageType = '-productpage'
      } else if (window.location.href.includes('/blog/')) {
        pageType = '-blogpost'
      }

      items = items.map(item => {
        let fetchBuyURL = item.getElementsByTagName('g:link')[0].textContent
        if (postSlug && postSlug !== '') {
          fetchBuyURL = `${fetchBuyURL}${
            fetchBuyURL.includes('?') ? '&' : '?'
          }utm_source=largeformat${pageType}&utm_medium=referral&utm_campaign=largeformat-printerfeed&utm_content=${postSlug}`
        }

        return {
          ref: item.getElementsByTagName('g:id')[0].textContent,
          title: item.getElementsByTagName('g:title')[0].textContent,
          text: item.getElementsByTagName('g:description')[0].textContent,
          price: item.getElementsByTagName('g:price')[0].textContent || null,
          anchorPrice:
            typeof item.getElementsByTagName('g:sale_price') !== 'undefined' &&
            item.getElementsByTagName('g:sale_price').length > 0
              ? item.getElementsByTagName('g:sale_price')[0].textContent || null
              : null,
          image: item.getElementsByTagName('g:image_link')[0].textContent,
          inStock: item.getElementsByTagName('g:availability')[0].textContent,
          buyURL: fetchBuyURL,
        }
      })
      setApiItems(items)
    } catch (e) {
      console.log('Error fetching products', e)
    }
  }

  useEffect(() => {
    if (url) fetchData()
  }, [])

  const gridElements = apiItems
    ? items &&
      items.length &&
      items
        .filter(item => getApiItem(item.hpRef, apiItems))
        .sort((a, b) => {
          const apiItemA = getApiItem(a.hpRef, apiItems)
          const apiItemB = getApiItem(b.hpRef, apiItems)
          if (
            apiItemA.inStock === 'in stock' &&
            apiItemB.inStock !== 'in stock'
          ) {
            return -1
          }
          if (
            apiItemA.inStock !== 'in stock' &&
            apiItemB.inStock === 'in stock'
          ) {
            return 1
          }
          return 0
        })
        .map(item => {
          const apiItem = getApiItem(item.hpRef, apiItems)
          return (
            <div
              key={item.ref + 'api'}
              className={`col-span-4 mb-4 md:mb-0 ${
                darkBackground ? 'text-white border-white' : ''
              } ${
                parentName === 'CategoryWrapper'
                  ? 'md:col-span-6 lg:col-span-4'
                  : 'sm:col-span-4 md:col-span-4'
              } border -ml-px`}
            >
              <PrinterShoppableItem item={apiItem} />
            </div>
          )
        })
        .slice(0, 3)
    : items &&
      items.length &&
      items
        .map(item => (
          <div
            key={item.ref + 'manager'}
            className={`col-span-4 mb-4 md:mb-0 ${
              darkBackground ? 'text-white border-white' : ''
            } ${
              parentName === 'CategoryWrapper'
                ? 'md:col-span-6 lg:col-span-4'
                : 'sm:col-span-4 md:col-span-4'
            } border -ml-px`}
          >
            <PrinterShoppableItem item={item} darkBackground={darkBackground} />
          </div>
        ))
        .slice(0, 3)

  if (
    !gridElements ||
    !gridElements.length ||
    (gridElements.length && gridElements.filter(i => i !== null).length === 0)
  ) {
    return <></>
  }

  const content = (
    <div className="grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12">
      {chunk([...gridElements], 3).map((row, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-4 col-span-4 sm:col-span-8 md:col-span-8 md:col-start-3 sm:grid-cols-8 md:grid-cols-12"
          >
            {row}
          </div>
        )
      })}
    </div>
  )

  if (parentName === 'CategoryWrapper') {
    return (
      <Spacer top bottom>
        <Container className="relative">
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4 md:mb-0">
              {content}
            </div>
          </Grid>
        </Container>
      </Spacer>
    )
  }

  return (
    <>
      <Spacer top small>
        <Container className="relative">
          <div className="grid w-full grid-cols-4 sm:grid-cols-8 md:grid-cols-12">
            <h3 className="w-full col-span-4 gap-4 font-normal leading-normal text-26px md:text-42px sm:col-span-8 md:col-span-8 md:col-start-3">
              {title || 'Printers'}
            </h3>
          </div>
        </Container>
      </Spacer>
      <hr className="w-screen mt-8 mb-14" />
      <Spacer bottom small>
        <Container>{content}</Container>
      </Spacer>
    </>
  )
}

export default PrinterShoppable
