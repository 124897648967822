// @flow

import React from 'react'

type Props = { url: string }

const IconX = ({ url }: Props) => {
  return (
    <a
      className="twitter-share-button cursor-pointer"
      href="https://twitter.com/intent/tweet"
      data-size="l"
      data-url={url}
      data-related="twitterapi,twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 16 18"
      >
        <path
          id="logo"
          d="M9.745,7.086,15.841,0H14.4L9.1,6.153,4.876,0H0L6.393,9.3,0,16.735H1.445l5.59-6.5,4.465,6.5h4.876L9.745,7.086Zm-1.979,2.3L7.119,8.46,1.965,1.087H4.184L8.343,7.037l.648.926L14.4,15.7H12.179L7.767,9.386Z"
          color="currentColor"
        />
      </svg>
    </a>
  )
}

export default IconX
