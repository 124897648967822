// @flow
import React, { useState, useEffect } from 'react'
import OverlayIframe from './OverlayIframe'
import makeLayer from '../lib/makeLayer'
import type { ApiImage } from '../types'
import LazyImage from './LazyImage'
import Button from './Button'
import Html from './Html'
import findGetParameter from '../lib/findGetParameter'

type Props = {
  image: ApiImage,
  ctaText: string,
  iframeUrl: string,
  text: string,
  backgroundColor?: string,
}

const InlineGatedContent = ({
  image,
  ctaText,
  iframeUrl,
  moduleName,
  text,
  backgroundColor = '#4C14B4',
}: Props) => {
  const [overlay, setOverlay] = useState(null)

  useEffect(() => {
    const gated = findGetParameter(window.location.href, 'gated')
    if (
      gated &&
      (iframeUrl === gated || gated.startsWith('https://reinvent.hp.com/'))
    ) {
      setOverlay(gated)
    }
  }, [])

  let documentID = false
  if (iframeUrl.indexOf('/api/download/') !== -1) {
    documentID = iframeUrl.split('/api/download/')[1]
  }

  return (
    <div
      style={{ background: backgroundColor }}
      className={`block mt-12 md:mb-0 text-white`}
    >
      <div className={'grid grid-cols-12 grid-rows-1 gap-6 items-center p-8'}>
        <div
          className={
            'col-start-1 col-span-5 sm:col-start-3 sm:col-span-3 md:col-start-4 md:col-span-2'
          }
        >
          <LazyImage image={image} alt={ctaText} />
        </div>
        <div
          className={
            'text-3xl col-start-6 col-span-7 sm:col-start-6 sm:col-span-5 md:col-start-6 md:col-span-4'
          }
        >
          <Html text={text} />
          <div className={'text-lg mt-6'}>
            <Button
              style="white"
              title={ctaText}
              color="white"
              href={`${iframeUrl}`}
              data-tag-name={`${moduleName}-cta1`}
              dataLayer={
                iframeUrl.indexOf('reinvent.hp.com') === -1
                  ? makeLayer({
                      event: 'e_document',
                      documentAction: 'download',
                      documentID: documentID || ctaText,
                      documentType: 'pdf',
                    })
                  : makeLayer({
                      event: 'e_contactClick',
                      linkPlacement: 'left-gated',
                      linkID: iframeUrl,
                    })
              }
            />
          </div>
        </div>
      </div>
      <OverlayIframe url={overlay} close={() => handleOverlay(null)} />
    </div>
  )
}

export default InlineGatedContent
