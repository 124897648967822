// @flow

import React, { useEffect, useState } from 'react'
import Grid from './Grid'
import Appear from './Appear'
import LazyImage from './LazyImage'
import Container from './Container'
import Markdown from './Markdown'
import Button from './Button'

type Props = {
  title: string,
  cards: Array,
  anchor: string,
}

const PolestarMasonry = ({ title, anchor, cards }: Props) => {
  let cntLeft = 0
  let cntRight = 0
  let loadedImages = 0

  const unevenCards = cards.length % 2 !== 0
  const rightLastItem = unevenCards ? cards[cards.length - 2] : null
  const leftLastItem = unevenCards ? cards[cards.length - 3] : null

  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [rightOffsetTop, setRightOffsetTop] = useState()
  const [leftOffsetTop, setLeftOffsetTop] = useState()

  const checkLoadedImages = () => {
    loadedImages ++
  }

  useEffect(() => {
    if (loadedImages === (cards.length * 2)) {
      setImagesLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (imagesLoaded && rightLastItem && leftLastItem) {
      const rightItemClass = rightLastItem.key
      const rightItem = document.querySelector(`.${rightItemClass}`)

      const leftItemClass = leftLastItem.key
      const leftItem = document.querySelector(`.${leftItemClass}`)

      setRightOffsetTop(rightItem.offsetTop)
      setLeftOffsetTop(leftItem.offsetTop)
    }
  }, [imagesLoaded])

  useEffect(() => {
  }, [rightOffsetTop, leftOffsetTop])
  

  const MasonryItem = (item, gridPosition) => (
    <Appear key={item.key} className={gridPosition}>
      <div className="w-full md:pb-6">
        <LazyImage className='h-30vh md:h-60vh' cover image={item.image} alt={item.title} onLoadCallback={checkLoadedImages()}/>
        <div>
          <h5 className="pt-6 text-xl font-normal md:block font-secondary">{item.title}</h5>
          <div className='h-px w-full bg-black mt-2 mb-4'></div>
          <Markdown text={item.text} className={`mt-4 ${item.ctaText ? 'mb-4 md:mb-6' : 'mb-8 md:mb-12'} text-xs md:mt-0 font-secondary md:text-base leading-5`} />
          {item.ctaText && item.ctaUrl &&
            <div className={`flex ${item.text2 ? 'flex-col' : 'flex-row'}`}>
              <div className="mb-4"><Button href={item.ctaUrl} title={item.ctaText} style="blackOutlined" /></div>
              {item.text2 && 
                <Markdown text={item.text2} className={`mt-2 mb-2 md:my-6 text-xs font-light font-primary md:text-base`} />
              }
              {item.ctaText2 && item.ctaUrl2 && 
                <div className={`mb-4 ${item.text2 ? '' : 'ml-2 md:ml-4'}`}>
                  <Button href={item.ctaUrl2} title={item.ctaText2} style="blackOutlined" />
                </div>
              }
            </div>
          }
        </div>
      </div>
    </Appear>
  )

  return (
    <div data-anchor={anchor}>
      <Container backgroundColor='bg-darkBlue23'>
        <Appear className="col-span-4 row-start-1 md:col-start-1 md:col-span-6 lg:col-span-4">
          <h2 className="py-4 leading-none text-left font-secondary text-3xl md:text-50px md:w-2/5">{title}</h2>
        </Appear>
      </Container>
      <Container className="bg-beige22 py-6 relative">
        <Grid>
          <div className='col-start-1 col-span-4 md:col-span-12 h-full'>
            <div className='grid grid-cols-4 md:grid-cols-12'>
              {cards && cards.length > 0 && cards.map((card, index) => {
                let gridPosition = ''
                if (index === 0) {
                  gridPosition = `col-span-4 md:pr-6 ${card.key}`
                  }
                if (index === 1) {
                  gridPosition = `col-span-8 ${card.key}`
                  }
                if (index === 2 || index === 4) {
                  gridPosition = `col-span-6 md:pr-6 ${card.key}`
                  }
                if (index === 3 || index === 5) {
                  gridPosition = `col-span-6 ${card.key}`
                }
                  if (unevenCards && index === cards.length - 1 && (leftOffsetTop > rightOffsetTop)) {
                    return null
                  }
                  return (
                    MasonryItem(card, gridPosition)
                  )
              })}
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default PolestarMasonry
