// @flow
import React, { useState, useEffect } from 'react'
import SimpleButton from './SimpleButton'
import LazyImage from './LazyImage'
import Html from './Html'
import OverlayIframe from './OverlayIframe'
import type { ApiImage } from '../types'
import makeLayer from '../lib/makeLayer'
import findGetParameter from '../lib/findGetParameter'

type Props = {
  image: ApiImage,
  text: string,
  ctaText: string,
  iframeUrl: string,
  ctaText2: string,
  ctaUrl2: string,
  verticalSlug?: string,
  target?: number,
  type?: string,
  whiteParentBG?: boolean,
  thereAreWhiteBGModules?: number
}

const CategoryBlock10 = ({
  moduleName,
  id,
  image,
  text,
  ctaText,
  iframeUrl,
  verticalSlug,
  ctaText2,
  ctaUrl2,
  target = 0,
  type = false,
  whiteParentBG = true,
  thereAreWhiteBGModules = 0
}: Props) => {
  const [overlay, setOverlay] = useState(null)
  const [hasGradient, setHasGradient] = useState(false)
  const handleOverlay = (value) => {
    setOverlay(value)
  }
  const handleMenuInGradient = (e) => {
    setHasGradient(e.detail.hasGradient || false)
  }
  useEffect(() => {
    document.addEventListener('menu-in-gradient', handleMenuInGradient)
    const gated = findGetParameter(window.location.href, 'gatedurl')

    const gatedModules = document.querySelectorAll('.gated')
    if (gated && !gatedModules.length && (iframeUrl === gated || gated.startsWith('https://reinvent.hp.com/'))) {
      setOverlay(gated)
    }
    return () => {
      document.removeEventListener('menu-in-gradient', handleMenuInGradient)
    }
  }, [])

  let documentID = false
  if (iframeUrl.indexOf('/api/download/') !== -1) {
    documentID = iframeUrl.split('/api/download/')[1]
  }

  const handleLinkColor = () => {
    if (hasGradient && thereAreWhiteBGModules === 1 && verticalSlug === "sustainability") {
      return "black"
    } else if ((hasGradient && thereAreWhiteBGModules === 0) || typeof verticalSlug === "sustainability") {
      return "black"
    } else if ((hasGradient && thereAreWhiteBGModules === 0) || typeof verticalSlug !== "undefined") {
      return "white"
    } else {
      return "blue"
    }
  }

  const hasGradientOnWhiteBG = hasGradient && thereAreWhiteBGModules === 1

  whiteParentBG=true
  
  return (
    <div className={`${typeof verticalSlug !== 'undefined' ? `${whiteParentBG || hasGradientOnWhiteBG ? "text-black" : "text-white"}` : ''} mt-8`}>
      <div className={type === 'post' ? 'flex text-normal md:text-smaller' : 'flex text-smaller'}>
        <div className={'flex-1'}>
          <LazyImage image={image} alt={ctaText} />
        </div>
        <div style={{flex: 3}} className={`pl-4 text-${handleLinkColor()}`}>
          <Html text={text} />
          <div className={'mt-2'}>
            <SimpleButton
              title={ctaText}
              color={handleLinkColor()}
              href={`${iframeUrl}`}
              data-tag-name={`${moduleName}-cta1`}
              dataLayer={ (iframeUrl.indexOf('reinvent.hp.com') === -1) ?
                  makeLayer({
                  event: 'e_document',
                  documentAction: 'download',
                  documentID: documentID || ctaText,
                  documentType: 'pdf'
                }) : makeLayer({
                  event: 'e_contactClick',
                  linkPlacement: 'left-gated',
                  linkID: iframeUrl
                })
              }
              smallArrow={thereAreWhiteBGModules}
            />
          </div>
          {typeof ctaText2 !== 'undefined' && typeof ctaUrl2 !== 'undefined' && ctaUrl2.length > 0 && <div className="ml-2">
            <SimpleButton
              title={ctaText2}
              color={hasGradient || typeof verticalSlug !== 'undefined' ? "white" : "blue"}
              href={ctaUrl2}
              target={target}
              data-tag-name={`${moduleName}-${id}-cta2`}
              dataLayer={ (iframeUrl.indexOf('reinvent.hp.com') === -1) ?
                makeLayer({
                  event: 'e_document',
                  documentAction: 'download',
                  documentID: documentID || ctaText2,
                  documentType: 'pdf'
                }) : makeLayer({
                  event: 'e_contactClick',
                  linkPlacement: 'left-gated',
                  linkID: iframeUrl
                })
              }
              smallArrow={thereAreWhiteBGModules}
            />
          </div>}
        </div>
      </div>
      <OverlayIframe url={overlay} close={() => handleOverlay(null)} />
    </div>
  )
}

export default CategoryBlock10