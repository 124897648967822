// @flow
import React from 'react'
import Container from './Container'
import Html from './Html'
import VimeoVideo from './VimeoVideo'

type Props = {
  title: string,
  subtitle: string,
  video: string,
}

const CardCenter = ({ title, subtitle, video }: Props) => {
  const videoComponent = video && <VimeoVideo url={video} />

  return (
    <Container customPadding="px-5 md:px-10 py-0">
      <div className="relative bg-black w-full overflow-hidden text-white rounded-lg justify-center flex flex-col items-center leading-normal text-center h-full sm:h-35vw md2:h-25vw xl:min-h-500">
        <div className="absolute h-full w-full object-cover">
          {videoComponent}
        </div>
        <div className="py-3 mt-20 md:mt-0 md:py-6 text-42px md:text-6xl md:w-7/12 z-20 leading-none personalized-big">
          <Html text={title} />
        </div>
        <div className="text-xl mb-20 md:mb-0 md:text-30px z-20 personalized-md">
          <Html text={subtitle} />
        </div>
      </div>
    </Container>
  )
}

export default CardCenter
