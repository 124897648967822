// @flow
import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import BlogFilters from './BlogFilters'
import Animated from './Animated'
import LazyImage from './LazyImage'
import { useLiterals } from 'store/'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'
import Appear from './Appear'
import SimpleButton from './SimpleButton'
import Cross from './Cross'
import LinkHP from './LinkHP'

type Story = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  url: string
}

type Category = {
  id: string,
  title: string,
  posts: Array<Story>
}

type Props = {
  categories: Array<Category>
}

const animations = {
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  },
  dropdown: () => {
    if (typeof document !== 'undefined') {
      const box = document.querySelector('#filters-box')
      let height = 0
      if (box) {
        height = box.offsetHeight
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`,
          'margin-top': 0
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`,
          'margin-top': '2rem'
        },
        duration: 0.5
      }
    }
  }
}

const match = (post, value) => {
  if (!value) return false
  if (
    post.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  ) {
    return true
  }
  return false
}

const filterPosts = (posts, selectedFilters, search) => {
  const selectedPosts = []
  posts.forEach(post => {
    if (!selectedFilters.length && !search) {
      selectedPosts.push(post)
    }
    if (
      (search && search.length > 0 && match(post, search))
      ||
      selectedFilters.find(filter => Number(filter.id) === Number(post.postCategory))
    ) {
      selectedPosts.push(post)
    }
  })
  return selectedPosts
}

const Posts = ({ categories, posts: rawPosts }: Props) => {
  let [selectedFilters, setSelectedFilters] = useState([])
  const [search, setSearch] = useState('')
  const isMobile = useIsMobile()
  const [dropdown, setDropdown] = useState(false)
  const [posts, setPosts] = useState(rawPosts)

  useEffect(() => {
    const selectedPosts = filterPosts(rawPosts, selectedFilters, search)
    setPosts([...selectedPosts])
  }, [selectedFilters, search])

  const selectFilter = filter => {
    const check = selectedFilters.find(
      ({ id: filterID }) =>
        filterID === filter.id
    )
    if (!check) {
      selectedFilters.push(filter)
    } else {
      selectedFilters = selectedFilters.filter(
        ({ label }) => label !== filter.label
      )
    }
    setSelectedFilters([...selectedFilters])
  }

  return (
    <Spacer top bottom small>
      <Container>
        <Grid className="relative">
          <div className="sticky top-4.5 md:relative md:top-0 z-20 col-span-4 py-8 bg-white md:col-span-2 md:row-start-1 md:row-span-2 md:py-0 md:pt-4">
            {isMobile ? (
              <>
                <div
                  className="z-20 flex justify-between"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <span className="font-light text-smaller">
                    {useLiterals('choose_your_printer')}
                  </span>
                  <div className="relative flex items-center md:hidden">
                    <Animated
                      key="dropdown-plus"
                      className="absolute h-px bg-black w-15px"
                      type="spring"
                      animation={animations.plus}
                      bind={dropdown}
                    ></Animated>
                    <div className="h-px bg-black w-15px"></div>
                  </div>
                </div>
                <Animated
                  bind={dropdown}
                  type="spring"
                  animation={animations.dropdown()}
                  className="overflow-hidden"
                >
                  {/* <BlogFilters
                    filters={categories}
                    selectedFilters={selectedFilters}
                    selectFilter={selectFilter}
                    onValueChange={setSearch}
                    isMobile={isMobile}
                    dropdown={dropdown}
                    inputValue={search}
                    type="posts"
                  /> */}
                </Animated>
              </>
            ) : (
              <>
                <div className="z-20 flex justify-between mb-8">
                  <span>{useLiterals('choose_your_printer')}</span>
                </div>
                <BlogFilters
                  filters={categories}
                  selectedFilters={selectedFilters}
                  selectFilter={selectFilter}
                  onValueChange={setSearch}
                  isMobile={isMobile}
                  dropdown={dropdown}
                  inputValue={search}
                  type="posts"
                />
              </>
            )}
          </div>
          <div
            className={`col-span-4 mt-12 row-start-1 md:col-span-9 md:col-start-4 md:mt-0 md:pt-4 ${
              !selectedFilters.length && !search ? 'hidden' : 'block'
            }`}
          >
            <div className="w-full pb-2 mb-2 font-light border-b">
              {useLiterals('we_found').replace(
                '*',
                ` ${posts.length} `
              )}
            </div>
            <div className="flex flex-wrap items-center h-full pb-4 font-light cursor-pointer jusitfy-center text-smaller">
              {selectedFilters.map(({ label, id, category }, index) => (
                <div
                  key={label}
                  className={`flex p-1 px-2 ${
                    !index ? 'mr-1 mt-1 mb-1' : 'm-1'
                  } text-black transition rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller`}
                  onClick={() => {
                    selectFilter({ category, id, label })
                  }}
                >
                  <span className="flex items-center text-xs">{label}</span>
                  <Cross small={true} />
                </div>
              ))}
              {search && (
                <div
                  className="flex p-1 px-2 m-1 text-black rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller"
                  onClick={() => {
                    setSearch('')
                  }}
                >
                  <span className="flex items-center text-xs">{search}</span>
                  <Cross small={true} />
                </div>
              )}
            </div>
          </div>
          <div
            className={`grid col-span-4 gap-4 md:col-start-4 md:col-span-9 ${(() => {
              if (!search && !selectedFilters.length) {
                return 'md:row-start-1 pt-2'
              }
              if (search || selectedFilters.length) {
                return 'md:row-start-2 pt-12'
              }
              return ''
            })()} md:row-span-3`}
          >
            <div className={`${posts.length ? 'block' : 'hidden'}`}>
              <div className="grid gap-4 md:grid-cols-3">
                {posts.map(
                  ({ image, title, id, url, category }, index) => (
                    <div
                      className="relative mb-6"
                      key={`${index}-${id}-${Math.random()}`}
                    >
                      <div className="relative mb-8">
                        <LinkHP className="relative" to={url}>
                          <LazyImage image={image} alt={title} />
                        </LinkHP>
                      </div>
                      <Appear>
                        <div className="flex flex-col">
                          <h6 className={`mb-4 text-gray`}>
                            {category}
                          </h6>
                          <h3 className={`mb-4 text-black text-big1`}>
                            <LinkHP to={url}>{title}</LinkHP>
                          </h3>
                          <div className={`${url ? 'block' : 'hidden'}`}>
                            <SimpleButton
                              to={url}
                              color="blue"
                              title={useLiterals('learn_more')}
                            />
                          </div>
                        </div>
                      </Appear>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className={`pt-2 ${posts.length ? 'hidden' : 'block'}`}
            >
              {useLiterals('not_found')}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default Posts
