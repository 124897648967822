// @flow
import React from 'react'
import LazySimpleImage from './LazySimpleImage'
import LinkExternalHP from './LinkExternalHP'
import { useMedia } from 'store/'

type Props = {
  ctaLabel: string,
  ctaLink: string,
}

const DownloadButton = ({ ctaLabel, ctaLink }: Props) => {
  return (
    <LinkExternalHP
      target="_blank"
      href={ctaLink}
      className="flex items-center py-4 hover:underline text-blue"
    >
      <div className="flex-shrink-0">
        <LazySimpleImage
          image={useMedia("document-blue")}
          alt={ctaLabel}
        />
      </div>
      <span className="ml-2 md:ml-4">{ctaLabel}</span>
    </LinkExternalHP>
  )
}

export default DownloadButton
