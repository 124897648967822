// @flow

import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import CarouselButtons from './CarouselButtons'
import LazyImage from './LazyImage'

type BasicImage = { id: string, options: { title: String, image: ApiImage } }
type Props = { items: Array<BasicImage> }
const BasicImageSlider = ({ items }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })
  return items?.length > 0 ? (
    <div className="my-8 flex flex-col gap-8 border-b pb-1 md:pb-4">
      <CarouselButtons emblaApi={emblaApi} className="justify-end" />
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex gap-4">
          {items.map(({ id, options }) => (
            <div key={id}>
              {options?.image ? (
                <LazyImage
                  image={options.image}
                  alt={options.title ?? ''}
                  cover
                  className="h-70vw md:h-14rem w-80vw md:w-16rem"
                />
              ) : null}
              {options?.title ? (
                <div className="text-16px pt-4 leading-normal">
                  {options.title}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
}

export default BasicImageSlider
