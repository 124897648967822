// @flow

import React, { useState, useRef } from 'react'
import Container from './Container'
import useIsMobile from '../hooks/useIsMobile'
import makeAssetUrl from '../lib/makeAssetUrl'
import smoothscroll from 'smoothscroll-polyfill'
import Html from './Html'
import Grid from "./Grid"
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from 'store/'


if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}
type Item = {
  image: ApiImage,
  id: string,
  title: string,
  url: string,
  teaser: string,
}

type Props = {
  items: Array<Item>
}

const drag = {
  active: false,
  start: 0,
  scrollLeft: 0,
  current: 0
}

const Blog3 = ({ items }: Props) => {
  const slider: Ref = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isMobile = useIsMobile()


  const handleSlider = (direction: string): void => {
    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    switch (direction) {
      case 'forwards':
        if (nextIndex < items.length - 1) {
          nextIndex += 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth'
          })
          setCurrentIndex(nextIndex)
        }
        break
      case 'backwards':
        if (nextIndex > 0) {
          nextIndex -= 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth'
          })
          setCurrentIndex(nextIndex)
        }
        break
    }
  }

  const slideTo = (index: number): void => {
    const childW = slider.current.children[0].offsetWidth
    slider.current.scrollTo({
      left: index * childW,
      behavior: 'smooth'
    })
    setCurrentIndex(index)
  }

  const handleDrag = e => {
    if (items.length < 2) return
    let pX = e.pageX
    if (e.type.includes('touch') && !e.type.includes('touchend')) {
      pX = e.touches[0].pageX
    }
    let nextIndex = currentIndex
    switch (e.type) {
      case 'mousedown':
        // case 'touchstart':
        slider.current.classList.remove('snap-x-mandatory')
        drag.active = true
        drag.start = pX - slider.current.offsetLeft
        drag.scrollLeft = slider.current.scrollLeft
        break
      case 'mouseup':
        // case 'touchend':
        drag.active = false
        if (
          drag.scrollLeft < slider.current.scrollLeft &&
          nextIndex < items.length - 1
        ) {
          nextIndex++
          slideTo(nextIndex)
        }
        if (drag.scrollLeft > slider.current.scrollLeft && nextIndex > 0) {
          nextIndex--
          slideTo(nextIndex)
        }
        break
      case 'mousemove':
        // case 'touchmove':
        if (drag.active) {
          const x = pX - slider.current.offsetLeft
          // El 2 es arbitrario. Define la velocidad en la que hace el scroll
          const walk = (x - drag.start) * 2
          slider.current.scrollLeft = drag.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const handleSliderScroll = ({ target }) => {
    if (items.length < 2) return
    if (isMobile) {
      if (!target.scrollLeft) {
        setCurrentIndex(0)
        return
      }
      const position =
        (target.offsetWidth * items.length) / target.scrollLeft - 1
      if (Number.isInteger(position) && position <= items.length) {
        setCurrentIndex(position)
      }
    }
  }

  return (
    <div>
      <div className="relative flex flex-col mb-8 overflow-hidden h-40rem md:h-43vw mt-menu-desktop">
        <button className='absolute z-50 flex items-center justify-start w-1/12 h-full pl-2' style={{ outline: "none" }}>
          <div
            className={`flex items-center justify-center w-12 h-12 r-1 bg-grayFooter rounded-full cursor-pointer flex-shrink-0`}
            onClick={() => handleSlider('backwards')}
          >
              <LazySimpleImage
                alt={useLiterals('previous')}
                image={useMedia('white-arrow-left')}
              />
          </div>
        </button>
        <button className='absolute right-0 z-50 flex items-center justify-end w-1/12 h-full pr-2' style={{ outline: "none" }}>
          <div
            className={`flex items-center justify-center w-12 h-12 pl-1 bg-grayFooter rounded-full cursor-pointer flex-shrink-0`}
            onClick={() => handleSlider('forwards')}
          >
              <LazySimpleImage
                alt={useLiterals('previous')}
                image={useMedia('white-arrow-right')}
              />
            </div>
          </button>
        <div
          className="absolute top-0 left-0 flex items-center w-full h-full overflow-y-hidden"
        >
          <div
            className={`flex items-center h-full w-full overflow-y-hidden  snap-x-mandatory ${
              items.length > 1 ? 'cursor-pointer bg-localactive:cursor-grab' : ''
            } md:overflow-x-hidden slider`}
            ref={slider}
            onMouseDown={handleDrag}
            onMouseMove={handleDrag}
            onMouseUp={handleDrag}
            onScroll={handleSliderScroll}
          >
            {items.map(
              (
                {
                  id,
                  image,
                  title,
                  url,
                },
                index
              ) => (
              <div key={id} className='relative flex flex-col flex-shrink-0 w-full h-full'>         
                  <a
                    id={`slide-${index}`}
                    data-slug={url}
                    className="relative object-cover w-full h-full bg-center bg-no-repeat bg-cover snap-align-center"
                    style={{
                      backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`
                    }}
                    href={url}
                  >
                  </a>
                  <a className="h-max bg-shinningBlue" href={url}>
                    <Container>
                      <Grid>
                        <div className="col-span-4 my-8 text-3xl leading-none select-none md:text-6xl md:col-span-10 md:col-start-2">
                          <h3>
                            <Html text={title} />
                          </h3>
                        </div>
                      </Grid>
                    </Container>
                  </a>
                </div> 
              )
            )}
          </div>
        </div>
      </div>
      {items.length > 1 && (
        <div className="flex items-center w-full h-full mb-8">
          <div className="grid w-full">
            <div className="flex justify-center">
              {items.map((item, index) => (
                <div
                  onClick={() => slideTo(index)}
                  key={index}
                  className={`md:w-3 md:h-3 w-2 h-2 mx-2px md:mx-3 cursor-pointer ${
                    currentIndex === index ? 'bg-black' : 'bg-transparent'
                  } border border-black rounded-full`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Blog3
