// @flow
import React, { useRef, useState } from 'react'
import Container from './Container'
import Html from './Html'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from '../store'
import LazyImage from './LazyImage'
import type { Ref } from 'types/'
import type { Module } from "../types/module"

type Props = {
  items: Array<Module>,
  anchor?: string,
}

const Applications = ({ items, anchor }: Props): React$Element<React$FragmentType> => {
  const [activeIndex, setActiveIndex] = useState(0)
  const slide: Ref = useRef()
  const [isTransitioning, setIsTransitioning] = useState(false)

  const handleSlider = direction => {
    if (isTransitioning) return

    setIsTransitioning(true)

    slide.current.classList.add('fade-out')

    setTimeout(() => {
      setIsTransitioning(false)

      if (direction === 'backwards') {
        setActiveIndex(activeIndex < 1 ? items.length - 1 : activeIndex - 1)
      } else {
        setActiveIndex(activeIndex >= items.length - 1 ? 0 : activeIndex + 1)
      }

      slide.current.classList.remove('fade-out')
    }, 250)
  }

  return (
    <>
      {items && items.length > 0 && (
        <Container
          customPadding="px-5 md:px-10 pt-5 md:py-8"
          className="md:min-h-65vh xl:min-h-50vh"
          anchor={anchor}
        >
          <div className="grid grid-cols-4 md:grid-cols-8">
            <div
              ref={slide}
              className="relative flex items-center justify-center col-span-4 transition transition-opacity md:col-span-6 duration-25"
            >
              <div className="absolute z-30 flex justify-between w-full px-4 md:px-10">
                <div
                  className={`flex items-center justify-center w-8 h-8 md:w-12 md:h-12 pr-1 mr-4 ${
                    items.length < 2 ? 'invisible' : ''
                  } rounded-full cursor-pointer`}
                  onClick={() => handleSlider('backwards')}
                  style={
                    ({ backgroundColor: 'transparent' },
                    items[activeIndex].options.arrowsColor === 'grey'
                      ? { border: '2px solid rgba(189,189,189)' }
                      : { border: '2px solid rgba(255,255,255)' })
                  }
                >
                  <LazySimpleImage
                    alt={useLiterals('previous')}
                    image={
                      items[activeIndex].options.arrowsColor === 'grey'
                        ? useMedia('leftGrayArrow')
                        : useMedia('white-arrow-left')
                    }
                  />
                </div>
                <div
                  className={`flex items-center justify-center w-8 h-8 md:w-12 md:h-12 pl-1 ${
                    items.length < 2 ? 'invisible' : ''
                  } rounded-full cursor-pointer`}
                  onClick={() => handleSlider('forwards')}
                  style={
                    ({ backgroundColor: 'transparent' },
                    items[activeIndex].options.arrowsColor === 'grey'
                      ? { border: '2px solid rgba(189,189,189)' }
                      : { border: '2px solid rgba(255,255,255)' })
                  }
                >
                  <LazySimpleImage
                    alt={useLiterals('previous')}
                    image={
                      items[activeIndex].options.arrowsColor === 'grey'
                        ? useMedia('rightGrayArrow')
                        : useMedia('white-arrow-right')
                    }
                  />
                </div>
              </div>
              <div className="relative w-full md:min-h-75vh">
                <LazyImage
                  cover
                  key={activeIndex}
                  alt={items[activeIndex].options.title}
                  image={items[activeIndex].options.image}
                  className="absolute top-0 left-0 w-full h-60vh md:h-83vh lg:h-75vh"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between col-span-4 leading-none text-white bg-black rounded-lg md:col-span-2 md:col-start-7 ">
              <div className="order-2 md:order-1">
                <h3 className="p-4 border-b-2 text-30px md:text-50px border-graySlider">
                  {items[activeIndex].options.title}
                </h3>
                {items[activeIndex].options.subtitle && (
                  <h4 className="p-4 text-lg border-b-2 md:text-28px border-graySlider">
                    {items[activeIndex].options.subtitle}
                  </h4>
                )}
                <div className="px-4 pt-4 pb-1 text-sm leading-5 md:text-base">
                  <Html text={items[activeIndex].options.text} />
                </div>
              </div>
              <div className="flex order-1 gap-2 p-6 border-b-2 md:border-t-2 md:border-b-0 border-graySlider md:order-2">
                {items.map((item, index) => {
                  return (
                    <div
                      className={`md:h-16 md:w-16 h-10 w-10 rounded-lg ${
                        activeIndex === index
                          ? 'border-2 border-white'
                          : 'opacity-50 border-2 border-graySlider'
                      }`}
                      onClick={() => setActiveIndex(index)}
                      key={item.id}
                    >
                      <LazyImage
                        contain
                        alt={item.options.title}
                        image={item.options.imageThumbnail}
                        className="h-full"
                        borderRadius
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export default Applications
