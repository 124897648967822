const shouldScrollTo = (selector) => {
    const element = document.querySelector(selector)
    const yOffset = -150
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
}

export default shouldScrollTo