// @flow

import React, { FC, ReactElement, memo } from 'react'

type Props = {
  title: string,
}

const AppearText: FC<Props> = ({ title }): ReactElement => {
  const divideAndLimitTitle = title.split(',').slice(0, 2)

  // cleans lines and adds the removed comma from the first line
  const lines = divideAndLimitTitle.map(
    (line, index) => `${line.trim()}${index === 0 ? ',' : ''}`
  )

  return (
    <span>
      {lines.map((line, index) => (
        <span
          key={index}
          className="block font-secondary px-5 md:px-8 border-b-2 border-white"
        >
          {line}
        </span>
      ))}
    </span>
  )
}

export default memo(AppearText)
