// @flow
import React from 'react'
import Appear from './Appear'
import TyphoonFeaturesRowScroll from './TyphoonFeaturesRowScroll'
import TyphoonFeaturesRowItem from './TyphoonFeaturesRowItem'
import Button from './Button'


type Props = {
  items: Array<Object>,
  ctaLabel: string,
  ctaLink: string,
}
const TyphoonFeaturesRow = ({ items, ctaLabel, ctaLink }: Props) => {
  return (
    <div className="grid grid-cols-4 gap-4 pb-12 md:pb-24 md:grid-cols-11">
      <div className="col-span-4 pb-16 sm:hidden">
        <Appear>
          <TyphoonFeaturesRowScroll items={items} />
        </Appear>
      </div>
      <div className="hidden sm:col-span-4 md:col-span-10 md:col-start-2 sm:block sm:px-4 md:px-0">
        <div className="grid gap-4 pt-16 sm:grid-cols-6 md:grid-cols-10">
          {items && items.map((item) => {
            const itemProps = item.options
            return (
              <div
                key={item.id}
                className="sm:col-span-2 md:col-span-3"
              >
                <Appear>
                  <TyphoonFeaturesRowItem
                    key={item.id}
                    title={itemProps.title}
                    text={itemProps.text}
                    image={itemProps.image || null}
                  />
                </Appear>
              </div>
            )})}
        </div>
      </div>
      {ctaLabel && <div className={`col-span-2 pl-4 ${ctaLink && ctaLink.length > 0 ? '' : 'pointer-events-none'} md:col-span-3 md:col-start-2 md:pl-0`}>
        <Appear>
          <Button title={ctaLabel} href={ctaLink} style="black" padded textClasses="text-sm md:text-base text-center"/>
        </Appear>
      </div>}
    </div>
  )
}

export default TyphoonFeaturesRow
