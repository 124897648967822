// @flow
import React from 'react'
import LazyImage from './LazyImage'
import Grid from './Grid'
import Spacer from './Spacer'
import Container from './Container'
import Html from './Html'
import Appear from './Appear'
import Bubble from './Bubble'
import makeAssetUrl from '../lib/makeAssetUrl'

import type { ApiImage } from '../types'

type Props = {
  items: Array<ApiImage>,
  text: string,
  texture: ApiImage,
  whiteParentBG?: boolean,
}

const CategoryBlock14 = ({ items, text, texture, whiteParentBG = false }: Props) => {
  return (
    <Spacer top bottom className="relative">
      <Container>
        <Grid className="relative">
          {text && (
            <div className={`col-span-2 row-start-1 mb-8 font-light leading-none ${whiteParentBG ? "text-black" : "text-white"} md:col-start-4 md:col-span-4 md:text-2xl`}>
              <Html text={text} />
            </div>
          )}
          {items.map((item, index) => {
            return (
              <Appear
                key={index}
                className={`z-10 ${
                  index % 2 === 0
                    ? `col-start-1 col-span-2 md:col-start-4 md:col-span-4 row-span-3`
                    : `col-start-3 col-span-2 md:col-start-8 md:col-span-5 row-span-2 row-start-${
                        text ? index + 3 : index + 2
                      }`
                }`}
              >
                <LazyImage alt={text} image={item.image} />
              </Appear>
            )
          })}
          {texture && !text && (
            <>
              <Appear className="absolute w-64 h-64 -mt-24 rounded-full bg-paleYellow justify-self-center md:w-200 md:h-200 md:ml-24">
                <div />
              </Appear>
              <Bubble
                src={makeAssetUrl({ asset: texture, isImage: true })}
                className="absolute w-32 h-32 rounded-full -bottom-10p bg-paleYellow justify-self-center md:w-64 md:h-64 md:ml-64"
              />
            </>
          )}
        </Grid>
      </Container>
    </Spacer>
  )
}

export default CategoryBlock14
