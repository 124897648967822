// @flow
import React from 'react'
import LazyImage from './LazyImage'
import Html from './Html'
import type { ApiImage } from '../types'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'


type Props = {
  title: string,
  image: ApiImage,
  text: string,
}

const TyphoonHighlight = ({
  title,
  image,
  text,
}: Props): React$Node => {
  return (
    <Container padding={false} className="py-32 pl-4 pr-4 md:pr-0">
      <Grid>
        <div className="flex flex-col justify-center col-span-4 md:col-start-2">
          <div className="mb-16 leading-none text-center md:w-3/4 md:text-left text-30px md:text-40px lg:text-50px">
            <Appear>
              <Html text={title} />
            </Appear>
          </div>
          <div className="pb-12 font-light md:pb-0 lg:w-3/4">
            <Appear>
              <Html text={text} />
            </Appear>
          </div>
        </div>
        <div className="col-span-4 md:col-start-6 md:col-span-7">
          <Appear>
            <LazyImage image={image} />
          </Appear>
        </div>
      </Grid>
    </Container>
  )
}

export default TyphoonHighlight
