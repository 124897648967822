// @flow
import React, { useEffect, useRef, useState } from 'react'
import Container from './Container'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from '../store'

export type CardSliderItem = {
  image: ApiImage,
  automaticSlide?: Boolean,
}

type Props = {
  items: Array<CardSliderItem>,
}

const CardSlider = ({ items, automaticSlide = false }) => {
  const sliderRef = useRef(null)
  const sliderItemRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startPosX, setStartPosX] = useState(0)
  const [startTranslate, setStartTranslate] = useState(0)
  const [currentTranslate, setCurrentTranslate] = useState(0)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [automaticSlider, setAutomaticSlider] = useState(
    automaticSlide ? true : false
  )

  useEffect(() => {
    if (automaticSlider) {
      const sliderInterval = setInterval(() => {
        const nextSlide = (currentSlide + 1) % items.length
        setCurrentSlide(nextSlide)
        const parentElement = sliderRef.current.parentElement
        if (parentElement) {
          const newTranslate = -nextSlide * sliderItemRef.current.offsetWidth
          sliderRef.current.style.transition = 'transform 0.3s ease'
          sliderRef.current.style.transform = `translateX(${newTranslate}px)`
          setCurrentTranslate(newTranslate)
        }
      }, [2000])
      return () => {
        clearInterval(sliderInterval)
      }
    }
  }, [currentSlide])

  const handleDirection = direction => {
    if (direction === 'backwards' && currentSlide !== 0) {
      const nextSlide = (currentSlide - 1) % items.length
      setCurrentSlide(nextSlide)
      const newTranslate = -nextSlide * sliderItemRef.current.offsetWidth
      console.log(sliderItemRef.current.offsetWidth, 'OFFSETWIDTH')
      sliderRef.current.style.transition = 'transform 0.3s ease'
      sliderRef.current.style.transform = `translateX(${newTranslate}px)`
      setCurrentTranslate(newTranslate)
    } else if (direction === 'forwards' && currentSlide !== items.length - 1) {
      const nextSlide = (currentSlide + 1) % items.length
      setCurrentSlide(nextSlide)
      const newTranslate = -nextSlide * sliderItemRef.current.offsetWidth
      console.log(sliderItemRef.current.offsetWidth, 'OFFSETWIDTH')

      sliderRef.current.style.transition = 'transform 0.3s ease'
      sliderRef.current.style.transform = `translateX(${newTranslate}px)`
      setCurrentTranslate(newTranslate)
      // console.log(newTranslate, 'THIS IS NEW TRANSLATE')
    }
  }

  /* DESKTOP */
  const handleMouseDown = e => {
    setIsDragging(true)
    setAutomaticSlider(false)
    setStartPosX(e.clientX)
    setStartTranslate(currentTranslate)
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'none'
    }
  }

  const handleMouseMove = e => {
    if (!isDragging) return
    const moveX = e.clientX - startPosX
    const newTranslate = Math.min(
      0,
      Math.max(
        -sliderRef.current.offsetWidth +
          sliderRef.current.parentElement.offsetWidth,
        startTranslate + moveX
      )
    )
    sliderRef.current.style.transform = `translateX(${newTranslate}px)`
  }

  const handleMouseUp = e => {
    if (isDragging) {
      setIsDragging(false)
      if (sliderRef.current) {
        sliderRef.current.style.transition = 'transform 0.3s ease'
        const moveX = e.clientX - startPosX
        const newTranslate = Math.min(
          0,
          Math.max(
            -sliderRef.current.offsetWidth +
              sliderRef.current.parentElement.offsetWidth,
            startTranslate + moveX
          )
        )
        sliderRef.current.style.transform = `translateX(${newTranslate}px)`
        setCurrentTranslate(newTranslate)
      }
    }
  }

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false)
      if (sliderRef.current) {
        sliderRef.current.style.transition = 'transform 0.3s ease'
        sliderRef.current.style.transform = `translateX(${startTranslate}px)`
      }
    }
  }

  /* MOBILE */
  const handleTouchStart = e => {
    setIsDragging(true)
    setStartPosX(e.touches[0].clientX)
    setStartTranslate(currentTranslate)
    if (sliderRef.current) {
      sliderRef.current.style.transition = 'none'
    }
  }

  const handleTouchMove = e => {
    if (!isDragging) return
    const moveX = e.touches[0].clientX - startPosX
    const newTranslate = Math.min(
      0,
      Math.max(
        -sliderRef.current.offsetWidth +
          sliderRef.current.parentElement.offsetWidth,
        startTranslate + moveX
      )
    )
    sliderRef.current.style.transform = `translateX(${newTranslate}px)`
  }

  const handleTouchEnd = e => {
    if (isDragging) {
      setIsDragging(false)
      if (sliderRef.current) {
        sliderRef.current.style.transition = 'transform 0.3s ease'
        const moveX = e.changedTouches[0].clientX - startPosX
        const newTranslate = Math.min(
          0,
          Math.max(
            -sliderRef.current.offsetWidth +
              sliderRef.current.parentElement.offsetWidth,
            startTranslate + moveX
          )
        )
        sliderRef.current.style.transform = `translateX(${newTranslate}px)`
        setCurrentTranslate(newTranslate)
      }
    }
  }

  return (
    <Container customPadding="py-10">
      <div
        className="relative flex w-full h-full gap-2 overflow-hidden"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="flex justify-between h-full items-center w-screen px-5 md:px-10  absolute z-30">
          <div
            className={`flex items-center justify-center w-8 h-8 md:w-12 md:h-12 pr-1 mr-4 ${
              items.length < 2 ? 'invisible' : ''
            } rounded-full cursor-pointer`}
            onClick={() => handleDirection('backwards')}
            style={{
              backgroundColor: 'transparent',
              border: '2px solid rgba(255,255,255)',
            }}
          >
            <LazySimpleImage
              alt={useLiterals('previous')}
              image={useMedia('white-arrow-left')}
            />
          </div>
          <div
            className={`flex items-center justify-center w-8 h-8 md:w-12 md:h-12 pl-1 ${
              items.length < 2 ? 'invisible' : ''
            } rounded-full cursor-pointer`}
            onClick={() => handleDirection('forwards')}
            style={{
              backgroundColor: 'transparent',
              border: '2px solid rgba(255,255,255)',
            }}
          >
            <LazySimpleImage
              alt={useLiterals('previous')}
              image={useMedia('white-arrow-right')}
            />
          </div>
        </div>
        <div
          className="flex relative"
          ref={sliderRef}
          style={{
            transition: 'transform 0.3s ease',
          }}
        >
          {items &&
            items?.map(item => (
              <div
                ref={sliderItemRef}
                key={item.id}
                className={`flex-shrink-0 w-screen h-50vh md2:h-70vh rounded-lg overflow-hidden`}
              >
                <LazyImage
                  image={item.options.image}
                  draggable={false}
                  cover
                  className="w-full h-full"
                />
              </div>
            ))}
        </div>
      </div>
    </Container>
  )
}

export default CardSlider
