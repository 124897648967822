// @flow

import React, { useState, useEffect } from 'react'

type Props = {
  shrink: boolean
}

const CustomCursor = ({ shrink }: Props) => {
  const [scale, setScale] = useState(0.6)


  useEffect(() => {
    setScale(shrink ? 0.6 : 0.7)
  }, [shrink])

  return (
    <svg
      width="86"
      height="86"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-none"
      style={{
        transform: `scale(${scale})`,
        transition: 'transform .3s'
      }}
    >
      <defs>
        <radialGradient
          cx="15.48%"
          cy="100%"
          fx="15.48%"
          fy="100%"
          r="100%"
          id="a"
        >
          <stop stopColor="#F66464" offset="0%" />
          <stop stopColor="#0632FF" offset="48.097%" />
          <stop stopColor="#4EB589" offset="100%" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#a)" opacity=".825" cx="43" cy="43" r="43" />
        <g fill="#FFF">
          <path d="M23.437 41.958l8.405-6.578a.854.854 0 011.172.118.785.785 0 01-.122 1.13l-8.406 6.58 8.396 6.563c.356.28.413.785.122 1.131a.854.854 0 01-1.171.12l-8.395-6.564c-.404-.317-.638-.772-.638-1.25 0-.477.233-.932.637-1.25zM62.563 41.958l-8.405-6.578a.854.854 0 00-1.172.118.785.785 0 00.122 1.13l8.406 6.58-8.396 6.563a.785.785 0 00-.122 1.131.854.854 0 001.171.12l8.395-6.564c.404-.317.638-.772.638-1.25 0-.477-.233-.932-.637-1.25z" />
        </g>
      </g>
    </svg>
  )
}

export default CustomCursor
