// @flow

import React, { useEffect, useState, useRef, useCallback } from "react"
import type { Node } from "react"
import Container from "./Container"
import Grid from "./Grid"
import Spacer from "./Spacer"
import { useLiterals } from "store/"
import slugify from "slugify"
import makeLayer from "../lib/makeLayer"
import shouldScrollTo from "../lib/shouldScrollTo"
import useIsMobile from '../hooks/useIsMobile'

import type { Ref } from "types/"
import throttle from "../lib/throttle"

type Item = {
  title: string,
  slug: string,
  menuId: string,
  items?: Array<Item>,
}
type Props = {
  items: {},
  textColor: string,
  textDefaultColor?: string,
  children: Node,
  alternativeMenuTitle?: string,
  gradientBlack?: number,
  textDefaultColor?: boolean,
}

const GRID_GAP = 16

const CategorySuperWrapperMenu = ({
  moduleName,
  id,
  children,
  items,
  textColor,
  textDefaultColor = false,
  alternativeMenuTitle,
  gradientBlack,
}: Props) => {
  const [currentMenuId, setCurrentMenuId] = useState(-1)
  const [currentHasGradient, setCurrentHasGradient] = useState(false)
  const [showSubitems, setShowSubitems] = useState(false)
  const titleRef: Ref = useRef(null)
  const contentRef: Ref = useRef(null)
  const container: Ref = useRef(null)
  const containerWrapperRef: Ref = useRef(null)
  const isMobile = useIsMobile()

  const selectedStyle = currentHasGradient
    ? { color: "#000" }
    : {
        color: `#${textColor}`,
      }

  const defaultStyle = currentHasGradient
    ? {
        color: "#000",
        borderColor: "#000",
      }
    : {
        color: `#${textDefaultColor}`,
        borderColor: `#${textDefaultColor}`,
      }

  const defaultBackgroundStyle = currentHasGradient
    ? {
        backgroundColor: "#000",
      }
    : {
        backgroundColor: `#${textDefaultColor}`,
      }

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-menuid="${menuId}"`)
    const yOffset = -100
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: "smooth" })
    }
  }

  const handleScroll = () => {
    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    const elements = document.querySelectorAll("[data-menuid]")
    let currentMenuId = -1
    elements.forEach(element => {
      const { top } = element.getBoundingClientRect()
      if (top <= h / 2) {
        currentMenuId = element.getAttribute("data-menuid")
      }
    })
    setCurrentMenuId(currentMenuId)

    const elements2 = document.querySelectorAll("[data-hasgradient]")
    let innerCurrentHasGradient = -1
    elements2.forEach(element => {
      const { top } = element.getBoundingClientRect()
      if (top <= h / 2) {
        innerCurrentHasGradient = element.getAttribute("data-hasgradient")
      }
    })
    const hasGradient = parseInt(innerCurrentHasGradient) === 1
    setCurrentHasGradient(hasGradient)
    const event = new CustomEvent("menu-in-gradient", {
      detail: { hasGradient },
    })
    document.dispatchEvent(event)
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split("#!")
      const lastPart = parts[parts.length - 1]
      if (lastPart.startsWith("#")) {
        const anchors = document.querySelectorAll("[data-anchor]")
        anchors.forEach(element => {
          const dataAnchor = element.getAttribute("data-anchor")
          if (lastPart === `#${dataAnchor}`) {
            setTimeout(() => {
              shouldScrollTo(`[data-anchor="${dataAnchor}"]`)
            }, 1000)
          }
        })
      } else {
        const parts2 = lastPart.split("_")
        if (parts2.length > 0) {
          setTimeout(() => {
            shouldScrollToId(parts2[0])
          }, 1000)
        }
      }
    }

    document.querySelectorAll('a[href^="#!"]').forEach(item => {
      item.addEventListener("click", e => {
        e.preventDefault()
        const fullId = `${item.getAttribute("href")}`
          .replace("#!", "")
          .split("_")
        if (fullId.length > 0) {
          shouldScrollToId(fullId[0])
        }
      })
    })

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    let title = ""
    Object.keys(items).map(itemKey => {
      const item = items[itemKey]
      if (item.menuId === currentMenuId) {
        title = item.title
      }
      item.items.forEach(subitem => {
        if (subitem.menuId === currentMenuId) {
          title = subitem.title
        }
      })
    })
    const url =
      currentMenuId !== -1
        ? `#!${currentMenuId}${
            title !== "" ? "_" + slugify(title, { lower: true }) : ""
          }`
        : "#"
    window.history.replaceState(null, null, url)
  }, [currentMenuId])

  const setTop = useCallback(throttle(() => {
    const mainMenuHeight = document.querySelector("#main-menu").offsetHeight
    const floatingMenuHeight = document.querySelector("#floating-menu-content")
      .offsetHeight
    const superWrapperMenuTitleHeight = document.querySelector(
      '[data-superwrappermenu="title"]'
    ).offsetHeight
    const titleHeight = titleRef.current.offsetHeight

    containerWrapperRef.current.style.paddingTop = isMobile
      ? 0
      : `${superWrapperMenuTitleHeight +
      GRID_GAP}px`
    container.current.style.top = `${mainMenuHeight +
      floatingMenuHeight +
      titleHeight}px`
  }, 1000), [isMobile])

  useEffect(() => {
    setTop()
    window.addEventListener("resize", setTop)
    return () => {
      window.removeEventListener("resize", setTop)
    }
  }, [isMobile])

  return (
    <div className={`h-full md:bg-transparent ${gradientBlack && gradientBlack === 1 ? "bg-realBlack" : "bg-black"}`} ref={containerWrapperRef} >
      <Spacer className="md:py-24 lg:py-24 relative h-full" smaller>
        <div
          className="z-50 md:block md:sticky md:top-0"
          ref={container}
        >
          <Container>
            <Grid>
              <div
                className={`col-span-4 col-start-1 relative ${!textDefaultColor ? "text-white" : ""} transition duration-500 pointer-events-auto md:col-span-2 md:col-start-1 text-smaller transition-color`}
                style={textDefaultColor ? defaultStyle : {}}
              >
                <div className="md:absolute inset-x-0 top-0 transform md:-translate-y-full">
                  <h4 className="pb-4  pt-8 text-big2" ref={titleRef}>
                    {alternativeMenuTitle && alternativeMenuTitle.length > 0
                      ? alternativeMenuTitle
                      : useLiterals("what_do_you_want_to_print")}
                  </h4>
                </div>
                <div ref={contentRef}>
                  {Object.keys(items).map(itemKey => {
                    const item = items[itemKey]
                    return (
                      <div
                        key={item.menuId}
                        className="py-2 border-t border-white"
                        style={textDefaultColor ? defaultStyle : {}}
                      >
                        <div
                          data-tag-name={`${moduleName}-menu-${itemKey}`}
                          dataLayer={makeLayer({
                            event: "e_contactClick",
                            linkPlacement: `product-${item.slug}`,
                            // linkID:`${moduleName}-menu-${itemKey}`,
                            linkID: "left-menu",
                          })}
                          style={
                            item.menuId === currentMenuId ||
                            `${currentMenuId}`.startsWith(item.menuId)
                              ? selectedStyle
                              : {}
                          }
                          onClick={() => {
                            setShowSubitems(!showSubitems)
                            shouldScrollToId(item.menuId)
                          }}
                          className="uppercase cursor-pointer hover:underline flex justify-start items-center w-full"
                        >
                          <div
                            className={`md:w-1/12 ${
                              item.menuId === currentMenuId ||
                              `${currentMenuId}`.startsWith(item.menuId)
                                ? "block md:mr-2"
                                : "hidden"
                            }`}
                          >
                            <div
                              className={`w-4 h-4 rounded-full mr-2`}
                              style={textDefaultColor ? defaultBackgroundStyle : {}}
                            />
                          </div>
                          <div className="md:w-10/12">{item.title}</div>
                        </div>
                        <div
                          className={`${
                            `${currentMenuId}`.startsWith(item.menuId) &&
                            showSubitems
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {item.items &&
                            item.items.length > 0 &&
                            item.items.map(subitem => {
                              return (
                                <div
                                  className="pb-4"
                                  key={`wrapper-${subitem.menuId}`}
                                >
                                  <span
                                    style={
                                      subitem.menuId === currentMenuId
                                        ? selectedStyle
                                        : {}
                                    }
                                    onClick={() =>
                                      shouldScrollToId(subitem.menuId)
                                    }
                                    className="inline-block ml-8 font-light cursor-pointer hover:underline"
                                    dataLayer={makeLayer({
                                      event: "e_contactClick",
                                      linkPlacement: `product-${item.slug}`,
                                      linkID: subitem.linkid,
                                    })}
                                  >
                                    {subitem.title}
                                  </span>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div>{children}</div>
              </div>
            </Grid>
          </Container>
        </div>
      </Spacer>
    </div>
  )
}

export default CategorySuperWrapperMenu
