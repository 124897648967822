import React from 'react'

const IconSamples = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="64.245"
      height="51.781"
      viewBox="0 0 64.245 51.781"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7617"
            data-name="Rectangle 7617"
            width="64.245"
            height="51.781"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_19224" data-name="Group 19224" transform="translate(0 0)">
        <g
          id="Group_19223"
          data-name="Group 19223"
          transform="translate(0 0.001)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_8990"
            data-name="Path 8990"
            d="M72.448,19.737a3.271,3.271,0,0,0-.967-2.317L60.639,6.767a3.185,3.185,0,0,0-2.245-.92H49.142a.809.809,0,0,0,0,1.617h8.851v8.163a3.157,3.157,0,0,0,3.141,3.164H70.5a1.61,1.61,0,0,1,.341.945v21.71A4.841,4.841,0,0,1,66.023,46.3H49.142a.809.809,0,0,0,0,1.618H66.023a6.455,6.455,0,0,0,6.425-6.472ZM59.6,15.628V8l9.329,9.169H61.134A1.542,1.542,0,0,1,59.6,15.628"
            transform="translate(-8.203 -0.993)"
            fill="currentColor"
          />
          <path
            id="Path_8991"
            data-name="Path 8991"
            d="M11.966,46.325H6.988A5.41,5.41,0,0,1,1.606,40.9V12.859A5.408,5.408,0,0,1,6.988,7.437h5.034a.809.809,0,0,0,0-1.618H6.988A7.022,7.022,0,0,0,0,12.859V40.9a7.023,7.023,0,0,0,6.988,7.042h4.979a.809.809,0,0,0,0-1.618"
            transform="translate(0 -0.988)"
            fill="currentColor"
          />
          <path
            id="Path_8992"
            data-name="Path 8992"
            d="M45.44,1.618A1.612,1.612,0,0,0,43.834,0H42.228a1.612,1.612,0,0,0-1.606,1.617V50.164a1.612,1.612,0,0,0,1.606,1.618h1.607a1.612,1.612,0,0,0,1.606-1.618Zm-3.212,0h1.606V50.164H42.228Z"
            transform="translate(-6.893 -0.001)"
            fill="currentColor"
          />
          <path
            id="Path_8993"
            data-name="Path 8993"
            d="M35.622,5.735a.737.737,0,1,1-.726.737.732.732,0,0,1,.726-.737m0-.83a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -0.833)"
            fill="#1a1718"
          />
          <path
            id="Path_8994"
            data-name="Path 8994"
            d="M29,5.141a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,5.141"
            transform="translate(-4.69 -0.873)"
            fill="#ffc400"
          />
          <path
            id="Path_8995"
            data-name="Path 8995"
            d="M23.377,5.613a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -0.953)"
            fill="#dd0063"
          />
          <path
            id="Path_8996"
            data-name="Path 8996"
            d="M18.222,5.819a.809.809,0,1,0,.8.81.807.807,0,0,0-.8-.81"
            transform="translate(-2.956 -0.988)"
            fill="#0088c4"
          />
          <path
            id="Path_8997"
            data-name="Path 8997"
            d="M35.622,11.681a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -1.983)"
            fill="#ffc400"
          />
          <path
            id="Path_8998"
            data-name="Path 8998"
            d="M29,11.917a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,11.917"
            transform="translate(-4.69 -2.023)"
            fill="#0088c4"
          />
          <path
            id="Path_8999"
            data-name="Path 8999"
            d="M23.377,12.389a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -2.103)"
            fill="#ffc400"
          />
          <path
            id="Path_9000"
            data-name="Path 9000"
            d="M18.227,12.625a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -2.143)"
            fill="#dd0063"
          />
          <path
            id="Path_9001"
            data-name="Path 9001"
            d="M35.622,18.456a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -3.132)"
            fill="#0088c4"
          />
          <path
            id="Path_9002"
            data-name="Path 9002"
            d="M29,19.522a.541.541,0,1,1-.531.541A.537.537,0,0,1,29,19.522m0-.83a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,18.692"
            transform="translate(-4.69 -3.172)"
            fill="#1a1718"
          />
          <path
            id="Path_9003"
            data-name="Path 9003"
            d="M23.377,19.164a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -3.252)"
            fill="#dd0063"
          />
          <path
            id="Path_9004"
            data-name="Path 9004"
            d="M18.227,19.4a.784.784,0,1,0,.778.784.78.78,0,0,0-.778-.784"
            transform="translate(-2.961 -3.292)"
            fill="#1a1718"
          />
          <path
            id="Path_9005"
            data-name="Path 9005"
            d="M35.622,25.7a1.568,1.568,0,1,0,1.556,1.568A1.562,1.562,0,0,0,35.622,25.7"
            transform="translate(-5.781 -4.362)"
            fill="#dd0063"
          />
          <path
            id="Path_9006"
            data-name="Path 9006"
            d="M29,25.939a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,25.939"
            transform="translate(-4.69 -4.402)"
            fill="#ffc400"
          />
          <path
            id="Path_9007"
            data-name="Path 9007"
            d="M23.377,27.241a.15.15,0,1,1-.143.149.146.146,0,0,1,.143-.149m0-.83a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -4.482)"
            fill="#1a1718"
          />
          <path
            id="Path_9008"
            data-name="Path 9008"
            d="M18.227,26.647a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -4.522)"
            fill="#ffc400"
          />
          <path
            id="Path_9009"
            data-name="Path 9009"
            d="M35.622,32.715a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -5.552)"
            fill="#0088c4"
          />
          <path
            id="Path_9010"
            data-name="Path 9010"
            d="M29,33.781a.541.541,0,1,1-.531.541A.537.537,0,0,1,29,33.781m0-.83a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,32.951"
            transform="translate(-4.69 -5.592)"
            fill="#1a1718"
          />
          <path
            id="Path_9011"
            data-name="Path 9011"
            d="M23.377,33.423a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -5.672)"
            fill="#dd0063"
          />
          <path
            id="Path_9012"
            data-name="Path 9012"
            d="M18.227,33.659a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -5.712)"
            fill="#0088c4"
          />
          <path
            id="Path_9013"
            data-name="Path 9013"
            d="M35.622,39.726a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -6.742)"
            fill="#ffc400"
          />
          <path
            id="Path_9014"
            data-name="Path 9014"
            d="M29,39.962a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,39.962"
            transform="translate(-4.69 -6.782)"
            fill="#dd0063"
          />
          <path
            id="Path_9015"
            data-name="Path 9015"
            d="M23.377,40.434a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -6.862)"
            fill="#0088c4"
          />
          <path
            id="Path_9016"
            data-name="Path 9016"
            d="M18.227,40.67a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -6.902)"
            fill="#1a1718"
          />
          <path
            id="Path_9017"
            data-name="Path 9017"
            d="M35.622,47.567a.737.737,0,1,1-.726.737.732.732,0,0,1,.726-.737m0-.83A1.568,1.568,0,1,0,37.178,48.3a1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -7.931)"
            fill="#1a1718"
          />
          <path
            id="Path_9018"
            data-name="Path 9018"
            d="M29,46.974a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,46.974"
            transform="translate(-4.69 -7.972)"
            fill="#0088c4"
          />
          <path
            id="Path_9019"
            data-name="Path 9019"
            d="M23.377,47.445a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -8.051)"
            fill="#dd0063"
          />
          <path
            id="Path_9020"
            data-name="Path 9020"
            d="M18.227,47.681a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -8.091)"
            fill="#ffc400"
          />
          <path
            id="Path_9021"
            data-name="Path 9021"
            d="M35.622,53.749a1.568,1.568,0,1,0,1.556,1.568,1.562,1.562,0,0,0-1.556-1.568"
            transform="translate(-5.781 -9.121)"
            fill="#dd0063"
          />
          <path
            id="Path_9022"
            data-name="Path 9022"
            d="M29,54.815a.541.541,0,1,1-.531.541A.537.537,0,0,1,29,54.815m0-.83a1.372,1.372,0,1,0,1.362,1.372A1.366,1.366,0,0,0,29,53.985"
            transform="translate(-4.69 -9.161)"
            fill="#1a1718"
          />
          <path
            id="Path_9023"
            data-name="Path 9023"
            d="M23.377,54.457a.98.98,0,1,0,.972.98.977.977,0,0,0-.972-.98"
            transform="translate(-3.802 -9.241)"
            fill="#ffc400"
          />
          <path
            id="Path_9024"
            data-name="Path 9024"
            d="M18.227,54.693a.784.784,0,1,0,.778.784.781.781,0,0,0-.778-.784"
            transform="translate(-2.961 -9.281)"
            fill="#0088c4"
          />
        </g>
      </g>
    </svg>
  )
}

export default IconSamples
