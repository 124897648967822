// @flow

import React from 'react'
import Spacer from './Spacer'
import type { ApiImage } from '../types'
import smoothscroll from 'smoothscroll-polyfill'
import FeaturedWebinars from './FeaturedWebinars'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}
type Item = {
  image: ApiImage,
  id: string,
  title: string,
  slug: string,
  supertitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  ctaText2: string,
  ctaUrl2: string,
  ctaText3: string,
  ctaUrl3: string,
  video: string,
  type: string,
  component: string,
}
type Props = {
  webinars: Array<Item>,
  country: string,
  language: string,
}

const Webinars = ({ webinars, country, type, language }: Props) => {
  return (
    <Spacer>
      {webinars
        .sort((a, b) => {
          return parseInt(a.position) - parseInt(b.position)
        })
        .map(webinar => (
          <FeaturedWebinars
            key={webinar.id}
            type={type}
            country={country}
            language={language}
            items={[webinar]}
            component="content"
          />
        ))}
    </Spacer>
  )
}

export default Webinars
