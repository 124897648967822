// @flow

import React from 'react'

type Props = { url: string }

const IconLinkedIn = ({ url }: Props) => {
  return (
    <a
      href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_7540"
              width="20"
              height="20"
              transform="translate(1066 132)"
            />
          </clipPath>
        </defs>
        <g
          id="linkedin"
          transform="translate(-1066 -132)"
          clipPath="url(#clip-path)"
        >
          <path
            id="iconmonstr-linkedin-3"
            d="M15.833,0H4.167A4.167,4.167,0,0,0,0,4.167V15.833A4.167,4.167,0,0,0,4.167,20H15.833A4.167,4.167,0,0,0,20,15.833V4.167A4.167,4.167,0,0,0,15.833,0ZM6.667,15.833h-2.5V6.667h2.5ZM5.417,5.61A1.47,1.47,0,1,1,6.875,4.14,1.465,1.465,0,0,1,5.417,5.61Zm11.25,10.223h-2.5v-4.67c0-2.807-3.333-2.594-3.333,0v4.67h-2.5V6.667h2.5V8.137C12,5.983,16.667,5.823,16.667,10.2Z"
            transform="translate(1066 132)"
            color="currentColor"
          />
        </g>
      </svg>
    </a>
  )
}

export default IconLinkedIn
