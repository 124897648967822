// @flow
import React, { useRef, useState, useEffect } from 'react'
import type { Ref } from 'types/'

type Props = {
  children: React$Node,
  className?: string,
  style?: Object,
  finalOpacity?: string,
}

let observer

const Appear = ({ className, children, style, finalOpacity }: Props) => {
  const [show, setShow] = useState(false)
  const [isEdge, setIsEdge] = useState(null)
  const mainRef: Ref = useRef(null)

  useEffect(() => {
    if (document.documentMode ||
      /Edge/.test(navigator.userAgent) ||
      /Edg/.test(navigator.userAgent)) 
    {
      setIsEdge(true)
    } else {
      setIsEdge(false)
    }
  }, [])

  useEffect(() => {
    if (show) {
      observer.unobserve(mainRef.current)
    }
  }, [show])

  useEffect(() => {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShow(true)
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1
      }
    )
    if (mainRef.current) {
      observer.observe(mainRef.current)
    }
  }, [isEdge])

  return (
    <div
      ref={mainRef}
      style={style}
      className={`${
        className ? className : ''
      } transition transform transition-normal duration-normal ${
        show || isEdge ? `opacity-${finalOpacity ? finalOpacity : '100'} translate-y-0` : 'opacity-0 translate-y-8'
      }`}
    >
      {children}
    </div>
  )
}

export default Appear
