// @flow
import React from 'react'
import * as Showdown from 'showdown'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

type Props = {
  text: string,
  md?: boolean,
  customSize?: boolean
}

const Html = ({ text, customSize = false, md = true }: Props): React$Node => {
  let parsedText = text
  if (text && text.length > 0 && md) {
    parsedText = converter.makeHtml(text)
    if (!parsedText) parsedText = ''
    parsedText = parsedText.replace(/[ ]*\n/g, "<br />\n")
    parsedText = parsedText.replace(/[ ]*<\/li><br \/>/g, "</li>")
    parsedText = parsedText.replace(/[ ]*<ul><br \/>/g, "<ul>")
  }
  return (
    <span className="contains-html" dangerouslySetInnerHTML={{ __html: parsedText }} />
  )
}

export default Html