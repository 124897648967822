// @flow
import React from 'react'
// import Appear from './Appear'
import Viewer360 from './Viewer360'

type Props = {
  image360: Array<string>,
  parent: React$Node,
  icon: boolean,
  iconPosition?: "top" | "bottom",
}

const ScrollViewer = ({ image360, parent, icon, iconPosition = "bottom" }: Props) => {
  return (
    <Viewer360
      images={image360}
      parent={parent}
      loadedCanvas={true}
      scrollController={true}
      icon={icon}
      iconPosition={iconPosition}
    />
  )
}

export default ScrollViewer
