// @flow

import React, { useMemo } from 'react'
import Html from './Html'
import Spacer from './Spacer'
import Grid from './Grid'
import Appear from './Appear'

type Props = {
  title: string,
  supertitle: string,
  text: string,
  position: string,
  type: string,
}

const StoryBlock6 = ({ title, supertitle, text, type }: Props) => {
  const isPost = useMemo(() => type === 'post', [type])

  const mdColClass = useMemo(() => {
    if (isPost) return 'md:col-start-1 md:col-span-12'
    if (['story', 'solution'].includes(type))
      return 'md:col-start-3 md:col-span-9'
    return 'md:col-start-4 md:col-span-9'
  }, [type, isPost])
  return (
    <Spacer bottom={isPost} smaller>
      <Appear>
        <Grid>
          <div className={`col-start-1 col-span-4 ${mdColClass}`}>
            {supertitle && <h4 className={`mb-8`}>{supertitle}</h4>}
            <h2 className={`mb-8 text-big2 font-light`}>{title}</h2>
            <div className={`font-light`}>
              <Html text={text} />
            </div>
          </div>
        </Grid>
      </Appear>
    </Spacer>
  )
}

export default StoryBlock6
