// @flow

import React from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import PrinterShoppableItem from './PrinterShoppableItem'

type Props = {
  items: any,
  parentName: string,
}

const PrinterShoppable = ({
  items,
  parentName
}: Props) => {

  console.log(items)

  const gridElements = items.map(item => <div key={item.key} className={`col-span-4 mb-4 ${parentName === 'CategoryWrapper' ? 'md:col-span-6 lg:col-span-4' : 'md:col-span-4 lg:col-span-3'}`}>
    <PrinterShoppableItem
      theID={item.theID}
      hpRef={item.hpRef}
      managerRef={item.ref}
      image={item.image}
      numberOfStars={item.numberOfStars}
      price={item.price}
      anchorPrice={item.anchorPrice}
      title={item.title}
      text={item.text}
      techSpecsURL={item.techSpecsURL}
      numberOfStarsURL={item.numberOfStarsURL}
      buyURL={item.buyURL}
      buyText={item.buyText}
      unitsLeft={item.unitsLeft}
      cashbackTitle={item.cashbackTitle}
      cashbackUrl={item.cashbackUrl}
      linkid={item.linkid}
      />
  </div>)

  const result = gridElements.reduce(function(result, value, index, array) {
    if (index % 2 === 0)
    result.push(array.slice(index, index + 2))
    return result
  }, [])
  let firstBlockSylte = 'hidden md:block md:col-span-3 lg:col-span-3'
  if (parentName === 'CategoryWrapper') {
    firstBlockSylte = 'hidden'
  } else if (parentName === 'PostWrapper') {
    firstBlockSylte = 'hidden md:block md:col-span-2 lg:col-span-3'
  }
  result.forEach((pair, index) => {
    pair.unshift(<div key={`invisible-${index+1}`} className={`${firstBlockSylte}`}  ></div>)
    pair.push(<div key={`invisible-${index+1*100}`} className={`${parentName === 'CategoryWrapper' ? 'hidden' : 'hidden lg:block lg:col-span-3'}`}  ></div>)
  })

  const content = (
    <Grid>
      {[...result]}
    </Grid>
  )

  if (parentName === 'CategoryWrapper') {
    return (
      <Spacer top bottom>
        <Container className="relative">
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4 md:mb-0">
              {content}
            </div>
          </Grid>
        </Container>
      </Spacer>
    )
  }

  return (
    <Spacer top bottom>
      <Container className="relative">
        {content}
      </Container>
    </Spacer>
  )
}

export default PrinterShoppable
