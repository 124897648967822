// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import LionRedSquare from './LionRedSquare'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'



type Props = {
title: string,
image: ApiImage,
}

const LionTitleImage = ({
    title,
    image
}: Props) => {
const isMobile = useIsMobile()
  return (
    <div className="relative bg-realBlack">
        <div className="absolute bottom-0 left-0 right-0 bg-white h-1/4 md:h-1/2">
            <Container>
                <Grid noGap>
                    <div className="hidden md:block md:col-span-2 md:col-start-2 md:row-start-1">
                        <Appear>
                            <LionRedSquare />
                        </Appear>
                    </div>
                    <div className="hidden md:block md:col-span-1 md:col-start-4 md:row-start-2">
                        <Appear>
                            <LionRedSquare />
                        </Appear>
                    </div>
                </Grid>
            </Container>
        </div>
        <Container className="mb-16">
            <Grid noGap>
                <div className="flex justify-end col-span-1 col-end-5 mt-4 md:row-start-1 md:mt-0 md:col-end-13 md:block">
                    <Appear>
                        <LionRedSquare width={isMobile && 50} />
                    </Appear>
                </div>
                <div className="col-span-4 row-start-2 my-8 md:col-start-2 md:my-0">
                    <Appear>
                        <h2 className="font-light leading-tight text-white text-3.5xl md:text-50px break-words">
                            <Html text={title} />
                        </h2>
                    </Appear>
                </div>
                <div className="flex justify-end row-start-3 md:col-span-1 md:col-start-6 md:row-start-4 md:block">
                    <Appear>
                        <LionRedSquare />
                    </Appear>
                </div>
                <div className="col-span-3 col-start-2 row-start-4 md:row-start-2 md:col-span-6 md:col-start-7 md:row-span-6">
                    <Appear>
                        <LazyImage image={image} />
                    </Appear>
                </div>
                <div className="flex justify-end row-start-5 md:col-span-1 md:col-start-6 md:row-start-8 md:block">
                    <Appear>
                        <LionRedSquare width={isMobile && 50} />
                    </Appear>
                </div>
            </Grid>
        </Container>
    </div>
  )
}

export default LionTitleImage