// @flow
import React from 'react'
import RelatedPrinters from './RelatedPrinters'
import Appear from './Appear'
import Bubble from './Bubble'
import Spacer from './Spacer'
import makeAssetUrl from '../lib/makeAssetUrl'

type FeaturedPrintersType = {
  id: string,
  image: Object,
  title: string,
  url: string
}

type Props = {
  title: string,
  text: string,
  url: string,
  printers: Array<FeaturedPrintersType>,
  texture: Object
}

const DesignJet6 = (props: Props) => {
  return (
    <Spacer bottom className="pt-64">
      <div className="relative">
        <RelatedPrinters
          {...props}
          labelKey="view_all_the_printers"
          noBg={true}
          textBlack={true}
        />
        <Appear className="w-64 h-64 -left-35p -top-40p absolute md:h-110 md:w-110 md:-left-25p md:top-0 lg:-left-15p">
          <Bubble className="w-full h-full" src={makeAssetUrl({ asset: props.texture, isImage: true})} />
        </Appear>
      </div>
    </Spacer>
  )
}

export default DesignJet6
