// @flow

import React from 'react'
import Spacer from './Spacer'

type Props = {
  children: React$Node,
  gradientStart?: String,
  gradientEnd?: String,
  cleanBottomSpacer?: number,
}

const CategoryWrapper = ({ children, gradientStart = 'ffffff', gradientEnd = 'ffffff', cleanBottomSpacer = 0 }: Props) => {
gradientStart="ffffff"
gradientEnd="ffffff"
  return (
    <div
      className="relative bg-white"
      // data-hasgradient={gradientStart && gradientStart.length > 0 && gradientEnd.length > 0 && gradientEnd !== gradientStart ? '1' : '0'}
      style={gradientStart && gradientStart.length > 0 && gradientEnd.length > 0 ? {
      backgroundImage: `linear-gradient(-45deg, #${gradientStart.replace('#', '')} 0%, #${gradientEnd.replace('#', '')} 100%)`
      } : {backgroundColor: `#${gradientStart.replace('#', '')}`}}
    >
      <Spacer
        top smaller bottom={cleanBottomSpacer === 0}
      >
        <div>
          {children}
        </div>
      </Spacer>
    </div>
  )
}

export default CategoryWrapper