// @flow
import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import Html from './Html'
import LazyImage from './LazyImage'
import LinkHP from './LinkHP'
import { Link } from 'gatsby'

type Props = {
  text: string,
  title: string,
  url: string,
  image: ApiImage,
}

const Blog1 = ({ text, title, image, url }: Props) => {
  if (!text && !title && !image) return null

  return (
    <Spacer top bottom smaller>
      <Container>
        <div className="flex md:items-center">
          <div className="flex-1">
            <LinkHP to={url}>
              <h3 className="text-big3"><Html text={`“${text}”`}/></h3>
              <div className="mt-4">{title}</div>
            </LinkHP>
          </div>
          <div className="flex-1">
            <LazyImage image={image} alt={title} />
          </div>
        </div>
      </Container>
    </Spacer>
  )
}

export default Blog1