// @flow

import React from 'react'
import Html from './Html'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'

type Props = {
  title: string,
  supertitle: string,
  text: string,
  position: string,
  type: string
}

const StoryBlock6 = ({ title, supertitle, text, type }: Props) => {
  return (
    <Spacer smaller>
      <Container>
        <Appear>
          <Grid>
            <div
              className={`col-span-4 col-start-1 ${type === "story" ? 'md:col-start-3' : 'md:col-start-4'} md:col-span-9`}
            >
              {supertitle && <h4 className={`mb-8`}>{supertitle}</h4>}
              <h2 className={`mb-8 text-big2 font-light`}>{title}</h2>
              <div className={`font-light`}>
                <Html text={text} />
              </div>
            </div>
          </Grid>
        </Appear>
      </Container>
    </Spacer>
  )
}

export default StoryBlock6
