// @flow

import React from 'react'
import Html from './Html'

import Appear from './Appear'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'

type Props = {
  text: string,
}

const StoryBlock3 = ({ extraProps, text }: Props) => {
  return extraProps.isBlogPost ? (
    <Appear>
      <div className="mb-4">
        <div className="font-light break-words md:break-normal text-16px md:text-20px">
          <Html text={text} md={true} />
        </div>
      </div>
    </Appear>
  ) : (
    <Spacer>
      <Container>
        <Appear>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-start-3 md:col-span-8 mb-4">
              <div className="font-light break-words md:break-normal">
                <Html text={text} md={true} />
              </div>
            </div>
          </Grid>
        </Appear>
      </Container>
    </Spacer>
  )
}

export default StoryBlock3
