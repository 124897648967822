// @flow
import React, { useEffect, useRef, useState } from 'react'
import useIsMobile from '../hooks/useIsMobile'
import {
  updateRequestAnimationFrameReference,
  loadImages,
  optimizeScrollPlay
} from '../lib/scrollVideoHelpers'
const config = require('../../config')

const menuHeight = 120

type Props = {
  folder: string,
  folderMobile: string,
  numberOfImages: string,
  videoDuration: string,
  backgroundColor: string,
}

const ScrollVideo = ({
  folder,
  folderMobile,
  numberOfImages,
  videoDuration,
  backgroundColor,
}: Props) => {
  const canvasRef = useRef(null)
  const canvasRefMobile = useRef(null)
  const canvasWrapperRef = useRef(null)
  const containerVideoRef = useRef(null)
  const scrollDurationRef = useRef(null)
  const [loadingMetadata, setLoadingMetadata] = useState(false)
  const isMobile = useIsMobile()
  const [currentFolder, setCurrentFolder] = useState()
  const [videoOver, setVideoOver] = useState(false)
  const [animationId, setAnimationId] = useState()
  const [images, setImages] = useState([])
  const [imagesMobile, setImagesMobile] = useState([])

  useEffect(() => {
    if (isMobile) {
      setCurrentFolder(`${config.scrollVideoUrl}/${folderMobile}`)
    } else if (isMobile !== undefined) {
      setCurrentFolder(`${config.scrollVideoUrl}/${folder}`)
    }
  }, [isMobile])

  useEffect(() => {
    if (isMobile === undefined) return
    if (!isMobile && loadingMetadata) return
    scrollDurationRef.current =
      (videoDuration / 25) * document.documentElement.scrollHeight
    setLoadingMetadata(true)
    loadImages({
      setImages,
      setImagesMobile,
      setLoadingMetadata,
      currentFolder,
      numberOfImages,
      isMobile,
    })
  }, [currentFolder])    

  useEffect(() => {
    if (animationId) window.cancelAnimationFrame(animationId)

    const { requestAnimationFrame, cancelAnimationFrame } = updateRequestAnimationFrameReference(window)
    if (requestAnimationFrame) window.requestAnimationFrame = requestAnimationFrame
    if (cancelAnimationFrame) window.cancelAnimationFrame = cancelAnimationFrame

    optimizeScrollPlay({
      scrollDurationRef,
      containerVideoRef,
      canvasRef: isMobile ? canvasRefMobile : canvasRef,
      numberOfImages,
      currentFolder,
      setVideoOver,
      menuHeight,
      canvasWrapperRef,
      canvasRefMobile,
      isMobile,
      images,
      imagesMobile,
    }).then(fn => {
      const windowAnimationId = window.requestAnimationFrame(fn)
      setAnimationId(windowAnimationId)

      return () => {
        window.cancelAnimationFrame(fn)
      }
    })
  }, [
    // Charge when 100 images are loaded
    images.length > 100,
    imagesMobile.length > 100,
    // Recharge when all images are loaded
    images.length === Number(numberOfImages),
    imagesMobile.length === Number(numberOfImages),
  ])

  return (
    <div
      style={{ backgroundColor: videoOver ? '#F3F3F3' : backgroundColor }}
      ref={containerVideoRef}
      className="relative transition-all duration-500 ease-in"
    >
      <div
        ref={canvasWrapperRef}
        className="sticky left-0 flex items-center justify-center h-screen"
        style={{ top: menuHeight / 2 + 'px' }}
      >
        {isMobile ? (
          <canvas
            ref={canvasRefMobile}
            className="object-cover w-screen h-screen"
          />
        ) : (
          <canvas
            ref={canvasRef}
            className="absolute object-cover w-screen h-screen"
          />
        )}
      </div>
      <div style={{ height: scrollDurationRef?.current * 1.2 }}></div>
    </div>
  )
}

export default ScrollVideo
