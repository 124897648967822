// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from '../types'

type Props = {
  title: string,
  text: string,
  menuId?: string,
  nav?: {next: string},
  gradientStart: string,
  gradientEnd: string,
  hasGradient: boolean,
  whiteParentBG?: boolean,
  backgroundColor?: string,
  cleanBottomSpacer?: boolean,
}

const CategoryBlock2Image = ({
  nav,
  menuId,
  title,
  text,
  hasGradient = false,
  gradientStart = '',
  gradientEnd = '',
  whiteParentBG = false,
  image,
  backgroundColor,
  cleanBottomSpacer = false,
}: Props) => {

  const style = hasGradient ? {
    position: 'relative',
    color: '#fff',
    background: `linear-gradient(-90deg, #${gradientStart.replace(
      '#',
      ''
      )} 0%, #${gradientEnd.replace('#', '')}  100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  } : {}

  return (
    <div style={{ backgroundColor: backgroundColor ? backgroundColor : 'auto' }} data-menuid={menuId} data-hasgradient={backgroundColor === "#ffffff" ? 1 : 0}>
      <Spacer top bottom={cleanBottomSpacer ? false : (typeof nav !== "undefined" && nav.next !== 'CategoryBlock3' || text && text.length > 0)}>
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 md:col-span-5 md:col-start-4 md:mr-8">
              <div className={`mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:mb-0`}>
                <Appear><h2 style={style} className={`${(text.length > 0 || typeof nav !== "undefined" && nav.next === 'CategoryBlock3') ? 'mb-4 md:mb-12' : ''} text-big3`}>{title}</h2></Appear>
              </div>
              {typeof text === "string" && text !== "" &&
                <div className={`col-span-4 col-start-1 mb-6 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-9 md:col-start-4 md:mb-0`}>
                  <div className="font-light"><Appear><Html text={text} /></Appear></div>
                </div>
              }
              </div>
            <div className="col-span-4 md:col-span-4 md:col-end-13 md:mt-12">
              <LazyImage image={image}  />
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock2Image