const querystring = require('querystring')

const post = async (url, body) => {
  const data = new FormData()

  Object.keys(body).forEach(field => {
    data.append(field, body[field])
  })

  const requestConfig = {
    method: 'POST',
    body: data,
    mode: 'cors'
  }

  try {
    const response = await fetch(
      url,
      requestConfig
    )
    return (response.status === 200)
  } catch (err) {
    return false
  }
}

const getter = async (rootUrl, params) => {
  const urlParams = querystring.stringify(params)
  const url = `${rootUrl.trim()}?${urlParams}`

  const requestConfig = {
    method: 'GET',
    mode: 'cors'
  }


  try {
    const response = await fetch(
      url,
      requestConfig
    )

    return response.json()
  } catch (err) {
    console.error(err)
    return false
  }
}

export { post, getter }
