// @flow

import React from 'react'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  image: {},
  position: string
}

const BackgroundImage = ({ image, position }: Props) => {
  const marginTop = -150
  const top = position === '100' ? 'unset' : `${position}%`
  return (
    <div
      style={{
        overflow: 'visible',
        position: 'absolute',
        height: 'auto',
        width: '100%',
        marginTop: parseInt(position) === 0 ? marginTop : 0,
        zIndex: -10,
        top,
        bottom: position === '100' ? '0%' : 'unset'
      }}
    >
      <img src={makeAssetUrl({ asset: image, isImage: true })} className="w-full h-auto" />
    </div>
  )
}

export default BackgroundImage
