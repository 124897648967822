// @flow

import React from 'react'
import SimpleHeaderImage from './SimpleHeaderImage'

type Props = {
  title: string,
  type?: string,
  childAfterMenu?: Boolean,
}

const StoryBlock0 = ({ title, type }: Props) => {
  return (
    <div className="bg-white h-0 mt-0">
      {
        <SimpleHeaderImage
          type="stories"
          big={type !== 'post'}
          title={title}
          postType={type}
          taller={true}
        />
      }
    </div>
  )
}

export default StoryBlock0
