import { useEffect } from "react"
import throttle from '../lib/throttle'
import useIsMobile from '../hooks/useIsMobile'

const Bax = () => {

  const isMobile = useIsMobile()

  let elements = []

  useEffect(() => {

    handleResize()

    if (isMobile) return

    elements = Array.from(document.querySelectorAll('[data-bax]'))

    if (elements.length > 0) {
      makeBreakpoints()
      startListening()
    }

    document.addEventListener('resize', handleResizeThrottled)

    return function cleanup() {
      document.removeEventListener('resize', handleResizeThrottled)
      if (elements.length > 0) {
        stopListening()
      }
    }
  })

  const startListening = () => {
    document.addEventListener('scroll', handleScroll)
  }

  const stopListening = () => {
    document.removeEventListener('scroll', handleScroll)
  }

  const makeBreakpoints = () => {
    elements.forEach(element => {
      applyDefaultStyles(element)
    })
  }

  const applyDefaultStyles = (element) => {
    element.style['backface-visibility'] = 'hidden'
    element.style['-webkit-backface-visibility'] = 'hidden'
    element.style['will-change'] = 'transform'
  }

  const handleResize = () => {
    if (isMobile) {
      stopListening()
    } else {
      startListening()
    }
  }

  const handleResizeThrottled = throttle(() => handleResize(), 100)

  const handleScroll = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    elements.forEach(element => {
      const { top, height } = element.getBoundingClientRect()
      const t = top - height
      const realT = top - windowHeight
      if (realT < 0 && Math.abs(realT) < Math.abs(windowHeight + height)) {
        const percentage = Math.abs(realT) /  Math.abs(windowHeight + height)
        transitionForElement(element, percentage)
      }
    })
  }

  const transitionForElement = (element, percentage) => {
    if (!element) return
    const [start, end] = element.getAttribute('data-bax').split(',').map(p => parseFloat(p))
    const value = start + ((end - start) * percentage)
    element.style['transform'] = `translate3d(0, ${value}%, 0)`
  }

  return null
}

export default Bax
