import { useState, useEffect } from "react"
import getAllUrlParams from './getAllUrlParams'

const urlparameters = (url, attachedParams, callback) => {
  const [urlWithParams, setUrlWithParams] = useState(url)

  useEffect(() => {

    const params = getAllUrlParams(url)
    
    const currentURLParams = new URLSearchParams(window.location.search)

    attachedParams.forEach(param => {
      if (currentURLParams.has(param)) {
        params[param] = currentURLParams.get(param)
      }
    })

    const urlParams = []
    Object.keys(params).forEach(key => {
      urlParams.push(`${key}=${params[key]}`)
    })

    
    if (url) {
      const finalUrl = `${url.split('?')[0]}${urlParams.length > 0 ? `?${urlParams.join('&')}` : ''}`
      setUrlWithParams(finalUrl)
      callback && callback(finalUrl)
    }
  }, [])

  return urlWithParams
}

export default urlparameters