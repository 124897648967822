// @flow
import React from 'react'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import LazyImage from './LazyImage'
import Youtube from './Youtube'
import Html from './Html'
import type { ApiImage } from 'types/'

type Props = {
  items: Array<Object>,
  text: string,
  lastReversed: boolean,
  videoUrl: string,
  videoImage: ApiImage
}

const getPosition = (index: number, length: number, reversed: boolean) => {
  switch (index) {
    case 0:
      return `col-start-2 col-span-2 row-start-1 md:col-start-2 md:col-span-4 md:row-start-1 md:self-end md:mt-64`
    case 1:
      return `col-start-1 col-span-2 row-start-2 md:col-start-3 md:col-span-4 md:row-start-2 md:self-end`
    case 2:
      return `col-start-2 col-span-3 row-start-3 md:col-start-7 md:col-span-6 md:row-start-1 md:row-span-2 md:self-end`
    case 3:
      return `col-start-1 col-span-2 row-start-4 md:row-start-3 md:col-span-4`
    case 4:
      return `col-start-3 col-span-2 row-start-4 md:row-start-3 md:col-span-4`
    case 5:
      return `col-start-1 col-span-2 row-start-5 md:row-start-3 md:col-span-4`
    case 6:
      if (length <= 7) {
        if (reversed) {
          return `col-start-2 col-span-3 row-start-6 md:row-start-4 md:col-start-5 md:col-span-7 md:row-span-2`
        }
        return `col-start-2 col-span-3 row-start-6 md:row-start-4 md:col-start-1 md:col-span-4 md:row-span-2`
      }
      return `col-start-2 col-span-3 row-start-6 md:row-start-4 md:col-start-1 md:col-span-7 md:row-span-2`
    case 7:
      if (length <= 7) {
        if (reversed) {
          return `col-start-2 col-span-3 row-start-6 md:row-start-4 md:col-start-1 md:col-span-4 md:row-span-2`
        }
        return `col-start-2 col-span-3 row-start-6 md:row-start-4 md:col-start-5 md:col-span-7 md:row-span-2`
      }
      return `col-start-1 col-span-2 row-start-7 md:row-start-4 md:col-start-8 md:col-span-5`
    case 8:
      return `col-start-3 col-span-2 row-start-7 md:row-start-6 md:col-start-3 md:col-span-5`
    case 9:
      return `col-start-1 col-span-2 row-start-8 mb-8 md:row-start-5 md:col-span-5 md:col-start-8 md:row-span-2`
  }
}

const DesignJet5 = ({
  items,
  text,
  lastReversed,
  videoUrl,
  videoImage
}: Props) => {
  return (
    <Spacer top>
      <Container className="relative overflow-hidden md:overflow-visible">
        <Grid>
          <div className="col-span-4 col-start-1 md:col-start-2 md:col-span-10">
            <Youtube
              url={videoUrl}
              id={Math.random() * 150}
              image={videoImage}
            />
          </div>
        </Grid>
        <Grid className="relative w-full overflow-visible">
          {items.map((item, index, arr) => {
            return (
              <Appear
                key={index}
                className={getPosition(index, arr.length, lastReversed)}
              >
                <LazyImage alt={text} image={item.image} />
              </Appear>
            )
          })}
          {text && (
            <Appear className="z-10 col-span-4 col-start-1 font-light text-smaller md:col-start-2 md:col-span-10 md:mt-8">
              <Html text={text} />
            </Appear>
          )}
        </Grid>
      </Container>
    </Spacer>
  )
}

export default DesignJet5
