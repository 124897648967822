// @flow
import React, { useState, useEffect, useRef } from 'react'
import jwt from 'jwt-simple'
import simpleTokenSecret from '../lib/simpleTokenSecret'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Button from './Button'
import { useLiterals, useMedia } from 'store/'
import type { ApiImage, Ref } from '../types'
import useIsMobile from '../hooks/useIsMobile'
import smoothscroll from 'smoothscroll-polyfill'
import Html from './Html'
import { getCookie } from '../lib/cookies'
import makeLayer from '../lib/makeLayer'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}
type Item = {
  image: ApiImage,
  id: string,
  title: string,
  slug: string,
  supertitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  ctaText2: string,
  ctaUrl2: string,
  ctaText3: string,
  ctaUrl3: string,
  ctaText4: string,
  ctaUrl4: string,
  ctaText5: string,
  ctaUrl5: string,
  video: string,
  language: string,
  linkid: string,
  position: string,
}
type Props = {
  country: string,
  items: Array<Item>,
  anchor: string,
}

const drag = {
  active: false,
  start: 0,
  scrollLeft: 0,
  current: 0,
}

const FeaturedWebinars = ({
  country,
  items,
  anchor,
}: Props): React$Element<'div'> => {
  const slider: Ref = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isMobile = useIsMobile()
  const [currentMenuId, setCurrentMenuId] = useState(-1)
  const [renderedItems, setRenderedItems] = useState(false)
  const sliderBackgroundColors = [
    {
      primary: 'bg-greenSld',
      secondary: 'bg-greenLightSld',
    },
    {
      primary: 'bg-cyanSld',
      secondary: 'bg-cyanLightSld',
    },
    {
      primary: 'bg-yellowSld',
      secondary: 'bg-yellowLightSld',
    },
    {
      primary: 'bg-orangeSld',
      secondary: 'bg-orangeLightSld',
    },
    {
      primary: 'bg-purpleSld',
      secondary: 'bg-purpleLightSld',
    },
    {
      primary: 'bg-springGreenSld',
      secondary: 'bg-springGreenLightSld',
    },
    {
      primary: 'bg-blueSld',
      secondary: 'bg-blueLightSld',
    },
  ]
  const getSliderBackground = index => {
    const arrayLength = sliderBackgroundColors.length
    const indexToUse = index % arrayLength
    return sliderBackgroundColors[indexToUse]
  }

  const handleSlider = (direction: string): void => {
    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    switch (direction) {
      case 'forwards':
        if (nextIndex < items.length - 1) {
          nextIndex += 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth',
          })
          setCurrentIndex(nextIndex)
        }
        break
      case 'backwards':
        if (nextIndex > 0) {
          nextIndex -= 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: 'smooth',
          })
          setCurrentIndex(nextIndex)
        }
        break
    }
  }

  const slideTo = (index: number): void => {
    const childW = slider.current.children[0].offsetWidth
    slider.current.scrollTo({
      left: index * childW,
      behavior: 'smooth',
    })
    setCurrentIndex(index)
  }

  const handleDrag = e => {
    if (items.length < 2) return
    let pX = e.pageX
    if (e.type.includes('touch') && !e.type.includes('touchend')) {
      pX = e.touches[0].pageX
    }
    let nextIndex = currentIndex
    switch (e.type) {
      case 'mousedown':
        // case 'touchstart':
        slider.current.classList.remove('snap-x-mandatory')
        drag.active = true
        drag.start = pX - slider.current.offsetLeft
        drag.scrollLeft = slider.current.scrollLeft
        break
      case 'mouseup':
        // case 'touchend':
        drag.active = false
        if (
          drag.scrollLeft < slider.current.scrollLeft &&
          nextIndex < items.length - 1
        ) {
          nextIndex++
          slideTo(nextIndex)
        }
        if (drag.scrollLeft > slider.current.scrollLeft && nextIndex > 0) {
          nextIndex--
          slideTo(nextIndex)
        }
        break
      case 'mousemove':
        // case 'touchmove':
        if (drag.active) {
          const x = pX - slider.current.offsetLeft
          // El 2 es arbitrario. Define la velocidad en la que hace el scroll
          const walk = (x - drag.start) * 2
          slider.current.scrollLeft = drag.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const handleSliderScroll = ({ target }) => {
    if (items.length < 2) return
    if (isMobile) {
      if (!target.scrollLeft) {
        setCurrentIndex(0)
        return
      }
      const position =
        (target.offsetWidth * items.length) / target.scrollLeft - 1
      if (Number.isInteger(position) && position <= items.length) {
        setCurrentIndex(position)
      }
    }
  }

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-name="${menuId}"`)
    const yOffset = -200

    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  const handleScroll = () => {
    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    const elements = document.querySelectorAll(
      '[data-name="featured-webinars"]'
    )
    let currentMenuId = -1
    elements.forEach(element => {
      const { top, bottom } = element.getBoundingClientRect()
      if (top <= h / 2 && bottom > 0) {
        currentMenuId = element.getAttribute('data-name')
      }
    })
    setCurrentMenuId(currentMenuId)
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart.length > 0 && lastPart.includes('featured')) {
        setTimeout(() => {
          shouldScrollToId('featured')
        }, 1000)
      }
    }

    if (typeof window !== 'undefined') {
      setRenderedItems(
        <div
          className={`flex h-full overflow-hidden bg-lightBeige22 w-full ${
            items.length > 1 ? 'cursor-pointer bg-localactive:cursor-grab' : ''
          } md:overflow-x-hidden slider border-b`}
          ref={slider}
          onMouseDown={handleDrag}
          onMouseMove={handleDrag}
          onMouseUp={handleDrag}
          onScroll={handleSliderScroll}
        >
          {items.map(
            (
              {
                id,
                image,
                slug,
                title,
                supertitle,
                text,
                ctaText,
                ctaUrl,
                video,
                ctaText2,
                ctaUrl2,
                ctaText3,
                ctaUrl3,
                ctaText4,
                ctaUrl4,
                ctaText5,
                ctaUrl5,
                linkid,
              },
              index
            ) => (
              <div
                key={id}
                id={`slide-${index}`}
                data-slug={slug}
                className="flex flex-col flex-shrink-0 w-full h-full md:flex-row"
              >
                <div className="flex justify-center w-full h-20vh md:h-full bg-lightBeige22 md:w-3/5">
                  <div className="hidden w-full md:block">
                    <LazyImage image={image} alt={title} />
                  </div>
                  <div className="block w-full md:hidden">
                    <LazyImage cover image={image} alt={title} />
                  </div>
                </div>
                <div className="flex flex-col w-full h-full md:w-2/5">
                  <span
                    className={`font-light uppercase select-none md:pl-8 pl-4 py-3 md:py-6 text-20px ${
                      getSliderBackground(index).primary
                    }`}
                  >
                    {supertitle}
                  </span>
                  <div
                    className={`w-full md:pl-8 pl-4 py-4 bg-blue ${
                      getSliderBackground(index).secondary
                    }`}
                  >
                    <div className="w-2/3 mb-4 leading-none select-none font-secondary md:mb-2 lg:mb-6 text-40px title-ellipsis">
                      <Html text={title} />
                    </div>
                  </div>
                  <div className="h-full py-4 pl-4 pr-16 text-xl font-light select-none lg:pr-12 md:pl-8 bg-lightBeige22">
                    <Html text={text} />
                  </div>
                  {(ctaUrl.length > 0 || ctaUrl2.length > 0) && (
                    <div className="flex flex-col flex-wrap items-start w-full p-4 h-max text-small">
                      {ctaUrl2 && ctaUrl2.length > 0 && (
                        <div className="flex justify-start w-full pb-1 md:w-full md:justify-start md:pr-6 lg:pr-12 lg:pb-2">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText2}
                            href={webinarCtaUrl(
                              ctaUrl2,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                      {ctaUrl3 && ctaUrl3.length > 0 && (
                        <div className="flex justify-start w-full pb-1 md:w-full md:justify-start md:pr-6 lg:pr-12 lg:pb-2">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText3}
                            href={webinarCtaUrl(
                              ctaUrl3,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                      {ctaUrl4 && ctaUrl4.length > 0 && (
                        <div className="flex justify-start w-full pb-1 md:w-full md:justify-start md:pr-6 lg:pr-12 lg:pb-2">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText4}
                            href={webinarCtaUrl(
                              ctaUrl4,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                      {ctaUrl5 && ctaUrl5.length > 0 && (
                        <div className="flex justify-start w-full pb-1 md:w-full md:justify-start md:pr-6 lg:pr-12 lg:pb-2">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText5}
                            href={webinarCtaUrl(
                              ctaUrl5,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                      {ctaUrl && ctaUrl.length > 0 && ctaUrl2 && (
                        <div className="flex justify-start w-full pb-1 md:w-2/3 md:pr-6 lg:pr-12 lg:w-auto lg:pb-2">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText}
                            href={webinarCtaUrl(
                              ctaUrl,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {(ctaUrl.length > 0 || ctaUrl2.length > 0) && !ctaUrl2 && (
                    <div className="flex flex-col flex-wrap items-start justify-start h-full select-none md:justify-end md:items-end md:text-xs lg:text-sm md:pb-18 bg-lightBeige22">
                      {ctaUrl && ctaUrl.length > 0 && (
                        <div className="flex justify-start w-full pl-4 mb-4 text-lg md:w-2/3 md:justify-end md:pr-6 lg:pr-12 lg:w-auto tb-1 lg:pt-2 md:mb-0 md:z-10">
                          <Button
                            style="blackOutlined"
                            padded={true}
                            title={ctaText}
                            href={webinarCtaUrl(
                              ctaUrl,
                              title,
                              slug,
                              id,
                              video,
                              country
                            )}
                            target="_blank"
                            dataLayer={makeLayer({
                              event: 'e_contactClick',
                              linkPlacement:
                                items.length > 1
                                  ? 'slider-webinar-link'
                                  : 'content-webinar-link',
                              linkID: linkid,
                            })}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      )
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (currentMenuId !== -1) {
      const url = `#!${currentMenuId || ''}`
      window.history.replaceState(null, null, url)
    }
  }, [currentMenuId])

  const webinarCtaUrl = (url, title, slug, id, video, country) => {
    if (url.includes('vimeo')) {
      return url
    }

    const params = []
    const jumpid = getCookie('jumpid')
    if (jumpid !== null) params.push(`jumpid=${jumpid}`)
    const bmtid = getCookie('bmtid')
    if (bmtid !== null) params.push(`bmtid=${bmtid}`)
    params.push(`country=${country}`)
    params.push(`slug=${slug}`)
    params.push(`title=${encodeURI(title)}`)
    if (video) {
      params.push(`live=${video.length > 0 ? 'yes' : 'no'}`)
    }
    params.push(`token=${jwt.encode({ webinarId: id }, simpleTokenSecret)}`)

    return `${url.trim()}${url.includes('?') ? '&' : '?'}${params.join('&')}`
  }

  useEffect(() => {
    const webinarUrl = window.location.href
    if (webinarUrl.includes('webinar/')) {
      shouldScrollToId('spacer')
    }
  }, [])

  return (
    <div
      data-name="featured"
      className="w-full h-full md: min-h-700 md2:min-h-600 bg-lightBeige22"
      data-anchor={anchor}
    >
      <div className="relative w-full md:h-full">
        {renderedItems}
        {items.length > 1 && (
          <div className="sticky bottom-0 flex items-center justify-between w-full p-4 md:relative md:justify-end md:p-0 bg-lightBeige22">
            <div
              className={`md:hidden flex items-center justify-center w-12 h-12 pr-1 bg-black rounded-full cursor-pointer flex-shrink-0`}
              onClick={() => handleSlider('backwards')}
            >
              <LazySimpleImage
                alt={useLiterals('previous')}
                image={useMedia('white-arrow-left')}
              />
            </div>
            <div className="flex items-center w-1/2 md:w-2/5 md:justify-start md:absolute md:bottom-0 md:pb-2 h-min">
              {items.length > 1 && (
                <div className="flex justify-center w-full md:justify-start md:pl-8 md:pb-6">
                  <div className="flex">
                    {items
                      .sort((a, b) => {
                        return parseInt(a.position) - parseInt(b.position)
                      })
                      .map((item, index) => (
                        <div
                          onClick={() => slideTo(index)}
                          key={index}
                          className={`w-2 h-2 mx-1 lg:mx-2 cursor-pointer ${
                            currentIndex === index
                              ? 'bg-black'
                              : 'bg-transparent'
                          } border rounded-full`}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`md:hidden flex items-center justify-center w-12 h-12 bg-black rounded-full cursor-pointer flex-shrink-0`}
              onClick={() => handleSlider('forwards')}
            >
              <LazySimpleImage
                alt={useLiterals('previous')}
                image={useMedia('white-arrow-right')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeaturedWebinars
