// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import type { ApiImage } from '../types'
import Spacer from './Spacer'
import CategoryBlockCard from './CategoryBlockCard'


type ItemProps = {
  image: ApiImage,
  title: string,
  teaser: string,
  ctaLabel: string,
  ctaLink: string,
  category?: string,
  type?: string,
  menuId?: string,
}

type Props = {
  items: Array<ItemProps>,
  backgroundColor?: string
}

const CategoryBlockCardRow = ({
  items,
  menuId,
  backgroundColor = "#ffffff"
}: Props) => {
  return (
    <div style={{ backgroundColor: backgroundColor }} data-hasgradient={backgroundColor === "#ffffff" ? 1 : 0}>
      <Spacer bottom data-menuid={menuId}>
        <Container>
          <Grid>
            <div className="grid col-span-4 gap-4 md:col-span-9 md:col-start-4 md:mt-18 md:grid-cols-10">
              {items.map((item, index) =>{
                return (
                  <div key={item.id} className={`col-span-5 mt-6 md:mt-0 ${index === 1 ? "md:col-start-6" : ""}`}>
                    <CategoryBlockCard  
                      image={item.options.image} 
                      title={item.options.title} 
                      teaser={item.options.teaser} 
                      ctaLabel={item.options.ctaLabel}
                      ctaLink={item.options.ctaLink}
                      type={item.options.type}
                      category={item.options.category}
                    />
                  </div>
                )
              })}
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlockCardRow