// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Spacer from './Spacer'
import Button from './Button'
import type { ApiImage } from '../types'
import makeLayer from '../lib/makeLayer'
import Markdown from './Markdown'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  pageName: [String],
  position: string,
  image: ApiImage,
  text: string,
  ctaText: string,
  ctaUrl: string,
  urlForm: any,
  title: string,
  supertitle: string,
  style?: string,
}

const ContactExpert = ({
  image,
  text,
  ctaText,
  ctaUrl,
  title,
  supertitle,
  urlForm,
  style,
  pageName,
}: Props) => {
  if (style === "630-printer") {
    return (
        <div className='bg-darkgray flex h-30rem md:h-25rem lg:h-30rem sm:w-47/100 rounded-lg overflow-hidden flex-col md:flex-row' id="contactExpert" style={{ marginRight: !useIsMobile() ? "48%" : 0 }}>
          <div className='h-2/3 md:h-full md:w-1/2 flex flex-col justify-between'>
            <div>
              <Appear>
                <h3 className="font-secondary text-big2 p-5">{title}</h3>
                <div className='w-full h-1px bg-graySlider'></div>
              </Appear>
              <Appear>
                <Markdown text={text} className='p-5' />
              </Appear>
            </div>
            <div className="flex p-5">
              {ctaText && (
                <Appear>
                  <Button
                    name="request-free-sample"
                    style="outlineWhite" 
                    textClasses='text-white text-normal hover:text-black'
                    title={ctaText}
                    href={urlForm === false ? ctaUrl : ''}
                    to={urlForm}
                    dataLayer={makeLayer({
                      event: 'e_contactClick',
                      linkPlacement: 'footer',
                      linkID: 'free-sample-kit',
                    })}
                    padded
                  />
                </Appear>
              )}
            </div>
          </div>
          <div className='h-1/3 md:h-full md:w-1/2 overflow-hidden'>
            <LazyImage cover image={image} alt={title} className='w-full h-full' />
          </div>
        </div>
    )
  }

  if (style === 'underline') {
    return (
      <Grid noGap>
        <div className='col-span-4 col-start-1 row-start-2 md:col-span-7 md:row-start-1'>
          <LazyImage cover image={image} alt={title}/>
        </div>
        <div className='flex flex-col justify-between col-span-4 col-start-1 row-start-1 pt-12 text-white bg-black md:row-start-1 md:col-start-8 md:col-span-5'>
          <Appear>
            <h3 className='w-2/3 px-4 leading-none text-30px md:text-50px xl:mr-auto lg:w-1/2'>{title}</h3>
            <div className='w-full my-6 border-b border-white' />
            <div className='px-4 pb-12 md:w-3/4 xl:mr-auto'>
              <Markdown text={text} />
            </div>
          </Appear>
          {ctaText && (
            <Appear className="p-4 pb-8">
              <Button
                title={ctaText}
                to={ctaUrl}
                style="outlineWhite"
                textClasses="text-white hover:text-black"
                padded
                dataLayer={
                  pageName[3] === 'hp-professional-print-service'
                    ? makeLayer({
                        event: 'e_contactClick',
                        linkPlacement: 'professional-print-service',
                        linkID: 'contact-expert',
                      })
                    : ''
                }
              />
            </Appear>
          )}
        </div>
      </Grid>
    )
  }

  if (style === 'visid') {
    return (
      <Container className="my-6 md:my-12">
        <Grid>
          <div className="relative flex flex-row col-span-4 text-black md:col-span-10 md:col-start-2">
            <div className="w-1/3 md:w-1/2">
              <LazyImage cover image={image} alt={title} />
            </div>
            <div className="flex flex-col w-2/3 md:w-1/2 bg-orange">
              <div className="flex flex-col justify-center p-3 md:p-6">
                <Appear>
                  {supertitle ? (
                    <h5 className="mb-2 text-normal">{supertitle}</h5>
                  ) : null}
                  <h3 className="text-big2">{title}</h3>
                </Appear>
              </div>
              <div className="flex-grow p-3 bg-nude md:p-6">
                <div className="col-span-4 col-start-1 md:col-span-4 md:col-start-2">
                  <Appear>
                    <div className="text-sm font-light md:text-20px">
                      <Html text={text} />
                    </div>
                  </Appear>
                </div>
                <div className="col-span-4 col-start-1 md:col-span-5 md:col-start-2">
                  <div className="flex mt-4 md:mt-6">
                    <Appear>
                      {ctaText && ctaUrl && (
                        <Button
                          name="contact-expert"
                          style="outlineOnly"
                          title={ctaText}
                          href={urlForm === false ? ctaUrl : ''}
                          to={urlForm}
                          dataLayer={makeLayer({
                            event: 'e_contactClick',
                            linkPlacement: 'footer',
                            linkID: 'contact-expert',
                          })}
                        />
                      )}
                    </Appear>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Container>
    )
  }

  return (
    <div className="text-black">
      <Container  customPadding="px-0">
        <Grid noGap>
          <div className="col-span-4 row-start-2 md:row-start-auto md:col-span-7 ">
            <LazyImage cover image={image} alt={title} />
          </div>
          <div className="col-span-4 md:col-span-5 md:mx-0 bg-beige22">
            <Appear className="flex flex-col h-full">
              <div className="px-8 py-2 md:pl-8 bg-cyan">
                <h5 className="mb-2 font-secondary text-normal">{supertitle}</h5>
                <h3 className="mb-4 font-secondary md:mb-3 text-big4 md:text-40px md2:text-50px lg:max-w-7/10">{title}</h3>
              </div>
              <div className="my-4" />
              <Appear className="mx-8 mt-4 max-w-450 md:mt-3 md:ml-8">
                <div className="text-lg font-light">
                  <Html text={text} />
                </div>
              </Appear>
              {ctaText && (
                <div className="flex items-end flex-1 mx-8 mt-8 mb-6 md:mt-10 md:ml-8">
                  <Appear>
                    <Button
                      name="contact-expert"
                      style="blackOutlined"
                      title={ctaText}
                      href={urlForm === false ? ctaUrl : ""}
                      to={urlForm}
                      dataLayer={makeLayer({
                        event: "e_contactClick",
                        linkPlacement: "footer",
                        linkID: "contact-expert",
                      })}
                    />
                  </Appear>
                </div>
              )}
            </Appear>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default ContactExpert
