// @flow

import React from 'react'
import SimpleHeaderImage from './SimpleHeaderImage'

type Props = {
  title: string,
  type?: string,
  childAfterMenu?: Boolean,
}

const StoryBlock0 = ({
  title,
  type,
  extraProps,
  image,
  imageAlt,
  childAfterMenu,
}: Props) => {
  return extraProps.isBlogPost ? (
    <div className="bg-white h-0 mt-0">
      {
        <SimpleHeaderImage
          type="stories"
          big={type !== 'post'}
          title={title}
          postType={type}
          taller={true}
        />
      }
    </div>
  ) : (
    <div
      className={`bg-white ${
        childAfterMenu ? 'mt-menu-mobile md:mt-menu-desktop' : ''
      }`}
    >
      {
        <SimpleHeaderImage
          type="stories"
          big={type !== 'post'}
          image={image}
          title={title}
          postType={type}
          taller={true}
          alt={imageAlt}
        />
      }
    </div>
  )
}

export default StoryBlock0
