// @flow

import React from 'react'

type Props = { url: string }

const IconFacebook = ({ url }: Props) => {
  return (
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer fb-xfbml-parse-ignore"
    >
      <svg
        id="f-Logo_White"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 20 20"
      >
        <path
          id="Path_3259"
          d="M20.308,10.154A10.154,10.154,0,1,0,8.567,20.185v-7.1H5.989V10.154H8.567V7.917A3.583,3.583,0,0,1,12.4,3.966a15.614,15.614,0,0,1,2.273.2v2.5H13.4a1.468,1.468,0,0,0-1.655,1.586v1.9h2.816l-.45,2.935H11.741v7.1A10.157,10.157,0,0,0,20.308,10.154Z"
          color="currentColor"
        />
      </svg>
    </a>
  )
}

export default IconFacebook
