// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import MadeWithPrinters from './MadeWithPrinters'
import type { ApiImage, SimplePrinter } from '../types'
import VideoSlider from './VideoSlider'

type SliderItem = {
  text: string,
  image: ApiImage,
  url: string,
  id: string,
}

type Props = {
  id: string,
  title: string,
  text: string,
  image: ApiImage,
  imagezoom: ApiImage,
  image360: ApiImage,
  printers: Array<SimplePrinter>,
  items: Array<SliderItem>,
  menuId?: string,
  inGradient?: boolean,
  gradientStart: string,
  gradientEnd: string,
  verticalSlug: string,
  whiteParentBG?: boolean,
}

const CategoryBlock11 = ({
  id,
  gradientStart,
  gradientEnd,
  inGradient,
  menuId,
  title,
  text,
  items,
  printers,
  verticalSlug,
  whiteParentBG = false,
}: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer className="text-red" top bottom smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 mb-12 ${whiteParentBG ? "text-black" : "text-white"} ol-start-1 md:flex md:flex-col md:justify-center md:col-span-9 md:col-start-4 md:mb-0`}>
              <VideoSlider id={id} gradientStart={gradientStart} gradientEnd={gradientEnd} inGradient={inGradient} items={items} text={text} />
              <div className="grid grid-cols-2 mt-12">
                {title.length > 0 ? <>
                  <div className="grid col-start-1 row-start-1 gap-4">
                    <h3 className="pr-12">{title}</h3>
                    <p className="pr-12">{text}</p>
                  </div>
                  <div className="grid col-span-2 col-start-1 row-start-2 gap-4 md:col-end-2 md:col-span-1 md:row-start-1 md:col-start-2">
                    <MadeWithPrinters inGradient={inGradient} printers={printers} />
                  </div>
                </> : <>
                  <div className="grid col-start-1 row-start-1 gap-4">
                    <MadeWithPrinters inGradient={inGradient} printers={printers} verticalSlug={verticalSlug} />
                  </div>
                </>}
              </div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock11
