// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  text: string,
  menuId?: string,
  style?: string,
}

const PrinterMoreInfo = ({ menuId, text, style }: Props) => {
  const isMobile = useIsMobile()
  return (
    <div data-menuid={menuId}>
      <Spacer top={style === "lion" && !isMobile ? false : true} bottom smaller={style === "lion" || style === "typhoon" ? false : true}>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 text-black text-smaller md:flex md:flex-col md:justify-center ${style ==='lion' || style === 'typhoon' ? 'md:col-start-2 md:col-span-10' : 'md:col-span-8 md:col-start-4'} md:mb-0`}>
              <div className="font-light"><Appear><Html text={text} /></Appear></div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default PrinterMoreInfo