const config = {
  v: 2,
  baseURL: 'https://c.hpvirtualbooth.com/api/public/api/', // production
  // baseURL: 'https://c.hpvirtualbooth.com/api-staging/public/api/', // staging
  blogFetchURL:
    'https://c.hpvirtualbooth.com/api/public/public/dynamic/blog?language=en',
  // blogFetchURL:'https://c.hpvirtualbooth.com/api-staging/public/public/dynamic/blog?language=en',
  baseURLStaging: 'https://c.hpvirtualbooth.com/api-staging/public/api/',
  scrollVideoUrl: 'https://c.hpvirtualbooth.com/api/static-uploads/scrollVideo',
  configEndpoint: 'config',
  cloudfront: {
    baseURL: 'https://dktffdy8n0jtu.cloudfront.net/',
    resized: '_resized',
  },
}

module.exports = config
