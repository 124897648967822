// @flow

import React from 'react'
import SimpleButton from './SimpleButton'
import LazyImage from './LazyImage'
import type { ApiImage } from '../types'
import LinkExternalHP from './LinkExternalHP'
import Html from './Html'

type Props = {
    image: ApiImage,
    title: string,
    teaser: string,
    ctaLabel: string,
    ctaLink: string,
    type?: string,
    category?: string,
    dataLayer: string,
}

const CategoryBlockCard = ({
    image,
    title,
    teaser,
    ctaLabel,
    ctaLink,
    type = "storiesItems",
    category,
    draggable = undefined,
    target = 0,
    callback,
    dataLayer,
}: Props) => {

const linkClassName = `text-white border-b border-white text-small font-normal hover:border-black`

return (
    <div className={`font-light mt-4 ${type === "blogItems" ? "text-white" : "text-black" }`}>
        <div className="w-full h-full">
            <LazyImage image={image} cover/>
        </div>
        {category && <div className="mt-6 font-normal text-20px sm:text-22px">{category}</div>}
        <div className={`${type === "blogItems" ? "text-24px sm:text-2.5xl md:text-3.5xl font-normal leading-tight my-5" : "text-22px mt-8 mb-4 lg:text-30px"} `}>{title}</div>
        <div className={`${type === "blogeItems" ? "font-normal" : ""} text-small mb-4`}><Html text={teaser} /></div>
       {type === "blogItems" ? 
         <LinkExternalHP draggable={draggable} onClick={callback} className={linkClassName} href={(ctaLink === 'gated') ? void(0) : ctaLink} dataLayer={dataLayer} target={(target) ? "_blank" : ""}>
            {ctaLabel}
          </LinkExternalHP>  : 
          <SimpleButton title={ctaLabel} href={ctaLink} color="blue" />}
    </div>  
)
}

export default CategoryBlockCard