// @flow
import React from 'react'
import Spacer from './Spacer'
import Button from './Button'
import Appear from './Appear'
import FeaturesRowScroll from './FeaturesRowScroll'
import FeaturesRowItem from './FeaturesRowItem'

type Feature = {
  key: string,
  modelName: string,
  title: string,
  position: number
}

type Props = {
  features: Array<Feature>,
  color?: string,
}

const FeaturesRow = ({ features, color }: Props) => {
  return (
    <Spacer className="overflow-hidden">
      <div className="hidden md:flex md:flex-row">
        {features && features.map((item, index) => (
          <div
            key={item.key}
            className={`${index === features.length - 1 ? '' : 'md:mr-6'} md:flex-1`}
          >
            <FeaturesRowItem
              title={item.title}
              text={item.text}
              color={color}
            />
          </div>
        ))}
      </div>
      <div className="py-6 md:hidden">
          <FeaturesRowScroll items={features} color={color} />
      </div>
    </Spacer>
  )
}

export default FeaturesRow
