// @flow
import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import Appear from './Appear'
import { navigate } from "gatsby"
import { useMedia } from 'store/'

type Props = {
  title: string,
  subtitle: string,
  items: Array<any>
}

const Blog2 = ({ title, subtitle, items }: Props) => {

  const handleChange = e => {
    if (e.target.value.length > 0) {
      navigate(e.target.value)
    }
  }

  const initialValue = items.filter(item => item.active)[0].url

  return (
    <div className="bg-black mt-28" style={{ background: `url(${useMedia('blog-header')}) no-repeat center center`, backgroundSize: 'cover' }}>
        <div style={{ background: 'rgba(0,0,0,.6)' }}>
          <Spacer top bottom smaller>
            <Container>
              <Appear>
                <div className="flex flex-col items-center justify-center text-white md:flex-row">
                  <div className="flex">
                    <h1 className="text-big2">{title}:&nbsp;</h1> 
                    <h2 className="text-big2">{subtitle}</h2>
                  </div>
                  <div className="ml-4 ">
                    <select value={initialValue} className="text-white bg-black blog-selector text-big2" onChange={handleChange}>
                      {items.map(item => <option key={item.url} value={item.url}>{item.title}</option>)}
                    </select>
                  </div>
                </div>
              </Appear>
            </Container>
          </Spacer>
        </div> 
      </div>
  )
}

export default Blog2