// @flow
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Animated from './Animated'
import LazySimpleImage from './LazySimpleImage'
import { useMedia } from 'store/'
import type { InputRef } from '../types'
import { debounce } from 'lodash'

type FiltersProps = {
  filters: Array<any>,
  selectedFilters: Array<any>,
  onValueChange: Function,
  isMobile: boolean,
  dropdown: boolean,
  selectFilter: Function,
  inputValue: string,
  type: string,
}

const animations = {
  filter: (filter: string, isMobile) => {
    if (typeof document !== 'undefined') {
      let height = 0
      const box = document.querySelector(`#${filter}`)
      if (box) {
        if (isMobile) {
          const filtersBox: any = document.querySelector('#filters-box')
          filtersBox.parentElement.style.overflow = 'scroll'
        }
        Array.from(box.children).forEach(child => {
          height += child.offsetHeight
        })
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`
        },
        duration: 0.5
      }
    }
  },
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  }
}

const BlogFilters = ({
  filters,
  selectedFilters,
  selectFilter,
  onValueChange,
  isMobile,
  dropdown,
  inputValue,
  type
}: FiltersProps): React$Node => {

  const [openFilters, setOpenFilters] = useState([])
  const input: InputRef = useRef()

  const handler = useCallback(
    debounce(() => {
      onValueChange(input.current.value)
    }, 200),
    []
  )

  useEffect(() => {
    input.current.value = inputValue
  }, [inputValue])

  useEffect(() => {
    if (!dropdown) {
      setOpenFilters([])
    }
  }, [dropdown])

  const handleFilterBox = (filter: string): void => {
    let currentFilters: Array<string> = []
    if (openFilters.includes(filter)) {
      currentFilters = openFilters.filter(f => f !== filter)
    } else {
      currentFilters.push(filter)
    }
    setOpenFilters(currentFilters)
  }

  return (
    <div id="filters-box" className="bg-white">
      <div className="flex justify-between pb-2 border-b border-1">
        <div className="flex-shrink-0">
          <LazySimpleImage image={useMedia('search')} alt="search" />
        </div>
        <input
          className="flex-1 pl-4 outline-none"
          ref={input}
          onChange={() => handler()}
        />
      </div>
      <div className="w-full mt-8 border-1">
        {filters.map((filter, index) => (
          <div key={filter.id}>
            <div
              className={`p-4 border ${index && 'border-t-0'} justify-between`}
            >
              <div
                  className={`relative flex items-start box-border cursor-pointer text-black`}
                  onClick={() =>
                    selectFilter({ filter, id: filter.id, label: filter.title })
                  }
                >
                  <div className="h-full">
                    <div
                      className={`${
                        selectedFilters.find(
                          ({ id: filterID }) =>
                            filterID === filter.id
                        )
                          ? 'checkbox-selected'
                          : 'checkbox'
                      } flex items-center justify-center flex-shrink-0 self-center`}
                    ></div>
                  </div>
                  <span className="pl-4 font-light text-smaller">
                    {filter.title}
                  </span>
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogFilters
