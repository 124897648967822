// @flow

import React from 'react'
import IconLinkedIn from './icons/IconLinkedIn'
import IconX from './icons/IconX'
import IconFacebook from './icons/IconFacebook'
import IconShare from './icons/IconShare'
import IconClose from './icons/IconClose'

const PostSharesocialMobile = () => {
  const [open, setOpen] = React.useState(false)
  const url = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <>
      <div
        className="fixed z-10 text-white md:hidden flex bottom-5 right-5 items-center justify-center bg-black rounded-full h-12 w-12"
        onClick={() => setOpen(!open)}
      >
        {open ? <IconClose /> : <IconShare />}
      </div>
      <div
        className={`fixed bottom-10 right-5 bg-white w-12 flex flex-col items-center justify-around border-black duration-250 rounded-t-full transition ease-in-out overflow-hidden ${
          open ? 'h-40 border pt-2 pb-8' : 'h-0 border-0 pb-0 pt-0'
        }`}
      >
        {open ? (
          <>
            <IconFacebook url={url} />
            <IconX url={url} />
            <IconLinkedIn url={url} />
          </>
        ) : null}
      </div>
    </>
  )
}

export default PostSharesocialMobile
