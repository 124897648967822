// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import Appear from './Appear'
import Spacer from './Spacer'
import type { ApiImage } from '../types'
import Bubble from './Bubble'
import LazyImage from './LazyImage'
import makeAssetUrl from '../lib/makeAssetUrl'
import SizedImage from './SizedImage'

type Props = {
  image: ApiImage,
  title: string,
  subtitle: string,
  texture: Object,
  texture2: Object,
  image2: Object,
  text: string,
  color: string
}

const DesignJet0 = ({
  texture,
  image,
  title,
  subtitle,
  text,
  texture2,
  image2,
  color
}: Props) => {
  return (
    <Spacer top>
      <Container className="relative md:overflow-visible">
        <Grid className="relative pt-24 md:pt-0">
          <div className="z-30 col-span-4 col-start-1 row-span-2 row-start-2 pr-8 md:z-20 font-primary md:col-span-4 md:col-start-2 md:row-start-1 md:row-span-1">
            <Appear>
              <h1 className="mt-24 mb-4 font-light md:mb-8 text-bigMax md:leading-none">
                {title}
              </h1>
              <div className="pr-24 md:pr-32 lg:pr-12 text-big3">
                <Html text={subtitle} />
              </div>
            </Appear>
          </div>
          <Appear className="z-20 w-40 h-40 col-span-2 col-start-3 row-start-2 md:justify-self-center md:col-span-4 md:row-start-1 md:row-span-4 md:col-start-4 md:z-10 md:w-88 md:h-88 lg:w-100 lg:h-100 xl:w-110 xl:h-110">
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture, isImage: true })} />
          </Appear>
          <Appear
            style={{
              clipPath: `circle(50% at 50% 50%)`
            }}
            className="col-span-4 col-start-1 row-span-2 row-start-1 rounded-full md:col-start-5 md:row-start-1 md:row-span-2 md:col-span-8"
          >
            <LazyImage
              alt={subtitle}
              image={image}
              className="w-full h-full rounded-full"
            />
          </Appear>
          <Appear className="relative flex items-center justify-center w-64 h-64 col-span-3 col-start-2 row-span-2 row-start-4 md:items-center md:justify-self-center md:jusitfy-center md:mt-12 md:h-120 md:row-start-2 md:col-start-1 md:row-span-4 md:col-span-6 md:w-120">
            <Bubble className="absolute w-full h-full" color={color} />
            <div className={`absolute px-12 text-2xl font-light leading-tight text-center md:text-5xl md:px-24 ${color === 'black' ? 'text-white' : ''}`}>
              <Html text={text} />
            </div>
          </Appear>
          <div className="w-32 h-32 col-span-2 col-start-1 row-span-2 row-start-5 mt-6 justify-self-center md:hidden">
            <Appear className="w-full h-full">
              <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture2, isImage: true })} />
            </Appear>
          </div>
          <SizedImage
            image={image2}
            title={title}
            className="relative z-10 col-span-4 col-start-1 row-start-7 md:z-10 md:col-start-5 md:col-span-8 md:row-start-7 md:mt-24"
          />
        </Grid>
        <div className="hidden md:block md:absolute md:w-80 md:h-80 md:top-55p md:-left-10p lg:-left-30p">
          <Appear className="w-full h-full">
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture2, isImage: true })} />
          </Appear>
        </div>
      </Container>
    </Spacer>
  )
}

export default DesignJet0
