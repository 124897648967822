import React from 'react'

const IconTalkExpert = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60.913"
      height="60.767"
      viewBox="0 0 60.913 60.767"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7536"
            data-name="Rectangle 7536"
            width="60.913"
            height="60.767"
            transform="translate(0 0.001)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_19027" data-name="Group 19027" transform="translate(0 0)">
        <g id="Group_19026" data-name="Group 19026" clipPath="url(#clip-path)">
          <path
            id="Path_8945"
            data-name="Path 8945"
            d="M57.523,37.031H35.482a3.391,3.391,0,0,0-3.391,3.391V52.314a3.391,3.391,0,0,0,3.391,3.391h8.457v3.367H42.264a.848.848,0,0,0,0,1.7h8.477a.848.848,0,0,0,0-1.7H49.017V55.705h8.506a3.391,3.391,0,0,0,3.39-3.391V40.422a3.391,3.391,0,0,0-3.39-3.391m-10.2,22.041H45.635V55.705h1.687Zm11.9-6.758a1.7,1.7,0,0,1-1.695,1.695H35.482a1.7,1.7,0,0,1-1.7-1.695V40.422a1.7,1.7,0,0,1,1.7-1.695H57.523a1.7,1.7,0,0,1,1.695,1.695Z"
            fill="currentColor"
          />
          <path
            id="Path_8946"
            data-name="Path 8946"
            d="M27.015,28.588A14.409,14.409,0,0,0,41.536,14.294a14.523,14.523,0,0,0-29.042,0A14.408,14.408,0,0,0,27.015,28.588m0-26.892a12.728,12.728,0,0,1,12.825,12.6,12.827,12.827,0,0,1-25.65,0A12.728,12.728,0,0,1,27.015,1.7"
            fill="currentColor"
          />
          <path
            id="Path_8947"
            data-name="Path 8947"
            d="M40.492,35.02a.845.845,0,0,0-.31-1.157A26.392,26.392,0,0,0,27,30.331C12.377,30.331.522,42.12.015,57.187a15.88,15.88,0,0,0,.122,2.751.848.848,0,0,0,.84.735.85.85,0,0,0,.112-.006.849.849,0,0,0,.729-.954,14.191,14.191,0,0,1-.108-2.487C2.185,43.1,13.3,32.026,27,32.026a24.708,24.708,0,0,1,12.33,3.3.849.849,0,0,0,1.157-.31"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}

export default IconTalkExpert
