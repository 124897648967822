// @flow
import React from 'react'
import Appear from './Appear'
import Button from './Button'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from './Markdown'

type ItemProps = {
    image: ApiImage,
    title: string, 
    text: string, 
    highlightedText?: string, 
    cta1Text: string, 
    cta1Url: string, 
    cta2Text: string, 
    cta2Url: string,
}

type Props = {
  title: string,
  ctaText: string,
  ctaUrl?: string,
  items: Array<ItemProps>,
  footnotes: string, 
} 

const PrinterFamily = ({title, ctaText, ctaUrl, items, footnotes }: Props) => {
  return (
    <>
      <Container className='pt-8 md:pt-16'>
        <Appear className='flex flex-col pb-4 sm:flex-row sm:items-center sm:justify-between md:pb-8'>
          <h2 className="mb-4 leading-tight text-40px sm:text-60px md:text-70px lg:text-80px sm:mb-0">{title}</h2>
          <div className='w-max'>
            <Button
            style="black"
            title={ctaText}
            href={ctaUrl}
            />
          </div>
        </Appear>
      </Container>
      <div className='w-full border-b border-black'/>
      <Container className='mt-8 overflow-hidden'>
        <Grid>
          {items.map((item) =>{
            return (
              <div key={item.key} className={`col-span-4 sm:col-span-2 ${items?.length <= 3 ? "md:col-span-4" : "md:col-span-3"} `}>
                <Appear className='border border-black'>
                  <div className="w-full py-12 border-b border-black md:py-16 px-1/10">
                    <LazyImage contain image={item.image} alt={title}/>
                  </div>
                  <h4 className='p-4 text-24px lg:text-30px'>{item.title}</h4>
                  <Markdown text={item.text} className="p-6 text-base border-t border-b border-black" />
                  {item.highlightedText && (
                    <Markdown text={item.highlightedText} className="p-6 text-base border-b border-black bg-lighterCyan" />
                  )}
                  <div className='flex'>
                    <Button
                      className='flex justify-center flex-1 text-center'
                      style="white"
                      title={item.cta1Text}
                      href={item.cta1Url}
                      textClasses="text-base"
                    />
                    <div className="flex-none w-px bg-black h-max" />                
                    <Button
                      className='flex justify-center flex-1 text-center'
                      style="white"
                      title={item.cta2Text}
                      href={item.cta2Url}
                      textClasses="text-base"
                    />                
                  </div>
                </Appear>
              </div>
            )
          })}
          <div className='col-span-4 py-16 text-xs md:col-span-10 md:col-start-2 sm:py-24'>
            <Appear><Markdown text={footnotes}/></Appear>
          </div>
        </Grid>
      </Container>
    </>
    
  )
}

export default PrinterFamily
