// @flow

import React, { useEffect, useState } from 'react'

type Props = {
  className: string,
  src?: string,
  color?: string
}

const Bubble = ({ src, className, color }: Props) => {
  let bgColor = ''
  if (!src) {
    bgColor = color === 'black' ? 'bg-black' : 'bg-white'
  }
  return (
    <div
      className={`${className && className} ${
        bgColor
      }`}
      style={{
        backgroundImage: src && `url(${src})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        clipPath: `circle(50% at 50% 50%)`
      }}
    />
  )
}

export default Bubble
