// @flow
import React from 'react'
import Appear from './Appear'
import LazyImage from './LazyImage'
import Html from './Html'


type Props = {
  items: Array<Object>,
  insideContainer?: boolean,
}

const TyphoonMasonry = ({
  items,
  insideContainer // this module is only used inside FloatingContainer right now, this prop is not been used.
}: Props) => {
    const evenItems = items.length % 2 === 0
    let cnt = 0

    const imageType = (type, cnt) => {
      switch(type) {
        case 'big':
          return `${cnt === 1 ? 'md:col-start-2' : 'md:col-start-7'} md:col-span-5 col-span-4`
        case 'small':
          return `${cnt === 2 ? 'md:col-start-8' : 'md:col-start-2'} md:col-span-4 col-span-4`
        case 'last':
          return 'col-span-4 md:col-start-2 md:col-span-8'
        default:
        break
      }
    }

    return (
      <div className="grid grid-cols-4 gap-4 px-4 pb-0 md:pb-16 md:grid-cols-11 md:pb-24 md:pr-4 md:pl-0">
       {items.map((item, index) => {
         let type
         if (cnt === 0) {
           cnt++
           type = 'big'
         } else if (cnt === 3) {
          cnt = 0
          type = 'big'
          } else if (index === items.length - 1 && !evenItems) {
          cnt = 0
          type = 'last'
          } else {
          cnt ++
          type = 'small'
        }
        return (
          <div key={item.id} className={`${imageType(type, cnt)} pb-8 md:pb-16`}>
            <Appear>
              <div className="w-full pb-6">
                <LazyImage image={item.options.image}/>
              </div>
              <div className="pb-4 text-sm"><Html text={item.options.title} /></div>
              <div className="text-sm font-light leading-none"><Html text={item.options.text} /></div>
            </Appear>
          </div>
        )
       })}
      </div>
    )
  }

export default TyphoonMasonry