// @flow

import React from 'react'
// import { useLiterals, useMedia } from 'store/'

type Props = {
  color: string,
  background: string,
  title: string
}

const HelloWorld = ({ color, background, title }: Props): React$Node => {
  // Asi accedes a los literals y a media
  // const literals = useLiterals()
  // const media = useMedia()
  return <h1 className={`bg-${background} text-${color}`}>{title}</h1>
}

export default HelloWorld
