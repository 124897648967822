// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import makeAssetUrl from '../lib/makeAssetUrl'
import Spacer from './Spacer'

type Props = {
  image: ApiImage,
  imageAlt: string,
  caption: string,
  respectSize: boolean,
}

// Image component
const StoryBlock4 = ({
  image,
  imageAlt,
  respectSize,
  caption,
  extraProps,
}: Props) => {

  if (extraProps.isBlogPost) {
    return (
      <div className={`my-8 ${caption ? 'border-b pb-1 md:pb-2' : ''}`}>
        <div className="w-full h-full overflow-hidden">
          {respectSize ? (
            <LazySimpleImage
              image={makeAssetUrl({ asset: image, isImage: true })}
              alt={imageAlt}
              className="w-full h-full object-cover"
            />
          ) : (
            <LazyImage
              image={image}
              alt={imageAlt}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        {caption ? <p>{caption}</p> : null}
      </div>
    )
  }

  return (
    <Spacer smaller>
      <Container>
        <Grid>
          <div
            className={`${respectSize
              ? 'col-span-3 sm:col-span-2 md:col-span-4'
              : 'col-span-4 md:col-span-8'
              } col-start-1 md:col-start-3 mb-8 mt-10`}
          // } col-start-1 col-span-12 md:col-start-4 md:col-span-9 my-6`}
          >
            <div className="w-full h-full overflow-hidden">
              {respectSize ? (
                <LazySimpleImage
                  image={makeAssetUrl({ asset: image, isImage: true })}
                  alt={imageAlt}
                  className="w-full h-full object-cover"
                />
              ) : (
                // {respectSize ? <LazySimpleImage image={makeAssetUrl({ asset: image, isImage: true, smallest: true })} alt={imageAlt} /> :
                <LazyImage
                  image={image}
                  alt={imageAlt}
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default StoryBlock4
