// @flow
import React from 'react'
import Button from './Button'
import Container from './Container'
import Grid from './Grid'
import Markdown from './Markdown'
import makeAssetUrl from '../lib/makeAssetUrl'
import LazyImage from './LazyImage'
import colorCodeFormat from '../lib/colorCodeFormat'
import Appear from './Appear'

type Props = {
  title: string,
  text: string,
  mainImage: ApiImage,
  textBlock1: string,
  ctaText1: string,
  image1: ApiImage,
  textBlock2: string,
  ctaText2: string,
  image2: ApiImage,
  primaryColor: string,
  secondaryColor: string,
  tertiaryColor: string,
  backgroundImage: ApiImage,
  ctaUrl1: string,
  ctaUrl2: string,
  anchor: string,
}

const PrinterGridSection = ({
  title,
  text,
  mainImage,
  textBlock1,
  ctaText1,
  image1,
  textBlock2,
  ctaText2,
  image2,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  backgroundImage,
  ctaUrl1,
  ctaUrl2,
  anchor,
}: Props) => {
  return (
    <Container
      backgroundColor="lightBlue"
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${makeAssetUrl({
                asset: backgroundImage,
                isImage: true,
              })})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : {}
      }
    >
      <Grid>
        <div className="col-span-4 my-24 md:col-span-11 md:col-start-2 md:my-56">
          <div className="grid grid-cols-4 md:grid-cols-11">
            <div
              className="col-span-4 col-start-1"
              style={{ backgroundColor: colorCodeFormat(primaryColor) }}
            >
              <Appear>
                <h3 className="p-6 leading-tight border-b border-black text-30px md:text-40px">
                  {title}
                </h3>
                <Markdown className="w-2/3 mx-auto my-8" text={text} />
              </Appear>
            </div>
            <div className="flex flex-col h-full col-span-4 md:col-start-5 md:col-span-7">
              <div className="flex-none md:min-h-1/2">
                <LazyImage image={mainImage} cover />
              </div>
              <div
                data-anchor={anchor}
                className="flex flex-col flex-1 md:flex-row"
                style={{ backgroundColor: colorCodeFormat(secondaryColor) }}
              >
                <Appear className="w-full p-6 md:w-7/12">
                  <Markdown text={textBlock1} className="pb-6 md:w-3/4" />
                  <div className="">
                    <Button
                      style="black"
                      title={ctaText1}
                      padded
                      href={ctaUrl1}
                    />
                  </div>
                </Appear>
                <div className="w-full md:w-5/12">
                  <LazyImage image={image1} cover />
                </div>
              </div>
              <div
                className="flex flex-col flex-1 md:flex-row"
                style={{ backgroundColor: colorCodeFormat(tertiaryColor) }}
              >
                <Appear className="w-full p-6 md:w-7/12">
                  <Markdown text={textBlock2} className="pb-6 md:w-3/4" />
                  <div className="">
                    <Button
                      style="black"
                      title={ctaText2}
                      padded
                      href={ctaUrl2}
                    />
                  </div>
                </Appear>
                <div className="w-full md:w-5/12">
                  <LazyImage image={image2} cover />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Container>
  )
}

export default PrinterGridSection
