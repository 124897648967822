// @flow
import React, { useState, useEffect } from 'react'
import OverlayIframe from './OverlayIframe'
import findGetParameter from '../lib/findGetParameter'

const OpenGated = () => {
  const [overlay, setOverlay] = useState(null)
  useEffect(() => {
    const gated = findGetParameter(window.location.href, 'gated') || findGetParameter(window.location.href, 'gatedurl')
    if (gated) {
      setOverlay(gated)
    }
  }, [])

  if (overlay) {
    return <OverlayIframe url={overlay} close={() => setOverlay(null)} />
  }

  return null
}

export default OpenGated