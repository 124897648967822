// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'

type Props = {
  title: string,
  subtitle: string,
  text?: string,
  menuId?: string,
  nav?: {next: string},
  gradientStart: string,
  gradientEnd: string,
  hasGradient: boolean,
  whiteParentBG?: boolean,
  moduleStyle?: string,
  pretitle?: string,
  backgroundColor?: string,
  titleBackground?: string,
  anchor: string
}


const CategoryBlock2 = ({
  nav,
  menuId,
  title,
  subtitle,
  text,
  hasGradient = false,
  gradientStart = '',
  gradientEnd = '',
  whiteParentBG = true,
  moduleStyle = 'default',
  pretitle,
  backgroundColor,
  titleBackground,
  anchor,
}: Props) => {
  const style = hasGradient ? {
    position: 'relative',
    color: '#fff',
    background: `linear-gradient(-90deg, #${gradientStart.replace(
      '#',
      ''
      )} 0%, #${gradientEnd.replace('#', '')}  100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  } : {}

  let textColumns = 'col-span-9'
  let widerTitle = false
  let biggerSpacer = false
  let cleanBottomSpacer = false

  switch(moduleStyle) {
    case 'text-w75':
        textColumns = 'col-span-4 md:col-span-7'
      break
    case 'text-w65':
        textColumns = 'col-span-4 md:col-span-6'
      break
    case 'top-no-bottom':
      biggerSpacer = true
      cleanBottomSpacer = true
    break
    case 'text-w65-top-no-bottom':
        textColumns = 'col-span-4 md: md:col-span-6'
        biggerSpacer = true
        cleanBottomSpacer = true
      break
    case 'title-w100-top-no-bottom':
      widerTitle = true
      biggerSpacer = true
      cleanBottomSpacer = true
    break
    case 'default':
    default:
      break
  }

  switch (titleBackground) {
    case "EF8D72":
      titleBackground = "bg-darkSalmon23"
      break
    case "F19880":
      titleBackground = "bg-salmon23"
        break
      }

  return (
    <div
      data-menuid={menuId}
      data-hasgradient={
        backgroundColor && backgroundColor === "ffffff" ? 1 : 0
      }
      data-anchor={anchor}
    >
      <div
        // style={{ backgroundColor: backgroundColor ? backgroundColor : "auto" }}
      >
        <Spacer
          top
          bottom={
            cleanBottomSpacer
              ? false
              : (typeof nav !== "undefined" && nav.next !== "CategoryBlock3") ||
                (text && text.length > 0)
          }
          smaller={biggerSpacer ? false : true}
        >
          <Container className={`${titleBackground && titleBackground}`}>
            <Grid>
              <div
                data-superwrappermenu="title"
                className={`col-span-4 col-start-1 md:pb-4 text-black md:flex md:flex-col md:justify-center md:col-start-4 ${
                  widerTitle ? "md:col-span-7" : "md:col-span-6 "
                }`}
              >
                {pretitle && pretitle.length > 0 && (
                  <Appear>
                    <h4
                      style={style}
                      className="mb-8 md:mb-10 text-normal md:text-big lg:text-big2"
                    >
                      {pretitle}
                    </h4>
                  </Appear>
                )}
                <Appear>
                  <h2 style={style} className="pt-6 text-big5">
                    {title}
                  </h2>
                </Appear>
              </div>
              {!titleBackground && (
                <div
                  className={`col-span-4 h-px md:w-full z-50 ${
                    whiteParentBG ? "bg-black " : "bg-white"
                  } md:col-span-9 md:col-start-4 ${
                    (text && text.length > 0) ||
                    (typeof nav !== "undefined" &&
                      nav.next === "CategoryBlock3")
                      ? "mb-4 md:mb-6 "
                      : ""
                  }`}
                />
              )}
              {subtitle && (
                <div
                  className={`col-span-4 col-start-1 mb-12 ${
                    whiteParentBG ? "text-black" : "text-white"
                  } md:flex md:flex-col md:justify-center md:col-span-6 md:col-start-4 md:mb-0`}
                >
                  <h3 className="font-light text-big2">
                    <Appear>
                      <Html text={subtitle} />
                    </Appear>
                  </h3>
                </div>
              )}
              {typeof text === "string" && text !== "" && (
                <div
                  className={`mb-12 col-start-1 col-span-4 md:col-start-4 md:col-span-7 md:mb-0 ${
                    whiteParentBG ? "text-black" : "text-white"
                  } flex md:flex-col md:justify-center`}
                >
                  <Appear>
                    <Html text={text} />
                  </Appear>
                </div>
              )}
            </Grid>
          </Container>
        </Spacer>
      </div>
    </div>
  )
}

export default CategoryBlock2
