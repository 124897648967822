// @flow

import React from 'react'
import Html from './Html'
import Appear from './Appear'

type Props = {
  title?: string,
  supertitle?: string,
  paragraphHeading?: string,
  subParagraphHeading?: string,
  text?: string,
  anchor?: string,
}

const StoryBlock2 = ({
  title,
  supertitle,
  paragraphHeading,
  subParagraphHeading,
  text,
  anchor,
}: Props) => {
  return (
    <div className="my-8 flex flex-col gap-4">
      {supertitle ? (
        <Appear>
          <h3 className="text-20px md:text-30px">{supertitle}</h3>
        </Appear>
      ) : null}
      {title ? (
        <Appear>
          <h2
            id={anchor ? anchor : null}
            className="text-24px md:text-38px leading-tight"
          >
            {<Html text={title} />}
          </h2>
        </Appear>
      ) : null}
      {paragraphHeading ? (
        <Appear>
          <h4 className="text-20px md:text-24px">{paragraphHeading}</h4>
        </Appear>
      ) : null}
      {subParagraphHeading ? (
        <Appear>
          <h5 className="text-16px md:text-20px">{subParagraphHeading}</h5>
        </Appear>
      ) : null}
      {text ? (
        <Appear>
          <div className="text-20px font-primary">
            <Html text={text} />
          </div>
        </Appear>
      ) : null}
    </div>
  )
}

export default StoryBlock2
