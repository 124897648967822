// @flow
import React, { useEffect, useState } from 'react'
import Bubble from './Bubble'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import TitleCtas from './TitleCtas'
import Html from './Html'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  texture: Object,
  texture1: Object,
  texture2: Object,
  ctaUrl1: string,
  ctaUrl2: string,
  ctaText1: string,
  ctaText2: string,
  title: string,
  items: Array<Object>
}

const TitleText = ({ text, title }: { text: string, title: string }) => (
  <>
    <h4 className="my-4 text-lg font-bold md:my-8">
      <Appear>{title}</Appear>
    </h4>
    <div className="font-light">
      <Appear>
        <Html text={text} />
      </Appear>
    </div>
  </>
)

const DesignJet4 = ({
  title,
  texture,
  ctaUrl1,
  ctaText1,
  ctaText2,
  ctaUrl2,
  items
}: Props) => {
  const [splittedItems, setSplittedItems] = useState({
    column1: [],
    column2: []
  })

  useEffect(() => {
    const half = Math.ceil(items.length / 2)
    const items1 = items.splice(0, half)
    const items2 = items.splice(-half)
    setSplittedItems(() => {
      return {
        column1: items1,
        column2: items2
      }
    })
  }, [])

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-menuid="${menuId}"`)
    const yOffset = 0
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      const parts2 = lastPart.split('_')
      if (parts2.length > 0) {
        setTimeout(() => {
          shouldScrollToId(parts2[0])
        }, 1000)
      }
    }
  }, [])

  return (
    <Spacer top>
      <Container className="relative">
        <Grid>
          <TitleCtas
            ctaUrl1={ctaUrl1}
            ctaText1={ctaText1}
            ctaUrl2={ctaUrl2}
            ctaText2={ctaText2}
            title={title}
            className="flex flex-col w-full col-span-4 md:col-start-2 md:items-start md:col-span-10"
          />
          <div data-menuid="explore"></div>
        </Grid>
        <Grid>
          <div className="col-span-4 col-start-1 md:col-start-2">
            {splittedItems.column1.map(({ title, text }) => (
              <div key={title}>
                <TitleText title={title} text={text} />
              </div>
            ))}
          </div>
          <div className="col-span-4 col-start-1 md:col-start-6">
            {splittedItems.column2.map(({ title, text }) => (
              <div key={title}>
                <TitleText title={title} text={text} />
              </div>
            ))}
          </div>
        </Grid>
        {texture && (
          <Appear className="hidden md:block absolute w-64 h-64 md:w-120 lg:h-120 md:h-120 lg:w-120 md:-right-35p md:top-15p lg:-right-30p">
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset: texture, isImage: true})} />
          </Appear>
        )}
      </Container>
    </Spacer>
  )
}

export default DesignJet4
