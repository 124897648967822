// @flow

import React from 'react'
import type { Node } from 'react'
import Spacer from './Spacer'
type Props = {
  children: Node,
  hasGradient: boolean,
  isDifferent: boolean
}

const StoryWrapperInner = ({ children }: Props) => {
  return (
    <Spacer top bottom>
      <div>{children}</div>
    </Spacer>
  )
}

export default StoryWrapperInner
