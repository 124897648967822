// @flow

import React, {
  FC,
  ReactElement,
  useCallback,
  useRef,
  useState,
  memo,
} from 'react'
import useOnFontsLoaded from '../hooks/useOnFontsLoaded'
import useOnMutationObserver from '../hooks/useMutationObserver'
import useOnWindowResize from '../hooks/useOnWindowResize'

function sanitize(string: string): string {
  return string.replace(new RegExp("'", 'g'), '')
}

function splitText(string: string): string[] {
  return string
    .replace(/(<br>)/g, '')
    .trim()
    .split('-')
    .join(' ')
    .split(' ')
}

type LineTuple = [number, string[]]

type Props = {
  children: string,
  lineClassName?: string,
  borderColor?: string,
  withoutSecondBorder?: boolean,
  featuredText?: boolean,
}

const AppearText: FC<Props> = ({
  children,
  lineClassName = '',
  borderColor = 'border-white',
  withoutSecondBorder = false,
  featuredText = false,
}): ReactElement => {
  const [text, setText] = useState<string[]>(children && splitText(children))
  const wrapper = useRef<HTMLSpanElement>(null)
  const fontsLoaded = useOnFontsLoaded()
  const [[id, lines], setLines] = useState<[string, LineTuple[]]>([null, null])
  useOnMutationObserver(
    wrapper,
    () => {
      const newText = wrapper.current.getAttribute('aria-label')
      setText(splitText(newText))
    },
    { attributes: true, characterData: true }
  )

  const calculateLines = useCallback(() => {
    if (!wrapper.current || !fontsLoaded) return

    const lines: LineTuple[] = []

    {
      text.length !== 0 &&
        text.forEach((word, index) => {
          if (!word) return
          const { y } = wrapper.current
            .querySelector(`[data-word='${sanitize(word)}-${index}']`)
            .getBoundingClientRect()

          if (lines.length === 0) return lines.push([y, [word]])

          const existingLine = lines.findIndex(([line]) => line === y)
          if (existingLine < 0) return lines.push([y, [word]])
          lines[existingLine][1].push(word)
        })
    }

    lines.sort(([lineA], [lineB]) => lineA - lineB)

    const newId = lines.map(([, words]) => words.length).join('-')

    setLines(current => {
      if (current[0] !== newId) return [newId, lines]
      return current
    })
  }, [text, fontsLoaded])

  useOnWindowResize(calculateLines, [
    calculateLines,
    children,
    wrapper,
    fontsLoaded,
  ])

  if (typeof children === 'undefined') return null

  return (
    <span
      key={id}
      ref={wrapper}
      aria-label={children}
      className="relative block leading-snug"
    >
      {text.length !== 0 && (
        <span className="inline-block px-5 md:px-8">
          <span
            translate="no"
            className={`inline-block ${
              featuredText ? 'md:max-w-7/10' : 'md:max-w-6/10'
            } invisible ${lineClassName}`}
          >
            {text.map((word, index) => {
              if (!word) return
              const childrenId = `${sanitize(word)}-${index}`
              return (
                <span key={childrenId} data-word={childrenId}>
                  {word}{' '}
                </span>
              )
            })}
          </span>
        </span>
      )}
      {lines && (
        <span
          key={children}
          className="absolute inset-x-0 top-0"
          translate="no"
          aria-hidden
        >
          {lines.map(([, words], lineIndex) => {
            return (
              <span
                key={lineIndex}
                translate="no"
                className={`block font-secondary px-5 md:px-8 ${lineClassName} ${
                  withoutSecondBorder && (lineIndex === 1 || lineIndex === 2)
                    ? ''
                    : `border-b-2 ${borderColor}`
                }`}
              >
                {words.map((word, wordIndex) => (
                  <span key={wordIndex} className="inline-flex" translate="no">
                    {word}
                    {wordIndex + 1 === words.length ? '' : ' '}
                  </span>
                ))}
              </span>
            )
          })}
        </span>
      )}
    </span>
  )
}

export default memo(AppearText)
