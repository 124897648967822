// @flow
import React from 'react'

type Props = {
  label: string,
  placeholder?: string,
  error: boolean,
  onChange: Function,
  onBlur: Function,
  id: string,
  className?: string,
  value: any,
  required?: boolean,
}

const Input = ({
  label,
  placeholder,
  error,
  onChange,
  onBlur,
  id,
  className = '',
  value,
  required = true,
}: Props): React$Node => {
  return (
    <div className={`flex flex-col ${className}`}>
      <label className="pb-2 font-light">{label} {required && '*'}</label>
      <input
        type={id !== 'phone' ? 'text' : 'number'}
        className={`border border-1 ${
          error ? 'border-red' : 'border-black'
        } outline-none h-10 p-2 font-light`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        value={value}
      />
    </div>
  )
}

export default Input
