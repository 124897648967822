// @flow
import React from 'react'
import type { ApiImage } from '../types'
import Container from "./Container"
import Grid from "./Grid"
import LazyImage from './LazyImage'
import Markdown from "./Markdown"
import makeAssetUrl from '../lib/makeAssetUrl'
import Appear from './Appear'

type PrinterHighlightType = {
  modelname: string,
  position: number,
  key: string,
  title: string,
  text: string,
  align: string,
  image: ApiImage,
}

type Props = {
  items: Array<PrinterHighlightType>,
  title?: string,
  subtitle?: string,
  style: "horizontal" | "diagonal",
  backgroundImage: ApiImage,
}

const PrinterHighlights = ({ items, title, subtitle, style, backgroundImage }: Props) => {
  return (
    <Container
      backgroundColor={style === "diagonal" ? "lightBlue" : ""}
      className={backgroundImage && backgroundImage.lazy ? "py-28 sm:py-24 md:py-40" : "py-10 md:py-16"}
      style={ backgroundImage && backgroundImage.lazy ? {
        backgroundImage: `url(${makeAssetUrl({ asset: backgroundImage, isImage: true })})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      } : {}}
    >
      {(title || subtitle) && <Grid className="mb-10 md:mb-16">
        {title && <div className="col-span-4 pb-4 border-b border-black md:col-span-12">
          <Appear><h2 className="leading-tight text-40px md:text-48px">{title}</h2></Appear>
        </div>}
        {subtitle && <div className="col-span-4 md:col-span-8">
          <Appear><Markdown text={subtitle} className="leading-tight text-20px" /></Appear>
        </div>}
      </Grid>}
      <Grid>
        {items.map((item, index) => {
          let position
          let color
          switch(index) {
            case 0:
              position = "col-start-1 col-span-4 sm:col-span-2 md:col-span-4 sm:row-span-2"
              color = style === "diagonal" ? "bg-cyan" : "bg-lavender"
              break
            case 1:
              position = "col-start-1 col-span-4 sm:col-span-2 md:col-span-4 sm:row-span-2 sm:row-start-2 sm:col-start-3 md:col-start-5"
              color = style === "diagonal" ? "bg-lightCyan" : "bg-lightLavender"
              break
            case 2:
            position = "col-start-1 col-span-4 sm:col-span-2 md:col-span-4 sm:row-span-2 sm:row-start-3 md:col-start-9"
            color = style === "diagonal" ? "bg-lighterCyan" : "bg-lighterLavender"
            break
          }
          return (
            <Appear key={item.key} className={`${style === "diagonal" ? position : "col-span-4 sm:col-span-2 md:col-span-4"} ${color} flex flex-col`}>
              <div className='flex-1'>
                <h3 className='px-6 pt-6 pb-4 leading-tight border-b border-black text-20px md:text-30px'>{item.title}</h3>
                <Markdown text={item.text} className="w-4/5 px-6 py-6 text-base"/>
              </div>
              <div className='flex-1 md:h-30vh'>
                <LazyImage className='h-full' image={item.image} cover />
              </div>
            </Appear>
          )
        })}
      </Grid>
    </Container>
  )
}

export default PrinterHighlights