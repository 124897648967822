// @flow

import React from 'react'
import IconLinkedIn from './icons/IconLinkedIn'
import IconX from './icons/IconX'
import IconFacebook from './icons/IconFacebook'

type Props = { label: string }

const PostSharesocial = ({ label }: Props) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  return (
    <div className="bg-lightBeige22 flex items-center justify-between text-20px px-4 h-14 mb-4">
      <span>{label}</span>
      <div className="flex gap-3 items-center">
        <IconLinkedIn url={url} />
        <IconX url={url} />
        <IconFacebook url={url} />
      </div>
    </div>
  )
}

export default PostSharesocial
