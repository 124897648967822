// @flow

import { useState, useEffect } from "react"

/**
 * Ejecuta un callback cuando las fuentes de la web hayan cargado. Devuelve un boolean con el estado de la carga.
 *
 * ```typescript
 * // Devolviendo un boolean
 * const loaded = useOnFontsLoaded()
 *
 * // Ejecutando un callback
 * useOnFontsLoaded(() => console.log("fonts loaded successfully"))
 * ```
 *
 * @param callback (opcional) El callback a ejecutar cuando las fuentes cargan.
 * @returns boolean con el estado de la carga.
 */
export default function useOnFontsLoaded(callback?: () => void): boolean {
  const [loadedFonts, setLoadedFonts] = useState(false)

  useEffect(() => {
    if (typeof document === "undefined" || !("fonts" in document)) {
      setLoadedFonts(true)
      typeof callback === "function" && callback()
    } else {
      document.fonts.ready.then(() => {
        setLoadedFonts(true)
        typeof callback === "function" && callback()
      })
    }
  }, [])

  return loadedFonts
}
