// @flow
import React, { useState, useEffect } from 'react'
import type { Node } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Filters from './Filters'
import Animated from './Animated'
import FeaturedPrinter from './FeaturedPrinter'
import { useLiterals } from 'store/'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'
import flat from '../lib/flat'
type Printer = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  optionMaterial: string,
  optionRollWidth: string,
  optionProductivity: string,
  optionInk: string,
  url: string
}

type Category = {
  id: string,
  title: string,
  printers: Array<Printer>
}

type SubFilter = {
  id: string,
  title: string
}

type FiltersType = {
  material: Array<SubFilter>,
  rollWidth: Array<SubFilter>,
  productivity: Array<SubFilter>,
  ink: Array<SubFilter>
}

type Filter = {
  id: string,
  category: string,
  label: string
}

type Props = {
  categories: Array<Category>,
  filters: FiltersType,
  type: string,
  children: Node
}

const animations = {
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  },
  dropdown: () => {
    if (typeof document !== 'undefined') {
      const box: any = document.querySelector('#filters-box')
      let height = 0
      if (box) {
        Array.from(box.children).forEach(child => {
          height += child.offsetHeight
        })
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`,
          'margin-top': 0
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`,
          'margin-top': '2rem'
        },
        duration: 0.5
      }
    }
  }
}

const match = (printer, value) => {
  if (!value) return false
  if (
    printer.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
    printer.teaser.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  ) {
    return true
  }
  return false
}

const filterPrinters = (categories, selectedFilters, search) => {
  const selectedPrinters = []

  categories
    .map(category => category.printers)
    .forEach((printerCategory, index) => {
      selectedPrinters[index] = []
      printerCategory.forEach(printer => {
        if (!selectedFilters.length && !search) {
          selectedPrinters[index].push(printer)
          return
        }
        let shouldBeIncluded = true
        selectedFilters.forEach(filter => {
          if (
            filter.category === 'material' &&
            !printer.optionMaterial.split(',').includes(filter.id)
          ) {
            shouldBeIncluded = false
          }

          if (
            filter.category === 'ink' &&
            !printer.optionInk.split(',').includes(filter.id)
          ) {
            shouldBeIncluded = false
          }

          if (
            filter.category === 'rollWidth' &&
            !printer.optionRollWidth.split(',').includes(filter.id)
          ) {
            shouldBeIncluded = false
          }
          if (
            filter.category === 'productivity' &&
            !printer.optionProductivity.split(',').includes(filter.id)
          ) {
            shouldBeIncluded = false
          }
        })
        if (search && !match(printer, search)) {
          shouldBeIncluded = false
        }
        if (shouldBeIncluded) {
          selectedPrinters[index].push(printer)
        }
      })
    })
  return selectedPrinters
}

const Cross = ({
  small = false,
  color = 'black'
}: {
  small?: boolean,
  color?: string
}) => {
  return (
    <div className="relative flex items-center justify-center w-6 h-6 text-white">
      <div
        className={`absolute h-px bg-${color} ${small ? 'w-10px' : 'w-15px'}`}
        style={{ transform: 'rotate(-45deg)' }}
      ></div>
      <div
        className={`absolute h-px bg-${color} ${small ? 'w-10px' : 'w-15px'}`}
        style={{ transform: 'rotate(45deg)' }}
      ></div>
    </div>
  )
}

const PrintersDJ = ({ filters, type, categories, children, text }: Props) => {
  let [selectedFilters, setSelectedFilters] = useState([])
  const [search, setSearch] = useState('')
  const isMobile = useIsMobile()
  const [dropdown, setDropdown] = useState(false)
  const [printers, setPrinters] = useState(
    categories.map(category => category.printers)
  )
  useEffect(() => {
    const selectedPrinters = filterPrinters(categories, selectedFilters, search)
    setPrinters([...selectedPrinters])
  }, [selectedFilters, search])

  const selectFilter = (filter: Filter) => {
    const check = selectedFilters.find(
      ({ id: filterID, category }) =>
        filterID === filter.id && filter.category === category
    )
    if (!check) {
      selectedFilters.push(filter)
    } else {
      selectedFilters = selectedFilters.filter(
        ({ label }) => label !== filter.label
      )
    }
    setSelectedFilters([...selectedFilters])
  }

  return (
    <Spacer top bottom>
      <Container>
        <Grid className="">
          <div className="sticky top-4.5 md:relative md:top-0 z-20 col-span-4 py-8 bg-white md:col-span-2 md:row-start-1 md:row-span-2 md:py-0 md:pt-4">
            {isMobile ? (
              <>
                <div
                  className="z-20 flex justify-between"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <span className="font-light text-smaller">
                    {useLiterals('search')}
                  </span>
                  <div className="relative flex items-center md:hidden">
                    <Animated
                      key="dropdown-plus"
                      className="absolute h-px bg-black w-15px"
                      type="spring"
                      animation={animations.plus}
                      bind={dropdown}
                    ></Animated>
                    <div className="h-px bg-black w-15px"></div>
                  </div>
                </div>
                <Animated
                  bind={dropdown}
                  type="spring"
                  animation={animations.dropdown()}
                  className="overflow-hidden"
                >
                  <Filters
                    filters={filters}
                    type={type}
                    selectedFilters={selectedFilters}
                    selectFilter={selectFilter}
                    onValueChange={setSearch}
                    isMobile={isMobile}
                    dropdown={dropdown}
                    inputValue={search}
                  />
                </Animated>
              </>
            ) : (
              <>
                <div className="z-20 flex justify-between mb-8">
                  <span>{useLiterals('search')}</span>
                </div>
                <Filters
                  filters={filters}
                  type={type}
                  selectedFilters={selectedFilters}
                  selectFilter={selectFilter}
                  onValueChange={setSearch}
                  isMobile={isMobile}
                  dropdown={dropdown}
                  inputValue={search}
                />
                <div className="hidden mt-8 md:block">{children}</div>
              </>
            )}
          </div>
          <div
            className={`col-span-4 mt-12 row-start-1 md:col-span-9 md:col-start-4 md:mt-0 md:pt-4 ${
              !selectedFilters.length && !search ? 'hidden' : 'block'
            }`}
          >
            <div className="w-full pb-2 mb-2 font-light border-b">
              {useLiterals('we_found').replace(
                '*',
                ` ${flat(printers).length} `
              )}
            </div>
            <div className="flex flex-wrap items-center h-full pb-4 font-light cursor-pointer jusitfy-center text-smaller">
              {selectedFilters.map(({ label, id, category }, index) => (
                <div
                  key={label}
                  className={`flex p-1 px-2 ${
                    !index ? 'mr-1 mt-1 mb-1' : 'm-1'
                  } text-black transition rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller`}
                  onClick={() => {
                    selectFilter({ category, id, label })
                  }}
                >
                  <span className="flex items-center text-xs">{label}</span>
                  <Cross small={true} />
                </div>
              ))}
              {search && (
                <div
                  className="flex p-1 px-2 m-1 text-black rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller"
                  onClick={() => {
                    setSearch('')
                  }}
                >
                  <span className="flex items-center text-xs">{search}</span>
                  <Cross small={true} />
                </div>
              )}
            </div>
          </div>
          <div
            className={`grid col-span-4 gap-4 md:col-start-4 md:col-span-9 ${
              !selectedFilters.length && !search
                ? 'md:row-start-1 pt-2'
                : 'md:row-start-2 pt-12'
            } md:row-span-3`}
          >
            <div className={`${flat(printers).length ? 'block' : 'hidden'}`}>
              {categories.map(({ title, id }, index) => (
                <React.Fragment key={id}>
                  {printers[index].length ? (
                    <div className="grid gap-4 md:grid-cols-3">
                      <h2
                        className={`${
                          !index ? 'mb-4' : 'my-4'
                        } text-big2 md:col-span-3`}
                      >
                        {title}
                      </h2>
                      <p className="mb-12 md:col-span-3">{text}</p>
                      {printers[index].map((printer: Printer) => (
                        <div
                          className="mb-6"
                          key={`${index}-${printer.id}-${Math.random()}`}
                        >
                          <FeaturedPrinter
                            {...printer}
                            small={true}
                            selectNone={false}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
            <div
              className={`pt-2 ${flat(printers).length ? 'hidden' : 'block'}`}
            >
              {useLiterals('not_found')}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PrintersDJ
