// @flow

import React, { useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Html from './Html'
import LazyImage from './LazyImage'
import Appear from './Appear'
import Button from './Button'
import type { ApiImage } from '../types'
import makeLayer from '../lib/makeLayer'
import Markdown from './Markdown'

type Props = {
  position: string,
  image: ApiImage,
  text: string,
  ctaText1: string,
  ctaUrl1: string,
  urlForm1: any,
  ctaText2: string,
  ctaUrl2: string,
  urlForm2: any,
  title: string,
  supertitle: string,
  anchor: string,
  style?: string,
} 

const BookDemo = ({
  image,
  text,
  ctaText1,
  ctaUrl1,
  ctaText2,
  ctaUrl2,
  title,
  supertitle,
  urlForm1,
  urlForm2,
  anchor,
  style,
}: Props) => {
  if (style === "630-printer") {
    // The following useEffect is taking the RequestFreeSample and ContactExpert components and 
    // putting them inside BookDemo component because of the desin requirements.
    // Is only used in hp-latex-630-printer-series. 

    useEffect(() => {
      if (document) {
        const requestComponent = document.getElementById("requestFreeSample")
        const contactComponent = document.getElementById("contactExpert")
    
        var request = document.getElementById("requestReplaced")
    
        if (request) {    
          var parentDiv = request.parentNode
          parentDiv.replaceChild(requestComponent, request)
        }
        
        var contact = document.getElementById("contactReplaced")
    
        if (contact) {    
          var parentDiv3 = contact.parentNode
          parentDiv3.replaceChild(contactComponent, contact)
        }
      }
    }, [])

    return (
      <Container className='bg-realBlack text-white py-10'>
        <div className='w-max flex flex-wrap mx-auto items-center justify-center flex-col sm:flex-row gap-3'>
          <div className='bg-darkgray flex h-30rem md:h-25rem lg:h-30rem sm:w-47/100 flex-col md:flex-row rounded-lg overflow-hidden' id="bookDemo">
            <div className='h-2/3 md:h-full md:w-1/2 flex flex-col justify-between'>
              <div>
                <Appear>
                  <h3 className="font-secondary text-big2 p-5">{title}</h3>
                  <div className='w-full h-1px bg-graySlider'></div>
                </Appear>
                <Appear>
                  <Markdown text={text} className='p-5' />
                </Appear>
              </div>
              {(ctaText1 || ctaText2) && 
                <Appear className='flex gap-4 p-5'>
                  {ctaText1 && <Button title={ctaText1} to={urlForm1 || ctaUrl1} style="outlineWhite" textClasses='text-white text-normal hover:text-black' padded />}
                  {ctaText2 && <Button title={ctaText2} to={urlForm2 || ctaUrl2} style="outlineWhite" textClasses='text-white text-normal hover:text-black' padded />}
                </Appear> 
              }
            </div>
            <div className='h-1/3 md:h-full md:w-1/2 overflow-hidden'>
              <LazyImage cover image={image} alt={title} className='w-full h-full' />
            </div>
          </div>
          <div id="requestReplaced" />
          <div id="contactReplaced" />
        </div>
      </Container>
    )
  }

  if (style?.toString() === "-1") {
    return(
    <Grid noGap>
      <div className='col-span-4 col-start-1 row-start-2 md:col-start-6 md:col-span-7 md:row-start-1'>
        <LazyImage cover image={image} alt={title}/>
      </div>
      <div className={`row-start-1 md:row-start-1 col-start-1 md:row-start-1 bg-lightBeige22 col-span-4 md:col-span-5 flex flex-col justify-between`}>
        <Appear>
          <h3 className='w-full p-6 leading-none font-secondary bg-cyan text-30px md:text-50px'>{title}</h3>
          <div className="w-full my-6" />
          <div className='px-4 pb-12 md:w-3/4 text-20px'>
            <Markdown text={text} />
          </div>
        </Appear>
        <Appear className='flex p-4 pb-8'>
        <Button style="black" to={urlForm1 || ctaUrl1} title={ctaText1} />
        </Appear> 
      </div>
    </Grid>
    )
  }

  if (style === "underline") {
    return (
      <Grid noGap>
        <div className='col-span-4 col-start-1 row-start-2 md:col-start-6 md:col-span-7 md:row-start-1'>
          <LazyImage cover image={image} alt={title}/>
        </div>
        <div className={`row-start-1 md:row-start-1 pt-12 col-start-1 md:row-start-1 col-span-4 md:col-span-5 bg-black text-white flex flex-col justify-between`}>
          <Appear>
            <h3 className='w-2/3 px-4 leading-none font-secondary text-30px md:text-50px'>{title}</h3>
            <div className="w-full my-6 border-b border-white" />
            <div className='px-4 pb-12 md:w-3/4'>
              <Markdown text={text} />
            </div>
          </Appear>
          {(ctaText1 || ctaText2) && <Appear className='flex p-4 pb-8'>
            {ctaText1 && <Button title={ctaText1} to={urlForm1 || ctaUrl1} style="outlineBlack" textClasses='text-white' padded/>}
            {ctaText2 && <Button title={ctaText2} to={urlForm2 || ctaUrl2} style="outlineWhite" textClasses='text-white' padded className='ml-4'/>}
          </Appear> }
        </div>
      </Grid>
    )
  }

  return (
    <div className='py-6 bg-white md:py-12'>
      <Container>
        <Grid>
          <div
            className={`col-span-4 relative text-black flex flex-row ${ style !== "visid" ? "md:col-span-10 md:col-start-2" : "md:col-span-8 md:col-start-3 "}`}
            data-anchor={anchor}
          >
              <div
                className="w-1/3 md:w-1/2"
              >
                <LazyImage cover image={image} alt={title} />
              </div>
              <div className={`w-2/3 md:w-1/2 flex flex-col ${style !== "visid" ? "bg-orange" : "bg-skyBlue"}`}>
                <div className='flex flex-col justify-center p-3 md:p-6'>
                  <Appear>
                    {supertitle ? <h5 className="mb-2 text-normal">{supertitle}</h5> : null}
                    <h3 className="font-secondary text-big2">{title}</h3>
                  </Appear>
                </div>
                <div className={`p-3 md:p-6 flex-grow ${style !== "visid" ? "bg-nude" : "bg-skyBlueLight"} `}>
                  <div className="col-span-4 col-start-1 md:col-span-4 md:col-start-2">
                    <Appear>
                      <div className="text-sm font-light md:text-20px"><Html text={text} /></div>
                    </Appear>
                  </div>
                  <div className="col-span-4 col-start-1 md:col-span-5 md:col-start-2">
                    <div className="flex mt-4 md:mt-6">
                      {ctaText1 && <div className="mr-4"><Appear><Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'footer',linkID:'live-product-demo'})} name="book-demo" style="outlineOnly" title={ctaText1} href={urlForm1 === false ? ctaUrl1 : ''} to={urlForm1} /></Appear></div>}
                      {ctaText2 && <Appear>
                        <Button name="book-demo-2" style="white" title={ctaText2} href={urlForm2 === false ? ctaUrl2 : ''} to={urlForm2} /></Appear>}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default BookDemo