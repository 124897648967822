// @flow
import React, { useState, useEffect } from 'react'
import Animated from './Animated'
import { setCookie, getCookie } from '../lib/cookies'
import { navigate } from 'gatsby'

type Item = {
  title: string,
  url: string,
  active: boolean
}

type Props = {
  title: string,
  text: string,
  items: Array<Item>,
  ctaText: string
}

const animations = {
  fade: {
    to: {
      opacity: 1
    },
    from: {
      opacity: 0
    },
    duration: 0.5
  },
  arrowLeft: {
    from: {
      transform: `translateX(-10px) rotate(45deg)`
    },
    to: {
      transform: `translateX(-10px) rotate(-45deg)`
    },
    duration: 0.3
  },
  arrowRight: {
    from: {
      transform: `rotate(-45deg)`
    },
    to: {
      transform: `rotate(45deg)`
    },
    duration: 0.3
  },
  selector: (length: number) => {
    if (typeof document !== 'undefined') {
      const box = document.querySelector('#selector')
      let height = 0
      if (box) {
        height = box.offsetHeight * length - length * 2
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`,
          'margin-top': 0
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`
        },
        duration: 0.5
      }
    }
  }
}

const Selector = ({
  moduleName,
  items,
  handleChange
}: {
  items: Array<Item>,
  handleChange: Function
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className="relative flex flex-col w-64 p-2 overflow-hidden bg-black border border-white cursor-pointer md:w-64 max-h-200px"
      onClick={() => setOpen(!open)}
      id="selector"
      // data-tag-name={`selector-${item.type}`}
    >
      <div className="flex items-center justify-between">
        <div className="text-white">
          {items.find(({ active }) => active).title}
        </div>
        <div className="relative">
          <Animated
            className="absolute h-px bg-white w-15px"
            type="spring"
            animation={animations.arrowLeft}
            bind={open}
          ></Animated>
          <Animated
            className="h-px bg-white w-15px"
            type="spring"
            animation={animations.arrowRight}
            bind={open}
          ></Animated>
        </div>
      </div>
      <Animated
        animation={animations.selector(
          items.filter(({ active }) => !active).length
        )}
        bind={open}
        type="spring"
        className="flex flex-col w-full overflow-scroll max-h-200px"
      >
        {items.map(({ url, title, active }: Item) => {
          if (active) return
          return (
            <div
              key={url}
              className="text-white transition border-b border-white duration-250 hover:bg-white hover:text-black"
              onClick={() => handleChange({ url, title, active })}
            >
              <p className="p-2">{title}</p>
            </div>
          )
        })}
      </Animated>
    </div>
  )
}

const LanguageSelector = ({ title, text, items, ctaText }: Props) => {
  const [overlay, setOverlay] = useState(false)
  const [itemsState, setItemsState] = useState(items)

  const handleChange = ({ url, title }: Item) => {
    const newState = itemsState.map(item => {
      if (item.title === title && item.url === url) {
        return {
          title,
          url,
          active: true
        }
      }
      return {
        title: item.title,
        url: item.url,
        active: false
      }
    })
    setItemsState(newState)
  }

  useEffect(() => {
    const cookie = getCookie('country')
    if (window.location.pathname === '/' && !cookie) {
      setTimeout(() => {
        setOverlay(true)
      }, 500)
    }
    // Aqui deberias hacer un listener para el evento que va a disparar el footer
    document.body.addEventListener('changeRegion', e => {
      setOverlay(e.detail.open)
    })
  }, [])


  const handleClick = () => {
    const destination = itemsState.find(({ active }) => active).url
    setCookie('country', destination)
    if (window.location.pathname.includes(destination)) {
      setOverlay(false)
    }
    navigate(destination)
  }

  if (!overlay) {
    return null
  }
  return (
    <div
      className={`fixed inset-0 z-50 overlay flex items-center justify-center fade-in opacity-0`}
    >
      <div className="flex flex-col items-center justify-center p-8 text-white bg-black w-9/10 md:p-24 md:w-7/10">
        <h4 className="mb-8">{title}</h4>
        <p className="mb-8 font-light">{text}</p>
        <div className="mb-8">
          <Selector items={itemsState} handleChange={handleChange} />
        </div>
        <div
          onClick={handleClick}
          className="inline-block w-24 p-2 text-center text-black transition bg-white border border-white cursor-pointer md: hover:bg-black hover:text-white duration-250 md:px-4 lg:px-6 md:py-3 text-small md:w-32"
        >
          {ctaText}
        </div>
      </div>
    </div>
  )
}

export default LanguageSelector
