// @flow
import React, { useState, useEffect } from "react"
import Button from "./Button"
import LazyImage from "./LazyImage"
import Html from "./Html"
import type { ApiImage } from "../types"
import { useLiterals } from "store/"
import LinkHP from "./LinkHP"
import makeLayer from "../lib/makeLayer"

type FeaturedPrintersType = {
  id: string,
  image: ApiImage,
  title: string,
  teaser?: string,
  url: string,
  width?: string | number,
  withLink?: boolean,
  className?: string,
  small?: boolean,
  selectNone?: boolean,
  i?: any,
  ignoreMargin?: boolean,
  invertTextColor?: boolean,
  type: "printers" | "printersWithBorders" | "stories",
  style?: string,
}

function resolveMargin(ignore: boolean, i: number, type: string): string {
  const margin = type === "printersWithBorders" ? "md:ml-0" : "md:ml-4 lg:ml-0"
  if (!ignore) {
    return i === 0 ? margin : ""
  }
  return ""
}

const FeaturedPrinter = ({
  moduleName,
  id,
  withLink,
  teaser,
  className,
  width,
  type,
  i,
  image,
  title,
  url,
  linkid,
  small = false,
  selectNone = true,
  ignoreMargin = false,
  invertTextColor = false,
  borders = true,
  style,
}: FeaturedPrintersType): React$Node => {
  const [eventType, setEventType] = useState()

  useEffect(() => {
    if (type) {
      switch (type) {
        case "printers":
          setEventType("e_learnClick")
          break
        case "stories":
          setEventType("e_linkClick")
          break
        default:
          break
      }
    }
  }, [])

  return (
    <div
      style={width ? { width: `${width}px` } : {}}
      className={`${className ? className : ""} text-left ${
        selectNone ? "select-none" : ""
      } flex-shrink-0 w-full ${
        type === "printersWithBorders" ? "px-4" : "mr-4"
      } ${resolveMargin(ignoreMargin, i, type)}`}
    >
      <div
        className={`
          ${(style === 'lion' || type === 'printersWithBorders') && borders ? "border-paleGray border" : ""}
          ${style === "lion" ? "bg-transparent p-6 md:p-10 mb-10" : "mb-8 bg-grayLight"}
        `}
      >
        {withLink ? (
          <LinkHP to={url}>
            <LazyImage cover={true} image={image} alt={title} />
          </LinkHP>
        ) : (
          <LazyImage
            cover={true}
            image={image}
            alt={title}
            draggable={!selectNone}
          />
        )}
      </div>
      {style === "lion" ? (
        <h5
          className={`mb-4 text-2.5xl md:text-30px text-black text-center max-w-170 mx-auto leading-tight `}
        >
          <LinkHP draggable={false} to={url}>
            <Html text={title} />
          </LinkHP>
        </h5>
      ) : (
        <h5
          className={`mb-4 ${!small ? "text-big2" : ""} ${
            invertTextColor ? "text-white" : "text-black"
          }`}
        >
          <LinkHP className="font-secondary" draggable={false} to={url}>
            <Html text={title} />
          </LinkHP>
        </h5>
      )}
      <div
        className={`${
          style === "lion" ? "max-w-230 text-center mx-auto pb-2" : "pb-2"
        } mb-4 font-light ${
          invertTextColor ? "text-white" : "text-black"
        }`}
      >
        <div className="text-xl"> 
          <Html text={teaser || ""} />
        </div>
      </div>
      <Button
        style="whiteOutlineBlack"
        padded={true}       
        title={useLiterals("learn_more")}
        href={url}
        dataLayer={makeLayer({
          event: eventType,
          linkPlacement: "related",
          linkID: linkid,
        })}
      />
    </div>
  )
}

export default FeaturedPrinter
