// @flow
import React, { useState, useRef, useEffect, useCallback } from 'react'
import Animated from './Animated'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from 'store/'
import type { InputRef } from '../types'
import { debounce } from 'lodash'
import makeLayer from '../lib/makeLayer'

type SubFilter = {
  id: string,
  title: string,
  slug: string,
}

type FiltersType = {
  material: Array<SubFilter>,
  rollWidth: Array<SubFilter>,
  productivity: Array<SubFilter>,
  ink: Array<SubFilter>
}

type FiltersProps = {
  filters: FiltersType,
  type: string,
  selectedFilters: Array<any>,
  onValueChange: Function,
  isMobile: boolean,
  dropdown: boolean,
  selectFilter: Function,
  inputValue: string,
  printerFilter: string,
}

const animations = {
  filter: (filter: string, isMobile) => {
    if (typeof document !== 'undefined') {
      let height = 0
      const box = document.querySelector(`#${filter}`)
      if (box) {
        if (isMobile) {
          const filtersBox: any = document.querySelector('#filters-box')
          filtersBox.parentElement.style.overflow = 'scroll'
        }
        Array.from(box.children).forEach(child => {
          height += child.offsetHeight
        })
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`
        },
        duration: 0.5
      }
    }
  },
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  },
}

const Filters = ({
  moduleName,
  id,
  filters,
  selectedFilters,
  selectFilter,
  onValueChange,
  isMobile,
  dropdown,
  inputValue,
  type,
  printerFilter
}: FiltersProps) => {
  let [openFilters, setOpenFilters] = useState([])
  const input: InputRef = useRef()

  const handler = useCallback(
    debounce(() => {
      onValueChange(input.current.value)
    }, 200),
    []
  )

  useEffect(() => {
    input.current.value = inputValue
  }, [inputValue])

  useEffect(() => {
    if (!dropdown) {
      setOpenFilters([])
    }
  }, [dropdown])

  const handleFilterBox = (filter: string) => {
    if (openFilters.includes(filter)) {
      openFilters = openFilters.filter(f => f !== filter)
    } else {
      openFilters.push(filter)
    }
    setOpenFilters([...openFilters])
  }

  return (
    <div id="filters-box" className="bg-white">
      <div className="flex justify-between pb-2 border-b border-1">
        <div className="flex-shrink-0">
          <LazySimpleImage image={useMedia('search')} alt="search" />
        </div>
        <input
          className="flex-1 pl-4 outline-none"
          ref={input}
          onChange={() => handler()}
        />
      </div>
      <div className="w-full mt-8 border-1">
        {Object.keys(filters).map((filter, index) => (
          <div key={filter}>
            <div
              data-tag-name={`${type}-filtertype-${filter}`}
              className={`p-4 border ${index && 'border-t-0'} justify-between`}
            >
              <div
                className="flex justify-between w-full col-span-2 cursor-pointer"
                onClick={() => handleFilterBox(filter)}
              >
                <span className="font-light text-smaller">{useLiterals(filter)}</span>
                <div className="relative flex items-center">
                  <Animated
                    key={`category-${index}`}
                    className="absolute h-px bg-black w-15px"
                    type="spring"
                    bind={openFilters.find(f => f === filter)}
                    animation={animations.plus}
                  ></Animated>
                  <div className="h-px bg-black w-15px"></div>
                </div>
              </div>
              <Animated
                animation={animations.filter(filter, isMobile)}
                bind={openFilters.find(f => f === filter)}
                type="spring"
                className="col-span-2 col-start-1 overflow-hidden border-box"
                id={filter}
              >
                {filters[filter].map(({ id, title, slug }, index) => (
                  <li
                    key={id}
                    className={`relative flex items-start box-border text-black cursor-pointer ${
                      !index ? 'pt-4 pb-2' : 'py-1'
                    }`}
                    data-tag-name={`${type}-filtertype-${filter}-filter-${id}`}
                    onClick={() => selectFilter({ category: filter, id, label: title, slug }) }
                  >
                    <div className="h-full">
                    <div
                      className={`${
                        selectedFilters.find(
                          ({ id: filterID, category }) =>
                            filterID === id && category === filter
                        )
                          ? 'checkbox-selected'
                          : 'checkbox'
                      } flex items-center justify-center flex-shrink-0 self-center`}
                    ></div>
                    </div>
                    <span className="pl-4 font-light text-smaller">{title}</span>
                  </li>
                ))}
              </Animated>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Filters
