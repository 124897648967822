// @flow

import React, { useRef } from "react"
import Container from "./Container"
import Grid from "./Grid"
import Appear from "./Appear"
import ScrollViewer from "./ScrollViewer"
import TitleCtas from "./TitleCtas"
import slugify from 'slugify'
import Button from "./Button"
import makeLayer from '../lib/makeLayer'

type Props = {
  title?: string,
  image360: Object,
  ctaText: string,
  ctaText2?: string,
  ctaUrl: string,
  ctaText2?: string,
  ctaUrl2?: string,
  iframeUrl?: string,
  insideModalContent?: Boolean,
  iframeKey?: string,
  index?: Number,
  style?: string,
  anchor: string,
}

const PolestarImage3D = ({
  title,
  ctaText,
  image360,
  ctaUrl,
  ctaText2 = false,
  ctaUrl2 = false,
  iframeUrl = false,
  insideModalContent = false,
  iframeKey = 'polestarimage3d' + slugify(iframeUrl, { lower: true }),
  index = 0,
  style,
  anchor,
}: Props) => {
  const viewerParent = useRef({})
  const blueCtas = style === "lion" || style === "typhoon"
  
  return (
    <>
    {style === "fullwidth" && title && (
      <div className="pt-16 pb-6 mb-12 border-b border-black md:pt-28 md:pb-8 md:mb-24">
        <Appear><h2 className="px-4 lg:px-0 mx-auto lg:w-9/10 lg:max-w-9/10 xl:max-w-1800 text-40px sm:text-60px md:text-70px lg:text-80px leading-tight text-left">{title}</h2></Appear>
      </div>
    )}
    <Container padding={!insideModalContent}>
      <Grid className={`${style === "lion" ? 'pb-10' : 'pb-16'} md:pb-16 polestarImage3D-${index}`}>
      <div data-anchor={anchor} />
        {title && style !== "fullwidth" && (
          <div
            className={`col-span-4 ${
              insideModalContent ? "" : "pt-5 md:pt-6"
            } pb-4 md:pb-8 md:col-span-12`}
            data-360
          >
            <Appear>
              <TitleCtas
                title={title}
                titleLight={false}
                className="flex flex-col items-start justify-start px-5 pb-4 md:px-8"
                // ctaUrl1={ctaUrl && blueCtas ? ctaUrl : null}
                // ctaText1={ctaText && blueCtas ? ctaText : null}
                // ctaUrl2={ctaUrl2 && blueCtas ? ctaUrl2 : null}
                // ctaText2={ctaText2 && blueCtas ? ctaText2 : null}
                ctaUrl4={ctaUrl}
                ctaText4={ctaText}
                titleBig={blueCtas}
                style={style}
              />
              <div className='w-full h-px bg-black'></div>
              {!style && <div className="flex flex-col items-center justify-center md:items-start md:flex-row">
                {ctaUrl && 
                  <Appear>
                    <div className={`mb-4 md:mx-2`}>
                      <Button
                        href={ctaUrl}
                        title={ctaText}
                        style="blackOutlined"
                        dataLayer={makeLayer({
                          event: 'e_document',
                          documentAction: 'download',
                          documentID: 'polestar-image3D-cta1',
                          documentType: 'pdf'
                        })}
                      />
                    </div>
                  </Appear>
                }
                {ctaUrl2 && 
                  <Appear>
                    <div className={`mb-4 md:mx-2`}>
                     <Button
                        href={ctaUrl2}
                        title={ctaText2}
                        style="blackOutlined"
                        dataLayer={makeLayer({
                          event: 'e_document',
                          documentAction: 'download',
                          documentID: 'polestar-image3D-cta2',
                          documentType: 'pdf'
                        })}
                      />
                    </div>
                  </Appear>
                }
              </div>}
            </Appear>
          </div>
        )}
        <span
          className={`flex flex-col items-center justify-center col-span-4 md:col-span-12`}
        >
          {image360 && image360.length > 0 && (
            <Appear>
              <div ref={viewerParent} className="mb-12">
                <ScrollViewer
                  parent={viewerParent}
                  image360={image360}
                  icon={true}
                  iconPosition={blueCtas || style === "fullwidth" ? "top" : "bottom"}
                />
              </div>
            </Appear>
          )}
          {iframeUrl && iframeUrl !== "" && (
            <Container>
              <Grid>
                <div className={`col-span-4 col-start-1 md:col-span-12 ${insideModalContent? 'bg-white' : ''}`}>
                  <div className="relative pt-80vh md:pt-169">
                    <iframe
                      id={iframeKey}
                      name={iframeKey}
                      src={iframeUrl}
                      frameBorder="0"
                      className="absolute top-0 left-0 w-full h-full px-0"
                    ></iframe>
                  </div>
                </div>
              </Grid>
            </Container>
          )}
        </span>
      </Grid>
    </Container>
    </>
  )
}

export default PolestarImage3D
