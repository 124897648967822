// @flow

import React, { useEffect, useState } from "react"
import Container from "./Container"
import Html from "./Html"
import Appear from "./Appear"
import type { ApiImage } from "../types"
import LazySimpleImage from "./LazySimpleImage"
import { useLiterals, useMedia } from "store/"
import useIsMobile from "../hooks/useIsMobile"
import makeAssetUrl from "../lib/makeAssetUrl"
import TitleUnderlines from "./TitleUnderlines"

type Props = {
  image: ApiImage,
  title: string,
  subtitle: string,
  text?: string,
  menuId?: string,
}

const Wrapper = ({
  menuId,
  image,
  children,
  height,
}: {
  menuId?: string,
  image: ApiImage,
  children: React$Node,
  height: number,
  gradientBlack: number,
}) => {
  return (
    <div
      data-menuid={menuId}
      className={`flex flex-col items-start justify-start w-full text-white bg-center bg-no-repeat bg-cover pt-menu-mobile md:pt-menu-desktop`}
      style={{
        backgroundImage: `url(${makeAssetUrl({
          asset: image,
          isImage: true,
        })})`,
        height: height,
      }}
    >
      {children}
    </div>
  )
}

const ScrollToDiscover = ({ title }: { title: string }) => {
  return (
    <div className="z-10 flex flex-col md:flex-row items-center justify-center w-full mb-8 font-light text-small md:px-8 py-8 md:py-0">
      <div className="h-10 w-10 border border-white rounded-full flex flex-col md:flex-row justify-center items-center p-3 order-2 md:order-1 mt-4 md:mt-0">
        <Appear>
          <LazySimpleImage
            imageClassName="h-3"
            image={useMedia("white-arrow-down")}
            alt={title}
          />
        </Appear>
      </div>
      <span className="inline-block md:ml-4 order-1 md:order-2">
        <Appear>{useLiterals("scroll_to_discover")}</Appear>
      </span>
    </div>
  )
}

const CategoryBlock0 = ({
  menuId,
  image,
  title,
  subtitle,
  text,
  gradientBlack = 0,
}: Props) => {
  const [height, setHeight] = useState(0)
  const isMobile = useIsMobile()
  
  useEffect(() => {
    if (isMobile) {
      setHeight(`${((window.innerHeight - 72) * 100) / window.innerHeight}vh`)
    } else {
      setHeight(`${((window.innerHeight - 110) * 100) / window.innerHeight}vh`)
    }
  }, [isMobile])

  if (text)
    return (
      <Wrapper menuId={menuId} image={image} height={height}>
        <Appear className="w-full pt-6">
          <h1 className="text-5xl sm:text-70px md:text-6.5xl md2:text-100px lg:text-120px">
            <TitleUnderlines>
              {title}
            </TitleUnderlines>
          </h1>
        </Appear>
        <Container
          height="h-full"
          className="relative z-10 flex flex-col w-full h-full pt-3 px-5 md:px-8"
        >
          <Appear className="pt-6">
            <span className="text-big2">
              <Html text={subtitle} />
            </span>
          </Appear>
          <Appear>
            <div className="text-xl font-light pt-6 w-3/4 lg:max-w-7/10">
              <Html text={text} />
            </div>
          </Appear>
        </Container>
        <div className="flex w-full md:w-auto">
          <ScrollToDiscover title={title} />
        </div>
      </Wrapper>
    )

  return (
    <Wrapper menuId={menuId} image={image} height="100vh">
      <Appear className="w-full pt-6 mb-10">
        <h1 className="text-4xl sm:text-70px md:text-6.5xl md2:text-90px">
          <TitleUnderlines>
            {title}
          </TitleUnderlines>
        </h1>
      </Appear>
      <Container
        height="flex-1"
        className="relative z-10 flex flex-col w-full h-full px-5 md:px-8"
      >
        <Appear>
          <span className="text-big2">
            <Html text={subtitle} />
          </span>
        </Appear>
      </Container>  
      <div className="flex w-full md:w-auto">
        <ScrollToDiscover title={title} />
      </div>
    </Wrapper>
  )
}

export default CategoryBlock0
