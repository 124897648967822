// @flow
import React from 'react'
import Appear from './Appear'
import Collapsible from './Collapsible'
import Container from './Container'
import Grid from './Grid'
import Markdown from "./Markdown"

type ItemProps = {
    title: string,
    text: string,
  }

type Props = {
  title?: string,
  items: Array<ItemProps>,
  iconCross: boolean;
  fullWidth: boolean; 
} 

const DropdownList = ({ title, items }: Props) => {

  return (
    <Container className="pt-8 pb-10 sm:pt-12 md:pb-16">
      <Grid>
        <Appear className="col-span-4 pb-4 mb-8 md:mb-12 border-b border-black md:col-span-12">
          <h1 className="text-40px sm:text-60px md:text-70px lg:text-80px leading-tight">
              {title}
          </h1>
        </Appear>
          {items.map((item) => (
              <Appear key={item.key} className='col-span-4 md:col-span-12'>
                <Collapsible
                  title={item.title}
                  index={item.index}
                  icon="cross"
                  fullWidth
                  titleClassName="text-base sm:text-20px md:text-30px pr-4 leading-tight"
                  spacing="my-2"
                >
                  <Markdown text={item.text} className="mb-4 md:mb-8"/>
                </Collapsible>
              </Appear>
          ))}
      </Grid>
    </Container>
  )
}

export default DropdownList