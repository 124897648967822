// @flow
import React from 'react'
import { ApiImage } from '../types'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from './Markdown'
import Button from './Button'
import Appear from './Appear'

type ItemProps = {
  title: string,
  text: string,
  size: "single" | "double",
  image1: ApiImage,
  image2?: ApiImage,
  ctaText?: string,
  ctaUrl?: string,
  image1Vertical?: number,
  image2Vertical?: number,
  }

type Props = {
  title: string,
  items: Array<ItemProps>,

} 

const PrinterApplicationsGrid = ({ title, items }: Props) => {

  return (
    <>
      <div className='pt-6 border-b border-black md:pt-12'>
        <Appear>
          <h3 className="px-4 pb-4 mx-auto mt-4 leading-tight lg:w-9/10 lg:max-w-9/10 xl:max-w-1800 lg:px-0 text-40px sm:text-60px md:text-70px lg:text-80px">{title}</h3>
        </Appear>
      </div>
      <Container className="pb-12 md:pb-24">
        <Grid>
          {items.map((item, index) => {
            const doubleItem = item.size === "double"
            const smallItem = (items[index - 1]?.size === "double") || (items[index + 1]?.size === "double")
              return (
                <Appear
                  key={item.key}
                  className={`
                  ${doubleItem ? "md:col-span-9" : `${smallItem ? "md:col-span-3 justify-start" : "md:col-span-4 justify-between"}`}
                  col-span-4 sm:col-span-2 mt-8 md:mt-16 flex flex-col`}
                >
                  <div className={doubleItem || smallItem ? "" : "flex-1"}>
                  <h4 className="pb-4 mb-4 border-b border-black text-20px" >{item.title}</h4>
                  <Markdown className="mb-6" text={item.text} />
                  </div>
                  <div className={`${doubleItem ? "h-full" : `${smallItem ? "" : "md:h-30vw flex-1"}`}`}>
                  <div className="flex items-stretch w-full h-full">
                    <div className={`${doubleItem ? `mr-2 md:mr-4 ${item.image1Vertical === 1 ? "w-1/3" : "w-2/3"}` : "w-full"}`}>
                      <LazyImage
                        cover
                        image={item.image1}
                        alt={item.title}
                      />
                    </div>
                    {item.image2.length !== 0 && <div className={`${doubleItem ? `${item.image2Vertical === 1 ? "w-1/3" : "w-2/3"}` : "w-full"} `}>
                      <LazyImage
                        cover
                        image={item.image2}
                        alt={item.title}
                      />
                    </div>}
                  </div>
                  </div>
                  {(item.ctaText || item.ctaUrl) && <div className="my-4 md:my-6">
                  <Button title={item.ctaText} href={item.ctaUrl} style="whiteOutlineBlack"/>
                  </div>}
                </Appear>
              )
            })}
        </Grid>
      </Container>
    </>
  )
}

export default PrinterApplicationsGrid
