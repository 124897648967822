// @flow
import React, { useState, useEffect } from 'react'

type Props = {
  image: string,
  alt: string,
  imageClassName: string,
}

const Svg = ({ image, imageClassName }: Props) => {
  const [imageData, setImageData] = useState(null)
  const [hasImage,] = useState((typeof image !== 'undefined' && image.length > 0))

  const loadImage = () => {
    fetch(image)
      .then(response => {
        if (response.status === 200) {
          return response.text()
        }
        return false
      })
      .then(svg => {
        if (svg) {
          svg = svg.replace('<svg', '<svg class="fill-current max-h-full max-w-full"')
          setImageData(svg)
        }
      })
  }

  useEffect(() => {
    if (hasImage) {
			loadImage()
		}
  }, [])

  return (
    <span className={imageClassName} dangerouslySetInnerHTML={{ __html: hasImage ? imageData : null }} />
  )
}

export default Svg
