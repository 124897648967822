// @flow

import React, { useState, useEffect } from 'react'
import LazyImage from './LazyImage'
import Video from './Video'
import Grid from './Grid'
import Markdown from './Markdown'
import Html from './Html'
import makeAssetUrl from '../lib/makeAssetUrl'
import LinkExternalHP from './LinkExternalHP'
import PolestarImage3D from './PolestarImage3D'
import makeLayer from '../lib/makeLayer'

type Props = {
  pointData: Object,
  inIframe: boolean,
}

const Polestar3DModalContent = ({ pointData, inIframe }: Props) => {
  const { title, description, content, point } = pointData
  const [orderedCategories, setOrderedCategories] = useState([])

  const IconArrow = () => (
    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Latex_800" transform="translate(-483.000000, -497.000000)" fill="#0096D6">
              <g id="header" transform="translate(148.000000, 294.000000)">
                  <g id="Group-33" transform="translate(0.000000, 184.000000)">
                      <g id="CTA_2" transform="translate(232.000000, 14.000000)">
                          <path d="M106.427136,15.6507571 L100.98889,8.77335125 C100.849694,8.59655624 100.876093,8.33656357 101.048888,8.19336762 C101.218483,8.04937168 101.472876,8.07497096 101.614472,8.25336592 L107.052719,15.1315718 C107.125517,15.2251691 107.215914,15.2755677 107.305512,15.2755677 C107.395909,15.2755677 107.485506,15.2251691 107.558304,15.1323717 L112.984551,8.26216567 C113.123747,8.08537066 113.37814,8.05897141 113.550135,8.20136739 C113.72213,8.34456335 113.74933,8.60455601 113.609334,8.782151 L108.183887,15.6507571 C107.957493,15.9363491 107.638302,16.1003444 107.306311,16.1003444 C106.973521,16.1003444 106.65433,15.9363491 106.427136,15.6507571 Z" id="Fill-1" transform="translate(107.299311, 12.100344) scale(-1, 1) rotate(90.000000) translate(-107.299311, -12.100344) "></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  )

  const getCategories = () => {
    const categories = []
    const filteredCategories = content.filter((item) => item.modelName === 'Printer3dCategory')
    if (filteredCategories.length === 0) {
      const categoryItems = content
      const category = {
        modelName: "Printer3dCategory",
        key: "ACPxT81B6X3CVw75iXdqA2npZotKOf",
        position: 10,
        categoryName: ""
      }
      let completeCategory = Object.assign(category, { categoryItems })
      categories.push(completeCategory)
    } else {
      const emptyCategoryItems = content.filter((item) => (item.categoryName === '') && item.modelName !== 'Printer3dCategory')
      const emptyCategory = {
        modelName: "Printer3dCategory",
        key: "ACPxT81B6X3CVw75iXdqA2npZotKOf",
        position: 10,
        categoryName: ""
      }
      let completeEmptyCategory = Object.assign(emptyCategory, { categoryItems: emptyCategoryItems })
      categories.push(completeEmptyCategory)
    
      filteredCategories.map(category => {
        const categoryItems = content.filter((item) => (item.categoryName === category.categoryName) && item.modelName !== 'Printer3dCategory')
        let completeCategory = Object.assign(category, { categoryItems })
        categories.push(completeCategory)
      })
    }
    setOrderedCategories(categories)
  }

  useEffect(() => {
    getCategories()
  }, [pointData])

  return (
    <Grid className="text-white md:px-8 md:pb-24 font-primary">
      <div className="col-span-4 mt-4 md:mt-0 md:col-span-6 md:col-start-3 md:pb-24">
        <div className="flex">
          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mb-6 -mt-1 border border-white border-solid rounded-full cursor-pointer md:mb-0 md:mt-1"
            style={{backgroundColor: "rgba(255, 255, 255, 0.25)"}}>
            <div className="flex items-center justify-center w-6 h-6 bg-white rounded-full">
              <span className="text-xs font-normal text-black font-primary">{`${point.replace('POINT', '')}`}</span>
            </div>
          </div>
          <h2 className="ml-6 font-normal leading-none md:pb-8 md:text-50px"><Html text={title} /></h2>
        </div>
        <Markdown text={description} className="font-light leading-normal md:text-base" />
      </div>
      <div className="w-full col-span-4 mt-10 md:mt-0 md:col-span-12">
        {orderedCategories.map((category, index) => {
          const { modelName, key, categoryName, categoryItems } = category
            return (
              <Grid key={index+key}>
                <div className="col-span-4 mb-4 md:col-start-1 md:col-span-1 md:mb-0 ">
                  <h4 className="font-light md:text-base">
                    <Html text={categoryName} />
                  </h4>
                </div>
                <div className="col-span-4 mb-6 md:mb-12 md:col-start-3 md:col-span-10">
                  <div className="grid grid-cols-4 gap-4 md:grid-cols-10">
                    {categoryItems.map((item, indexItem) => {
                      const { modelName, key, title, description, url, image, ctaTitle, ctaUrl, internalUrl } = item
                      if (['Printer3dVideo', 'Printer3dImage'].includes(modelName)) {
                        return (
                          <div key={key} className="col-span-4 pb-2 mb-6 border-b border-grayFooter md:col-span-10">
                            {title && <h3 className="pb-6 text-2xl font-light"><Html text={title} /></h3>}
                            {modelName === 'Printer3dVideo' && <Video extraId={key} url={url} image={image} noGrid={true} centerPlay={true} />}
                            {modelName === 'Printer3dImage' && <LazyImage image={image} alt={title} />}
                            {description && <Markdown text={description} className="w-full py-8 text-base font-light leading-normal md:w-6/10" />}
                            {ctaTitle && ctaUrl && <LinkExternalHP
                            target="_blank"
                              href={`${ctaUrl}${inIframe && ctaUrl.includes('contact') ? '?iniframe=iniframe' : ''}`}
                              className={`text-blue flex items-center hover:underline py-4 flex-1`}
                              dataLayer={makeLayer({
                                event: 'e_contactClick',
                                linkPlacement: (point === 'POINT11') ? 'meeting-room–stand' : 'print-samples-stand',
                                linkID: internalUrl,
                              })}
                            >
                              <span className="text-lg md:text-base font-primary">{ctaTitle}</span>
                              <div className="flex-shrink-0 ml-2 font-primary">
                                <IconArrow />
                              </div>
                            </LinkExternalHP>}
                          </div>
                        )
                      }
                      if (modelName === 'Terms') {
                        const { key, text } = item
                        return (
                          <div key={key} className="col-span-4 pb-2 mb-6 border-b border-grayFooter md:col-span-10">
                            <div className={`mb-8`}>
                              <Markdown text={text} className="text-sm font-light" />
                            </div>
                          </div>
                        )
                      }
                      if (modelName === 'PolestarImage3D') {
                        return (
                          <div key={key} className="col-span-4 pb-2 mb-6 border-b border-grayFooter md:col-span-10">
                            <PolestarImage3D {...item} insideModalContent={true} iframeKey={key} index={indexItem}></PolestarImage3D>
                          </div>
                        )
                      }
                      if (['Printer3dPdf', 'Printer3dGated'].includes(modelName)) {
                        const { modelName, key, title, description, image, ctaTitle, ctaUrl, linkid } = item
                        return (
                          <div key={key} className="flex grid grid-cols-4 col-span-4 pb-2 mb-6 border-b md:grid-cols-10 border-grayFooter md:col-span-5">
                            <div
                              className="flex-shrink-0 w-full col-span-4 mt-1 bg-center bg-top bg-no-repeat bg-contain md:col-span-4 snap-align-start min-h-150 md:snap-align-center"
                              style={{
                                backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`
                              }}
                            ></div>
                            <div className="col-span-4 mt-2 md:mt-0 md:ml-4 md:col-span-6 md:col-start-5">
                              {title && <h5 className="mb-3 text-base"><Html text={title} /></h5>}
                              {description && <Markdown text={description} className="text-base font-light" />}
                              {ctaTitle && ctaUrl && <LinkExternalHP
                              target="_blank"
                              href={ctaUrl}
                              className={`text-blue flex items-center hover:underline py-4 flex-1`}
                              dataLayer={ (ctaUrl.indexOf('reinvent.hp.com') === -1) ?
                                makeLayer({
                                  event: 'e_document',
                                  documentAction: 'download',
                                  documentID: linkid,
                                  documentType: 'pdf'
                                })
                              :
                                makeLayer({
                                  event: 'e_contactClick',
                                  linkPlacement: 'content-gated',
                                  linkID: ctaUrl,
                                })
                              }
                            >
                              <span className="text-lg md:text-base font-primary">{ctaTitle}</span>
                              <div className="flex-shrink-0 ml-2 font-primary">
                                <IconArrow />
                              </div>
                            </LinkExternalHP>}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </Grid>
            )
        })}
      </div>
    </Grid>
  )
}

export default Polestar3DModalContent
