// @flow

import React from 'react'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import { useLiterals } from 'store/'
import LinkExternalHP from './LinkExternalHP'
import LinkHP from './LinkHP'
import makeLayer from '../lib/makeLayer'
import Html from './Html'

type Props = {
  item: {
    image: ApiImage,
    title: string,
    buyText: string,
    text: string,
    price?: string,
    anchorPrice?: string,
    inStock: string,
    buyURL?: string,
    ctaUrl?: string,
    ref: Ref,
  },
  darkBackground?: boolean,
}

const parsePrice = (price: string): string => {
  if (price.length === 0) return 0
  const regex = /(?<numbers>(\d|,|\.)+)/
  const parsedNumber = regex.exec(price.replace(',', ''))?.groups?.numbers
  return Number(Number(parsedNumber).toFixed(2))
}

const roundToTwoDecimals = currencyString =>
  currencyString.replace(
    /(\d+)(\.\d+)?/g,
    (match, integerPart, decimalPart) => {
      if (decimalPart) {
        const rounded = parseFloat(integerPart + decimalPart).toFixed(2)
        return rounded.toString()
      }
      return match
    }
  )

const PrinterShoppableItem = ({
  item,
  darkBackground,
}: Props): React$Element<*> => {
  if (!item) return <></>

  const {
    image,
    title,
    buyText,
    text,
    price,
    anchorPrice,
    inStock,
    buyURL,
    ref,
    ctaUrl,
  } = item

  const hasBuyUrl = buyURL && buyURL.length > 0
  const hasCtaUrl = ctaUrl && ctaUrl.length > 0

  const dataLayer =
    hasBuyUrl || hasCtaUrl
      ? makeLayer({
          event: 'e_buyClick',
          linkPlacement: 'content',
          linkID: ref,
        })
      : null

  const displayAnchorPrice =
    parsePrice(anchorPrice) > 0 && parsePrice(price) > parsePrice(anchorPrice)

  return (
    <div
      className="flex flex-col h-full min-h-600 md:min-h-0"
      data-json={JSON.stringify(item)}
    >
      <div className="px-4 relative">
        {image && image.lazy ? (
          <LazyImage image={image} alt={title} />
        ) : (
          <LazySimpleImage image={image} alt={title} />
        )}
        {inStock && inStock.length > 0 ? (
          <div className="absolute bg-darkerGreen22 text-16px text-black bottom-0 left-0 ml-4 px-3 py-1">
            {useLiterals('in_stock') || 'In Stock'}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col flex-grow px-6 pt-4 pb-8">
        <h3 className="mb-5 leading-tight text-left text-18px md:text-20px">
          <Appear>{title}</Appear>
        </h3>
        <span className="block mb-4 font-light leading-6 text-16px">
          <Appear>
            <Html text={text} />
          </Appear>
        </span>
        <Appear className="flex flex-col justify-end flex-grow">
          <div className="flex items-start justify-between text-black">
            <div>
              {anchorPrice !== 'blank' && displayAnchorPrice ? (
                <>
                  {price && price !== 'blank' && (
                    <p className="font-light line-through text-16px text-gray">
                      {roundToTwoDecimals(price)}
                    </p>
                  )}
                  <p className="inline-block font-light text-greenPrice text-22px text-primary">
                    {roundToTwoDecimals(anchorPrice)}
                  </p>
                </>
              ) : (
                <>
                  {price !== 'blank' && (
                    <p className="inline-block font-light text-greenPrice text-22px text-primary">
                      {roundToTwoDecimals(price)}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </Appear>
        {hasBuyUrl || hasCtaUrl ? (
          <hr className="w-full mt-2 mb-5 text-black" />
        ) : null}
        <Appear>
          <div className={darkBackground ? 'border border-white' : ''}>
            {hasBuyUrl ? (
              <LinkExternalHP
                dataLayer={dataLayer}
                data-buyctaref={ref}
                data-buyctahpref={ref}
                className="inline-block px-4 py-3 text-center text-white whitespace-no-wrap bg-black border border-black text-16px hover:bg-white hover:border-black hover:text-black w-full"
                href={buyURL}
                target="_blank"
              >
                {buyText || useLiterals('buy_now')}
              </LinkExternalHP>
            ) : hasCtaUrl ? (
              <LinkHP
                dataLayer={dataLayer}
                className="inline-block px-4 text-center text-white whitespace-no-wrap bg-black border border-black text-16px hover:bg-white hover:border-black hover:text-black"
                to={buyURL}
              >
                {buyText || useLiterals('buy_now')}
              </LinkHP>
            ) : (
              <></>
            )}
            {/* {ref && ref.length > 0 ? <p className="ml-2 font-light text-gray text-normal">{ref}</p> : <p/>} */}
          </div>
        </Appear>
      </div>
    </div>
  )
}

export default PrinterShoppableItem
