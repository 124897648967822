// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import type { ApiImage } from '../types'
import Html from './Html'
import Button from './Button'
import Spacer from './Spacer'

type Props = {
    text: string,
    menuId: string,
    image: ApiImage,
    ctaLabel?: string,
    ctaLink?: string,
    ctaLabel2?: string,
    ctaLink2?: string,
    ctaLabel3?: string,
    ctaLabel4?: string,
    ctaLabel5?: string,
    ctaLink3?: string,
    ctaLink4?: string,
    ctaLink5?: string,
  }
  
  const CategoryBlock20 = ({
    text,
    menuId,
    image,
    ctaLink,
    ctaLabel,
    ctaLabel2,
    ctaLink2,
    ctaLabel3,
    ctaLink3,
    ctaLabel4,
    ctaLabel5,
    ctaLink4,
    ctaLink5,
  }: Props) => {
  
    return (
      <div data-menuid={menuId} className="text-black bg-white" data-hasgradient={1}>
        <Container>
          <Spacer bottom small className="pt-16 md:pt-32">
            <Grid>
                <div className="col-span-4 md:col-start-4 md:col-span-4 md:pr-6"><Html text={text} /></div>
                <div className="col-span-4 mt-8 md:mt-0 md:col-span-5 md:col-start-8">
                    <LazyImage image={image} />
                    <div className="flex flex-wrap items-center">
                        {ctaLink &&
                            <div className="mt-6 sm:mr-6 md:mr-2 lg:mr-6">
                              {ctaLabel && ctaLink && <Button href={ctaLink} title={ctaLabel} style="blackOutlined" />}
                          </div>
                        }
                        {ctaLink2 &&
                            <div className="mt-6 sm:mr-6 md:mr-2 lg:mr-6">
                              {ctaLabel2 && ctaLink2 && <Button href={ctaLink2} title={ctaLabel2} style="blackOutlined" />}
                          </div>
                        }
                        {ctaLink3 &&
                            <div className="mt-6 sm:mr-6 md:mr-2 lg:mr-6">
                              {ctaLabel3 && ctaLink3 && <Button href={ctaLink3} title={ctaLabel3} style="blackOutlined" />}
                          </div>
                        }
                        {ctaLink4 &&
                            <div className="mt-6 sm:mr-6 md:mr-2 lg:mr-6">
                              {ctaLabel4 && ctaLink4 && <Button href={ctaLink4} title={ctaLabel4} style="blackOutlined" />}
                          </div>
                        }
                        {ctaLink5 &&
                            <div className="mt-6 sm:mr-6 md:mr-2 lg:mr-6">
                              {ctaLabel5 && ctaLink5 && <Button href={ctaLink5} title={ctaLabel5} style="blackOutlined" />}
                          </div>
                        }
                    </div>
                </div>
            </Grid>
            </Spacer>
        </Container>
      </div>
    )
  }
  
  export default CategoryBlock20