// @flow

import React from 'react'
import Html from './Html'
import Markdown from './Markdown'

type Props = {
  title: string,
  text: string,
  color?: string,
}

const FeaturesRowItem = ({ title, text, color = 'text-black' }: Props) => {
  return (
    <div className='w-full h-full'>
      <h4 className={`py-5 text-3xl leading-9 w-7/10 ${color} font-secondary`}><Html text={title}/></h4>
      <div className='h-px w-full bg-black mb-5'></div>
      <Markdown text={text} className={`font-light ${color} md:w-9/10 md:text-xl leading-6`} />
    </div>
  )
}

export default FeaturesRowItem
