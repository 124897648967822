// @flow
import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Html from './Html'
import { useLiterals } from 'store/'
import queryString from 'query-string'
import Player from '@vimeo/player'
import jwt from 'jwt-simple'
import simpleTokenSecret from '../lib/simpleTokenSecret'

type WebinarItem = {
  video: string,
  title: string,
  text: string,
  id: string,
}

type Props = {
  item: WebinarItem,
  id: string,
}

type VimeoParams = {
  id?: string,
  url?: string,
  responsive?: boolean,
}

const getVimeoId = url => {
  const match = url
    .split('/')
    .filter(
      segment =>
        /\S/.test(segment) &&
        !segment.includes('http') &&
        !segment.includes('vimeo.com')
    )
  if (match) {
    if (match[1]) {
      return `${match[0]}?h=${match[1]}`
    } else {
      return match[0]
    }
  }
  return '0'
}

const shouldScrollToId = menuId => {
  const element = document.querySelector(`[data-name="${menuId}"`)
  const yOffset = -200
  if (element) {
    const { top } = element.getBoundingClientRect()
    const y = top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

const DisplayWebinar = ({ id, item }: Props): React$Node => {
  const [hasAccess, setHasAccess] = useState(false)

  const loadVideo = () => {
    const vimeoId = getVimeoId(item.video)
    const params: VimeoParams = { responsive: true }
    if (vimeoId.toString().includes('?')) {
      params.url = `https://player.vimeo.com/video/${vimeoId}`
      params.id = id
    } else {
      params.id = vimeoId
    }
    new Player(`player-${id}`, params)
  }

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart.length > 0 && lastPart.includes('featured')) {
        setTimeout(() => {
          shouldScrollToId('featured')
        }, 1000)
      }
    }
  }, [])

  useEffect(() => {
    if (hasAccess) {
      loadVideo()
    }
  }, [hasAccess])

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    if (typeof parsed.token !== 'undefined') {
      var segments = parsed.token.split('.')
      if (segments.length === 3) {
        try {
          const decoded = jwt.decode(parsed.token, simpleTokenSecret)
          console.log('decoded', decoded, item)
          if (Number(decoded.webinarId) === Number(item.id)) {
            setHasAccess(true)
          }
        } catch (e) {
          console.log('Token error', e)
        }
      }
    }
  }, [])

  return (
    <Spacer top bottom>
      <Container>
        <Grid>
          <div
            data-name="featured"
            className="col-span-4 col-start-1 md:col-span-10 md:col-start-2"
          >
            {hasAccess ? (
              <div>
                <div className="mb-12 select-none text-big3">
                  <Html text={item.title} />
                </div>
                <div className="w-full h-full" id={`player-${id}`}></div>
              </div>
            ) : (
              <span>{useLiterals('webinar_token_invalid')}</span>
            )}
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default DisplayWebinar
