// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import LazyImage from './LazyImage'
import Markdown from './Markdown'
import Button from './Button'

type ItemProps = {
  image: ApiImage,
  title: string,
  subtitle: string,
  imageInlineLogo: ApiImage,
  ctaText?: string,
  ctaUrl?: string,
  anchor?: string,
}

type Props = {
  items: Array<ItemProps>,
}

const SpecialCards = ({ title, items }: Props) => {
  return (
    <Container className="pt-12 pb-24 md:pb-32">
      <Grid>
        {items.map(item => {
          return (
            <div
              key={item.key}
              data-anchor={item.anchor}
              className="col-span-4 mb-10 sm:col-span-2 md:col-span-4 xl:col-span-3 h-60vh sm:h-63vw md:h-43vw xl:h-30vw"
            >
              <div className="relative w-full h-full">
                <div className="h-full">
                  <LazyImage cover image={item.image} alt={title} />
                </div>
                <div className="absolute inset-x-0 bottom-0">
                  <div className="p-2 border-b border-white">
                    <Markdown
                      className="leading-none text-white text-30px md:text-40px"
                      text={item.title}
                    />
                  </div>
                  <div className="flex items-center justify-between px-2 py-1">
                    <Markdown
                      text={item.subtitle}
                      className="text-white sm:text-sm"
                    />
                    <div className="w-5 h-5">
                      <LazyImage
                        contain
                        image={item.imageInlineLogo}
                        alt={item.title}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {item.ctaText && (
                <div className="mt-6">
                  <Button
                    style="blackOutlined"
                    href={item.ctaUrl}
                    title={item.ctaText}
                    padded
                  />
                </div>
              )}
            </div>
          )
        })}
      </Grid>
    </Container>
  )
}

export default SpecialCards
