// @flow

import React, { useState } from 'react'
import Appear from './Appear'
import Container from './Container'
import LinkExternalHP from './LinkExternalHP'

type Props = {
  closeModal: Function,
  children: React$Node,
}

const Modal = ({ closeModal, children }: Props) => {
  const Icon = <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="0%" y1="100%" x2="100%" y2="100%" id="linearGradient-1">
            <stop stopColor="#F66464" offset="0%"></stop>
            <stop stopColor="#0632FF" offset="48.0968045%"></stop>
            <stop stopColor="#4EB589" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="06_HomeVB" transform="translate(-22.000000, -2352.000000)">
            <g id="Group-6" transform="translate(12.000000, 2352.000000)">
                <g id="Group-3" transform="translate(10.000000, 0.000000)">
                    <circle id="Oval" stroke="url(#linearGradient-1)" strokeWidth="3" fill="#1D1E1F" cx="25" cy="25" r="23.5"></circle>
                    <g id="X" transform="translate(25.507215, 25.295830) rotate(45.000000) translate(-25.507215, -25.295830) translate(14.792930, 15.091749)" stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                        <line x1="10.5" y1="0" x2="10.5" y2="20" id="Line-3"></line>
                        <line x1="10.5" y1="5.25307797e-10" x2="10.5" y2="20" id="Line-3" transform="translate(10.500000, 10.000000) rotate(-90.000000) translate(-10.500000, -10.000000) "></line>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
  
  return (
    <Appear
      className="fixed top-0 bottom-0 left-0 right-0 w-full h-full overflow-x-hidden overflow-y-scroll z-superTop bg-modal polestarModal"
      finalOpacity="99"
    >
      <div className="sticky top-0 flex items-center justify-end w-full p-6 z-superTop">
        <a className="cursor-pointer" onClick={() => closeModal()}>{Icon}</a>
      </div>
      <Container>
        {children}
      </Container>
    </Appear>
  )
}

export default Modal
