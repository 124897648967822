// @flow
import React from "react"
import Container from "./Container"
import Grid from "./Grid"
import Spacer from "./Spacer"
import Button from "./Button"
import Appear from "./Appear"
import type { ApiImage } from "../types"
import FeaturedPrinter from "./FeaturedPrinter"
import PrintersGallery from "./PrintersGallery"
import makeLayer from "../lib/makeLayer"

type Story = {
  id: string,
  image: ApiImage,
  title: string,
  url: string,
}

type Props = {
  title: string,
  ctaUrl: string,
  stories: Array<Story>,
  ctaText: string,
  page?: string,
  type: string,
}

const RelatedStories = ({
  moduleName,
  id,
  title,
  stories,
  ctaUrl,
  ctaText,
  type,
  page,
}: Props) => {
  return (
    // <Spacer top bottom className={`overflow-hidden ${page && page === 'printer' ? 'text-black bg-white' : 'text-white bg-black'}`}>
    <Spacer
      bottom
      className="overflow-hidden text-black bg-white pt-8 md:pt-16 md:pb-48"
    >
      <Container>
        <Grid>
          <div
            className={`col-span-4 col-start-1  ${
              page && page === "printer"
                ? "md:col-span-9 md:col-start-4 md:-ml-4 flex flex-col md:flex-row justify-between text-left md:items-end"
                : "md:col-span-12 md:col-start-1 flex flex-col md:flex-row justify-between text-left md:items-end"
            }`}
          >
            <Appear>
              {/* <h3 className={`mb-6 text-big3 ${page && page === 'printer' ? 'text-black' : 'text-white'}`}>{title}</h3> */}
              <div className={`${page && page === 'printer' ? "" : "w-full md:w-2/3" }`}>
                <h3 className={`mb-8 md:mb-0 text-big5 text-black`}>{title}</h3>
              </div>
            </Appear>
            <Appear>
              <Button
                style="black"
                title={ctaText}
                href={ctaUrl}
                dataLayer={makeLayer({
                  event: "e_linkClick",
                  linkPlacement: "related",
                  linkID: "all-stories",
                })}
              />
            </Appear>
          </div>
          <div className={`border-b-2 border-black mt-8 md:mt-12 mb-0 -mx-6 md:-ml-4 md:-mr-8 ${page && page === 'printer' ? "block col-span-4 md:col-span-9 md:col-start-4" : "hidden" }`} />
        </Grid>
      </Container>
      <div className={`border-b-2 border-black mt-8 md:mt-12 mb-0 md:mb-16 ${page && page === 'printer' ? "hidden" : "block" }`} />
      {stories.length >= 4 ? (
        <Grid>
          <div
            className={`col-span-4 col-start-1 text-center ${
              page && page === "printer"
                ? "md:col-span-9 md:col-start-4"
                : "md:col-span-12 md:col-start-1"
            }`}
          >
            <div
              className={` ${
                page && page === "printer" ? "md: mt-16" : "mt-8 md:mt-16"
              } md:flex md:justify-start`}
            >
              <PrintersGallery page={page} printers={stories} borders={false} type="printersWithBorders" />
            </div>
          </div>
        </Grid>
      ) : (
        <Grid>
          {stories.map(printer => (
            <FeaturedPrinter
              {...printer}
              withLink={true}
              className="w-full col-span-4 mt-16 mb-8 md:col-span-4 md:mb-0"
              i={0}
              key={printer.id}
              invertTextColor={false}
              ignoreMargin={true}
              type={type}
            />
          ))}
        </Grid>
      )}
    </Spacer>
  )
}

export default RelatedStories
