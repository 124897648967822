/* eslint-disable no-useless-escape */
const setCookie = (name, value, maxAgeMilliseconds = 8765 * 60 * 60 * 1000) => {
  const date = new Date()
  date.setTime(date.getTime() + maxAgeMilliseconds)
  const expires = `;expires=${date.toGMTString()}`
  document.cookie = `${name}=${JSON.stringify(value)};${expires}; path=/`
}

const getCookie = name => {
  const cookie = checkCookie(name)
  if (cookie) {
    return JSON.parse(cookie)
  }
  return null
}

const checkCookie = name => {
  function escape(s) {
    return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, '\\$1')
  }
  if (typeof document != "undefined") {
    var match = document.cookie.match(
      RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
    )
    return match ? match[1] : null
  }
  return null
}

function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export { setCookie, getCookie, checkCookie, removeCookie }
