// @flow
import React, { useEffect, useState } from 'react'
import ModuleIterator from './ModuleIterator'
import { ReverseSubtractEquation } from 'three'

type Props = {
  content: any,
  initialBackgroundColor: string,
  backgroundColor?: string,
  reverseBackgroundAnimation: boolean,
  moduleId: string,
}

const BgColorWrapper = ({
  content,
  backgroundColor,
  initialBackgroundColor,
  reverseBackgroundAnimation,
  moduleId,
}: Props) => {
  const [transition, setTransition] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.screen.availHeight
      const wrapperContainer = document
        .getElementById(moduleId)
        .getBoundingClientRect()

      const limit = reverseBackgroundAnimation
        ? wrapperContainer.bottom
        : wrapperContainer.top

      if (screenHeight - limit > screenHeight / 2) {
        setTransition(true)
      } else {
        setTransition(false)
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getBackgroundColor = () => {
    if (!initialBackgroundColor) return backgroundColor
    return transition ? backgroundColor : initialBackgroundColor
  }

  return (
    <div
      id={moduleId}
      style={{
        backgroundColor: getBackgroundColor(),
        transition: 'background-color 0.5s ease-out',
      }}
    >
      <ModuleIterator modules={content} />
    </div>
  )
}

export default BgColorWrapper
