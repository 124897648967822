const loadYoutube = (callback = () => {}) => {

  if (typeof window.YT !== 'undefined' && typeof window.YT.Player !== 'undefined') {
    callback()
    return
  }

  if (!window.hpYTVideos) window.hpYTVideos = []
  window.hpYTVideos.push(callback)

  window.onYouTubeIframeAPIReady = () => {
    if (typeof window.YT !== 'undefined') {
      window.hpYTVideos.forEach((cb) => {
        cb()
      })
      window.hpYTVideos = []
    }
  }

  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag: any = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

export default loadYoutube