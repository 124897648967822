// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import type { ApiImage } from '../types'

type Props = {
  image: ApiImage,
  menuId?: string,
  whiteParentBG?: boolean,
  sidePosition?: string,
  backgroundColor?: string,
  biggerSpacer?: number,
  cleanTopSpacer?: number,
  hasGradient?: boolean
}

const CategoryBlock9 = ({ menuId, image, whiteParentBG = false, sidePosition = "full", backgroundColor, biggerSpacer = 0, cleanTopSpacer = 0, hasgradient }: Props) => {

  return (
    <div data-menuid={menuId} style={{ backgroundColor: backgroundColor ? backgroundColor : 'auto'}} data-hasgradient={hasgradient && 0 }>
      <Spacer top={cleanTopSpacer ? false : true} bottom smaller={biggerSpacer === 1 ? false : true} className={cleanTopSpacer === 1 ? 'pt-4 md:pt-12 lg:pt-12' : ''}>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center ${sidePosition === "right" ? "md:col-span-6 md:col-start-7" : `${sidePosition === "left" ? "md:col-start-4 md:col-span-6" : "md:col-span-9 md:col-start-4"}`}`}>
              <LazyImage image={image} alt={"Decoration image"}/>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock9