// @flow
import React, { useState, useRef, useEffect } from 'react'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Button from './Button'
import Html from './Html'
import type { ApiImage } from 'types/'
import { useLiterals, useMedia } from '../store'
import type { Ref } from 'types/'

type PrinterApplicationsItemOptions = {
  ctaText: string,
  ctaUrl: string,
  image: ApiImage,
  imagezoom: ApiImage,
  text: string,
  id: string,
}
type Props = {
  items: Array<PrinterApplicationsItemOptions>,
  inGradient?: boolean,
  gradientStart?: string,
  gradientEnd?: string,
  backgroundColor?: String,
}

const scale = 1.5

const Slider = ({
  moduleName,
  // gradientStart = "",
  // gradientEnd = "",
  items,
  inGradient,
  backgroundColor,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [zoom, setZoom] = useState(false)
  const zoomContainer: Ref = useRef()
  const slide: Ref = useRef()

  switch (backgroundColor) {
    case 'B0A8F9':
      backgroundColor = 'bg-purpleSld'
      break
    case 'EF8D72':
      backgroundColor = 'bg-darkSalmon23'
      break
  }

  // const inGradientStyle =
  //   typeof gradientStart !== "undefined" && typeof gradientEnd !== "undefined"
  //     ? {
  //         backgroundImage: `linear-gradient(-45deg, #${gradientStart.replace(
  //           "#",
  //           ""
  //         )} 0%, #${gradientEnd.replace("#", "")} 100%)`,
  //       }
  //     : {}

  useEffect(() => {
    slide.current.style.opacity = '1'
  }, [activeIndex])

  const handleSlider = direction => {
    slide.current.style.opacity = '0'
    setTimeout(() => {
      if (zoom) {
        setZoom(false)
      }
      if (direction === 'backwards') {
        setActiveIndex(activeIndex < 1 ? items.length - 1 : activeIndex - 1)
        return
      }
      setActiveIndex(activeIndex >= items.length - 1 ? 0 : activeIndex + 1)
    }, 250)
  }

  const handleZoom = () => {
    setZoom(!zoom)
    if (!zoom) {
      zoomContainer.current.style.cursor = 'none'
      document.body.style.touchAction = 'none'
      zoomContainer.current.style.clipPath = `circle(10% at 50% 50%)`
      zoomContainer.current.style.transform = `scale(${scale})`
    } else {
      zoomContainer.current.style.cursor = 'none'
      document.body.style.touchAction = 'auto'
    }
  }

  const handleZoomMove = e => {
    if (zoom) {
      const { clientX, clientY } = e
      const { top, left } = slide.current.getBoundingClientRect()
      const { width, height } = zoomContainer.current.getBoundingClientRect()
      let x, y
      if (e.touches) {
        x = (e.touches[0].clientX - left) / scale + width * 0.1
        y = (e.touches[0].clientY - top) / scale + height * 0.1
      } else {
        x = (clientX - left) / scale + width * 0.1
        y = (clientY - top) / scale + height * 0.1
      }
      requestAnimationFrame(() => {
        zoomContainer.current.style.clipPath = `circle(10% at ${x}px ${y}px)`
      })
    }
  }

  const zoomOff = () => {
    setZoom(false)
    document.body.style.touchAction = 'auto'
  }

  return (
    <>
      {items && items.length > 0 && (
        <div
          onClick={() => {
            if (zoom) {
              zoomOff()
            }
          }}
        >
          <div
            className="relative w-full overflow-hidden"
            onMouseMove={handleZoomMove}
            onTouchMove={handleZoomMove}
            onMouseLeave={zoomOff}
          >
            <div
              className="relative flex items-center justify-center w-full transition transition-opacity duration-250"
              ref={slide}
              style={{ paddingTop: '56.25%' }}
            >
              <div className="absolute inset-0">
                <LazyImage
                  contain
                  key={activeIndex}
                  alt={items[activeIndex].ctaText}
                  image={items[activeIndex].image}
                />
              </div>
            </div>
            {items[activeIndex] && items[activeIndex].ctaUrl && (
              <div className="absolute bottom-0 left-0 z-10 mb-4 ml-4">
                <Button
                  style="white"
                  href={items[activeIndex].ctaUrl}
                  to={items[activeIndex].ctaUrl}
                  title={items[activeIndex].ctaText}
                />
              </div>
            )}
            {items[activeIndex].imagezoom && (
              <div
                className={`absolute top-0 right-0 z-10 flex items-center w-12 h-12 mt-4 mr-4 cursor-pointer justify-center ${
                  zoom ? 'opacity-0' : 'opacity-1'
                }`}
                onClick={handleZoom}
              >
                <LazySimpleImage alt="zoom" image={useMedia('zoom')} />
              </div>
            )}
            <div
              className={`absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center transition duration-500 transition transition-opacity ease-in-out ${
                zoom
                  ? 'opacity-1 pointer-events-all'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              <div
                className="flex items-center justify-center w-full h-full overflow-hidden rounded-full"
                ref={zoomContainer}
              >
                <LazyImage
                  alt={'zoom'}
                  image={items[activeIndex].image}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
          <div
            className={`flex items-center ${backgroundColor} ${
              typeof items[activeIndex].text !== 'undefined' &&
              items[activeIndex].text.length > 0
                ? 'justify-between'
                : 'py-4 justify-end'
            }`}
          >
            <div className="w-1/2 md:w-9/12">
              {typeof items[activeIndex].text !== 'undefined' &&
                items[activeIndex].text.length > 0 && (
                  <div
                    className="flex items-center p-4 text-black text-smaller"
                    // style={
                    //   inGradient
                    //     ? { backgroundColor: "rgba(10, 10, 10, 0.4)" }
                    //     : inGradientStyle
                    // }
                  >
                    <span className="text-normal">
                      <Html text={items[activeIndex].text} />
                    </span>
                  </div>
                )}
            </div>
            <div className="w-1/2 md:w-4/12 flex px-4 justify-end items-end">
              <div
                className={`flex items-center justify-center w-10 h-10 md:w-12 md:h-12 pr-1 mr-4 ${
                  inGradient ? 'bg-black' : ''
                } ${
                  items.length < 2 ? 'invisible' : ''
                } rounded-full cursor-pointer`}
                onClick={() => handleSlider('backwards')}
                // style={inGradient ? {} : inGradientStyle}
                style={{
                  backgroundColor: 'transparent',
                  border: '2px solid rgba(10, 10, 10, 0.4)',
                }}
                data-tag-name={`${moduleName}-Slider-previous`}
              >
                <LazySimpleImage
                  alt={useLiterals('previous')}
                  image={useMedia('black-arrow-left')}
                />
              </div>
              <div
                className={`flex items-center justify-center w-10 h-10 md:w-12 md:h-12 pl-1 ${
                  inGradient ? 'bg-black' : ''
                } ${
                  items.length < 2 ? 'invisible' : ''
                } rounded-full cursor-pointer`}
                onClick={() => handleSlider('forwards')}
                // style={inGradient ? {} : inGradientStyle}
                style={{
                  backgroundColor: 'transparent',
                  border: '2px solid rgba(10, 10, 10, 0.4)',
                }}
                data-tag-name={`${moduleName}-Slider-forwards`}
              >
                <LazySimpleImage
                  alt={useLiterals('previous')}
                  image={useMedia('black-arrow')}
                />
              </div>
            </div>
          </div>

          {items.length > 1 && (
            <div className="flex flex-wrap items-center mt-8 overflow-hidden md:flex-no-wrap md:items-stretch">
              {items.map((item, index) => (
                <div
                  key={`slide-${item.id ? item.id : index}`}
                  className={`relative cursor-pointer flex-basis-1/5 md:h-auto h-12 sm:h-24 mb-2 md:mb-0 w-full mr-2 md:mr-4 ${
                    activeIndex === index ? 'opacity-50' : ''
                  }`}
                  onClick={() => setActiveIndex(index)}
                >
                  <div className="relative w-full h-full md:h-0 md:pt-169">
                    <div className="absolute inset-0">
                      <LazyImage
                        contain
                        alt={item.ctaText}
                        image={item.image}
                        className="h-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Slider
