// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'

type Props = {
  text: string,
  menuId?: string,
  whiteParentBG: boolean,
}

const CategoryBlock7 = ({ menuId, text, whiteParentBG = false }: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer top bottom smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 text-black text-smaller md:flex md:flex-col md:justify-center md:col-span-8 md:col-start-4 md:mb-0`}>
              <div className="font-light"><Appear><Html text={text} /></Appear></div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock7