// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
  contentLeft: Node
}

const StoryWrapper = ({ children, contentLeft }: Props) => {
  return (
    <div className="relative bg-white">
      {contentLeft && (
        <div className="z-50 md:top-0 md:bottom-0 md:left-0 md:right-0 md:pointer-events-none md:absolute">
          {contentLeft}
        </div>
      )}
      <div className="z-0 bg-white">{children}</div>
    </div>
  )
}

export default StoryWrapper
