// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import Slider from './Slider'
import Button from './Button'

import type { ApiImage } from 'types/'

type PrinterApplicationsItemOptions = {
  ctaText: string,
  ctaUrl: string,
  image: ApiImage,
  id: string,
  imagezoom: ApiImage,
  text: string,
}
type PrinterApplicationsItem = {
  options: PrinterApplicationsItemOptions,
}
type Props = {
  title: string,
  text: string,
  items: Array<PrinterApplicationsItem>,
  bgcolor?: string,
  color?: string,
  ctaTitle?: string,
  ctaUrl?: string,
}

const PrinterApplications = ({ title, text, items, bgcolor = '#F4EDDD', color = 'black', ctaTitle, ctaUrl }: Props) => {
  return (
    <div data-hasgradient={0} style={{ backgroundColor: bgcolor, color}}>
      <Spacer top bottom>
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 mb-12 md:col-span-5 md:col-start-4">
              <Appear>
                <h3 className="mb-4 text-big1">{title}</h3>
              </Appear>
              <Appear>
                <div>
                  <Html text={text} />
                </div>
              </Appear>
            </div>
            <div className="col-span-4 col-start-1 overflow-hidden md:col-span-9 md:col-start-4">
              <Slider items={items.map(item => item.options)} />
            </div>
            {ctaTitle && ctaUrl && 
              <div className="flex justify-center w-full col-span-4 col-start-1 mt-12 overflow-hidden md:mt-24 md:col-span-9 md:col-start-4"><Appear><Button style="white" title={ctaTitle} href={ctaUrl} /></Appear></div>
            }
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default PrinterApplications
