import config from '../../config'

const makeAssetUrl = ({ asset, isImage = false, extension = false, lazy = false, smallest = false }) => {
  
  if (asset && asset.sizes) {
    if (!asset.name || !asset.sizes.length) return ''
    if (lazy) {
      if (asset.sizes.includes('lazy')) {
        return `${config.cloudfront.baseURL}_resized/${asset.name}-wlazy-qlazy.${extension || 'jpg'}`
      }
      return ''
    }
    const highestRes = asset.sizes.sort((a, b) => Number(b) - Number(a))
    if (highestRes.length > 0) {
      if (smallest) {
        highestRes.reverse()
      }
      const imageSize = highestRes[0]
      return `${config.cloudfront.baseURL}_resized/${asset.name}-w${imageSize}-q65.${extension || 'jpg'}`
    }
    return ""
  }

  if (typeof asset === 'undefined' || !asset || (Array.isArray(asset) && asset.length === 0)) return ''
  if (isImage) {
    // TODO: remove this when we are sure that all images have the new format
    if (lazy) {
      if (typeof asset.lazy !== 'undefined') {
        const imageSizeExtensions = Object.keys(asset.lazy)
        const name = extension ? asset.lazy[extension] : asset.lazy[imageSizeExtensions[0]]
        return `${config.cloudfront.baseURL}_resized/${name}`  
      }
      return ''
    }
    const highestRes = Object.keys(asset).filter(i => i !== 'lazy').sort((a, b) => Number(b) - Number(a))
    if (highestRes.length > 0) {
      if (smallest) {
        highestRes.reverse()
      }
      const imageSize = asset[highestRes[0]]
      const imageSizeExtensions = Object.keys(imageSize)
      const name = extension ? asset[highestRes[0]][extension] : asset[highestRes[0]][imageSizeExtensions[0]]
      return `${config.cloudfront.baseURL}_resized/${name}`
    }
  }
  return `${config.cloudfront.baseURL}${asset.file}`
}

export default makeAssetUrl