// @flow

import React from 'react'
import Page from 'components/Page'
import type { Module, Meta, KeyValue, alternateUrl } from 'types/'

type DefaultTemplateContext = {
  meta: Meta,
  structure: Array<Module>,
  language: string,
  literals: {},
  media: Array<KeyValue>,
  pageName: Array<String>,
  alternateUrls?: Array<alternateUrl>,
}

const DefaultTemplate = ({
  pageContext
}: {
  pageContext: DefaultTemplateContext
}): React$Element<any> => {
  const { structure, meta, literals, language, media, pageName, alternateUrls } = pageContext

  return (
    <Page
      pageName={pageName}
      structure={structure}
      meta={meta}
      literals={literals}
      language={language}
      media={media}
      alternateUrls={alternateUrls}
    />
  )
}

export default DefaultTemplate
