// @flow
import React, { useRef, useEffect } from 'react'
import type { Ref } from '../types'

const Loader = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        animation: 'rotate 2s linear infinite'
      }}
    >
      <path
        d="M72 36C72 55.8822 55.8822 72 36 72C16.1177 72 0 55.8822 0 36C0 16.1177 16.1177 0 36 0C55.8822 0 72 16.1177 72 36ZM11.0792 36C11.0792 49.7634 22.2366 60.9208 36 60.9208C49.7634 60.9208 60.9208 49.7634 60.9208 36C60.9208 22.2366 49.7634 11.0792 36 11.0792C22.2366 11.0792 11.0792 22.2366 11.0792 36Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="36"
          y1="0"
          x2="36"
          y2="72"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.151042" stopColor="#F66464" />
          <stop offset="0.479167" stopColor="#0632FF" />
          <stop offset="0.786458" stopColor="#4EB589" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Loader
