// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'

type Props = {
  id: string,
  url: string,
}

const PrinterApplications = ({ url }: Props) => {
  return (
    <div>
       <Container>
        <Grid>
          <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4">
            <div className="relative pt-80vh md:pt-169">
              <iframe src={url} frameBorder="0" className="absolute top-0 left-0 w-full h-full"></iframe>
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default PrinterApplications
