// @flow

import { useEffect } from "react"
import throttle from "lodash/throttle"

export default function useOnWindowResize(
  callback: () => unknown,
  dependencies: Array<unknown>,
  delay = 100
): void {
  const throttleResize = throttle(callback, delay)

  useEffect(() => {
    if (typeof window === "undefined") return
    callback()
    window.addEventListener("resize", throttleResize, true)
    return window.removeEventListener("resize", throttleResize)
  }, dependencies)
}
