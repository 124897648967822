// @flow
import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import SharedFilters from './SharedFilters'
import Animated from './Animated'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from 'store/'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'
import flat from '../lib/flat'
import Appear from './Appear'
import Html from './Html'
import SimpleButton from './SimpleButton'
import Overlay from './Overlay'
import Cross from './Cross'
import Youtube from './Youtube'
import LinkExternalHP from './LinkExternalHP'
import { getCookie } from "../lib/cookies"
import jwt from 'jwt-simple'
import simpleTokenSecret from '../lib/simpleTokenSecret'

type Video = {
  id: string,
  image: ApiImage,
  title: string,
  teaser: string,
  url: string,
  printerId: string,
  printerTitle: string,
  printerUrl: string
}

type Category = {
  id: string,
  title: string,
  videos: Array<Video>
}

type Props = {
  categories: Array<Category>,
  featured: Video | false
}

const animations = {
  plus: {
    from: {
      transform: 'rotate(90deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    },
    duration: 0.3
  },
  dropdown: () => {
    if (typeof document !== 'undefined') {
      const box = document.querySelector('#filters-box')
      let height = 0
      if (box) {
        height = box.offsetHeight
      }
      return {
        from: {
          'max-height': 0,
          overflow: 'hidden',
          height: `${height}px`,
          'margin-top': 0
        },
        to: {
          'max-height': `${height}px`,
          height: `${height}px`,
          'margin-top': '2rem'
        },
        duration: 0.5
      }
    }
  }
}

const match = (video, value) => {
  if (!value) return false
  if (
    video.printerTitle
      .toLocaleLowerCase()
      .includes(value.toLocaleLowerCase()) ||
    video.teaser.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  ) {
    return true
  }
  return false
}

const filterVideos = (categories, selectedFilters, search) => {
  const selectedVideos = []
  categories
    .map(category => category.howtos)
    .forEach((videoCategory, index) => {
      selectedVideos[index] = []
      videoCategory.forEach(video => {
        if (!selectedFilters.length && !search) {
          selectedVideos[index].push(video)
        }
        if (
          selectedFilters.find(filter => filter.label === video.title) ||
          match(video, search)
        ) {
          selectedVideos[index].push(video)
        }
      })
    })
  return selectedVideos
}

const HowTos = ({ moduleName, id, featured, categories, country }: Props) => {
  let [selectedFilters, setSelectedFilters] = useState([])
  const [search, setSearch] = useState('')
  const isMobile = useIsMobile()
  const [dropdown, setDropdown] = useState(false)
  const [videos, setVideos] = useState(
    categories.map(category => category.howtos)
  )
  const [overlay, setOverlay] = useState(null)

  useEffect(() => {
    const selectedPrinters = filterVideos(categories, selectedFilters, search)
    setVideos([...selectedPrinters])
  }, [selectedFilters, search])

  const selectFilter = filter => {
    const check = selectedFilters.find(
      ({ id: filterID, category }) =>
        filterID === filter.id && filter.category === category
    )
    if (!check) {
      selectedFilters.push(filter)
    } else {
      selectedFilters = selectedFilters.filter(
        ({ label }) => label !== filter.label
      )
    }
    setSelectedFilters([...selectedFilters])
  }

  const handleOverlay = video => {
    setOverlay(video)
    window.dataLayer = window.dataLayer || []  
    window.dataLayer.push({
      event: 'e_videoPlay',
      videoName: video,
    })
  }

  const howToCtaUrl = (url, title, slug, id, video, country) => {
    
    if (url.includes('vimeo')) {
      return url
    }

    const params = []
    const jumpid = getCookie('jumpid')
    if (jumpid !== null) params.push(`jumpid=${jumpid}`)
    const bmtid = getCookie('bmtid')
    if (bmtid !== null) params.push(`bmtid=${bmtid}`)
    params.push(`country=${country}`)
    params.push(`slug=${slug}-HOWTO`)
    params.push(`title=${encodeURI(title)}`)
    if (video) {
      params.push(`live=${video.length > 0 ? 'yes' : 'no'}`)
    }
    params.push(`token=${jwt.encode({ webinarId: id }, simpleTokenSecret)}`)

    return `${url.trim()}${url.includes('?') ? '&' : '?'}${params.join('&')}`
  }

  return (
    <Spacer top bottom small>
      <Container>
        <Grid className="relative">
          <div className="sticky top-4.5 md:relative md:top-0 z-20 col-span-4 py-8 bg-white md:col-span-2 md:row-start-1 md:row-span-2 md:py-0 md:pt-4">
            {isMobile ? (
              <>
                <div
                  className="z-20 flex justify-between"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <span className="font-light text-smaller">
                    {useLiterals('howto-filter-title')}
                  </span>
                  <div className="relative flex items-center md:hidden">
                    <Animated
                      key="dropdown-plus"
                      className="absolute h-px bg-black w-15px"
                      type="spring"
                      animation={animations.plus}
                      bind={dropdown}
                    ></Animated>
                    <div className="h-px bg-black w-15px"></div>
                  </div>
                </div>
                <Animated
                  bind={dropdown}
                  type="spring"
                  animation={animations.dropdown()}
                  className="overflow-hidden"
                >
                  <SharedFilters
                    filters={categories}
                    selectedFilters={selectedFilters}
                    selectFilter={selectFilter}
                    onValueChange={setSearch}
                    isMobile={isMobile}
                    dropdown={dropdown}
                    inputValue={search}
                    type="videos"
                  />
                </Animated>
              </>
            ) : (
              <>
                <div className="z-20 flex justify-between mb-8">
                  <span>{useLiterals('howto-filter-title')}</span>
                </div>
                <SharedFilters
                  filters={categories}
                  selectedFilters={selectedFilters}
                  selectFilter={selectFilter}
                  onValueChange={setSearch}
                  isMobile={isMobile}
                  dropdown={dropdown}
                  inputValue={search}
                  type="howtos"
                />
              </>
            )}
          </div>
          {featured && !selectedFilters.length && !search && (
            <div className="col-span-4 row-start-1 mt-12 md:col-span-9 md:col-start-4 md:mt-0 md:pt-4">
              <div className="relative mb-6">
                {isMobile ? (
                  <Youtube
                    url={featured.url}
                    image={featured.image}
                    id="featured-video"
                  />
                ) : (
                  <LinkExternalHP
                    className="relative block mb-8"
                    href={howToCtaUrl(featured.ctaUrl, featured.title, featured.slug, featured.id, featured.url, country)}
                  >
                    <LazyImage image={featured.image} alt={featured.teaser} />
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end justify-start pb-4 pl-4 cursor-pointer">
                      <LazySimpleImage
                        imageClassName="w-12 h-12"
                        image={useMedia('player')}
                        alt="Video"
                      />
                    </div>
                  </LinkExternalHP>
                )}
                <Appear>
                  <div className="flex flex-col items-start justify-center">
                    <h5 className={`mb-4 text-gray text-center`}>
                      <LinkExternalHP href={howToCtaUrl(featured.ctaUrl, featured.title, featured.slug, featured.id, featured.url, country)}>{featured.title}</LinkExternalHP>
                    </h5>
                    <div
                      className={`${featured.ctaUrl ? 'block' : 'hidden'}`}
                    >
                      <SimpleButton
                        href={howToCtaUrl(featured.ctaUrl, featured.title, featured.slug, featured.id, featured.url, country)}
                        color="blue"
                        title={useLiterals('learn_more')}
                      />
                    </div>
                  </div>
                </Appear>
              </div>
            </div>
          )}
          <div
            className={`col-span-4 mt-12 row-start-1 md:col-span-9 md:col-start-4 md:mt-0 md:pt-4 ${
              !selectedFilters.length && !search ? 'hidden' : 'block'
            }`}
          >
            <div className="w-full pb-2 mb-2 font-light border-b">
              {useLiterals('we_found').replace('*', ` ${flat(videos).length} `)}
            </div>
            <div className="flex flex-wrap items-center h-full pb-4 font-light cursor-pointer jusitfy-center text-smaller">
              {selectedFilters.map(({ label, id, category }, index) => (
                <div
                  key={label}
                  className={`flex p-1 px-2 ${
                    !index ? 'mr-1 mt-1 mb-1' : 'm-1'
                  } text-black transition rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller`}
                  onClick={() => {
                    selectFilter({ category, id, label })
                  }}
                >
                  <span className="flex items-center text-xs">{label}</span>
                  <Cross small={true} />
                </div>
              ))}
              {search && (
                <div
                  className="flex p-1 px-2 m-1 text-black rounded-full bg-grayLight hover:opacity-75 duration-250 text-smaller"
                  onClick={() => {
                    setSearch('')
                  }}
                >
                  <span className="flex items-center text-xs">{search}</span>
                  <Cross small={true} />
                </div>
              )}
            </div>
          </div>
          <div
            className={`grid col-span-4 gap-4 md:col-start-4 md:col-span-9 ${(() => {
              if (!featured && !search && !selectedFilters.length) {
                return 'md:row-start-1 pt-2'
              }
              if (featured && (search || selectedFilters.length)) {
                ('md:row-start-2 pt-12')
              }
              return ''
            })()} md:row-span-3`}
          >
            <div className={`${flat(videos).length ? 'block' : 'hidden'}`}>
              {categories.map(({ title: categoryTitle, id }, index) => (
                <React.Fragment key={id}>
                  {videos[index].length ? (
                    <div>
                      <h2
                        className={`${
                          !index ? 'mb-12' : 'my-12'
                        } text-big2 md:col-span-3`}
                      >
                        {categoryTitle}
                      </h2>
                      {videos[index].map(
                        (
                          {
                            id: howToId,
                            ctaUrl,
                            slug,
                            image,
                            teaser,
                            printerId,
                            printerUrl,
                            title,
                            anchor,
                            url
                          },
                          index
                          ) => (
                            <div
                            className="relative mb-6"
                            key={`${index}-${printerId}-${Math.random()}`}
                          >
                            {isMobile ? (
                              <Youtube url={url} image={image} id={index} />
                            ) : (
                              <div data-anchor={anchor}>
                                <LinkExternalHP
                                  className="relative block mb-8"
                                  href={howToCtaUrl(ctaUrl, title, slug, howToId, url, country)}
                                >
                                  <LazyImage image={image} alt={teaser} />
                                  <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end justify-start pb-4 pl-4 cursor-pointer">
                                    <LazySimpleImage
                                      imageClassName="w-12 h-12"
                                      image={useMedia('player')}
                                      alt="Video"
                                    />
                                  </div>
                                </LinkExternalHP>
                              </div>
                            )}
                            <Appear>
                              <div className="flex flex-col" data-anchor={anchor}>
                                <h5 className={`mb-4 text-gray`}>
                                  <LinkExternalHP href={howToCtaUrl(ctaUrl, title, slug, howToId, url, country)}>{title}</LinkExternalHP>
                                </h5>
                                <div
                                  className={`mb-4 text-small font-light w-2/3`}
                                >
                                  <Html text={teaser} />
                                </div>
                                <div
                                  className={`${
                                    printerUrl ? 'block' : 'hidden'
                                  }`}
                                >
                                  <SimpleButton
                                    href={howToCtaUrl(ctaUrl, title, slug, howToId, url, country)}
                                    color="blue"
                                    title={useLiterals('learn_more')}
                                  />
                                </div>
                              </div>
                            </Appear>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
            <div className={`pt-2 ${flat(videos).length ? 'hidden' : 'block'}`}>
              {useLiterals('not_found')}
            </div>
          </div>
        </Grid>
        <Overlay video={overlay} close={() => handleOverlay(null)} />
      </Container>
    </Spacer>
  )
}

export default HowTos
