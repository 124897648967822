// @flow

import React from 'react'
import { useMedia } from 'store/'
import Html from './Html'
import LinkExternalHP from './LinkExternalHP'

type Props = {
  buyURL: string,
  buyText: string
}

const PrinterShoppableSticky = ({
  buyURL,
  buyText,
}: Props) => {
  return (
    <div className="fixed right-0 p-4 bg-blue bottom-8 z-top">
      <LinkExternalHP className="flex items-center justify-between text-white hover:underline" href={buyURL} target="_blank">
        <span className="mr-4"><Html text={buyText} /></span>
        <img src={useMedia('white-arrow-right')}/>
      </LinkExternalHP>
    </div>
  )
}

export default PrinterShoppableSticky
