// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import PolestarDiscoverCard from './PolestarDiscoverCard'
import PrintersGallery from './PrintersGallery'

type Props = {
  title: string,
  subtitle?: string,
  items: Array<Object>,
}

const TyphoonSolutions = ({
  title,
  subtitle,
  items,
}: Props) => {

  return (
      <Container backgroundColor="bg-white" className="pt-12 pb-32 overflow-hidden md:py-24 lg:py-32">
        <Grid>
          <div className="col-span-4 leading-tight text-center md:col-span-6 md:col-start-4 text-30px md:text-40px lg:text-50px">
            <Appear>{title}</Appear>
          </div>
          {subtitle && <div className="col-span-4 mt-8 text-lg font-light leading-tight text-center md:mt-16 md:col-span-6 md:col-start-4 md:text-20px">
            <Appear>{subtitle}</Appear>
          </div>}
          <div className='col-span-4 col-start-1 mt-12 text-center md:hidden md:mt-20 md:col-span-12'>
            <Appear>
              <div
                className='ml-12'
              >
                <PrintersGallery printers={items} />
              </div>
            </Appear>
          </div>
          <div className={`${items.length > 2 ? 'col-span-10 col-start-2' : 'col-span-8 col-start-3'} hidden mt-32 md:block`}>
            <div className={`${items.length > 2 ? 'grid-cols-9' : 'grid-cols-8'} grid gap-4`}>
              {items.map(printer => (
                <div className={`${items.length > 2 ? 'col-span-3' : 'col-span-4'} text-left`} key={printer.id}>
                <PolestarDiscoverCard 
                {...printer.options}
                key={printer.id}
                selectNone={true}
                type="typhoon"
              />
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
  )
}

export default TyphoonSolutions
