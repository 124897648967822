// @flow

import React from 'react'
import Appear from './Appear'
import LazyImage from './LazyImage'
import Button from './Button'
import Markdown from './Markdown'
import makeLayer from '../lib/makeLayer'
import Grid from './Grid'
import Container from './Container'
import Spacer from './Spacer'

type Props = {
  image: Object,
  image2?: Object,
  text: string,
  text2?: string,
  ctaLabel: string,
  ctaLabel2?: string,
  ctaLink: string,
  ctaLink2?: string,
}


const CategoryBlockDownloadLinksRow = ({
  image,
  image2,
  text,
  text2,
  ctaLabel,
  ctaLabel2,
  ctaLink,
  ctaLink2,
}: Props) => {

  const twoInARow = (image2 && Object.keys(image2).length > 0) || (ctaLabel2 && ctaLabel2.length > 0) || (text2 && text2.length > 0) || (ctaLink2 && ctaLink2.length > 0 )

  return (
    <Spacer bottom className="pt-16 md:pt-10 lg:pt-12">
      <Container>
        <Grid>
          <div className="flex flex-col col-span-4 md:flex-row md:col-start-4 md:col-span-9 md:grid md:grid-cols-10 md:gap-4">
            <Appear className={`flex flex-col md:flex-row col-span-4 font-light ${twoInARow ? "md:col-span-5" : "md:col-start-1  md:col-span-5"}`}>
              <LazyImage image={image} alt={ctaLabel} className="flex-1 md:max-w-300"/>
              <div className="flex flex-col justify-start flex-1 md:pl-6 md:max-w-300">
                <Markdown text={text} className="pt-2 pb-4 text-sm font-normal leading-tight text-white md:pt-0 md:text-base md:pb-4"/>
                <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaLink})} target="_blank" title={ctaLabel} href={ctaLink} style="white" textClasses="text-center font-normal"/>
              </div>
            </Appear>
            {twoInARow && <Appear className="flex flex-col col-span-4 mt-10 font-light md:mt-0 md:flex-row md:col-start-6 md:col-span-5">
              {image2 && <LazyImage image={image2} alt={ctaLabel2} className="flex-1 md:max-w-300"/>}
              <div className="flex flex-col justify-start flex-1 md:pl-6 md:max-w-300">
                {text2 && <Markdown text={text2} className="pt-2 pb-4 text-sm font-normal leading-tight text-white md:pt-0 md:text-base md:pb-4"/>}
                {ctaLabel2 && <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaLink2})} target="_blank" title={ctaLabel2} href={ctaLink2} style="white" textClasses="text-center font-normal"/>}
              </div>
            </Appear>}
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default CategoryBlockDownloadLinksRow