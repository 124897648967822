// @flow

import React from "react"
import Html from "./Html"
import LazyImage from "./LazyImage"
import Appear from "./Appear"
import Button from "./Button"
import { useLiterals } from "store/"
import type { ApiImage } from "../types"
import Container from "./Container"

type Props = {
  image: ApiImage,
  title: string,
  text: string,
  url: string,
  gradientEnd?: string,
}

const FeaturedCategory = ({
  image,
  title,
  text,
  url,
  gradientEnd = "",
}: Props) => {
  return (
    <div className="flex flex-col h-full md:flex-row md:h-43vw">
      <div className="relative w-full h-full md:w-2/5">
        <Container
          className="h-25rem md:h-43vw bg-sand"
          customPadding="px-0 py-0"
        >
          <Container style={{ backgroundColor: `#${gradientEnd}` }} className="py-5 h-max md:py-8">
            <Appear>
              <h2 className="font-secondary text-big5 md2:text-80px md:text-50px md2:text-80 lg:max-w-7/10">{title}</h2>
            </Appear>
          </Container>
          <div className="px-5 py-5 md:py-8 md:px-8 text-30px leading-9 font-secondary">
            <Appear>
              <Html text={text} />
            </Appear>
          </div>
          <div className="absolute bottom-8 left-6 md:left-8">
            <Appear>
              <Button style="black" to={url} title={useLiterals("explore")} />
            </Appear>
          </div>
        </Container>
      </div>
      <div className="w-full md:w-3/5">
        <LazyImage cover className="h-full" image={image} alt={title} />
      </div>
    </div>
  )
}

export default FeaturedCategory
