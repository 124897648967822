// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import LazyImage from './LazyImage'
import type { ApiImage } from '../types'

type Props = {
  image1?: ApiImage,
  image2?: ApiImage,
  image3?: ApiImage,
  menuId?: string,

}

const CategoryBlock18 = ({ image1, image2, image3, menuId = "", }: Props) => {

  const emptyImage1 = Array.isArray(image1) && image1.length === 0
  const emptyImage2 = Array.isArray(image2) && image2.length === 0
  const emptyImage3 = Array.isArray(image3) && image3.length === 0

  if (emptyImage1 && emptyImage2 && emptyImage3) return null

  return (
    <div data-menuid={menuId} className="pb-24 md:pb-12">
      <Container>
        <Grid>
          {emptyImage1 ? null : (
            <Appear className="col-span-4 col-start-1 md:col-span-9 md:col-start-4">
              <LazyImage
                image={image1}
                className="w-full"
              />
            </Appear>
          )}
          {emptyImage2 ? null : (
            <Appear className="col-span-2 col-start-1 md:col-span-4 md:col-start-5">
            <LazyImage
              image={image2}
              className="w-full"
            />
          </Appear>
          )}
          {emptyImage3 ? null : (
            <Appear className="col-span-2 col-end-5 md:col-span-4 md:col-end-13">
              <LazyImage
                image={image3}
                className="w-full"
              />
            </Appear>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default CategoryBlock18
