// @flow
import React, { useState, useRef, useEffect } from "react"
import LazyImage from "./LazyImage"
import LazySimpleImage from "./LazySimpleImage"
import loadYoutube from "../lib/loadYoutube"
import type { ApiImage } from "types/"
import { useLiterals, useMedia } from "../store"
import type { Ref } from "types/"
import youtubeParser from "../lib/youtubeParser"

type PrinterApplicationsItemOptions = {
  url: string,
  image: ApiImage,
  text: string,
  anchor: string,
}
type Props = {
  items: Array<PrinterApplicationsItemOptions>,
  inGradient?: boolean,
  gradientStart?: string,
  gradientEnd?: string,
  id: string,
}

const Slider = ({
  id,
  gradientStart = '',
  gradientEnd = '',
  items,
  inGradient,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [player, setPlayer] = useState(false)
  const slide: Ref = useRef()

  const loadVideo = videoId => {
    loadYoutube(() => {
      const current = new window.YT.Player(`youtube-player${id}`, {
        videoId,
        playerVars: {
          autoplay: 0,
          controls: true,
          fs: 1,
          rel: 0,
          showinfo: 0,
          modestbranding: 1,
        }
      })
      setPlayer(current)
    })
  }

  const inGradientStyle =
    typeof gradientStart !== "undefined" && typeof gradientEnd !== "undefined"
      ? {
          backgroundImage: `linear-gradient(-45deg, #${gradientStart.replace(
            "#",
            ""
          )} 0%, #${gradientEnd.replace("#", "")} 100%)`,
        }
      : {}

  useEffect(() => {
    slide.current.style.opacity = "1"
    const videoId = youtubeParser(items[activeIndex].url)
    if (player) {
      player.cueVideoById(videoId)
    }
  }, [activeIndex])

  useEffect(() => {
    const videoId = youtubeParser(items[0].url)
    loadVideo(videoId)
  }, [])

  const handleSlider = direction => {
    slide.current.style.opacity = "0"
    setTimeout(() => {
      if (direction === "backwards") {
        setActiveIndex(activeIndex < 1 ? items.length - 1 : activeIndex - 1)
        return
      }
      setActiveIndex(activeIndex >= items.length - 1 ? 0 : activeIndex + 1)
    }, 250)
  }

  return (
    <>
      {items && items.length > 0 && (
        <div data-anchor={items[activeIndex].anchor}>
          <div className="relative w-full overflow-hidden">
            <div
              className="relative flex items-center justify-center w-full transition transition-opacity duration-250"
              ref={slide}
              style={{ paddingTop: "56.25%" }}
            >
              <div className="absolute inset-0">
                <div
                  className="absolute top-0 left-0 w-full h-full"
                  id={`youtube-player${id}`}
                ></div>
              </div>
            </div>
            {items.length > 1 &&
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center pointer-events-none">
                <div className="flex justify-between w-full px-4 pointer-events-none">
                  <div
                    className={`pointer-events-auto flex items-center justify-center w-12 h-12 pr-1 ${
                      inGradient ? "bg-black" : ""
                    } rounded-full cursor-pointer`}
                    onClick={() => handleSlider("backwards")}
                    style={inGradient ? {} : inGradientStyle}
                  >
                    <LazySimpleImage
                      alt={useLiterals("previous")}
                      image={useMedia("white-arrow-left")}
                    />
                  </div>
                  <div
                    className={`pointer-events-auto flex items-center justify-center w-12 h-12 pl-1 ${
                      inGradient ? "bg-black" : ""
                    } rounded-full cursor-pointer`}
                    onClick={() => handleSlider("forwards")}
                    style={inGradient ? {} : inGradientStyle}
                  >
                    <LazySimpleImage
                      alt={useLiterals("previous")}
                      image={useMedia("white-arrow-right")}
                    />
                  </div>
                </div>
              </div>
            }
          </div>

          {typeof items[activeIndex].text !== "undefined" &&
            items[activeIndex].text.length > 0 && (
              <div
                className="flex items-center p-4 text-black text-smaller"
                style={
                  inGradient
                    ? { backgroundColor: "rgba(10, 10, 10, 0.4)" }
                    : inGradientStyle
                }
              >
                {items[activeIndex].text}
              </div>
            )}

          {items.length > 1 && <div className="flex flex-wrap items-center mt-8 overflow-hidden md:flex-no-wrap md:items-stretch">
            {items.map((item, index) => (
              <div
                // data-anchor={item.anchor}
                key={`slide-${item.id}`}
                className={`relative cursor-pointer flex-basis-1/5 md:h-auto h-12 mb-2 md:mb-0 w-full mr-2 md:mr-4 ${
                  activeIndex === index ? "opacity-50" : ""
                }`}
                onClick={() => setActiveIndex(index)}
              >
                <div className="relative w-full h-full md:h-0 md:pt-169">
                  <div className="absolute inset-0">
                    <LazyImage
                      contain
                      alt={item.ctaText}
                      image={item.image}
                      className="h-full"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>}
        </div>
      )}
    </>
  )
}

export default Slider
