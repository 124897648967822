// @flow
import React, { useEffect, useState } from 'react'
import Container from './Container'
import Html from './Html'
import LazyImage from './LazyImage'
import useIsMobile from '../hooks/useIsMobile'
import type { ApiImage } from "../types/ApiImage";

type Props = {
  imageBackground: string,
  pretitle: string,
  title: string,
  text: string,
  image: ApiImage,
  imageBackgroundMobile: ApiImage,
  textColor: string,
  reversed?: number,
  objectRightBottom?: number,
  moduleWithMargin?: boolean,
  imageLogo?: ApiImage,
}

const CardFullWith = ({
  imageBackground,
  pretitle,
  title,
  text,
  image,
  textColor,
  imageBackgroundMobile,
  reversed = 0,
  moduleWithMargin = false,
  objectRightBottom = 0,
  imageLogo,
}: Props): React$Node => {
  const isMobile = useIsMobile()
  const [style, setStyle] = useState({})

  const updateStyle = () => {
    if (typeof window !== 'undefined' && moduleWithMargin) {
      const heightDiff = ((window.innerWidth / 16) * 9 - window.innerHeight) / 2
      const mobileHeightDiff =
        ((window.innerWidth / 9) * 16 - window.innerHeight) / 2

      const marginTop = Math.round(isMobile ? mobileHeightDiff : heightDiff)

      setStyle({
        marginTop: `${marginTop < 0 ? marginTop : 0}px`,
        paddingTop: '1rem',
      })
    }
  }

  useEffect(() => {
    updateStyle()
    window.addEventListener('resize', updateStyle)
  }, [isMobile])

  return (
    <Container customPadding={`px-5 md:px-10 py-4 md2:py-8`} style={style}>
      <div
        style={{
          color: textColor,
        }}
        className="relative grid object-cover w-full h-full grid-cols-4 px-8 py-16 pb-56 bg-center bg-cover rounded-lg md:py-10 md:grid-cols-8 xl:min-h-500"
      >
        <div className="absolute z-10 w-full h-full">
          {imageLogo && (
            <div className="absolute z-20 w-20 h-20 bottom-16 left-6">
              <LazyImage
                className="w-full h-full"
                image={imageLogo}
                alt="Logo"
              />
            </div>
          )}
          {isMobile ? (
            <LazyImage
              key="mobile-image"
              className="w-full h-full "
              image={imageBackgroundMobile}
              alt="Printer 630"
              borderRadius
              cover
            />
          ) : (
            <LazyImage
              key="desktop-image"
              className="w-full h-full"
              image={imageBackground}
              alt="Printer 630"
              borderRadius
              cover
              objectRightBottom={objectRightBottom === 1}
            />
          )}
        </div>
        <div
          className={`col-span-4 z-20 ${
            reversed ? 'md:col-start-5 md:col-span-4 md:pr-10' : 'md:col-span-3'
          }`}
        >
          <div className="text-base uppercase text-blue md:text-20px">
            {pretitle}
          </div>
          <div className="py-6 leading-tight text-32px md:text-40px personalized-md">
            <Html text={title} />
          </div>
          <div className="w-full leading-5 text-15px md:text-lg">
            <Html text={text} />
          </div>
        </div>
        {image && (
          <div className="col-span-4 md:col-start-6 md:col-span-2">
            <LazyImage
              className="w-full h-full"
              image={image}
              alt="I've got things you need"
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default CardFullWith
