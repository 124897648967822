// @flow

import React from 'react'
import LazyImage from './LazyImage'
import LinkExternalHP from './LinkExternalHP'
import Container from './Container'
import Grid from './Grid'

type Props = {
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  image: ApiImage,
}
const DownloadEbook = ({ title, text, image, ctaText, ctaUrl }: Props) => (
  <Container className="my-10 md:my-70px w-full">
    <Grid>
      <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 bg-grayLight border flex flex-col md:flex-row">
        {image ? (
          <div className="w-full md:w-1/3 border-b md:border-b-0 md:border-r flex items-center justify-center">
            <LazyImage
              image={image}
              alt={title}
              cover
              className="w-2/3 h-2/3"
            />
          </div>
        ) : null}
        <div className="flex flex-col">
          <h4 className="text-18px md:text-30px p-4 border-b">{title}</h4>
          <div className="p-4">
            <p className="text-16px md:text-18px">{text}</p>
            <LinkExternalHP
              href={ctaUrl}
              className="inline-block mt-4 mb-12 md:mb-0 px-4 py-2 text-center text-white whitespace-no-wrap bg-black border border-black focus:outline-none cursor-pointer hover:bg-white hover:border-black hover:text-black text-16px"
            >
              {ctaText}
            </LinkExternalHP>
          </div>
        </div>
      </div>
    </Grid>
  </Container>
)

export default DownloadEbook
