// @flow

import React from 'react'
import type { ApiImage } from '../types'

type Props = {
  position: string,
  image: ApiImage,
  title: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
}

const TestModule = ({ image, title, text, ctaText, ctaUrl }: Props) => {
  return (<div className="mt-200px">
  </div>)
}

export default TestModule