// @flow
import React from 'react'
import type { Node } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Button from './Button'
import Html from './Html'
import { useLiterals } from 'store/'

type PodcastType = {
  id: string,
  title: string,
  urlId: string
}

type Props = {
  items: Array<PodcastType>,
  children: Node
}

const Podcasts = ({moduleName, id, items, children }: Props) => {
  return (
    <Spacer top bottom small>
      <Container>
        <Grid className="">
          <div className="sticky top-4.5 md:relative md:top-0 z-20 col-span-4 py-8 bg-white md:col-span-2 md:row-start-1 md:row-span-2 md:py-0 md:pt-4">
            <div className="hidden mt-8 md:block">{children}</div>
          </div>
          <div
            className={`col-span-4 md:col-start-4 md:col-span-9 md:row-span-3`}
          >
            <div className="mb-8">
              <p className="mb-4 font-light">
                <Html text={useLiterals('podcasts_text')} />
              </p>
              <Button style="whiteOutlineBlack" title={useLiterals('podcasts_ctaText')} href={useLiterals('podcasts_ctaUrl')} />
            </div>
            <div className={`grid col-span-4 gap-4 md:grid-cols-3`}>
              {items.map((podcast: PodcastType) => (
                <div className="mb-6" key={`${podcast.id}}`}>
                  <div className={`text-center flex-shrink-0 w-full mr-4`}>
                    <div className={`mb-4 bg-grayLight p-4`}>
                      <iframe
                        className="w-full"
                        style={{ height: 100 }}
                        src={`https://www.podbean.com/media/player/${podcast.urlId}?from=yiiadmin&download=1&version=1&skin=1&btn-skin=107&auto=0&share=1&fonts=Helvetica&download=1&rtl=0&pbad=1`}
                        frameBorder="0"
                      ></iframe>
                    </div>
                    <h5 className="mb-4">{podcast.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default Podcasts
