// @flow

import React from 'react'
import type { Node } from 'react'

type Props = {
  children: Node,
  contentLeft: Node,
}

const CategorySuperWrapper = ({ children, contentLeft }: Props) => {
  return (
    <div
      className="relative h-full bg-white"
    >
      {contentLeft && <div className="z-50 md:absolute md:top-0 md:bottom-0 md:left-0 md:right-0 md:pointer-events-none">{contentLeft}</div>}
      <div className="relative">
        {children}
      </div>
    </div>
  )
}

export default CategorySuperWrapper