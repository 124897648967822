// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Html from './Html'
import Appear from './Appear'

type Props = {
  title: string,
  text: string,
}

const PrinterTitleText = ({ title, text }: Props) => {
  return (
    <Spacer top bottom smaller>
      <Container>
        <Grid>
          <div className="col-span-4 col-start-1 md:col-span-6 md:col-start-4">
            <h3 className="mb-4 text-big3 md:mb-8"><Appear><Html text={title} /></Appear></h3>
            <div className="font-light"><Appear><Html text={text} /></Appear></div>
          </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default PrinterTitleText