// @flow
import React from 'react'

export type ContainerProps = {
  className?: string,
  children: React$Node,
  backgroundColor?: string,
  height?: string,
  style?: Object,
  customPadding?: string,
  anchor?: string,
}

const Container = ({
  className,
  children,
  backgroundColor = '',
  height,
  style,
  customPadding,
  anchor,
}: ContainerProps): React$Element<'div'> => {
  return (
    <div
      style={style}
      className={`w-full ${height ? height : 'h-auto'} ${
        backgroundColor ? `${backgroundColor}` : ''
      }`}
      data-anchor={anchor}
    >
      <div
        className={`w-full ${customPadding || 'px-5 md:px-8'} mx-auto ${
          className ? className : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export default Container