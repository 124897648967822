// @flow
import React from 'react'
import type { ApiImage } from 'types/'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import makeAssetUrl from '../lib/makeAssetUrl'
import Html from './Html'
import Bubble from './Bubble'
import { useMedia } from '../store/'
import Button from './Button'
import Container from './Container'

type CardType = {
  ctaText1: string,
  ctaText: string,
  ctaUrl1: string,
  urlForm1: string,
  urlForm: string,
  title: string,
  supertitle: string,
  text: string
}

type Props = {
  image: ApiImage,
  texture: ApiImage,
  texture2: ApiImage,
  card1: CardType,
  card2: CardType,
  imageHeader: ApiImage
}

const Card = ({ ctaText, ctaUrl, title, supertitle, text }) => {
  let name = ''
  if (ctaUrl && ctaUrl.includes('live-product-demo')) {
    name = 'book-demo'
  } else if (ctaUrl && ctaUrl.includes('contact-expert')) {
    name = 'contact-expert'
  }
  return (
    <Appear
      className="relative z-10 px-6 pt-32 pb-12 text-white bg-black border border-white"
      style={{
        maxWidth: '400px'
      }}
    >
      <span className="pr-2 font-light">{supertitle}</span>
      <h4 className="mt-2 mb-8 font-light text-big3">{title}</h4>
      <div className="pr-16 mb-8 font-light">
        <Html text={text} />
      </div>
      <Button
        name={name}
        title={ctaText}
        to={ctaUrl}
        padded={true}
        style="white"
      />
      <div
        className="absolute top-0 right-0 w-16 h-16 bg-black"
        style={{ top: -1, right: -2 }}
      >
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-white"
          style={{ clipPath: 'polygon(0 0, 0% 100%, 100% 100%)', top: -1 }}
        />
      </div>
    </Appear>
  )
}

const DesignJet7 = ({
  image,
  texture,
  texture2,
  card1,
  card2,
  imageHeader = { normal: '', lazy: '' }
}: Props) => {
  return (
    <Spacer className="relative">
      <div
        // className="absolute w-full"
        style={{
          backgroundImage: `url(${makeAssetUrl({ asset: imageHeader, isImage: true })})`,
          backgroundRepeat: 'repeat-y',
          backgroundSize: 'cover',
          width: '100%',
          height:
            typeof window !== 'undefined' && window.innerWidth > 768
              ? '32rem'
              : '10rem',
        }}
      />
      <div className="pb-16 -mt-2 bg-black md:pb-32 lg:pb-48">
        <Container className="relative overflow-hidden bg-black md:overflow-visible">
          <Grid className="relative w-full overflow-visible md:gap-8">
            <div className="col-span-4 col-start-1 mb-8 md:col-start-4 md:justify-self-end md:self-end">
              <Card
                ctaText={card1.ctaText1}
                ctaUrl={card1.urlForm1}
                title={card1.title}
                supertitle={card1.supertitle}
                text={card1.text}
              />
            </div>
            <div className="col-span-4 col-start-1 row-start-2 mb-8 md:col-start-8 md:row-start-2 md:self-center md:-mt-32 lg:-mt-48 md:ml-8">
            {card2 &&
              <>
                <div className="z-10 hidden col-span-1 col-start-4 mb-8 md:block">
                  <LazySimpleImage
                    image={useMedia('free_sample_white')}
                    alt={card1.title}
                    />
                </div>
                <Card
                  ctaText={card2.ctaText}
                  ctaUrl={card2.urlForm}
                  title={card2.title}
                  supertitle={card2.supertitle}
                  text={card2.text}
                />
              </>
            }
            </div>
            <div className="z-10 col-span-3 col-start-1 row-start-3 md:col-start-2 md:col-span-6 md:row-start-2">
              <LazyImage image={image} alt={card1.title} />
            </div>
            <div className="col-span-1 col-start-4 row-start-3 md:hidden">
              <LazySimpleImage
                image={useMedia('free_sample_white')}
                alt={card1.title}
              />
            </div>
          </Grid>
          <Appear className="absolute md:-left-35p md:-bottom-20p w-264 h-264">
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture, isImage: true})} />
          </Appear>
          <Appear className="absolute md:-top-55p md:-right-30p lg:-right-45% lg:-top-35p w-120 h-120">
            <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture2, isImage: true})} />
          </Appear>
        </Container>
      </div>
    </Spacer>
  )
}

export default DesignJet7
