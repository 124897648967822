/* eslint-disable no-prototype-builtins */
// @flow

import React from 'react'
import type { ApiImage } from 'types/'
import type { Node } from 'react'
import Spacer from './Spacer'
import ModuleIterator from './ModuleIterator'
import makeAssetUrl from '../lib/makeAssetUrl'
import Grid from './Grid'
import Container from './Container'

type Props = {
  image: ApiImage,
  content: Array<Node>,
  verticalSlug: string,
  inGradient: boolean,
  biggerMargins: boolean,
  backgroundColor?: string,
  gradientBottom?: number
}

  
const CategoryBlockBGWrapper = ({ image, content, biggerMargins = false, backgroundColor, gradientBottom = 0 }: Props) => {
  const modules = content.map((module, index) => {
    if (!module.options.hasOwnProperty("nav")) module.options.nav = { prev: "", next: ""}
    if (index !== 0) {
      module.options.nav.prev = content[index - 1].module
    }
    if (index + 1 !== content.length) {
      module.options.nav.next = content[index + 1].module
    }

    let isWhite
    let colorChanged = false
    if (module.options.hasOwnProperty("whiteParentBG")) {
      isWhite = module.options.whiteParentBG
      if (
        index > 0
        && content[index -1].options.hasOwnProperty("whiteParentBG")
        && content[index -1].options.whiteParentBG !== isWhite
      ) colorChanged = true
    }

    return (
      <div key={module.id} className="relative">
        <div className="absolute inset-0">
          <Grid className="h-full">
            <div className={`col-span-4 px-4 md:-mr-4 md:col-span-10 md:col-end-13 bg-white`}
            />
          </Grid>
        </div>
        <div
          className={`
            md:-mx-4
            ${isWhite ? "text-black" : "text-white"}
            ${index === 0 || colorChanged ? "pt-12 md:pt-24" : ""}
            ${index + 1 === content.length ? "pb-6" : ""}
          `}
        >
          <ModuleIterator modules={[module]} />
        </div>
      </div>
    )
  })

  return (
    <div
      className={`relative ${gradientBottom === 1 ? '' : 'mb-12 bg-100'} back`}
      data-hasgradient="0"
      style={{ backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`, backgroundColor: backgroundColor ? backgroundColor : "auto" }}
    >
      <Spacer
        top bottom bigger={biggerMargins}
      >
        <Container>
          {modules}
        </Container>
      </Spacer>
      {gradientBottom === 1 && <div className="h-48" style={{ backgroundImage: `linear-gradient(transparent, ${backgroundColor ? backgroundColor : '#000000'})`}} />}
    </div>
  )
}

export default CategoryBlockBGWrapper