// @flow

import React, { useState, useEffect, useRef } from 'react'
import Grid from './Grid'
import Modal from './Modal'
import Polestar3DModalContent from './Polestar3DModalContent'
import Container from './Container'
import Stand3D from './Stand3D'
import Polestar3DMenu from './Polestar3DMenu'

type Props = {
  printer3d: string,
  title: string,
  ctaText1: string,
  ctaText2: string,
  ctaText3: string,
  url1: string,
  url2: string,
  url3: string,
  explore: string,
  loading: string,
  type: string,
  points: Array,
  posterImage: any,
  style?: string,
  printer3d: string,
  anchor: string,
}

const Polestar3D = ({
  title,
  ctaText1,
  ctaText2,
  ctaText3,
  url1,
  url2,
  url3,
  points,
  explore,
  loading: loadingString,
  posterImage,
  type,
  style,
  printer3d,
  anchor,
}: Props) => {
  const [ready, setReady] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [clickedMenuButton, setClickedMenuButton] = useState(false)
  const [screenHeight, setScreenHeight] = useState(0)
  const [screenWidth, setScreenWidth] = useState(0)
  const [standHeight, setStandHeight] = useState()
  const standRef = useRef({})
  
  const handleResize = () => {
    const width = document.documentElement.clientWidth || document.body.clientWidth
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setScreenWidth(width)
    setScreenHeight(height)
  }

  const handleStandHeight = () => {
    if (standRef.current) {
      const height = standRef.current.offsetHeight
      setStandHeight(height)
    }
  }

  const blockScroll = () => {
    if (openModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }

  const pointsDataLayer = {
    'POINT1': 'product-area',
    'POINT2': 'technology',
    'POINT3': 'presentation-area',
    'POINT4': 'applications',
    'POINT5': 'sustainable-printing',
    'POINT6': 'hp-print',
    'POINT7': 'support-services',
    'POINT8': 'workflow-solutions',
    'POINT9': 'datasheets-brochures',
    'POINT10': 'print-samples',
    'POINT11': 'meeting-room',
    'POINT12': 'download-here-our-free-ebooks',
  }

  const handleOpenModal = (point) => {
    setSelectedPoint(point)
    if (point !== null) {      
      const currentPoint = points.find(item => item.point === point)
      setModalData(currentPoint)
      setTimeout(() => {
        setOpenModal(true)

        window.dataLayer = window.dataLayer || []
        const newLayer = {
          event: 'e_pageView',
          pageNameL5: 'landing',
          pageNameL6: '3dstand' || '',
          pageNameL7: '',
          pageNameL8: pointsDataLayer[point] || '3dstand',
        }
        window.dataLayer.push(newLayer)
        window.dataLayer.push({event: 'pageview'})
      }, 750)

      setTimeout(() => {
        const newTaggedElements = document.querySelectorAll('.polestarModal [data-layer], .polestarModal [datalayer]')
        if (newTaggedElements && newTaggedElements.length > 0) {
          newTaggedElements.forEach(taggedElement => {
            taggedElement.addEventListener('click', () => {
              let dataJson = taggedElement.getAttribute('data-layer')
              if (!dataJson) {
                dataJson = taggedElement.getAttribute('datalayer')
              }
              console.log('JSON', dataJson)
              try {
                const data = JSON.parse(dataJson)
                window.dataLayer = window.dataLayer || []  
                window.dataLayer.push(data)
              } catch (e) {
                console.log('Error parsing JSON')
              }
            })
          })
        }
      }, 1000)
    } else {
      setOpenModal(false)
      setModalData(null)

      window.dataLayer = window.dataLayer || []
      const newLayer = {
        event: 'e_pageView',
        pageNameL5: 'landing',
        pageNameL6: '3dstand' || '',
        pageNameL7: '',
        pageNameL8: 'largeformat-home',
      }
      window.dataLayer.push(newLayer)
      window.dataLayer.push({event: 'pageview'})
    }
  }

  const handleIsReady = r => setReady(r)

  const shouldScrollTo = (selector) => {
    const element = document.querySelector(selector)
    const yOffset = -112
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleStandHeight()
  }, [screenWidth, screenHeight])

  useEffect(() => {
    if (clickedMenuButton) {
      window.dataLayer = window.dataLayer || []
        const newLayer = {
          event: 'e_pageView',
          pageNameL5: 'landing',
          pageNameL6: '3dstand' || '',
          pageNameL7: '',
          pageNameL8: 'largeformat-home',
        }
      window.dataLayer.push(newLayer)
      window.dataLayer.push({event: 'pageview'})
    }
  }, [clickedMenuButton])

  useEffect(() => {
    if (ready) {
      window.dataLayer = window.dataLayer || []
      const newLayer = {
        event: 'e_pageView',
        pageNameL5: 'landing',
        pageNameL6: '3dstand' || '',
        pageNameL7: '',
        pageNameL8: '3dstand',
      }
      window.dataLayer.push(newLayer)
      window.dataLayer.push({event: 'pageview'})
    }
  }, [ready])

  useEffect(() => {
    blockScroll()
  }, [openModal])

  useEffect(() => {
    if (window.location.hash && window.location.hash.length > 0) {
      const parts = window.location.hash.split('#!')
      const lastPart = parts[parts.length - 1]
      if (lastPart === '3dvirtualbooth') {
        setTimeout(() => {
          shouldScrollTo(`[data-stand]`)
        }, 1000)
      }
      if (lastPart === '#herovideo') {
        setTimeout(() => {
          shouldScrollTo(`[data-polestar-video]`)
        }, 1000)
      }
      if (lastPart === '#360printer') {
        setTimeout(() => {
          shouldScrollTo(`[data-360]`)
        }, 1000)
      }
    }
  }, [])

  const inIframe = type === '3dstand-iframe'

  return (
    <div data-anchor={anchor} data-type={type} className={`${style === "lion" ? 'bg-realBlack' : 'bg-white'} ${type === '3dstand' ? 'pt-28' : 'pt-10'} h-full`}>
      <Container backgroundColor='palePurple22' className={`relative z-20 w-full ${inIframe ? 'h-screen' : 'h-full'}`} ref={standRef}>
        <Stand3D 
          selectedPoint={selectedPoint !== 'POINT12' ? selectedPoint : null}
          handleOpenModal={handleOpenModal}
          availablePoints={points.map(point => point.point)}
          infoPoints={points}
          handleIsReady={handleIsReady}
          explore={explore}
          loading={loadingString}
          posterImage={posterImage}
          inIframe={inIframe}
          printerId={printer3d}
          title={title}
        />
        <div
          className="absolute top-0 bottom-0 left-0 right-0 z-10 w-11/12 mx-auto pointer-events-none sm:right-initial sm:left-2 sm:w-7/12 sm:mx-0 sm:ml-4 md:ml-6 md:flex"
          style={{
            alignItems: clickedMenuButton ? 'flex-start' : 'center',
          }}
        >
          <Polestar3DMenu
            ctaText1={ctaText1 || ''}
            ctaText2={ctaText2 || ''}
            ctaText3={ctaText3 || ''}
            url1={url1 || ''}
            url2={url2 || ''}
            url3={url3 || ''}
            points={[...points] || []}
            handleOpenModal={handleOpenModal}
            clickedMenuButton={setClickedMenuButton}
            ready={ready}
            standHeight={standHeight}
            inIframe={inIframe}
          />
        </div>
      </Container>
     
      {openModal && modalData && (
        <Modal
          closeModal={() => handleOpenModal(null)}
        >
          <Polestar3DModalContent inIframe={inIframe} pointData={modalData} />
        </Modal>
      )}
    </div>
  )
}

export default Polestar3D
