// @flow
import React from 'react'
import Appear from './Appear'
import LazyImage from './LazyImage'
import Html from './Html'
import LinkHP from './LinkHP'
import { useLiterals } from 'store/'
import Button from "./Button"

type Props = {
  small?: boolean,
  title: string,
  teaser: string,
  url: string,
  image?: ApiImage,
  category: string,
  fontColor?: string,
  bgColor?: string,
  refactored?: Boolean
}

const BlogHomePost = ({ small = false, title, url, teaser, image, category, bgColor = '', fontColor = '', refactored = false }: Props) => {
  if (refactored) {
    return (
      <a className="w-full flex md:flex-col flex-row h-full md:justify-between" style={{ backgroundColor: bgColor}} href={url}>
          <div className='flex md:flex-col w-full h-full'>
            <div className="mb-4 md:mb-0 w-1/3 md:w-full bg-gray h-full md:h-72 overflow-hidden">
              <LazyImage image={image} alt={title} className="w-full object-cover" cover />
            </div>
            <div className="text-black w-2/3 md:w-full md:bg-serenityBlue bg-transparent mb-3">
              <div className='flex flex-col h-full'>
                {category && category.length > 0 && 
                  <>
                    <div className='md:bg-shinningBlue w-full h-full px-4 pt-4 pb-2 md:py-3'>
                      <Appear className='h-full w-full'>
                        <span className=" bg-transparent h-max">{category}</span>
                      </Appear>
                    </div>
                    <div className="w-full h-1px bg-black md:hidden" />
                  </>
                }
                <div className='px-4 py-3 md:bg-serenityBlue bg-transparent'>
                  <Appear className='w-full h-full'>
                    <h3 className={`break-words ${small ? 'text-big1' : 'text-big2'}`} style={{ color: fontColor }}>{title}</h3>
                  </Appear>
                </div>
              </div>
            </div>
            <div className="px-4 mb-4 hidden md:block" style={{ color: fontColor }}>
              <Appear>
                <Html text={teaser} />
              </Appear>
            </div>
          </div>
          <div className='hidden md:block'>
            <Appear>
              <Button style='outlineOnly' className='mx-4 mb-4' href={url} title={useLiterals('read_more')} />
            </Appear>
          </div>
      </a>
    )
  } else {
    return (
      <div className="w-full p-4 md:flex" style={{ backgroundColor: bgColor}}>
          <div className="flex-1 mb-8 overflow-hidden md:mb-0">
            <LazyImage image={image} alt={title} />
          </div>
          <div className="flex-1 text-black md:ml-8">
            <Appear>
              {category && category.length > 0 && <div className={`${small ? 'mb-8' : 'mb-4'} text-gray`}>{category}</div>}
              <h3 className={`mb-4 ${small ? 'text-big1' : 'text-big2'}`} style={{ color: fontColor }}>{title}</h3>
              <div className="mb-8" style={{ color: fontColor }}><Html text={teaser} /></div>
              <LinkHP className="underline" to={url} style={{ color: fontColor }}>{useLiterals('read_more')}</LinkHP>
            </Appear>
          </div>
      </div>
    )
  }
  
}

export default BlogHomePost