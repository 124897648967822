// @flow

import React, { useState, useRef, useEffect } from 'react'

import LazyImage from './LazyImage'
import Scene from '../lib/stand3d/Scene'
import Controls from '../lib/stand3d/Controls'
import Textures from '../lib/stand3d/Textures'
import Lights from '../lib/stand3d/Lights'
import Objects from '../lib/stand3d/Objects'
import Points from '../lib/stand3d/Points'
import { toScreenPosition } from '../lib/stand3d/helpers'
import { useLiterals, useMedia } from 'store/'
import useIsMobile from '../hooks/useIsMobile'
import Button from './Button'
import Container from './Container'
import Appear from './Appear'
import TitleUnderlines from './TitleUnderlines'

type Props = {
  selectedPoint: any,
  handleOpenModal: func,
  handleIsReady: func,
  availablePoints: array,
  infoPoints: array,
  explore: string,
  posterImage: any,
  inIframe: boolean,
  printerId: string,
  title?: title,
  classnmae?: string,
}

const THREE = require('three')
const positions = {
  zoomTarget: false,
  zoomPosition: false,
  originalTarget: new THREE.Vector3(0, 2, 0),
  originalPosition: new THREE.Vector3(0, 0, 25),
  goToOriginal: true,
  isInitial: true,
}
let controls = null
let points = null
let intersected = false

const Stand3D = ({
  printerId,
  inIframe,
  selectedPoint,
  handleIsReady,
  handleOpenModal,
  availablePoints,
  infoPoints,
  explore,
  posterImage,
  className,
  title,
}: Props) => {
  const mainRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (loading) {
      play()
    }
  }, [loading])

  const showLabel = (show, position, text) => {
    const stand = document.querySelector('[data-stand]')
    const label = document.querySelector('[data-label]')
    const pageWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    if (label) {
      label.style.display = show ? 'block' : 'none'
      if (pageWidth <= 600) {
        label.style.display = 'none'
      }
    }
    if (show) {
      const labelText = label.querySelector('div span')
      stand.classList.add('cursor-pointer')
      if (pageWidth >= 640) {
        label.style.left = position.x + 'px'
        label.style.top = position.y + 'px'
      }
      labelText.textContent = text
    } else {
      if (stand) stand.classList.remove('cursor-pointer')
    }
  }

  useEffect(() => {
    if (!controls) return
    if (!selectedPoint) {
      positions.goToOriginal = true
      controls.setIsShowingInfo(false)
    } else {
      const point = points.getPoint(selectedPoint)
      positions.originalPosition = new THREE.Vector3(
        controls.getCamera().position.x,
        controls.getCamera().position.y,
        controls.getCamera().position.z
      )
      const pointData = points.get(selectedPoint)
      const distance = 9
      const x =
        point.position.x +
        distance * Math.sin((pointData.rotation * Math.PI) / 180)
      const z =
        point.position.z +
        distance * Math.cos((pointData.rotation * Math.PI) / 180)
      positions.zoomPosition = new THREE.Vector3(x, point.position.y, z)
      positions.zoomTarget = new THREE.Vector3(
        point.position.x,
        point.position.y,
        point.position.z
      )
      controls.enableZoom(false)
      controls.setIsShowingInfo(true)
    }
  }, [selectedPoint])

  const play = async () => {
    const textures = new Textures(printerId)
    const objects = new Objects(printerId, textures)

    let totalObjectsToLoad =
      textures.numberOfObjectsToLoad() + objects.numberOfObjectsToLoad() + 4
    let objectsLoaded = 0

    await textures.load(() => {
      objectsLoaded++
      setLoadingPercentage(parseInt((objectsLoaded / totalObjectsToLoad) * 100))
    })
    await objects.load(() => {
      objectsLoaded++
      setLoadingPercentage(parseInt((objectsLoaded / totalObjectsToLoad) * 100))
    })

    const scene = new Scene(mainRef)
    objectsLoaded++
    controls = new Controls(scene)
    scene.addControls(controls)
    objectsLoaded++
    points = new Points(scene, textures, availablePoints)
    new Lights(scene)

    objectsLoaded++
    setLoadingPercentage(parseInt((objectsLoaded / totalObjectsToLoad) * 100))

    scene.background = textures.get('envirohp2')
    scene.environment = textures.get('envirohp2')
    points.load()
    await objects.add(scene)

    objectsLoaded++
    setLoadingPercentage(parseInt((objectsLoaded / totalObjectsToLoad) * 100))

    handleIsReady(true)

    setLoading(false)
    setPlaying(true)

    const raycaster = new THREE.Raycaster()

    const mouse = new THREE.Vector2()

    const pointsDataLayer = {
      POINT1: 'product-area',
      POINT2: 'technology',
      POINT3: 'presentation-area',
      POINT4: 'applications',
      POINT5: 'sustainable-printing',
      POINT6: 'hp-print',
      POINT7: 'support-services',
      POINT8: 'workflow-solutions',
      POINT9: 'datasheets-brochures',
      POINT10: 'print-samples',
      POINT11: 'meeting-room',
      POINT12: 'Download here our free ebooks',
    }

    scene.getRenderer().domElement.addEventListener('click', () => {
      if (intersected) {
        window.dataLayer = window.dataLayer || []
        const newLayer = {
          event: 'e_linkClick',
          linkPlacement: 'numbers-3d-stand',
          linkID: pointsDataLayer[intersected.userData.id],
        }
        window.dataLayer.push(newLayer)

        handleOpenModal(intersected.userData.id)
      }
    })
    scene.getRenderer().domElement.addEventListener('mousemove', event => {
      const { x, y } = scene.getRenderer().getSize(new THREE.Vector2())
      mouse.x = (event.layerX / x) * 2 - 1
      mouse.y = -(event.layerY / y) * 2 + 1
    })

    scene.getRenderer().domElement.addEventListener('touchstart', event => {
      const { top } = scene.getRenderer().domElement.getBoundingClientRect()
      const layerX = event.touches[0].clientX
      const layerY = event.touches[0].clientY - top

      const { x, y } = scene.getRenderer().getSize(new THREE.Vector2())
      mouse.x = (layerX / x) * 2 - 1
      mouse.y = -(layerY / y) * 2 + 1

      if (intersected) {
        handleOpenModal(intersected.userData.id)
      }
    })

    function animate() {
      if (positions.zoomPosition !== false) {
        controls.setTarget(positions.zoomTarget)
        controls.setCameraPosition(positions.zoomPosition, false, true)
        if (controls.setCameraPosition(positions.zoomPosition, true) <= 0.2) {
          positions.zoomPosition = false
          positions.zoomTarget = false
        }
      } else if (positions.goToOriginal) {
        controls.setTarget(positions.originalTarget)
        controls.setCameraPosition(
          positions.originalPosition,
          false,
          positions.isInitial
        )
        const distance = controls.setCameraPosition(
          positions.originalPosition,
          true
        )
        if (distance <= 0.2 || (positions.isInitial && distance < 2)) {
          controls.enableZoom(true)
          positions.originalPosition = false
          positions.goToOriginal = false
          if (positions.isInitial) {
            positions.isInitial = false
            controls.get().maxDistance = 30
          }
        }
      }

      if (!controls.getIsShowingInfo()) {
        raycaster.setFromCamera(mouse, controls.getCamera())
        const intersects = raycaster.intersectObjects(points.getPoint(), false)
        if (intersects.length > 0) {
          const inter = points.getHelper(intersects[0].object.userData.id)
          if (intersected !== inter) {
            intersected = inter
          }
        } else {
          intersected = false
        }
      } else {
        intersected = false
      }

      controls.get().update()
      scene.getRenderer().render(scene.get(), controls.getCamera())
      points.lookAtCamera(controls.getCamera())
      objects.lookAtCamera(
        ['squares', 'pers1', 'pers2', 'pers3', 'pers4', 'pers5', 'pers6'],
        controls.getCamera()
      )

      points.getPoint().forEach(point => {
        if (controls.getIsShowingInfo()) {
          point.material.opacity = 0
        } else {
          var scaleVector = new THREE.Vector3()
          var scaleFactor = 35
          var scale =
            scaleVector
              .subVectors(point.position, controls.getCamera().position)
              .length() / scaleFactor
          point.scale.set(scale, scale, 1)
          if (isMobile) {
            point.scale.set(2, 2, 1)
          }
          let offset = 0
          if (scale < 0.1) {
            offset = 0.5
          } else if (scale < 0.2) {
            offset = 0.4
          } else if (scale < 0.3) {
            offset = 0.3
          } else if (scale < 0.4) {
            offset = 0.2
          }
          point.material.opacity = Math.min(Math.max(1 - scale + offset, 0), 1)
        }
      })

      if (intersected) {
        const position = toScreenPosition(
          intersected,
          scene.getRenderer(),
          controls.getCamera()
        )
        const infoPoint = infoPoints.find(
          infoPoint => infoPoint.point === intersected.userData.id
        )
        controls.enableRotation(false)
        showLabel(true, position, infoPoint.title)
      } else {
        controls.enableRotation(true)
        showLabel(false)
      }
      requestAnimationFrame(animate)
    }
    animate()
  }

  const LoadingIcon = (
    <svg
      className="rotate-infinite"
      width="50px"
      height="50px"
      viewBox="0 0 50 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="100%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#F66464" offset="0%"></stop>
          <stop stopColor="#0632FF" offset="48.0968045%"></stop>
          <stop stopColor="#4EB589" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <circle
          id="Oval"
          stroke="url(#linearGradient-1)"
          strokeWidth="3"
          fill="#1D1E1F"
          cx="25"
          cy="25"
          r="23.5"
        ></circle>
      </g>
    </svg>
  )

  return (
    <div className="h-full" data-stand>
      <div ref={mainRef} className="relative flex flex-col items-center justify-center">
        <div
          className={`${className} flex flex-col items-center justify-center w-full h-full ${
            playing && !loading ? 'opacity-0 pb-10' : ''
          }`}
        >
          <LazyImage alt={explore} image={posterImage} />
          <div className="flex w-full h-20 mb-8 -mt-2">
            <div className="flex items-center p-6 leading-9 bg-cyan md:w-1/3 text-20px md:text-3xl">
              {explore}
            </div>
            <div className="flex justify-end p-3 bg-lightBeige22 md:w-2/3 ">
              <Button
                style="blackOutlined"
                className="flex items-center text-20px"
                padded={true}
                title="Visit Now"
                onClick={() => setLoading(true)}
              />
            </div>
          </div>
        </div>
        {loading && !playing && (
          <div className="absolute flex items-center justify-center text-white">
            {LoadingIcon}
            <div className="absolute inset-0 flex items-center justify-center text-sm">
              <span>{loadingPercentage}%</span>
            </div>
          </div>
        )}
        <div
          data-label
          className="fixed left-0 right-0 z-50 hidden w-40 mt-4 ml-4 mr-4 overflow-hidden bg-white rounded"
        >
          <div
            className="h-4"
            style={{
              background: `url(/stand3d/assets/p${printerId}/label.jpg) no-repeat`,
              backgroundSize: 'cover',
            }}
          ></div>
          <div className="p-2 text-xs md:p-4 md:text-sm">
            <span className="block"></span>
            <span className="flex items-center text-blue">
              {useLiterals('learn_more')}
              <img
                className="ml-2"
                src={useMedia('blue-arrow')}
                alt={useLiterals('learn_more')}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stand3D
