// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import Button from './Button'
import Spacer from './Spacer'
import PrintersGallery from './PrintersGallery'
import type { FeaturedPrinter as FeaturedPrinterType } from '../types'
import { useLiterals } from 'store/'

type Props = {
  position?: string,
  title: string,
  url: string,
  printers: Array<FeaturedPrinterType>
}

const FeaturedPrinters = ({ moduleName, title, url, printers }: Props) => {
  return (
    <div className="overflow-hidden text-black bg-white">
      <Spacer top bottom className="pt-8 md:pb-32" small>
        <Container className="relative z-10">
          <Grid className="mb-8">
            <div className="col-span-4 col-start-1 md:col-span-12 md:col-start-1 flex flex-col md:flex-row md:justify-between md:items-end">
              <Appear>
                <div className='w-full md:w-2/3'>
                 <h3 className="font-secondary mb-6 md:mb-0 text-80px leading-none text-black w-full">{title}</h3>
                </div>
              </Appear>
              <Appear>
                <Button
                  style="blackOutlined"
                  title={useLiterals('view_all_the_products')}
                  href={url}
                />
              </Appear>
            </div>
          </Grid>
        </Container>
        <div className="border-b-2 border-black mb-12 md:mb-20" />
        <div className="overflow-visible">
          <PrintersGallery printers={printers} type="printersWithBorders" />
        </div>
      </Spacer>
    </div>
  )
}

export default FeaturedPrinters
