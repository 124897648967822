// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'

type Props = {
  nav?: {prev: string, next: string},
  title: string,
  menuId?: string,
  whiteParentBG: boolean,
}

const CategoryBlock19 = ({ nav, menuId, title, whiteParentBG = false }: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer top={true} bottom={(typeof nav !== "undefined" && nav.next !== 'CategoryBlock8')} smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-6 md:col-start-4 md:mb-0 ${(typeof nav !== "undefined" && nav.prev !== 'CategoryBlock2') ? '' : '-mt-4'}`}>
              <h3 className="font-light text-big2"><Appear><Html text={title} /></Appear></h3>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock19