// @flow

import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'

type Props = {
  title: string,
  type: string,
  big: boolean,
  postType?: string,
}

const SimpleHeaderImage = ({
  type,
  title,
  postType = false,
  big = false,
}: Props) => {
  const [inIframe, setInIframe] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('iniframe')) {
      setInIframe(true)
    }
  }, [])

  if (inIframe) return null

  return (
    <div
      className={`relative flex flex-col items-center ${
        type === 'printers' || type === 'stories'
          ? 'justify-center'
          : 'justify-end'
      } ${postType === 'post' ? 'h-full' : 'h-32rem gradient-overlay'}
      w-full text-white bg-center bg-no-repeat bg-cover`}
    >
      {postType === 'post' ? (
        <Container className="relative z-10 h-menu" />
      ) : (
        title &&
        title.length > 0 && (
          <Container className="relative z-10 h-full">
            <Grid>
              <h1
                className={`${
                  type === 'stories' ? 'mb-32 mt-32' : 'mb-8'
                } z-20 flex flex-col w-full h-full
                ${
                  type === 'printers' || type === 'stories'
                    ? 'col-start-1 col-span-4 md:col-start-4 md:col-span-6 text-center'
                    : 'col-span-3 md:col-start-1 md:col-span-5'
                }
                ${big ? 'text-big4' : 'text-big3'}
              `}
              >
                {title}
              </h1>
            </Grid>
          </Container>
        )
      )}
    </div>
  )
}

export default SimpleHeaderImage
