// @flow
import React, { useState, useEffect } from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import { useMedia } from 'store/'
import type { ApiImage } from '../types'
import youtubeParser from '../lib/youtubeParser'
import loadYoutube from "../lib/loadYoutube"
import GradientModule from './GradientModule'

type Props = {
  url: string,
  image: ApiImage,
  id: string,
  noGrid: boolean,
  centerPlay?: boolean,
  gradientStart?: string,
  gradientEnd?: string,
  gradientOrientation?: string,
  gradientHeading?: string
}

const Video = ({ url, image, noGrid = false, centerPlay = false, extraId, gradientStart = '', gradientEnd = '', gradientOrientation = false, gradientHeading = false }: Props) => {

  const [playing, setPlaying] = useState(false)
  const [player, setPlayer] = useState(false)

  const togglePlaying = () => {
    if (!playing) {
      setPlaying(true)
      player.playVideo()
    }
  }

  const loadVideo = () => {
    loadYoutube(() => {
      const id = youtubeParser(url)
      const p = new window.YT.Player(`youtube-player-${id}-${extraId}`, {
        videoId: id,
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
        host: `${window.location.protocol}//www.youtube.com`,
      })
      setPlayer(p)
    })
  }

  useEffect(() => {
    if (!player) {
      loadVideo()
    }
  }, [])

  if (noGrid) {
    return (
      <div
        onClick={togglePlaying}
        className="relative w-full overflow-hidden"
        style={{ paddingTop: '56.2%' }}
      >
        <div
          className={`absolute top-0 left-0 w-full h-full ${
            playing ? 'visible' : 'invisible'
          }`}
        >
          <div
            className="w-full h-full"
            id={`youtube-player-${youtubeParser(url)}-${extraId}`}
          ></div>
        </div>
        <div
          className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 ${
            playing ? 'hidden' : ''
          }`}
        >
          <div className="relative">
            <LazyImage image={image} alt="Video" />
            <div className={`${centerPlay ? 'top-0 right-0 flex items-center justify-center' : '' } absolute bottom-0 left-0 cursor-pointer`} style={ centerPlay ? {} : { marginBottom: '6%', marginLeft: '2%'}}>
              <LazySimpleImage image={useMedia('player')} alt="Video" imageClassName="w-10 h-10 md:w-auto md:h-auto"/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={gradientStart && gradientStart.length > 0 && gradientEnd.length > 0 ? {
        backgroundImage: `linear-gradient(${gradientOrientation? gradientOrientation : '90'}deg, #${gradientStart.replace('#', '')} 0%, #${gradientEnd.replace('#', '')} 100%)`
      } : {
        backgroundColor: `#${gradientStart.replace('#', '')}`
      }}
    >
      <GradientModule gradientHeading={gradientHeading}>
        <Spacer top bottom className="relative pt-16 pb-16 md:pt-16 lg:pt-16 md:pb-16 lg:pb-16">
          <Container>
            <Grid>
              <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4">
                <div
                  onClick={togglePlaying}
                  className="relative w-full"
                  style={{ paddingTop: '56.2%' }}
                >
                  <div
                    className={`absolute top-0 left-0 w-full h-full ${
                      playing ? 'visible' : 'invisible'
                    }`}
                  >
                    <div
                      className="w-full h-full"
                      id={`youtube-player-${youtubeParser(url)}-${extraId}`}
                    ></div>
                  </div>
                  <div
                    className={`absolute top-0 left-0 w-full h-full transition transition-opacity duration-500 ${
                      playing ? 'hidden' : ''
                    }`}
                  >
                    <LazyImage image={image} alt="Video" />
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center cursor-pointer">
                      <LazySimpleImage image={useMedia('player')} alt="Video" />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Container>
        </Spacer>
      </GradientModule>
    </div>
  )
}

export default Video
