// @flow

import React from 'react'
import Grid from './Grid'
import Container from './Container'
import Appear from './Appear'

type Props = {
  text: string,
}

const TyphoonHeadingWide = ({ text }: Props) => {
  
  return (
    <Container backgroundColor="bg-black">
      <Grid>
        <div className="col-span-4 py-16 text-center text-white md:py-24 md:col-start-3 md:col-span-8">
          <Appear>
            <div className="font-light leading-tight text-20px md:text-30px">{text}</div>
          </Appear>
        </div>
      </Grid>
    </Container>
  )
}

export default TyphoonHeadingWide
