// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import VideoSlider from './VideoSlider'

import type { ApiImage } from 'types/'

type PrinterApplicationsItemOptions = {
  text: string,
  image: ApiImage,
  url: string,
  id: string,
}
type PrinterApplicationsItem = {
  options: PrinterApplicationsItemOptions,
}
type Props = {
  id: string,
  title: string,
  text: string,
  items: Array<PrinterApplicationsItem>
}

const PrinterApplicationsVideo = ({ id, title, text, items }: Props) => {
  return (
    <div data-hasgradient={0}>
      <Spacer top bottom className="text-black bg-beige22">
        <Container>
          <Grid>
            <div className="col-span-4 col-start-1 mb-12 md:col-span-5 md:col-start-4">
              <Appear>
                <h3 className="mb-4 text-big1">{title}</h3>
              </Appear>
              <Appear>
                <div>
                  <Html text={text} />
                </div>
              </Appear>
            </div>
            <div className="col-span-4 col-start-1 overflow-hidden md:col-span-9 md:col-start-4">
              <VideoSlider id={id} items={items} />
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default PrinterApplicationsVideo
