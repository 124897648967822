// @flow

import React from 'react'
import Container from './Container'
import Spacer from './Spacer'
import Appear from './Appear'
import Button from './Button'
import PolestarFamilyItem from './PolestarFamilyItem'
import Grid from './Grid'
import makeLayer from '../lib/makeLayer'

type Props = {
  title: string,
  subtitle: string,
  ctaText: string,
  url: string,
  items: Array<any>,
  linkid: string,
}

const PolestarFamily = ({ title, subtitle, ctaText, url, items, linkid }: Props): React$Node => {
  return (
    <>
      <Container>
        <Grid>
          <Appear className="col-span-4 col-start-1 md:col-span-12 flex flex-col md:flex-row justify-between md:items-end">
            <div className="flex flex-col pb-6 md:pb-0 md:w-2/3">
              <h2 className="md:text-80px text-5xl leading-none font-secondary">{title}</h2>
              {subtitle && <h3 className="font-light pt-4">{subtitle}</h3>}
            </div>
            <div>
              <Button title={ctaText} href={url} style="blackOutlined" padded={true} dataLayer={makeLayer({
                event: 'e_contactClick',
                linkPlacement: 'content',
                linkID: linkid,
              })}/>
            </div>
          </Appear>
        </Grid>
      </Container>
      <div className='h-px w-full bg-black my-8'></div>
      <Container>  
        <Grid>
          <Appear className="col-span-4 md:col-start-1 md:col-span-12 md:flex md:flex-wrap">
                {items && items.length > 0 && items.map(({ key, image, title, subtitle, text, ctaText, ctaUrl}, index) => (
                  <div key={key} className="w-full md:w-1/3">
                    <PolestarFamilyItem
                      image={image}
                      title={title}
                      subtitle={subtitle}
                      text={text}
                      ctaText={ctaText}
                      ctaUrl={ctaUrl}
                      dataTagName={`polestar-family-cta${index + 2}`}
                    />
                  </div>
                ))}
          </Appear>
        </Grid>
      </Container>
    </>
  )
}

export default PolestarFamily
