// @flow
import React from 'react'

const Cross = ({
  small = false,
  big = false,
  color = 'black'
}: {
  small?: boolean,
  big?: boolean,
  color?: string
}) => {
  const width = () => {
    if (small) {
      return 'w-10px'
    }
    if (big) {
      return 'w-20px'
    }
    return 'w-15px'
  }
  return (
    <div className="relative flex items-center justify-center w-6 h-6 text-white">
      <div
        className={`absolute h-px bg-${color} ${width()}`}
        style={{ transform: 'rotate(-45deg)' }}
      ></div>
      <div
        className={`absolute h-px bg-${color} ${width()}`}
        style={{ transform: 'rotate(45deg)' }}
      ></div>
    </div>
  )
}

export default Cross
