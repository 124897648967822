// @flow
import React, { useState, useEffect } from 'react'
import makeAssetUrl from '../lib/makeAssetUrl'
import useIsMobile from '../hooks/useIsMobile'

type Video = {
  file: string,
}

type Props = {
  video: Video,
  videoMobile: Video,
}

const VideoHeading = ({ video, videoMobile }: Props) => {
  const isMobile = useIsMobile()
  const [videoFile, setVideoFile] = useState()

  useEffect(() => {
    if (isMobile) {
      const file = makeAssetUrl({ asset: videoMobile, isImage: false })
      setVideoFile(file)
    } else {
      const file = makeAssetUrl({ asset: video, isImage: false })
      setVideoFile(file)
    }
  }, [isMobile])

  return (
    <div className="relative w-full h-full mt-menu-mobile md:mt-menu-desktop bg-realBlack">
      {videoFile && <video
        key={isMobile ? 'mobile' : 'desktop'}
        autoPlay
        controls={false}
        muted
        height="100%"
        width="100%"
      >
        <source
          src={videoFile}
          type="video/mp4"
        />
      </video>}
      <div
        className="absolute bottom-0 left-0 right-0 h-20"
        style={{
            backgroundImage: "linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)"
        }}
    />
    </div>
  )
}

export default VideoHeading
