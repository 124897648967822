// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Button from './Button'
import makeLayer from '../lib/makeLayer'
import LazyImage from './LazyImage'
import Html from './Html'
import type { ApiImage } from 'types/'

type Props = {
  title: string,
  text: string,
  ctaLabel: string,
  ctaLink: string,
  image: ApiImage,
  whiteParentBG: boolean,
  anchor: string,
}

const CategoryBlockLinkImage = ({ title, text, ctaLabel, ctaLink, image, whiteParentBG = false, anchor }: Props) => {
  return (
    <div data-anchor={anchor}>
      <Spacer top={true} bottom smaller>
        <Container className={whiteParentBG ? "text-black" : "text-white"}>
          <Grid>
            <Appear className="col-span-4 pb-8 md:pb-20 md:col-start-4">
              <h2 className="pb-10 md:w-2/3 lg:w-full md:pb-8 text-big3">{title}</h2>
              <div className="pb-6">
                <Html text={text} className="font-light" />
              </div>
              {ctaLink && ctaLabel && <Button dataLayer={makeLayer({event:'e_contactClick', linkPlacement:'content-gated',linkID: ctaLink})} target="_blank" title={ctaLabel} href={ctaLink} style="blackOutlined"/>}
            </Appear>
            <Appear className="col-span-4 pb-8 md:col-start-9 md:pb-12 lg:pb-16">
              <LazyImage image={image} alt={title}/>
            </Appear>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlockLinkImage