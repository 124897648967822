import React from 'react'

const IconBookDemo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="65.619"
      height="52.495"
      viewBox="0 0 65.619 52.495"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7616"
            data-name="Rectangle 7616"
            width="65.619"
            height="52.495"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_19222" data-name="Group 19222" transform="translate(0 0)">
        <g
          id="Group_19221"
          data-name="Group 19221"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_8977"
            data-name="Path 8977"
            d="M21.848,43a.82.82,0,0,0,.82-.82V40.539a.82.82,0,0,0-1.64,0V42.18a.82.82,0,0,0,.82.82"
            transform="translate(-6.264 -11.831)"
            fill="currentColor"
          />
          <path
            id="Path_8978"
            data-name="Path 8978"
            d="M24.381,19.8a.837.837,0,0,0-1.116-.312,4.223,4.223,0,0,0-1.493,1.394.836.836,0,0,0,.689,1.263.771.771,0,0,0,.689-.394,2.6,2.6,0,0,1,.9-.836.821.821,0,0,0,.33-1.112l0,0"
            transform="translate(-6.45 -5.776)"
            fill="currentColor"
          />
          <path
            id="Path_8979"
            data-name="Path 8979"
            d="M22.668,49.283v-1.64a.82.82,0,1,0-1.64,0v1.64a.82.82,0,0,0,1.64,0"
            transform="translate(-6.264 -13.947)"
            fill="currentColor"
          />
          <path
            id="Path_8980"
            data-name="Path 8980"
            d="M21.848,28.982a.82.82,0,0,0,.82-.82V26.521a.82.82,0,0,0-1.64,0v1.641a.82.82,0,0,0,.82.82"
            transform="translate(-6.264 -7.656)"
            fill="currentColor"
          />
          <path
            id="Path_8981"
            data-name="Path 8981"
            d="M21.848,35.991a.82.82,0,0,0,.82-.82V33.53a.82.82,0,0,0-1.64,0v1.641a.82.82,0,0,0,.82.82"
            transform="translate(-6.264 -9.743)"
            fill="currentColor"
          />
          <path
            id="Path_8982"
            data-name="Path 8982"
            d="M24.058,55.017a2.464,2.464,0,0,1-.9-.837.82.82,0,0,0-1.394.853,4.058,4.058,0,0,0,1.476,1.411.768.768,0,0,0,.41.114.826.826,0,0,0,.41-1.542"
            transform="translate(-6.456 -16.039)"
            fill="currentColor"
          />
          <path
            id="Path_8983"
            data-name="Path 8983"
            d="M30.427,18.692h-1.64a.82.82,0,1,0,0,1.64h1.64a.82.82,0,1,0,0-1.64"
            transform="translate(-8.33 -5.568)"
            fill="currentColor"
          />
          <path
            id="Path_8984"
            data-name="Path 8984"
            d="M37.46,56.074H35.82a.82.82,0,1,0,0,1.64h1.64a.82.82,0,1,0,0-1.64"
            transform="translate(-10.425 -16.703)"
            fill="currentColor"
          />
          <path
            id="Path_8985"
            data-name="Path 8985"
            d="M37.506,18.692h-1.64a.82.82,0,0,0-.821.82.8.8,0,0,0,.788.82H37.49a.836.836,0,0,0,.82-.82.82.82,0,0,0-.8-.82"
            transform="translate(-10.439 -5.568)"
            fill="currentColor"
          />
          <path
            id="Path_8986"
            data-name="Path 8986"
            d="M30.4,56.074h-1.64a.82.82,0,1,0,0,1.64H30.4a.82.82,0,1,0,0-1.64"
            transform="translate(-8.323 -16.703)"
            fill="currentColor"
          />
          <path
            id="Path_8987"
            data-name="Path 8987"
            d="M59.057,8.2H55.775V4.1A4.167,4.167,0,0,0,51.544,0H14.075A4.167,4.167,0,0,0,9.843,4.1h0V8.2h-4.1A5.741,5.741,0,0,0,0,13.944v7.382a1.64,1.64,0,0,0,1.64,1.64H8.2V51.626a.82.82,0,1,0,1.64,0V45.934H55.775v5.741a.82.82,0,0,0,1.64,0V22.967h6.562a1.64,1.64,0,0,0,1.64-1.64V14.765A6.562,6.562,0,0,0,59.057,8.2M11.483,4.1a2.526,2.526,0,0,1,2.59-2.461h37.47A2.527,2.527,0,0,1,54.135,4.1V8.2H11.483Zm52.5,17.225H53.314a.82.82,0,1,0,0,1.64h2.461V44.293H9.843V22.967H12.32a.82.82,0,1,0,0-1.64H1.64V13.944a4.1,4.1,0,0,1,4.1-4.1H59.057a4.921,4.921,0,0,1,4.921,4.921Z"
            transform="translate(0 0)"
            fill="currentColor"
          />
          <path
            id="Path_8988"
            data-name="Path 8988"
            d="M60.115,18H45.35v-.821a.82.82,0,1,0-1.64,0v.9h-.788a.837.837,0,0,0-.82.821.82.82,0,0,0,.82.82h.788V44.243h-.788a.82.82,0,1,0,0,1.64h.788v.82a.82.82,0,1,0,1.64,0v-.82H60.115a3.281,3.281,0,0,0,3.28-3.281V21.276A3.281,3.281,0,0,0,60.115,18m1.64,23.951a2.3,2.3,0,0,1-2.3,2.3H45.35V32l3.9,4.216a1.194,1.194,0,0,0,.885.377,1.214,1.214,0,0,0,.886-.377L53.749,33.5a.952.952,0,0,1,1.312,0l6.693,6.726Zm0-4.052L56.21,32.35a2.477,2.477,0,0,0-1.8-.755,2.545,2.545,0,0,0-1.821.755l-2.427,2.428L45.35,29.594V19.636H60.115a1.64,1.64,0,0,1,1.64,1.64Z"
            transform="translate(-12.541 -4.872)"
            fill="currentColor"
          />
          <path
            id="Path_8989"
            data-name="Path 8989"
            d="M54.682,25.7a3.281,3.281,0,1,0,3.28,3.281,3.281,3.281,0,0,0-3.28-3.281m0,4.921a1.641,1.641,0,1,1,1.64-1.64,1.641,1.641,0,0,1-1.64,1.64"
            transform="translate(-15.311 -7.656)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}

export default IconBookDemo
