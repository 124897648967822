// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'
import slugify from 'slugify'

type Props = {
  nav: {prev: string, next: string},
  title: string,
  text: string,
  menuId?: string,
  whiteParentBG?: boolean,
}

const CategoryBlock15 = ({ nav, menuId, title, text, whiteParentBG = true }: Props) => {
  const anchor = 'printer-' + slugify(title, { lower: true })
  return (
    <div data-menuid={menuId ? menuId : anchor}>
      <Spacer top={(nav.prev !== 'CategoryBlock2')} smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-6 md:col-start-4 md:mb-0`}>
              <h3 className="my-4 font-bold md:my-8 text-big2">
                <Appear>{title}</Appear>
              </h3>
              <h3 className="font-light text-big2"><Appear><Html text={text} /></Appear></h3>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock15