// @flow
import React from 'react'
import Container from "./Container"
import type { ContainerProps } from "./Container"
import Grid from "./Grid"
import Markdown from "./Markdown"
import Button from "./Button"
import Appear from './Appear'
import makeLayer from '../lib/makeLayer'

const checkIcon = (
<svg width="100%" height="100%" viewBox="0 0 25 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ServicesAndSolutions_Vertical_V03_1" transform="translate(-873.000000, -2312.000000)" fill="#000000" fillRule="nonzero">
            <g id="done_black_24dp-copy" transform="translate(868.000000, 2304.000000)">
                <path d="M13.125,23.625 L8.02083333,18.5208333 C7.45208333,17.9520833 6.54791667,17.9520833 5.97916667,18.5208333 C5.41041667,19.0895833 5.41041667,19.99375 5.97916667,20.5625 L12.0895833,26.6729167 C12.6583333,27.2416667 13.5770833,27.2416667 14.1458333,26.6729167 L29.6041667,11.2291667 C30.1729167,10.6604167 30.1729167,9.75625 29.6041667,9.1875 C29.0354167,8.61875 28.13125,8.61875 27.5625,9.1875 L13.125,23.625 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
)

//TODO types
type ColumnsType = {
  title: string,
  text: string,
}
type RowsType = {
  title: string,
  subrows: Array<{
    title: string,
    text: string,
    tag?: string,
  }>,
}

type Props = {
  pageName: [String],
  title: string,
  cta1Text: string,
  cta1Url: string,
  columns: Array<ColumnsType>,
  rows: Array<RowsType>,
  footerText: string,
  cta2Text: string,
  cta2Url: string,
}

const Table = ({ title, cta1Text, cta1Url, columns, rows, footerText, cta2Text, cta2Url, pageName }: Props): React$Element<(_0: ContainerProps) => React$Element<"div">> => {
  return (
    <Container className="pt-10 pb-8 md:pt-16 md:pb-12">
      <Appear className="flex flex-col pb-6 border-b border-black md:pb-10 md:mb-12 md:items-center md:justify-between md:flex-row">
        <h2 className="mb-4 leading-tight text-40px sm:text-60px md:text-70px lg:text-80px md:mb-0">{title}</h2>
        <div className="w-max">
          <Appear>
            <Button 
              title={cta1Text} 
              to={cta1Url} 
              style="blackOutlined" 
              padded
              dataLayer={
                pageName[3] === 'hp-professional-print-service'
                ? makeLayer({
                  event: 'e_contactClick',
                  linkPlacement: 'professional-print-service',
                  linkID: 'contact-expert',
                })
                : ''
              }
              />
            </Appear>
        </div>
      </Appear>
      {/* table desktop */}
      <Appear className="hidden md:block">
        <Grid noGap>
          {/* top empty space */}
          <div className="col-span-6 col-start-1 row-start-1 border-r border-black" />
          {/* columns  */}
          {columns.map((col, index) => {
            return (
            <div
            key={`${index}-col`}
            className={`px-4 py-6 col-span-3 ${index === 0 ? "col-start-7" : "col-start-10"} row-start-1 border-t border-r border-black`}
            >
              <h4 className="mb-3 text-30px">{col.title}</h4>
              <Markdown text={col.text} className="text-base" />
            </div>
          )})}
          {/* rows & subrows */}
          {rows.map((row, index) => {
            return (
            <>
              <div
                key={`${index}-row`}
                className={`col-span-2 col-start-1 p-4 ${index === 0 ? "border-t" : ""} border-b border-l border-r border-black flex items-center justify-center`}
                style={{
                  gridRow: `span ${row.subrows.length}/span ${row.subrows.length}`
                }}
              >
                <h4 className="text-30px">{row.title}</h4>
              </div>
              {row.subrows.map((sub, i) => {
                return (
                  <>
                    <div key={`${i}-info`} style={{ minHeight: 73.19 }} className={`${i === 0 && index === 0 ? "border-t" : ""} flex flex-col justify-center border-b border-r border-black col-span-4 col-start-3 row-span-1 p-3 relative`}>
                      <Markdown text={sub.title} className="mb-1 text-base"/>
                      <Markdown text={sub.text} className="text-sm"/>
                      {sub.tag && sub.tag.length > 0 && <div className="absolute top-0 right-0 px-2 py-1 m-1 text-xs leading-none rounded-sm bg-lighterLavender">{sub.tag}</div>}
                    </div>
                    {/* column 1 */}
                    <div key={`${i}-basic`} className={`${i === 0 && index === 0 ? "border-t" : ""} border-b border-r border-black col-span-3 col-start-7 row-span-1 p-3 flex items-center justify-center`}>
                      {(index === 0 && (i === 0 || i === 1 || i === 3))
                      || (index === 1 && (i === 0 || i === 1 || i === 2  || i === 5))
                      || (index === 2 && (i === 0))
                      ? <div className="w-6 h-6">{checkIcon}</div> : null}
                    </div>
                    {/* column 2 */}
                    <div key={`${i}-plus`} className={`${i === 0 && index === 0 ? "border-t" : ""} border-b border-r border-black col-span-3 col-start-10 row-span-1 p-3 flex items-center justify-center`}>
                      <div className="w-6 h-6">{checkIcon}</div>
                    </div>
                  </>
                )
              })}
            </>
          )})}
        </Grid>
      </Appear>
      {/* table mobile */}
      <Appear className="md:hidden">
        <Grid noGap>
          {columns.map((col, colIndex) => {
            return (
              <>
                <div
                key={`${colIndex}-col`}
                className={`px-4 py-6 col-span-4 col-start-1 mt-6`}
                >
                  <h4 className="mb-3 text-30px">{col.title}</h4>
                  <Markdown text={col.text} className="text-base" />
                </div>
                {rows.map((row, rowIndex) => {
                return (
                  <>
                    <div key={`${rowIndex}-row`} className={`col-span-4 col-start-1 p-4 ${rowIndex === 0 ? "border-t" : ""} border-b border-l border-r border-black`}>
                      <h4 className="text-20px md:text-30px">{row.title}</h4>
                    </div>
                    {row.subrows.map((sub, i) => {
                      return (
                        <>
                          <div key={`${i}-info`} style={{ minHeight: 112.19 }} className={`flex flex-col justify-center border-l border-b border-r border-black col-span-3 col-start-1 p-3 relative`}>
                            <Markdown text={sub.title} className="w-4/5 mb-1 text-base"/>
                            <Markdown text={sub.text} className="w-4/5 text-sm"/>
                            {sub.tag && sub.tag.length > 0 && <div className="absolute top-0 right-0 px-2 py-1 m-1 text-xs leading-none rounded-sm bg-lighterLavender">{sub.tag}</div>}
                          </div>
                          {colIndex === 0 && <div key={`${i}-basic`} className={`border-b border-r border-black col-span-1 col-start-4 p-3 flex items-center justify-center`}>
                            {(rowIndex === 0 && (i === 0 || i === 1 || i === 3))
                            || (rowIndex === 1 && (i === 0 || i === 1 || i === 2))
                            || (rowIndex === 2 && i === 0)
                            ? <div className="w-6 h-6">{checkIcon}</div> : null}
                          </div>}
                          {colIndex === 1 && <div key={`${i}-plus`} className={`border-b border-r border-black col-span-1 col-start-4 p-3 flex items-center justify-center`}>
                            <div className="w-6 h-6">{checkIcon}</div>
                          </div>}
                        </>
                      )
                    })}
                  </>
                )})}
              </>
          )})}
        </Grid>
      </Appear>
      <Appear className="mt-12">
        <Markdown text={footerText} className="mb-6 leading-tight text-24px md:text-48px"/>
        <Button 
          title={cta2Text} 
          to={cta2Url} 
          style="blackOutlined" 
          padded
          dataLayer={makeLayer({
            event: 'e_contactClick',
            linkPlacement: 'professional-print-service',
            linkID:{cta2Url}
          })}
          />
      </Appear>
    </Container>
  )
}

export default Table