// @flow

import { MutableRefObject, useEffect, useRef } from "react"

type Options = MutationObserverInit & {
  /**
   * Permite desconectar condicionalmente el `MutationObserver`
   */
  disconnect?: boolean
}

/**
 * Crea un `MutationObserver` y observa la referencia proporcionada.
 *
 * ```tsx
 * useMutationObserver(
 *   ref,
 *   record => console.log(record),
 *   { attributes: true }
 * )
 * ```
 */
export default function useMutationObserver(
  reference: MutableRefObject<HTMLElement>,
  callback: (record: MutationRecord) => void,
  options?: Options
): void {
  const observer = useRef<MutationObserver>(null)

  function disconnectFn(): void {
    observer.current && observer.current.disconnect()
  }

  useEffect(() => {
    const { disconnect, ...observerOptions } = options

    if (disconnect) return disconnectFn()

    if (reference.current) {
      observer.current = new MutationObserver(([record]) => {
        callback && callback(record)
      })
      observer.current.observe(reference.current, observerOptions)
    }

    return disconnectFn
  }, [callback, options, reference])
}
