const THREE = require('three')

class Points {

  constructor(scene, textures, availablePoints) {
    this.scene = scene
    this.textures = textures
    this.availablePoints = availablePoints
  }

  load() {
    const pointsGroup = new THREE.Group()
    const helperPointsGroup = new THREE.Group()

    this.points = this.loadPoints()
    this.points.forEach(points => pointsGroup.add(points))
    this.scene.add(pointsGroup)
    
    this.helperPoints = this.loadHelperPoints()
    this.helperPoints.forEach(helperPoints => helperPointsGroup.add(helperPoints))
    // this.scene.add(helperPointsGroup)
  }

  get(id) {
    return this.list().find(item => item.id === id)
  }
  
  getPoint(id) {
    if (id) {
      return this.points.find(item => item.userData.id === id)
    }
    return this.points
  }

  getHelper(id) {
    if (id) {
      return this.helperPoints.find(mesh => mesh.userData.id === id)
    }
    return this.helperPoints
  }

  list() {
    const points = [{
      id: 'POINT11'  ,
      position: [-7.848904 , 0.902415 , -5.9790007],
      rotation: -10,
    },
    {
      id: 'POINT3',
      position: [-7.5315873 , 1.4076785 ,3.6785711],
      rotation: 10,
    },
    {
      id: 'POINT4',
      position: [7.5338978 , 1.3782335,  8.153114],
      rotation: 50,
    },
    {
      id: 'POINT2',
      position: [10.451391 , 2.050687 , 1.1795896],
      rotation: 100,
    },
    {
      id: 'POINT9',
      position: [4.6843165 , 1.4029208 , -6.4518618],
      rotation: 130,
    },
    {
      id: 'POINT6',
      position: [11.55916 , 1.4029208 , -5.8560291],
      rotation: 120,
    },
    {
      id: 'POINT7',
      position: [1.9336695 , 1.3320909 , -10.35034],
      rotation: 140,
    },
    {
      id: 'POINT8',
      position: [-4.4462038 , 2.1564248 , -13.458271],
      rotation: 190,
    },
    {
      id: 'POINT10',
      position: [-2.2168535 , 1.9342015 ,-1.5256221],
      rotation: -70,
    },
    {
      id: 'POINT5',
      position: [-10.66383 , 1.3240641 , -0.37541921],
      rotation: -85,
    },
    {
      id: 'POINT1',
      position: [3.3662815 , 1.4076785 , 0.10090662],
      rotation: 0,
    }]

    return points.filter(point => this.availablePoints.includes(point.id))
  }

  loadPoints() {
    const geometry = new THREE.PlaneGeometry(0.7, 0.7)
    const circles = []
    this.list().forEach(point => {

      const material = new THREE.MeshBasicMaterial({ 
        color: 0xffffff,
        depthTest: false,
        map: this.textures.get(point.id.toLowerCase()),
        transparent: true,
      })
      const circle = new THREE.Mesh(geometry, material)
      circle.userData.id = point.id
      circle.position.set(point.position[0], point.position[1], point.position[2])
      circles.push(circle)
    })
    return circles
  }

  loadHelperPoints() {
    const geometry = new THREE.PlaneGeometry(0.7, 0.7)
    const helpers = []
    this.list().forEach(point => {
      const material = new THREE.MeshBasicMaterial({ 
        color: 0x0000ff,
        depthTest: false,
      })
      const helper = new THREE.Mesh(geometry, material)
      helper.rotateY(point.rotation * Math.PI/180)
      helper.userData.id = point.id
      helper.position.set(point.position[0], point.position[1], point.position[2])
      helpers.push(helper)
    })
    return helpers
  }

  lookAtCamera(camera) {
    this.points.forEach(point => point.lookAt(camera.position))
  }

}

export default Points