// @flow

import React from 'react'
import Html from './Html'
import LazyImage from './LazyImage'
import type { ApiImage } from '../types'

type Props = {
  title: string,
  text: string,
  image?: ApiImage
}

const TyphoonFeaturesRowItem = ({ title, text, image }: Props) => {
  const hasImage = typeof image === 'object' && image.length !== 0

  return (
    <div className={`flex flex-col items-start justify-between ${hasImage ? '' : 'border-t-10 border-t-solid border-paleGray'}`}>
      {hasImage && <div className="w-full">
        <LazyImage image={image} alt={title} draggable={false}/>
      </div>}
      <h4 className={`mb-4 md:mb-6 w-full md:w-3/4 pt-5 font-light leading-tight text-2.5xl md:text-30px font-primary`}><Html text={title}/></h4>
      <div className="text-sm font-light md:text-base font-primary">
        <Html text={text} />
      </div>
    </div>
  )
}

export default TyphoonFeaturesRowItem
