// @flow

import React from 'react'
import Button from './Button'
import Grid from './Grid'
import Container from './Container'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from '../types'
import makeAssetUrl from '../lib/makeAssetUrl'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  title: string,
  subtitle: string,
  backgroundImage?: ApiImage,
  backgroundImageMobile?: ApiImage,
  ctaLabel: string,
  ctaLink: string,
}

const TyphoonHeading = ({
  title,
  subtitle,
  backgroundImage,
  backgroundImageMobile,
  ctaLabel,
  ctaLink,
}: Props) => {
  const isMobile = useIsMobile()

  return (
    <div
      className={`${isMobile ? 'h-screen' : 'h-80vh'} w-full bg-gray`}
      style={{
          backgroundImage: `url(${makeAssetUrl({ asset: isMobile ? backgroundImageMobile : backgroundImage, isImage: true })})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
    >
      <Container className="md:mt-menu-desktop mt-menu-mobile">
        <Grid>
          <div className="col-span-4 text-center text-white md:col-span-5 lg:col-span-4 mt-215px md:mt-150px md:text-left">
            <Appear>
              <h1 className="mb-10 leading-tight text-30px md:text-50px"><Html text={title} /></h1>
              <div className="mb-8 text-lg font-light md:text-22px"><Html text={subtitle} /></div>
              <Button title={ctaLabel} to={ctaLink} style="white" padded textClasses="text-sm md:text-base"/>
            </Appear>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

export default TyphoonHeading
