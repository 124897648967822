// @flow

import React from 'react'
import LazyImage from './LazyImage'
// import TitleCtas from './TitleCtas'
import Html from './Html'
import Button from "./Button"
import makeLayer from '../lib/makeLayer'

type Props = {
  image: Array<Object>,
  title: string,
  subtitle: string,
  text: string,
  ctaText: string,
  ctaUrl: string,
  dataTagName: string,
}

const PolestarFamilyItem = ({ image, title, subtitle, text, ctaUrl, ctaText, dataTagName }: Props): React$Node => {
  return (
    <div className="flex flex-col h-full mx-2 mb-8 bg-white">
      <LazyImage image={image} alt={title} className="border border-grayLighter" />
      <div className="flex flex-col justify-between flex-grow p-5 md:p-8 bg-white">
        <div>
          <h3 className="pb-2 text-3xl font-normal leading-none font-secondary">{title}</h3>
          <h5 className="py-3 text-xl">{subtitle}</h5>
          <span className="block m-4 text-xl font-light listNarrow">
            <Html text={text}/>
          </span>
        </div>
        <div className={`mb-4`}>
          <Button
            href={ctaUrl}
            title={ctaText}
            style="whiteOutlineBlack"
            dataLayer={makeLayer({
              event: 'e_document',
              documentAction: 'download',
              documentID: dataTagName,
              documentType: 'pdf'
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default PolestarFamilyItem