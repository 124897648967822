// @flow

import React from 'react'
import Appear from './Appear'

type BTColumnType = { modelName: string, key: string, text: string }
type BTRowType = {
  modelName: string,
  key: string,
  content: Array<BTColumnType>,
}
type Props = {
  BTHeader: Array<BTRowType>,
  BTBody: Array<BTRowType>,
}

const BTable = ({ BTBody, BTHeader }: Props) => {
  return (
    <Appear className="overflow-x-scroll md:overflow-x-auto">
      <table className="w-180vw md:w-full border-collapse px-5 mt-10 mb-5">
        {BTHeader.length > 0 && BTHeader?.[0]?.content?.length > 0 ? (
          <thead>
            <tr className="w-full bg-lightBeige22">
              {BTHeader[0].content.map(({ text, key }) => (
                <th
                  key={key}
                  className="px-4 border py-3 text-left text-30px font-normal"
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        {BTBody.length > 0 && BTBody?.[0]?.content?.length > 0 ? (
          <tbody className="w-full border">
            {BTBody.map(({ content, key }, index) => (
              <tr
                key={key}
                className={index % 2 === 0 ? 'bg-white' : 'bg-grayLight'}
              >
                {content.map(({ key: innerKey, text }) => (
                  <td key={innerKey} className="px-4 py-3 border-l">
                    {text}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
    </Appear>
  )
}

export default BTable
