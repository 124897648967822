// @flow

import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import LazyImage from './LazyImage'
import CarouselButtons from './CarouselButtons'
import CarouselDots from './CarouselDots'
import LinkHP from './LinkHP'
import Grid from './Grid'
import Container from './Container'

type ItemType = {
  id: string,
  url: string,
  image: ApiImage,
  teaser: string,
  bgColor: string,
  fontColor: string,
  title: string,
  category: string,
}
type Props = { title: string, items: Array<ItemType> }

const FeaturedPostsSlider = ({ title, items }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })

  return items?.length > 0 ? (
    <>
      <Container className="mt-5">
        <Grid>
          <div className="w-full col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 flex justify-between">
            <h4 className="text-30px md:text-48px">{title}</h4>
            <CarouselButtons emblaApi={emblaApi} className="hidden md:flex" />
          </div>
        </Grid>
      </Container>
      <hr className="w-full mt-6 mb-4 md:mb-12" />
      <Container className="mb-5">
        <CarouselButtons
          emblaApi={emblaApi}
          className="flex md:hidden mb-4 md:mb-0 justify-end mr-4"
        />

        <div ref={emblaRef} className="overflow-hidden">
          <div className="flex h-16rem md:h-24rem gap-4 px-4 md:px-20 lg:px-64 xl:px-88">
            {items.map(({ id, image, title, category, url }) => (
              <div
                key={id}
                className="h-auto w-19rem flex-none md:w-21rem border md:border-0"
                style={{ aspectRatio: '3 / 2' }}
              >
                <LinkHP to={url} key={id}>
                  {image ? (
                    <LazyImage
                      image={image}
                      alt={title ?? ''}
                      cover
                      className="w-full h-2/3"
                    />
                  ) : null}
                  <div className="px-2 text-16px mt-1">{category}</div>
                  <h4
                    className="overflow-hidden border-t grow text-18px md:text-24px md:leading-8 leading-6 px-2 pt-2"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '2',
                    }}
                  >
                    {title}
                  </h4>
                </LinkHP>
              </div>
            ))}
          </div>
        </div>
        <CarouselDots emblaApi={emblaApi} className="md:hidden" />
      </Container>
    </>
  ) : null
}

export default FeaturedPostsSlider
