// @flow

import React, { useCallback, useEffect, useState } from 'react'
import IconArrow from './icons/IconArrow'

const CarouselButtons = ({ emblaApi, className = '' }) => {
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  const prev = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])
  const next = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback(emblaApi => {
    setPrevDisabled(!emblaApi.canScrollPrev())
    setNextDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  const arrowClass =
    'border p-1 w-8 h-8 flex items-center justify-center md:p-1 md:w-6 md:h-6'
  return (
    <div className={`flex gap-5 md:gap-2 ${className}`}>
      <div
        className={`${arrowClass} ${
          prevDisabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
        }`}
        onClick={prevDisabled ? () => {} : prev}
      >
        <IconArrow direction="left" />
      </div>
      <div
        className={`${arrowClass} ${
          nextDisabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
        }`}
        onClick={nextDisabled ? () => {} : next}
      >
        <IconArrow direction="right" />
      </div>
    </div>
  )
}

export default CarouselButtons
