// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'

type Props = {
  title: string,
  gradientStart?: string,
  gradientEnd?: string,
  gradientOrientation?: string,
  style?: string,
}

const SectionTitle = ({ title, gradientStart = '', gradientEnd = '', gradientOrientation = false, style = "" }: Props) => {
  const splitTitle = title.split('<br>')

  return (
    <div
    style={gradientStart && gradientStart.length > 0 && gradientEnd.length > 0 ? {
      backgroundImage: `linear-gradient(${gradientOrientation? gradientOrientation : '90'}deg, #${gradientStart.replace('#', '')} 0%, #${gradientEnd.replace('#', '')} 100%)`
    } : {}} className="relative">
      <Spacer top bottom smaller>
        <Container className={style === "fullwidth" ? "pt-16 md:pt-20" : ""}>
          <Grid>
            {style === "fullwidth"
              ? <div className="col-span-4 col-start-1 md:col-span-12 md:col-start-1">
                  <Appear>
                    <h2 className='leading-none text-40px sm:text-60px md:text-70px lg:text-80px'>
                      {splitTitle.map(titleHalf => <div key={titleHalf}>{titleHalf}</div>)}
                    </h2>
                  </Appear>
                </div>
              : <div className="col-span-4 col-start-1 md:col-span-9 md:col-start-4">
                  <h2 className={`text-big3 ${gradientStart? 'text-white' : ''}`}><Appear>{title}</Appear></h2>
                </div>
            }
          </Grid>
        </Container>
      </Spacer>
      {style === "fullwidth" && <div className='w-full h-px border-b-2 border-black' />}
    </div>
  )
}

export default SectionTitle