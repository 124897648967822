// // @flow
import React, { useEffect, useState } from 'react'
import urlparameters from '../lib/urlparameters'

const LinkExternalHP = (props) => {

  urlparameters(props.href, ['jumpid', 'bmtid'], (theUrl) => {
    setHref(theUrl)
  })

  const [href, setHref] = useState(props.href)
  const [finalUrl, setFinalUrl] = useState(props.href)

  useEffect(() => {
    if (href && href.indexOf('reinvent.hp.com') !== -1) {
      let gated = `gated=${props.href}${window.location.search}`.split((/(\?)/g))
      gated.forEach((element, index) => {
        if (element === '?' && index > 2) {
          gated[index] = '&'
        }
      })
      gated = gated.join('')
      setFinalUrl(`${window.location.origin}${window.location.pathname}?${gated}${window.location.hash}`)
    } else if (href) {
      setFinalUrl(href)
    }
  }, [href])

  return (
    <a {...props} href={finalUrl}>
      {props.children}
    </a>
  )
}

export default LinkExternalHP
