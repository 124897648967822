import React from 'react'

const IconChevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 7"
      width="100%"
      height="100%"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M6.5 7 0 .86.91 0 6.5 5.28 12.09 0l.91.86L6.5 7Z"
      />
    </svg>
  )
}

export default IconChevron
