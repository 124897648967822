// @flow
import React from 'react'
import Appear from './Appear'
import LinkHP from './LinkHP'
import type { SimplePrinter } from '../types'
import { useLiterals } from 'store/'
import makeLayer from '../lib/makeLayer'
import slugify from 'slugify'

type Props = {
  printers: Array<SimplePrinter>,
  inGradient?: boolean,
  learnMore?: boolean,
  verticalSlug?: string,
  eventTagModuleName: string,
  whiteParentBG?: boolean,
}

const MadeWithPrinters = ({ moduleName, id, printers, inGradient, eventTagModuleName, verticalSlug = '', learnMore = false, whiteParentBG }: Props) => {
  if (printers.length === 0) {
    return null
  }

  let text = useLiterals(verticalSlug.includes('technical') ? 'view_more' : 'made_with')
  if (learnMore) {
    text = useLiterals('learn_more')
  }

  return (
    <div className="font-light">
      <span>
        <Appear>{text}</Appear>
      </span>
      <div className="text-small mt-3">
        {printers.map(printer => (
          <LinkHP
            key={printer.id}
            to={printer.url}
            className={`mt-4 mr-4  text-black`}
            data-tag-name={`madewith-${printer.id}-printer`}
            dataLayer={makeLayer({
              event: 'e_linkClick',
              linkPlacement: `${eventTagModuleName}-${printer.id}`,
              linkID: slugify(printer.title, { lower: true }),
            })}
          >
            <Appear className="flex items-center hover:underline border-t border-b border-black py-2 mt">
              <div className="flex-shrink-0">
                <div className={`w-4 h-4 rounded-full bg-black`} />
              </div>
              <span className="ml-2">{printer.title}</span>
            </Appear>
          </LinkHP>
        ))}
      </div>
    </div>
  )
}

export default MadeWithPrinters
