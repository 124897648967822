// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Button from './Button'
import Appear from './Appear'
import { useLiterals } from 'store/'
import type { ApiImage } from '../types'
import FeaturedPrinter from './FeaturedPrinter'
import PrintersGallery from './PrintersGallery'
import makeLayer from '../lib/makeLayer'

type FeaturedPrintersType = {
  id: string,
  image: ApiImage,
  title: string,
  url: string,
}

type Props = {
  title: string,
  text: string,
  url: string,
  printers: Array<FeaturedPrintersType>,
  type: string,
}

const CategoryBlockRelatedPrinters = ({ moduleName, url, printers,type }: Props) => {
  return (
    <Spacer top bottom small className="overflow-hidden text-black bg-white">
      <Container>
        <Grid>
          <div className="col-span-4 col-start-1 text-center md:col-span-12 md:col-start-1">
            <div className='flex flex-col md:flex-row justify-between items-start md:items-end'>
              <Appear>
                <h3 className="w-full md:w-2/3 text-big5 text-black text-left mb-8 md:mb-0">
                  {useLiterals('related_printers')}
                </h3>
              </Appear>
              <Appear>
                <Button
                  style="blackOutlined"
                  title={useLiterals('view_all_the_products')}
                  href={url}
                  dataLayer={makeLayer({
                    event: 'e_learnClick',
                    linkPlacement: 'related',
                    linkID: 'all-products',
                  })}
                />
              </Appear>
            </div>
          </div>
        </Grid>
      </Container>
      <div className="border-b-2 border-black mt-8 md:mt-12 mb-8 md:mb-28" />
        {printers.length >= 4 ? (
          <Grid>
            <div className='col-span-4 col-start-1 text-center md:col-span-12 md:col-start-1'>
              <div className={`md:mt-12 md:flex md:justify-start`}>
                <PrintersGallery printers={printers} type="printersWithBorders" />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid className='px-5 md:px-8'>
            {printers.map(printer => (
              <FeaturedPrinter
                {...printer}
                withLink
                className="w-full mb-8 md:mb-0 col-span-4 md:col-span-4"
                i={0}
                key={printer.id}
                type="printersWithBorders"
              />
            )) }
          </Grid>
        )}
    </Spacer>
  )
}

export default CategoryBlockRelatedPrinters
