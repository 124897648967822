const THREE = require('three')

class Lights {

  constructor(scene) {
    this.addLights(scene)
  }

  addLights(scene) {
    const light = new THREE.AmbientLight(0xFFFAEE)
    light.intensity = 1
    scene.add(light)
  }
}

export default Lights