// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import LazyImage from './LazyImage'
import LionRedSquare from './LionRedSquare'
import type { ApiImage } from '../types'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  image: ApiImage,
  squares?: boolean,
}

const FullImage = ({ image, squares = 0 }: Props) => {
  const isMobile = useIsMobile()

  if (squares === 0) {
    return (
      <Appear className="w-full h-auto">
        <LazyImage image={image} cover />
      </Appear>
    )
  }
  return (
    <Container backgroundColor="bg-realBlack">
      <Grid className="relative pb-12" noGap>
        <div className="col-span-4 md:col-span-12">
          <Appear>
            <LazyImage image={image} />
          </Appear>
        </div>
        {squares === 1 && (
          <div className="relative col-span-4 md:col-span-12">
            <div className="absolute bottom-0 left-0 right-0">
              <Grid noGap>
                <div className="md:col-span-2 md:row-start-2 md:row-end-4">
                  <Appear>
                    <LionRedSquare />
                  </Appear>
                </div>
                <div className="hidden md:block md:col-span-2 md:col-start-10 md:row-start-1 md:row-end-3">
                  <Appear>
                    <LionRedSquare />
                  </Appear>
                </div>
                <div className="hidden md:block md:col-span-1 md:col-start-12 md:row-start-3 md:row-end-4">
                  <Appear>
                    <LionRedSquare />
                  </Appear>
                </div>
              </Grid>
            </div>
          </div>
        )}
        {squares === 1 && (
          <div className="col-span-4 md:col-span-12">
            <Grid noGap>
              <div className="col-span-1 col-start-2 row-start-1 md:col-start-3">
                <Appear>
                  <LionRedSquare width={isMobile && 50} />
                </Appear>
              </div>
              <div className="flex justify-end col-span-1 row-start-2 md:block md:col-start-2">
                <Appear>
                  <LionRedSquare width={isMobile && 50} />
                </Appear>
              </div>
            </Grid>
          </div>
        )}
      </Grid>
    </Container>
  )
}

export default FullImage