// @flow
import React from 'react'

type Props = {
  json: string,
}

const UserActivity = ({ json }: Props) => {

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: json }}></script>

}

export default UserActivity
