// @flow
import React from 'react'
import type { Node } from 'react'

type Props = {
  top?: boolean,
  bottom?: boolean,
  small?: boolean,
  smaller?: boolean,
  big?: boolean,
  bigger?: boolean,
  className?: string,
  children?: React$Node,
  dataName?: string,
}

const Spacer = ({ small = false, smaller = false, big = false, bigger = false, className, top, bottom, children, dataName }: Props) => {
  let topClassName = 'pt-16 md:pt-32 lg:pt-48'
  let bottomClassName = 'pb-16 md:pb-32 lg:pb-48'
  if (small) {
    topClassName = 'pt-8 md:pt-16 lg:pt-16'
    bottomClassName = 'pb-8 md:pb-16 lg:pb-16'
  } else if (smaller) {
    topClassName = 'pt-4 md:pt-12 lg:pt-12'
    bottomClassName = 'pb-4 md:pb-12 lg:pb-12'
  } else if (big) {
    topClassName = 'pt-36 md:pt-72 lg:pt-88'
    bottomClassName = 'pb-36 md:pb-72 lg:pb-88'
  } else if (bigger) {
    topClassName = 'pt-48 md:pt-100 lg:pt-100'
    bottomClassName = 'pb-48 md:pb-100 lg:pb-100'
  }
  return (
    <div data-name={dataName} className={`${top ? topClassName : ''} ${bottom ? bottomClassName : ''} ${className ? className : ''}`}>
      {children}
    </div>
  )
}

export default Spacer