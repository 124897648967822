/* eslint-disable react/jsx-no-target-blank */
// @flow

import React, { useState } from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import { useMedia, useLiterals } from 'store/'
import Appear from './Appear'
import LazySimpleImage from './LazySimpleImage'

const BlogSocial = () => {
  const shareLink = typeof window !== "undefined" ? window.location.href : ''
  const [showCopied, setShowCopied ] = useState()

  return shareLink ? (
    <div className='py-4 hidden md:block'>
      <Container>
        <Appear>
          <Grid>
            <div className={`col-span-3 col-start-1 md:col-start-8 md:col-span-3 flex justify-end items-center gap-6`}>
              <div className='relative cursor-pointer rounded-lg w-8' onClick={() => {
                try {
                  navigator.clipboard.writeText(shareLink).then(() => {
                    setShowCopied(true)
                    setTimeout(() => setShowCopied(false), 5000)
                  }, () => {
                    document.execCommand("copy", false, shareLink)
                  })
                } catch (e) {
                  console.log(e)
                }
              }}>
                <LazySimpleImage
                  alt={useLiterals('copy_link')}
                  image={useMedia('link_share')}
                />
                <div className='absolute text-center top-100p left-50p mt-4 px-6 py-1 bg-paleGray' style={{ transition: 'opacity 0.5s ease', opacity: showCopied ? 1 : 0 , transform: "translate(-50%,0)"}}>
                  <p className='text-sm leading-5 break-words whitespace-no-wrap'>{useLiterals('link_copied')}</p>
                </div>
              </div>
              <div 
                className='cursor-pointer rounded-lg w-10'
                onClick={() => window.open(`https://twitter.com/intent/tweet?text=%20&url=${shareLink}`, '_blank', 'location=yes,height=570,width=520')} 
              >
                <LazySimpleImage
                  alt={useLiterals('twitter_share')}
                  image={useMedia('twitter_share')}
                />
              </div>
              <div 
                className='cursor-pointer rounded-lg w-10'
                onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${shareLink}`, '_blank', 'location=yes,height=570,width=520')} 
              >
                <LazySimpleImage
                  alt={useLiterals('facebook_share')}
                  image={useMedia('facebook_share')}
                />
              </div>
              <div
                className='cursor-pointer rounded-lg w-10'
                onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`, '_blank', 'location=yes,height=570,width=520')} 
              >
                <LazySimpleImage
                  alt={useLiterals('linkedin_share')}
                  image={useMedia('linkedin_share')}
                />
              </div>
            </div>
          </Grid>
        </Appear>
      </Container>
    </div>
  ) : null
}

export default BlogSocial
