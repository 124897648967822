// @flow
import React, { useState, useEffect, useRef } from 'react'
import type { ApiImage, Ref } from 'types/'
import Viewer360 from './Viewer360'
import LazyImage from './LazyImage'
import LazySimpleImage from './LazySimpleImage'
import Appear from './Appear'
import Loader from './Loader'
import { useMedia, useLiterals } from 'store/'
// import useIsMobile from '../hooks/useIsMobile'

let offsetX = 40
let offsetY = 40
type Props = {
  image360: Array<ApiImage>,
  image: ApiImage,
  imageZoom: ApiImage,
  alt: string,
  iconColor?: string,
  iconsCenter?: boolean,
  isNew: number
}

let scale = 1.2

const Viewer = ({
  image360,
  image,
  imageZoom,
  alt,
  iconColor = 'white',
  iconsCenter = false,
  isNew = 0
}: Props) => {
  const container: Ref = useRef()
  const zoomContainer: Ref = useRef()
  const mock: Ref = useRef()
  const [showCanvas, setShowCanvas] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [zoom, setZoom] = useState(false)
  const [loadedCanvas, setLoadedCanvas] = useState(false)
  const hasImage360 = Array.isArray(image360) && image360.length > 0
  // const isMobile = useIsMobile()

  // const [mouseDown, setMouseDown] = useState(false)

  const [mousePosition, setMousePosition] = useState({
    x: null,
    y: null,
    opacity: 0,
    state: 'out'
  })

  const handleHover = e => {
    const { top, left, height } = container.current.getBoundingClientRect()
    if (e.type === 'mousemove' || e.type === 'mouseenter') {
      setMousePosition({
        ...mousePosition,
        x: e.clientX - left - offsetX,
        y: e.clientY - top - offsetY - height,
        opacity: 1,
        state: 'in'
      })
    }
    if (e.type === 'mouseleave') {
      setMousePosition({
        ...mousePosition,
        state: 'out',
        opacity: 0
      })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', zoomOff)
    return () => window.removeEventListener('scroll', zoomOff)
  }, [])

  const handleZoom = () => {
    setZoom(!zoom)
    if (!zoom) {
      zoomContainer.current.style.cursor = 'none'
      document.body.style.touchAction = 'none'
      zoomContainer.current.style.clipPath = `circle(10% at 50% 50%)`
      const { width, height } = zoomContainer.current.getBoundingClientRect()
      mock.current.style.width = `${height * 2}px`
      mock.current.style.height = `${width * 2}px`
      mock.current.style.transform = `scale(${scale})`
      zoomContainer.current.style.transform = `scale(${scale})`
    } else {
      zoomContainer.current.style.cursor = 'none'
      document.body.style.touchAction = 'auto'
    }
  }

  const handleZoomMove = e => {
    if (zoom) {
      const { clientX, clientY } = e
      const { top, left } = container.current.getBoundingClientRect()
      const { width, height } = zoomContainer.current.getBoundingClientRect()
      let x, y
      if (e.touches) {
        x = (e.touches[0].clientX - left) / scale + width * 0.1
        y = (e.touches[0].clientY - top) / scale + height * 0.1
      } else {
        x = (clientX - left) / scale + width * 0.1
        y = (clientY - top) / scale + height * 0.1
      }
      requestAnimationFrame(() => {
        zoomContainer.current.style.clipPath = `circle(10% at ${x}px ${y}px)`
      })
    }
  }

  const zoomOff = () => {
    setZoom(false)
    document.body.style.touchAction = 'auto'
  }

  const loadCanvas = () => {
    setShowCanvas(true)
    // if (type === 'mousedown') setMouseDown(true)
    // if (type === 'mouseup') setMouseDown(false)
  }

  return (
    <div
      className="relative grid grid-cols-1 grid-rows-3 p-2 overflow-hidden"
      ref={container}
      onMouseMove={e => {
        if (zoom) {
          handleZoomMove(e)
          return
        }
        handleHover(e)
      }}
      onTouchMove={handleZoomMove}
      onClick={loadCanvas}
      onMouseUp={zoomOff}
      onTouchEnd={zoomOff}
    >
      <div
        className={`z-20 flex ${
          !iconsCenter
            ? 'justify-between row-start-1 p-2'
            : 'justify-center items-end row-start-3'
        } pointer-events-all col-span-2 col-start-1`}
      >
        {isNew ? (
          <div
            style={{
              backgroundImage:
                'linear-gradient(-45deg, rgb(232, 164, 187) 0%, rgb(16, 56, 252) 100%)'
            }}
            className="flex items-center justify-center w-24 h-24 rounded-full font-primary"
          >
            {useLiterals('new').toUpperCase()}
          </div>
        ) : (
          <div
            className={`cursor-pointer select-none ${
              hasImage360 ? 'visible' : 'invisible'
            }`}
          >
            <LazySimpleImage
              image={useMedia(iconColor !== 'white' ? '360_black' : '360')}
              alt="Viewer"
              imageClassName={`cursor-pointer z-10`}
            />
          </div>
        )}
        {!iconsCenter && (
          <Appear>
            {!Array.isArray(imageZoom) && Object.keys(imageZoom).length > 0 && !zoom && (
              <div className="cursor-pointer select-none" onClick={handleZoom}>
                <LazySimpleImage
                  image={useMedia(iconColor !== 'white' ? 'zoom_black' : 'zoom')}
                  alt="Zoom"
                  imageClassName={`w-8 cursor-pointer z-10`}
                />
              </div>
            )}
          </Appear>
        )}
      </div>

      <div
        className={`relative w-full h-full flex items-center justify-center col-start-1 row-span-4 row-start-1 transition transition-opacity duration-500 overflow-visible ${
          zoom ? 'opacity-1 z-10' : 'opacity-0 z-0'
        }`}
      >
        <div
          className="absolute top-0 bottom-0 w-full h-full bg-black"
          ref={zoomContainer}
        >
          <div className="absolute z-20 bg-black" ref={mock}></div>
          <LazyImage
            image={hasImage360 ? image360[currentImage] : imageZoom}
            alt={alt}
            className="z-50 h-full"
          />
        </div>
      </div>
      <div
        className={`flex items-center justify-center col-start-1 row-span-4 row-start-1 transition transition-opacity duration-500 ease-in-out relative`}
      >
        {hasImage360 && showCanvas && (
          <LazyImage className="invisible" image={image} alt={alt} />
        )}
        {hasImage360 && showCanvas ? (
          <div className="absolute top-0 left-0 w-full h-full">
            <Viewer360
              images={image360}
              parent={container}
              setCurrentImage={setCurrentImage}
              setLoadedCanvas={setLoadedCanvas}
              loadedCanvas={loadedCanvas}
            />
            {!loadedCanvas && (
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            )}
          </div>
        ) : (
          <LazyImage image={image} alt={alt} className="w-full h-full" />
        )}
        {/* {!isMobile && (
            <div
              className="absolute transition transition-opacity duration-250"
              style={{
                transform: `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0)`,
                zIndex: 99,
                opacity: mousePosition.opacity,
                pointerEvents: 'none',
                position: 'absolute'
              }}
            >
              <CustomCursor shrink={mouseDown} />
            </div>
          )} */}
      </div>
    </div>
  )
}

export default Viewer
