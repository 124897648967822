// @flow
import React, { useEffect, useState } from 'react'
import Container from './Container'
import Spacer from './Spacer'
import BlogHomePost from './BlogHomePost'
import Grid from "./Grid"
import { navigate } from 'gatsby'
import LazySimpleImage from './LazySimpleImage'
import { useLiterals, useMedia } from 'store/'


type Props = {
  title: string,
  items: Array<any>,
  posts: Array<any>,
  h1: string,
  page: number,
  numberOfPages: number,
}

const Blog6 = ({ items, title, posts, h1, page, numberOfPages}: Props) => {
  const handleChange = e => {
    const splitValue = e.target.value.split('/')
    const hiddenQuery = "?hiddenmodal=1"
    if (splitValue[splitValue.length - 1] === "blog") {
      navigate(`${e.target.value}${hiddenQuery}`)
    } else {
      navigate(`${e.target.value}`)
    }
  }
  const titleParts = title.split("${blog}")
  const currentUrl = typeof window !== 'undefined' ? window?.location.pathname : ''

  const handlePagination = (value) => {
    const urlParts = currentUrl.split("/")
    const hasPage = urlParts[urlParts.length - 1] == page
    const hasSearchQuery = urlParts[urlParts.length - 1] == "blog?hiddenmodal=1"
    let url = ""

    if (hasPage) {
      urlParts.splice(urlParts.length - 1, 1)
    }
    if (hasSearchQuery) {
      const lastPart = urlParts[urlParts.length - 1].split('?')
      urlParts.splice(urlParts.length - 1, 1)
      urlParts.push(lastPart[0])
    }

    if (value === "prev") {
      const prevPageNumber = `${page - 1}`
      if (prevPageNumber !== '1') {
        urlParts.push(prevPageNumber)
      }
      const prevUrl = urlParts.join('/')
      url = prevUrl
    } else if (value === "next") {
      const nextPageNumber = `${page + 1}`
      urlParts.push(nextPageNumber)
      const nextUrl =  urlParts.join('/')
      url = nextUrl
    } else {
      if (value !== '1') {
        urlParts.push(value)
      }
      const redirectUrl =  urlParts.join('/')
      url = redirectUrl
    }
    navigate(url)
  }

  return (
    <Spacer>
    <Container className="pb-8">
      <Grid>
        <div className='col-span-4 md:col-span-10 md:col-start-2'>
          <div className='w-full mb-5 bg-black h-1px' /> 
          <div className='flex gap-2 text-4xl'>
            <h4>{titleParts[0]}</h4><h1>{h1}</h1><h4>{titleParts[1]}</h4>  
          </div>
          <div className='w-full mt-5 bg-black h-1px' /> 
        </div>

        <div className="flex justify-end w-full col-span-4 my-2 md:col-span-10 md:col-start-2">
          <select value={items.find(item => item.url === currentUrl)?.url} className="w-full h-16 p-3 bg-white border border-black md:w-3/12 blog-selector text-text-3xl" onChange={handleChange}>
            {items.map(item => <option key={item.url} value={item.url}>{item.title}</option>)}
          </select>
        </div>
      
        <div className='flex flex-wrap justify-between col-span-4 md:col-span-10 md:col-start-2'>
          {posts.length > 0 && posts.map((item, index) => {          
            return (
              <div className={`w-full bg-nude mb-3 md:mb-6 ${(index % 10  === 0 || index % 10 === 6)  ? "md:w-66/100" : "md:w-32/100"}`} 
                key={item.id}
              >
                <BlogHomePost {...item} refactored />
              </div>
            )
          })}
        </div>
      </Grid>
        <div className='flex items-center justify-center'>
          <button className="flex items-center justify-center flex-shrink-0 w-12 h-12 transform rotate-180 cursor-pointer" onClick={() => handlePagination('prev')} disabled={page === 1}>
            <LazySimpleImage
              alt={useLiterals('previous')}
              image={useMedia('black-arrow')}
              />
          </button>
          <div className='flex'>
           {(()=>{
            let pagination =[]
              for(let i = 1; i <= numberOfPages; i++){
                pagination.push(<button onClick={() => handlePagination(`${i}`)} disabled={i === page}
                  className={`mx-2 w-8 h-8 pt-1 flex justify-center items-center ${page === i && "bg-grayFooter rounded-full text-white"}`}
                  >
                    {i}
                  </button>
                )
              }
              return pagination
           })()}
          </div>
          <button className="flex items-center justify-center flex-shrink-0 w-12 h-12 cursor-pointer" onClick={() => handlePagination('next')} disabled={page === numberOfPages}>
            <LazySimpleImage
              alt={useLiterals('previous')}
              image={useMedia('black-arrow')}
            />
          </button>
        </div>
    </Container>
  </Spacer>
  )
}

export default Blog6