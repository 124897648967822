// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Viewer from './Viewer'
import type { ApiImage } from '../types'

type Props = {
  image1: ApiImage,
  image2: ApiImage,
  image1zoom: ApiImage,
  image2zoom: ApiImage,
  menuId?: string,
  inGradient?: boolean,
  verticalSlug: string,
  whiteParentBG?: boolean,
}

const CategoryBlock12 = ({
  menuId,
  image1,
  image2,
  image1zoom,
  image2zoom,
  whiteParentBG = false
}: Props) => {
  return (
    <div data-menuid={menuId}>
      <Spacer top bottom smaller>
        <Container>
          <Grid>
            <div className={`col-span-4 col-start-1 mt-8 mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:col-span-10 md:col-start-4 md:mb-0`}>
              <div className="md:flex">
                <div className={`mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:mr-4 md:mb-0 md:flex-1`}>
                  <Viewer image={image1} imageZoom={image1zoom} image360={false} alt={""} />
                </div>
                <div className={`mb-12 ${whiteParentBG ? "text-black" : "text-white"} md:flex md:flex-col md:justify-center md:mb-0 md:flex-1`}>
                  <Viewer image={image2} imageZoom={image2zoom} image360={false} alt={""} />
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock12