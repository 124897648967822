// @flow
import React from 'react'
import Bubble from './Bubble'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Container from './Container'
import LazyImage from './LazyImage'
import Html from './Html'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  items: Array<Object>,
  texture: Object,
  texture1: Object,
  texture2: Object,
  text: string,
  text2: string
}

const getPosition = (index: number) => {
  switch (index) {
    case 0:
      return `col-start-3 col-span-2 md:col-start-8 md:col-span-4 md:row-start-1 md:row-span-3`
    case 1:
      return `col-start-1 col-span-4 row-start-3 row-span-4 px-8 md:px-0 md:col-start-3 md:col-span-6 md:row-start-3 md:row-span-3 z-10`
    case 2:
      return `col-start-1 col-span-2 row-start-6 row-span-3 md:col-start-1 md:col-span-4 md:row-start-5 md:z-10 md:row-span-3 z-10`
    case 3:
      return `col-start-1 col-span-4 md:col-start-5 md:col-span-7 md:row-start-6 md:row-span-4 z-10`
    case 4:
      return `col-start-1 col-span-3 md:col-start-1 md:col-span-7 md:row-start-8 md:row-span-3 z-10`
    case 5:
      return `col-start-2 col-span-3 mb-8 md:col-start-8 md:col-span-5 md:row-start-10 md:z-10 md:mb-8 z-10`
  }
}

const DesignJet2 = ({ items, texture, texture2, text, text2 }: Props) => {
  return (
    <Spacer top className="relative">
      <Appear className="absolute md:w-264 md:h-264 md:-mt-64 md:-right-25p md:top-35p lg:w-264 lg:h-264 lg:top-25p lg:-right-35p">
        <Bubble className="w-full h-full" src={makeAssetUrl({ asset: texture, isImage: true })} />
      </Appear>
      <Container className="relative overflow-hidden">
        <Grid className="relative w-full overflow-visible">
          <Appear className="col-span-4 row-start-1 font-light md:col-start-2 md:col-span-4 text-big1">
            <Html text={text} />
          </Appear>
          {items.map((item, index) => {
            return (
              <Appear key={index} className={getPosition(index)}>
                <LazyImage alt={text} image={item.image} />
              </Appear>
            )
          })}
          {text2 && (
            <Appear className="z-10 col-span-4 col-start-1 font-light text-smaller md:col-start-2 md:col-span-10">
              <Html text={text2} />
            </Appear>
          )}
        </Grid>
        <Appear className="h-120 w-120 -right-100p top-30p absolute md:h-80 md:w-80 md:-left-10p lg:-left-30p md:bottom-5p">
          <Bubble className="w-full h-full" src={makeAssetUrl({ asset:texture2, isImage: true})} />
        </Appear>
      </Container>
    </Spacer>
  )
}

export default DesignJet2
