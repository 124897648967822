// @flow

import React from 'react'
import LazyImage from './LazyImage'
import { Link } from 'gatsby'
import type { ApiImage } from '../types'
import Container from './Container'
import Grid from './Grid'

type ItemType = {
  id: string,
  url: string,
  image: ApiImage,
  teaser: string,
  bgColor: string,
  fontColor: string,
  title: string,
  category: string,
}

type Props = {
  items: Array<ItemType>,
}

const RelatedToPosts = ({ items }: Props): React$Element<*> => {
  const hasItems = items?.length > 0
  const firstItem = hasItems ? items?.[0] : null
  const nextItems = hasItems ? items?.slice(1) : []

  return (
    <Container>
      <Grid>
        <section className="mt-menu-filters-mobile md:mt-menu-filters-desktop col-span-12 col-start-1 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 justify-center w-full">
          {hasItems ? (
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
              {firstItem ? (
                <Link
                  to={firstItem.url}
                  className="relative bg-grayLight min-h-365 md:min-h-600 zoom-parent"
                >
                  <div
                    className="absolute bottom-0 left-0 flex flex-col justify-end w-full h-full gap-4 pb-4 text-white"
                    style={{
                      zIndex: 10,
                      background:
                        'linear-gradient(180deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0.8) 100%)',
                    }}
                  >
                    <div className="flex flex-col gap-2 pt-5">
                      <hr style={{ color: 'white' }} />
                      <p className="pl-5 text-16px md:text-18pxs">
                        {firstItem.category}
                      </p>
                      <hr style={{ color: 'white' }} />
                    </div>
                    <h4
                      className="pl-5 overflow-hidden leading-tight text-26px md:text-48px"
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '2',
                      }}
                    >
                      {firstItem.title}
                    </h4>
                  </div>
                  <LazyImage
                    image={firstItem.image}
                    alt={firstItem.title ?? ''}
                    cover
                    className="overflow-hidden"
                    imageClassName="zoom-child"
                  />
                </Link>
              ) : null}
              {nextItems.length > 0 ? (
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:grid-rows-2">
                  {nextItems.map(({ image, category, title, url, id }) => (
                    <Link
                      to={url}
                      key={id}
                      className="flex flex-col gap-4 zoom-parent"
                    >
                      {image && Object.keys(image).length > 0 ? (
                        <LazyImage
                          image={image}
                          alt={title}
                          cover
                          className="h-10rem md:h-1/2 overflow-hidden"
                          imageClassName="zoom-child"
                        />
                      ) : (
                        <div className="w-full bg-grayLight h-1/2"></div>
                      )}
                      <div className="flex flex-col gap-1">
                        <p className="text-16px md:text-18px">{category}</p>
                        <hr />
                        <h4
                          className="overflow-hidden leading-snug text-18px md:text-24px"
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '3',
                          }}
                        >
                          {title}
                        </h4>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </section>
      </Grid>
    </Container>
  )
}

export default RelatedToPosts
