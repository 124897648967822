// @flow
import React, { useEffect, useState } from 'react'
import type { ApiImage } from '../types'
import Button from "./Button"
import Grid from "./Grid"
import Markdown from "./Markdown"
import LazyImage from './LazyImage'
import Appear from './Appear'
import makeAssetUrl from '../lib/makeAssetUrl'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  supertitle: string,
  title: string,
  subtitle: string,
  text: string,
  image: ApiImage,
  cta1Text: string,
  cta1Url: string,
  cta2Text: string,
  cta2Url: string,
  backgroundImage: ApiImage,
  video: string,
  videoMobile: string,
}

const PrinterHero = ({
  supertitle,
  title,
  subtitle,
  text,
  image,
  cta1Text,
  cta1Url,
  cta2Text,
  cta2Url,
  backgroundImage,
  video,
  videoMobile,
}: Props) => {
  const isMobile = useIsMobile()
  const [videoFile, setVideoFile] = useState()

  useEffect(() => {
    if (video || videoMobile) {
      if (isMobile) {
        const file = videoMobile ? makeAssetUrl({ asset: videoMobile, isImage: false }) : makeAssetUrl({ asset: video, isImage: false })
        setVideoFile(file)
      } else {
        const file = makeAssetUrl({ asset: video, isImage: false })
        setVideoFile(file)
      }
    }
  }, [isMobile])

  return (
    <Grid className="h-max md:max-h-screen mt-menu-mobile md:mt-menu-desktop md:min-h-60vh lg:min-h-75vh" noGap>
      <div className="flex flex-col items-start justify-between col-span-4 col-start-1 pt-10 pb-8 sm:col-span-2 md:col-span-4">
        <Appear className='w-full'>
          <div className="px-4 leading-tight uppercase text-20px md:text-30px">{supertitle}</div>
          <h1 className="px-4 py-4 my-4 leading-tight border-t border-b border-black text-40px md:text-50px">{title}</h1>
          <h3 className="px-4 mb-12 leading-tight text-20px md:text-30px md:mb-4">{subtitle}</h3>
        </Appear>
        <Appear className='px-4'>
          <Markdown text={text} className="text-base md:text-20px" />
          <div className="flex items-center mt-8 sm:justify-between">
            <div className="mr-2 sm:flex-1">
              <Button title={cta1Text} href={cta1Url} style="blackOutlined" className='w-full' textClasses='text-center'/>
            </div>
            <div className="ml-2 sm:flex-1">
              <Button title={cta2Text} href={cta2Url} style="blackOutlined" className='w-full' textClasses='text-center'/>
            </div>
          </div>
        </Appear>
      </div>
      <div className="col-span-4 col-start-1 overflow-hidden bg-lightBlue sm:col-span-2 sm:col-start-3 md:col-span-8 md:col-start-5">
        <div className='relative w-full h-full md:max-h-screen'>
          {videoFile ? (
            <div className="sm:absolute sm:inset-0 sm:flex sm:items-stretch sm:justify-center">
              <video
                key={isMobile ? 'mobile' : 'desktop'}
                autoPlay
                controls={false}
                muted
                height="100%"
                width="100%"
                className="sm:object-cover"
              >
                <source
                  src={videoFile}
                  type="video/mp4"
                />
              </video>
            </div>
          ) : (
            <>
              <LazyImage image={backgroundImage} cover />
              <div className='absolute inset-0 flex items-center justify-center'>
                <div className='w-2/3'>
                  <Appear>
                    <LazyImage image={image} contain />
                  </Appear>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Grid>
  )
}

export default PrinterHero