// @flow
import React from 'react'
import LinkHP from './LinkHP'
import LinkExternalHP from './LinkExternalHP'
import LazySimpleImage from './LazySimpleImage'
import { useMedia } from 'store/'


type Props = {
  href?: string,
  to?: string,
  title: string,
  color: string,
  callback?: Function,
  target?: number,
  dataLayer?: Object,
  smallArrow?: number,
  draggable?: boolean,
}

const SimpleButton = ({ dataLayer, color, title, to, href, callback, draggable = false, target = 0, smallArrow = 0 }: Props): React$Element<"div"> => {
  const linkClassName = `border-transparent hover:border-${color} border-b`

  let arrowImage = useMedia('blue-arrow')
  if (color === 'black') arrowImage = useMedia('black-arrow')
  if (color === 'white') arrowImage = useMedia('white-arrow-right')

  return (
    <div className={`text-${color} inline-flex items-center font-light`}>
      <span className="mr-2 -mt-px">
        {href && href.length > 0 ? (
          <LinkExternalHP draggable={draggable} onClick={callback} className={linkClassName} href={(href === 'gated') ? void(0) : href} dataLayer={dataLayer} target={(target) ? "_blank" : ""}>
            {title}
          </LinkExternalHP>
        ) : (
          <LinkHP draggable={draggable} className={linkClassName} to={to} dataLayer={dataLayer}>
            {title}
          </LinkHP>
        )}
      </span>
      <LazySimpleImage
        image={arrowImage}
        alt={title}
        imageClassName={`${(color === 'white' || smallArrow === 1) ? 'h-2' : ''}`}
      />
    </div>
  )
}

export default SimpleButton
