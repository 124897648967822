// @flow

import React from 'react'
import Spacer from './Spacer'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import Html from './Html'
import type { ApiImage } from 'types/'
import LazyImage from './LazyImage'
import Button from './Button'


type Props = {
    title: string,
    text: string,
    text2: string,
    ctaText?: string,
    ctaUrl?: string, 
    titleColor: string,
    imageToRight?: boolean,
    image: ApiImage,
}

const StoryBlock8 = ({ title, text, text2, ctaText, ctaUrl, titleColor, imageToRight=false, image }: Props) => {
  return (
    <Spacer>
      <Container className="md:pt-10 md:pb-10 pt-4 pb-4">
        <Grid>
            <div className='col-span-4 md:col-start-3 md:col-span-9'>
                <div className='flex flex-col md:flex-row'>
                    <div className={`md:w-55/100 ${imageToRight ? "order-2" : "md:order-1"}`}>
                        <LazyImage cover image={image} />
                    </div>
                    <div className={`md:w-45/100 min-h-full bg-grayLight ${imageToRight ? "order-1" : "md:order-2"}`}>
                        <h2 className='p-4 text-20px' style={{ backgroundColor:`#${titleColor}`}}>{title}</h2>
                        <div className='p-4'>
                            <div className='text-20px leading-6'>
                                <Html text={text} />
                            </div>
                            {text2 &&(
                                <div className='text-sm leading-5 border-t pt-4  border-black'>
                                    <Html text={text2} />
                                </div>
                            )}
                            </div>
                            {ctaUrl && (
                            <div className='p-4 flex justify-end'>
                                <Button
                                    style="black"
                                    title={ctaText}
                                    href={ctaUrl}
                                    />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Grid>
      </Container>
    </Spacer>
  )
}

export default StoryBlock8
