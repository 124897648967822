// @flow
import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Spacer from './Spacer'
import Appear from './Appear'
import Html from './Html'

type Props = {
  nav?: {prev: string, next: string},
  title: string,
  menuId?: string,
  whiteParentBG: boolean,
  backgroundColor?: string
}



const CategoryBlock3 = ({ nav, menuId, title,backgroundColor, whiteParentBG = true }: Props) => {

  switch (backgroundColor) {
    case "F19880":
      backgroundColor = "bg-salmon23"
      break
      }
  return (
    <div data-menuid={menuId}>
      <Spacer className={`${backgroundColor && backgroundColor} py-2`} top={(typeof nav !== "undefined" && nav.prev !== 'CategoryBlock2')} bottom={(typeof nav !== "undefined" && nav.next !== 'CategoryBlock8')} smaller>
        <Container>
          <Grid>
          <div className={`col-span-4 col-start-1 mb-12 text-black md:flex md:flex-col md:justify-center md:col-span-6 md:col-start-4 md:mb-0`}>
              <h3 className="font-light text-big2"><Appear><Html text={title} /></Appear></h3>
            </div>
          </Grid>
        </Container>
      </Spacer>
    </div>
  )
}

export default CategoryBlock3